/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function () {
    'use strict';

    angular.module('meetingMinutes.main.profile')
        .controller('ProfileTasksController', ProfileTasksController);

    ProfileTasksController.$inject = [
        '$rootScope', '$scope', 'userResource', 'ngJcropConfig', 'knowledgeObjectResource', 'toastr', 'googleCalendarResource', 'dictionaries', 'numbers', 'msgraphCalendarResource', 'tokenResource'
    ];

    function ProfileTasksController($rootScope,
        $scope,
        userResource,
        ngJcropConfig,
        knowledgeObjectResource,
        toastr,
        googleCalendarResource,
        dictionaries,
        numbers,
        msgraphCalendarResource,
        tokenResource
    ) {

        $scope.options = {
            skin: {
                type: 'tron',
                width: 2,
                color: '#494B52',
                spaceWidth: 1
            },
            barColor: '#494B52',
            trackWidth: 2,
            barWidth: 10,
            textColor: '#494B52',
            step: 1,
            min: 0,
            max: 0,
            size: 50
        };

        $scope.optionsTimeBuffer = {
            skin: {
                type: 'tron',
                width: 2,
                color: '#494B52',
                spaceWidth: 1
            },
            barColor: '#494B52',
            trackWidth: 2,
            barWidth: 10,
            textColor: '#494B52',
            step: 1,
            min: 0,
            max: 100,
            size: 50
        };

        $scope.utilizationOptions = {
            skin: {
                type: 'tron',
                width: 2,
                color: '#494B52',
                spaceWidth: 1
            },
            barColor: '#494B52',
            trackWidth: 2,
            barWidth: 10,
            textColor: '#494B52',
            step: 1,
            max: 480,
            size: 50
        };

        $scope.msg = {
            calendarList: null,
            creatingCalendar: false,
            formData: {},
            isLoading: true,
            synchronized: null,
            status: 0,
        };

        $scope.ggl = {
            calendarList: null,
            creatingCalendar: false,
            formData: {},
            isLoading: true,
            synchronized: null
        };

        $scope.deleteRefreshToken = function() {
            tokenResource.removeTokenCurrentUser()
                .then(function() {
                    toastr.success("Logout successful");
                });
        }

        // $scope.calendarList = null;
        // $scope.creatingCalendar = false;
        // $scope.formData = {};
        // $scope.isLoading = true;
        // $scope.synchronized = null;

        $rootScope.$context.$set('/profile', $scope);

        userResource.getSettings().then(function (response) {
            $scope.settings = response;
            $scope.settings.ready = true;
        });

        userResource.getTimezones().then((response) => {
            $scope.timezones = response;
        });


        $scope.updateAllSettings = function () {
            userResource.setSettings($scope.settings).then(function () {
                toastr.success("Settings updated");
            });
        };


        $scope.updateProfile = function () {
            userResource.updateSignedUser($scope.formData.profile).then(function () {
                toastr.success("Profile updated");
            });
        };

        $scope.connectWithCalendar = function () {
            // $window.location.href = "api/calendar/connect";
            googleCalendarResource.connectWithGoogleCalendar()
                .then(function successCallback(response) {
                        window.location.href = response;
                        // googleCalendarResource.getCalendarsList()
                        //     .then(function successCallback(response) {
                        //         $scope.calendarList = response;
                        //
                        //     }, function errorCallback(response) {
                        //
                        //     });
                    },
                    function errorCallback(response) {
                        $scope.ggl.isLoading = false;
                        $scope.ggl.synchronized = false;

                    });
        };

        $scope.gglDisconnectWithCalendar = function () {
            googleCalendarResource.disconnectWithGoogleCalendar()
                .then(function successCallback(response) {
                        $scope.ggl.synchronized = !response;
                        $scope.ggl.connected = false;
                    },
                    function errorCallback(response) {
                        $scope.ggl.isLoading = false;
                        $scope.ggl.connected = true;
                    });
        };

        $scope.gglSelectCalendar = function () {
            if ($scope.ggl.formData.selectedCalendar != null && $scope.ggl.formData.selectedCalendar !== undefined) {
                $scope.ggl.isLoading = true;
                googleCalendarResource.selectCalendar(angular.toJson($scope.ggl.formData.selectedCalendar));
                googleCalendarResource.synchronizeActivities(angular.toJson($scope.ggl.formData.selectedCalendar))
                    .then(function successCallback(response) {
                            $scope.ggl.isLoading = !response;
                            $scope.ggl.synchronized = response;
                            $scope.ggl.connected = true;
                        },
                        function errorCallback(response) {
                            $scope.ggl.isLoading = false;
                            $scope.ggl.synchronized = false;
                            $scope.ggl.connected = true;
                            toastr.error("An error has occurred during activity synchronization.");
                        });
            }
        };

        $scope.gglCreateCalendar = function () {
            $scope.ggl.creatingCalendar = true;
            var calendarName = $scope.ggl.formData.newCalendarName;
            if (calendarName == null || calendarName === undefined || calendarName.length === 0) return;

            var calendar = {
                calendarName: calendarName
            }
            googleCalendarResource.createNewCalendar(calendar, numbers.uuidv4())
                .then(function successCallback(response) {
                        toastr.success("New calendar has been created! Check calendars list.");

                        googleCalendarResource.getCalendarsList()
                            .then(function successCallback(response) {
                                $scope.ggl.creatingCalendar = false;
                                $scope.ggl.calendarList = response;

                            }, function errorCallback(response) {
                                $scope.ggl.creatingCalendar = false;
                            });
                    },
                    function errorCallback(response) {
                        $scope.ggl.creatingCalendar = false;
                        toastr.error("Cannot create new calendar. An error has occurred.")
                    });
        };

        function handleError(error) {
            $scope.msg.isLoading = false;
            $scope.ggl.isLoading = false;
            toastr.error("An error has occurred: " + error.data);
        }

        $scope.msgConnectWithCalendar = function () {
            msgraphCalendarResource.authorizeAgainstMsg().then(function (url) {
                window.location = url;
            }, handleError);
        };

        $scope.msgCreateCalendar = function () {
            msgraphCalendarResource.createCalendar($scope.msg.formData.newMsgCalendarName).then(function () {
                toastr.success("New calendar has been created! Check calendars list.");
                loadMsgCalendars();
            }, handleError);

        };

        $scope.msgSelectCalendar = function () {
            $scope.msg.isLoading = true;
            msgraphCalendarResource.selectCalendar($scope.msg.formData.selectedCalendar).then(function (status) {
                toastr.success("Calendar has been selected!");
                loadMsgCalendarStatus();
            }, function (error) {
                loadMsgCalendarStatus();
                handleError(error);
            });
        };

        $scope.msgDisconnectWithCalendar = function () {
            msgraphCalendarResource.disconnectFromCalendar().then(function (status) {
                toastr.success("You have disconnected from Microsoft Office 365 Calendar!");
                loadMsgCalendarStatus();
            }, handleError);
        };

        function loadMsgCalendarStatus() {
            msgraphCalendarResource.checkCalendarStatus().then(function (status) {
                $scope.msg.isLoading = false;

                if (status === 'NotConnected') {
                    $scope.msg.synchronized = false;
                    $scope.msg.connected = false;
                }
                if (status === 'ConnectedNoCalendar') {
                    $scope.msg.synchronized = false;
                    $scope.msg.connected = true;

                    $scope.msg.isLoading = true;

                    loadMsgCalendars();
                }
                if (status === 'ConnectedInitialSync' || status === 'ConnectedSubscribed') {
                    $scope.msg.synchronized = true;
                    $scope.msg.connected = true;
                    $scope.msg.isLoading = false;
                }
            }, function (data) {
                $scope.msg.isLoading = false;
                $scope.msg.connected = false;
                $scope.msg.synchronized = false;
                handleError(data);
            });
        }

        function loadMsgCalendars() {
            msgraphCalendarResource.getCalendarsList().then(function (calendarList) {
                $scope.msg.calendarList = calendarList.value;
                $scope.msg.isLoading = false;
            }, handleError)
        }

        function loadGglCalendarStatus() {
            googleCalendarResource.checkIfUserCalendarSync()
                .then(function successCallback(response) {
                        $scope.ggl.isLoading = false;
                        if (dictionaries.google().synchronizationStatus[response].status === "None") {
                            $scope.ggl.synchronized = false;
                        }
                        if (dictionaries.google().synchronizationStatus[response].status === "Connected") {
                            $scope.ggl.synchronized = false;
                            $scope.ggl.connected = true;

                            $scope.ggl.isLoading = true;

                            googleCalendarResource.getCalendarsList()
                                .then(function successCallback(response) {
                                    $scope.ggl.isLoading = false;
                                    $scope.ggl.creatingCalendar = false;
                                    $scope.ggl.calendarList = response;

                                }, function errorCallback(response) {
                                    $scope.ggl.creatingCalendar = false;
                                    $scope.ggl.isLoading = false;
                                });
                        }
                        if (dictionaries.google().synchronizationStatus[response].status === "Synchronized") {
                            $scope.ggl.synchronized = true;
                        }
                    },
                    function errorCallback(response) {
                        $scope.ggl.isLoading = false;
                        $scope.ggl.synchronized = false;
                    });
        }

        loadMsgCalendarStatus();
        loadGglCalendarStatus();



    }

})();
