/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main')
        .controller('MainController', MainController);

    MainController.$inject = [
        'accessPopup', 'removePopup', '$cookies', '$cookieStore', '$rootScope', '$scope', '$state',
        'authenticationResource', 'workgroupResource', 'userResource', 'noteResource', 'meetingResource',
        'knowledgeObjectResource', '$location', 'tagsResource',
        // 'googleCalendarResource'
    ];

    function MainController(accessPopup,
        removePopup,
        $cookies,
        $cookieStore,
        $rootScope,
        $scope,
        $state,
        authenticationResource,
        workgroupResource,
        userResource,
        noteResource,
        meetingResource,
        knowledgeObjectResource,
        $location,
        tagsResource,
    // googleCalendarResource
    ) {

        activate();

        $rootScope.getTagDefinition = function(item) {
            var res = null;
            angular.forEach($rootScope.authentication.tagTypes,
                function(tagType, key) {
                    if (tagType.constName.toLowerCase() == item.toLowerCase()) {
                        res = tagType;
                    }
                });
            return res;
        };

        userResource.getApplicationSettings().then(function(response) {
            angular.globalSettings.Application = response;
        });

        $rootScope.crmCtx = {
            opportunity: $cookieStore.get('crmCtx')
        };

        $rootScope.setOpportunityContext = function(item) {
            var dto = {
                id: item.id,
                // title: item.knowledgeObject.title,
                completeness: item.completeness,
                // customer: item.person.knowledgeObject.title
            };
            if(item.name)
                dto.title = item.name;
            else if(item.knowledgeObject)
                dto.title = item.knowledgeObject.title;

            if(item.person.contactPerson)
                dto.customer = item.person.contactPerson;
            else if(item.person.knowledgeObject)
                dto.customer = item.person.company;

            $cookieStore.put('crmCtx', dto);
            $rootScope.crmCtx.opportunity = dto;
        };

        $rootScope.setEntryMessage = function(item) {
            $cookieStore.put('message', item);
        };

        $rootScope.cutEntryMessage = function() {
            var message = $cookieStore.get('message');
            $cookieStore.put('message', null);
            return message;
        };

        $scope.repository = {};

        $rootScope.removePopup = function(scope, item, callback) {
            removePopup.open(scope, item, callback);
        };

        $rootScope.gotoDashboard = function() {
            $location.path('/meetings');
        };

        $rootScope.sharePopup = function(scope, item, callback) {
            accessPopup.open(scope, item, callback);
        };

        // googleCalendarResource.checkIfUserCalendarSync()
        //     .then(function successCallback(response) {
        //             if(response === true) {
        //                 var calendar = {id: null};
        //                 googleCalendarResource.synchronizeActivities(calendar)
        //                     .then(function successCallback(response) {
        //
        //                         },
        //                         function errorCallback(response) {
        //                             $scope.isLoading = false;
        //                             $scope.synchronized = false;
        //                             toastr.error("An error has occurred during activity synchronization.")
        //                         });
        //             }
        //         },
        //         function errorCallback(response) {
        //
        //
        //         });

        var getPersonDetails = function(tag) {
            if($rootScope.authentication.personTags)
                for (var i = 0; i < $rootScope.authentication.personTags.length; i++) {
                    if (tag == 'p:' + $rootScope.authentication.personTags[i].id) {
                        return $rootScope.authentication.personTags[i].knowledgeObject.title;
                    }
                }

            return null;
        };

        var getActionDetails = function(tag) {
            console.error(tag);
            console.error($rootScope.authentication.actions);
            if($rootScope.authentication.actions)
                for (var i = 0; i < $rootScope.authentication.actions.length; i++) {
                    if (tag == 't:a' + $rootScope.authentication.actions[i].id) {
                        return $rootScope.authentication.actions[i].label;
                    }
                }

            return null;
        };

        var getActionIcon = function(tag) {
            if($rootScope.authentication.actions)
                for (var i = 0; i < $rootScope.authentication.actions.length; i++) {
                    if (tag == 't:a' + $rootScope.authentication.actions[i].id) {
                        return $rootScope.authentication.actions[i].icon;
                    }
                }

            return null;
        };

        var getEmotionDetails = function(tag) {
            if($rootScope.authentication.emotions)
                for (var i = 0; i < $rootScope.authentication.emotions.length; i++) {
                    if (tag == 't:e' + $rootScope.authentication.emotions[i].id) {
                        return $rootScope.authentication.emotions[i].label;
                    }
                }

            return null;
        };

        var getEmotionIcon = function(tag) {
            if($rootScope.authentication.emotions)
                for (var i = 0; i < $rootScope.authentication.emotions.length; i++) {
                    if (tag == 't:e' + $rootScope.authentication.emotions[i].id) {
                        return $rootScope.authentication.emotions[i].icon;
                    }
                }

            return null;
        };


        var getUserDetails = function(tag) {
            if($rootScope.authentication.userTags)
                for (var i = 0; i < $rootScope.authentication.userTags.length; i++) {
                    if (tag == 'u:' + $rootScope.authentication.userTags[i].id) {
                        return $rootScope.authentication.userTags[i].fullName;
                    }
                }

            return null;
        };


        $rootScope.converted = function(tag) {
            var tagEx = {
                tag: tag,
                isSpecial: false
            };
            if(tag == null || tag === undefined) return;
            if (tag.startsWith('u:')) {
                tagEx.loading = true;
                tagEx.facade = null;
                tagEx.isSpecial = true;

                tagEx.translatedAvatarUrl = tagsResource.translateTag(tag);
                tagEx.details = getUserDetails(tag);

                return tagEx;
            }
            if (tag.startsWith('p:')) {
                tagEx.loading = true;
                tagEx.isSpecial = true;

                tagEx.translatedAvatarUrl = tagsResource.translateTag(tag);
                tagEx.details = getPersonDetails(tag);
                return tagEx;

            }
            if (tag.startsWith('t:ai')) {
                tagEx.loading = true;
                tagEx.isSpecial = false;

                tagEx.tag = 'action-item';

                return tagEx;
            }
            if (tag.startsWith('t:a')) {
                tagEx.loading = true;
                tagEx.isSpecial = false;

                tagEx.details = getActionDetails(tag);
                tagEx.translatedAvatarUrl = getActionIcon(tag);
                return tagEx;

            }
            if (tag.startsWith('t:e')) {
                tagEx.loading = true;
                tagEx.isSpecial = false;

                tagEx.details = getEmotionDetails(tag);
                tagEx.translatedAvatarUrl = getEmotionIcon(tag);

                return tagEx;
            }

            return tagEx;
        }

        //Instant meeting can be created through to-do or through instant-meeting button on header. To-Do is saved in purpose of
        //preparing meeting due to the data saved in To-Do (such as title and descriptionTxt). Other tipes have no implicite influence
        //on created meeting.
        $rootScope.createNewMeeting = function(prevMeetingId, tag, backUrl, mode, object) {
            if(mode === 'instant'){

            }
            if(mode === 'withToDo'){
                $rootScope.meetingToDo = object;
            }
            mode = 'instant';

            meetingResource.planNewMeeting(prevMeetingId, tag, backUrl, mode);
        }

        $rootScope.pinNotesCount = 0;

        $rootScope.setPinNotesCount = function() {
            var request = {
                from: 0,
                to: 100,
                take: 100,
                tagFilter: []
            };
            request.tagFilter.push('pin-note');

            noteResource.getNotes(request).then(function(result) {
                $rootScope.pinNotesCount = result.results.length;
            });
        }

        $rootScope.contextTags = {};
        $rootScope.contextTags.tags = [];

        $rootScope.formData = {};

        $rootScope.addToContext = function(tag) {
            if ($rootScope.isInContext(tag) === false)
                $rootScope.contextTags.tags.push(tag);
        };

        $rootScope.removeFromContext = function(tag) {
            var newContext = [];
            for (var i = 0; i < $rootScope.contextTags.tags.length; i++) {
                if ($rootScope.contextTags.tags[i].toLowerCase() !== tag.toLowerCase()) {
                    newContext.push($rootScope.contextTags.tags[i]);
                }
            }
            $rootScope.contextTags.tags = newContext;
        }

        $rootScope.isInContext = function(tag) {
            for (var i = 0; i < $rootScope.contextTags.tags.length; i++) {
                if ($rootScope.contextTags.tags[i].toLowerCase() === tag.toLowerCase()) {
                    return true;
                }
            }
            return false;
        }

        $scope.signOut = function() {
            localStorage.clear();
            sessionStorage.clear();
            
            var cookies = document.cookie.split(";");
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].trim();
                var eqPos = cookie.indexOf("=");
                var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/client";
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/Client";
            }

            authenticationResource.signOut();
            window.location.href = '/client/index#/sign-in';
        }

        function _menuLevel() {
            var counter = 0;
            var path = $rootScope.location.path();
            for (var i = 0; i < path.length; i++) {
                if (path[i] == '/') {
                    counter++;
                }
            }
            return counter;
        }

        $rootScope.uniquePush = function(item, array) {
            for (var i = 0; i < array.length; i++) {
                if (array[i].id && array[i].id == item.id) {
                    return;
                }

                if (!array[i].id && array[i] == item) {
                    return
                }
            }
            array.push(item);
        }

        $rootScope.uniquePushByText = function(item, array) {
            for (var i = 0; i < array.length; i++) {
                if (array[i].text && array[i].text == item.text) {
                    return;
                }

                if (!array[i].id && array[i] == item) {
                    return
                }
            }
            array.push(item);
        }


        function createCtx(_ctx, _obj) {
            $rootScope.$context = {
                $selected: '',
                $weekNo: 0,
                $object: _obj,
                mainButtonsVisible: false,
                headerVisible: true,
                $set: function(_ctx) {
                    $rootScope.$context.$selected = _ctx;
                    $rootScope.menuLevel = _menuLevel();
                    $rootScope.$context.meetings.cl.menulink = 'menu_link';
                    $rootScope.$context.knowledge.cl.menulink = 'menu_link';
                    $rootScope.$context.reports.cl.menulink = 'menu_link';
                    $rootScope.$context.workgroup.cl.menulink = 'menu_link';
                    $rootScope.$context.dashboard.cl.menulink = 'menu_link';
                    $rootScope.$context.pinboard.cl.menulink = 'menu_link';
                    $rootScope.$context.crm.cl.menulink = 'menu_link';
                    $rootScope.$context.linkedin.cl.menulink = 'menu_link';
                    $rootScope.$context.mainButtonsVisible = false;
                    $rootScope.$context.headerVisible = true;


                    switch (_ctx) {
                    case '/library':
                        $rootScope.$context.knowledge.cl.menulink = 'menu_link_selected';
                        $rootScope.$context.headerVisible = false;
                        break;
                    case '/meetings':
                        $rootScope.$context.meetings.cl.menulink = 'menu_link_selected';
                        $rootScope.$context.headerVisible = false;
                        break;
                    case '/meetings/plan':
                        $rootScope.$context.headerVisible = false;
                        break;
                    case '/meetings/view':
                        $rootScope.$context.headerVisible = false;
                        break;
                    case '/meetings/timeline':
                        $rootScope.$context.headerVisible = false;
                        break;
                    case '/reports':
                        $rootScope.$context.reports.cl.menulink = 'menu_link_selected';
                        break;
                    case '/workgroup':
                        $rootScope.$context.workgroup.cl.menulink = 'menu_link_selected';
                        $rootScope.$context.headerVisible = false;
                        break;
                    case '/pinboard':
                        $rootScope.$context.pinboard.cl.menulink = 'menu_link_selected';
                        $rootScope.$context.headerVisible = false;
                        break;
                    case '/user/create':
                        $rootScope.$context.headerVisible = false;
                        break;
                    case '/dashboard':
                        $rootScope.$context.dashboard.cl.menulink = 'menu_link_selected';
                        $rootScope.$context.headerVisible = false;
                        break;
                    case '/profile':
                        $rootScope.$context.profile.cl.menulink = 'menu_link_selected';
                        $rootScope.$context.headerVisible = false;
                        break;
                    case '/crm':
                        $rootScope.$context.crm.cl.menulink = 'menu_link_selected';
                        $rootScope.$context.headerVisible = false;
                        break;
                    case '/crm':
                        $rootScope.$context.linkedin.cl.menulink = 'menu_link_selected';
                        $rootScope.$context.headerVisible = false;
                        break;
                    default:
                        break;
                    }
                    return $rootScope.$context;
                },
                global: {
                    actions:
                    {
                        back: {
                            exec: function() { window.history.back(); },
                            visible: function() { return true; }
                        },
                        access: {
                            exec: function() {},
                            visible: function() {}
                        },
                        publish: {
                            exec: function() {
                                knowledgeObjectResource.publish($rootScope.$context.$object.knowledgeObject.id).then(
                                    function() {
                                        $rootScope.$context.$object.knowledgeObject.status = 1;
                                        window.history.back();
                                    })
                            },
                            visible: function() { return true; }
                        },
                        draft: function() {
                            knowledgeObjectResource.draft($rootScope.$context.$object.knowledgeObject.id).then(
                                function() {
                                    $rootScope.$context.$object.knowledgeObject.status = 0;
                                    window.history.back();
                                });
                        },
                        notes: {
                            exec: function() { $state.go('main.library/list'); },
                            visible: function() { return true; }
                        },
                    }
                },
                planMeeting: {
                    actions:
                    {
                        go: function() { $state.go('main.planMeeting', { meetingId: null }); },
                        publish: function() { meetingResource.publish($rootScope.$context.$object.id); },
                        remove: function() { meetingResource.remove($rootScope.$context.$object.id); },
                        draft: function() { meetingResource.draft($rootScope.$context.$object.id); },
                        save: function() { meetingResource.save($rootScope.$context.$object); }
                    },
                    availabilityCondition: function() {
                        return $rootScope.$context.$selected == "planMeeting";
                    }
                },
                viewMeeting: {
                    actions:
                    {
                        go: function() {
                            $state.go('main.meetingDetails', { meetingId: $rootScope.$context.$object.id });
                        },
                        edit: function() {
                            $state.go('main.planMeeting', { meetingId: $rootScope.$context.$object.id });
                        },
                        create: function() { $state.go('main.planMeeting', { meetingId: null }); },
                    },
                    availabilityCondition: function() {
                        return $rootScope.$context.$selected == "viewMeeting";
                    },
                    enableShareButton: false,
                    enableEditButton: false,
                    enableStartButton: false,
                    enableJoinButton: false,
                    enableCancelButton: false,
                    enableAbandonButton: false
                },
                note: {
                    actions:
                    {
                        create:
                        {
                            exec: function() {

                                $state.go('main.note/create', { id: null });
                            },
                            visible: function() {
                                return true;
                            }
                        },
                        edit:
                        {
                            exec: function() {

                                $state.go('main.note/edit', { id: $rootScope.$context.$object.id });
                            },
                            visible: function() {
                                return $rootScope.$context.$object.id != null;
                            }
                        },
                        publish:
                        {
                            exec: function() {
                                $rootScope.$context.$object.knowledgeObject.status = 1;
                                noteResource.saveNote($rootScope.$context.$object.id, $rootScope.$context.$object);
                            },
                            visible: function() {
                                $rootScope.$context.$object.knowledgeObject.status == 1;
                            }
                        },
                        remove: {
                            exec: function() {}
                        },
                        draft: {
                            exec: function() {
                                $rootScope.$context.$object.knowledgeObject.status = 1;
                                noteResource.saveNote($rootScope.$context.$object.id, $rootScope.$context.$object);
                            },
                            visible: function() {
                                $rootScope.$context.$object.knowledgeObject.status == 0;
                            }
                        },
                    },
                    availabilityCondition: function() {
                        return $rootScope.$context.$selected == "/note/create";
                    }
                },
                meetings: {
                    cl: {
                        menulink: ''
                    },
                    go: function() { $location.path('/meetings'); },
                    planMeeting: function() { $location.path('/meeting/plan/'); },
                    showCalendar: false,
                    onlyPlannedAndRunningMeetings: true,
                    showOnlyMeetingsYouOrganize: false,
                    availabilityCondition: function() {
                        return $rootScope.$context.$selected == "/meetings";
                    }
                },
                knowledge: {
                    cl: {
                        menulink: ''
                    },
                    actions: {
                        notes: function() { $location.path('/note/list'); },
                        createNote: function() { $location.path('note/create') },
                        createPerson: function() { $location.path('person/create') },
                        remove: {
                            exec: function() {}
                        },
                        grant: {
                            exec: function() {},
                            visible: function() {}
                        },
                        draft: {
                            exec: function() {
                                $rootScope.$context.$object.knowledgeObject.status = 0;
                                knowledgeObjectResource.draft($rootScope.$context.$object.knowledgeObject.id);
                            },
                            visible: function() {
                                $rootScope.$context.$object.knowledgeObject.status == 1;
                            }
                        },
                        publish: {
                            exec: function() {
                                $rootScope.$context.$object.knowledgeObject.status = 1;
                                knowledgeObjectResource.publish($rootScope.$context.$object.knowledgeObject.id);
                            },
                            visible: function() {
                                $rootScope.$context.$object.knowledgeObject.status == 0;
                            }
                        }
                    },
                    availabilityCondition: function() {
                        return $rootScope.$context.$selected == "/note/list";
                    }
                },
                reports: {
                    cl: {
                        menulink: ''
                    },
                    go: function() { $location.path('/reports'); }
                },
                workgroup: {
                    cl: {
                        menulink: ''
                    },
                    go: function() { $location.path('/workgroup'); }
                },
                dashboard: {
                    cl: {
                        menulink: ''
                    },
                    go: function() { $location.path('/dashboard'); }
                },
                pinboard: {
                    cl: {
                        menulink: ''
                    },
                    go: function() { $location.path('/pin-board'); }
                },
                crm: {
                    cl: {
                        menulink: ''
                    },
                    go: function() { $location.path('/crm'); }
                },
                linkedin: {
                    cl: {
                        menulink: ''
                    },
                    go: function() { $location.path('/linkedin'); }
                },

                profile: {
                    redirectTo: function() {
                        authenticationResource.redirectTo().then(function(result) {
                            window.location = result;
                        });
                    },
                    cl: {
                        menulink: ''
                    },
                    actions: {
                        go: function() {
                            $location.path('/profile');
                        }
                    }
                }
            };
            return $rootScope.$context;
        }

        function activate() {
            createCtx($rootScope.location.path());
        }
    }
})();
