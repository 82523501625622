/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function () {
    'use strict';

    angular.module('meetingMinutes.main.crm')
        .controller('CrmController', CrmController);

    CrmController.$inject = [
        '$cookieStore', '$q', '$filter', '$rootScope', '$state', '$scope', '$location', '$compile', 'crmResource', '$uibModal',
        'personResource', 'workgroupResource', 'dictionaries', 'DTOptionsBuilder', 'DTColumnBuilder', '$interval', '$window',
        'selectUserPopup', 'noteResource'
    ];

    function CrmController($cookieStore,
                           $q,
                           $filter,
                           $rootScope,
                           $state,
                           $scope,
                           $location,
                           $compile,
                           crmResource,
                           $uibModal,
                           personResource,
                           workgroupResource,
                           dictionaries,
                           DTOptionsBuilder,
                           DTColumnBuilder,
                           $interval,
                           $window,
                           selectUserPopup,
                           noteResource
    ) {

        //flag to toggle 'show all leads' button
        $scope.allLeadsClicked = false;

        if ($rootScope.crmDateFilter == null || $rootScope.crmDateFilter == undefined) {
            $rootScope.crmDateFilter = {
                years: [],
                months: []
            };
        }

        if ($rootScope.selectedMembers == null || $rootScope.selectedMembers == undefined)
            $rootScope.selectedMembers = [];
        $scope.selectedOwners = [];

        $scope.loggedUser = $rootScope.authentication.user;
        $scope.$context = $rootScope.$context.$set('/crm');
        $scope.crmStats = {
            opportunitiesCount: 0,
            opportunitiesAmount: 0,
            opportunitiesPipeAmount: 0,
            totalOpportunitiesPipeAmount: 0,
            opportunitiesClosed: 0,
            target: 0
        };

        $scope.salesProcesses = [];

        $scope.stages = 'default';
        $scope._today = {};

        $scope.quarters = dictionaries.calendar().quarters;
        $scope.months = dictionaries.calendar().months;
        $scope.years = [
            {year: (new Date()).getFullYear() - 2, selected: false},
            {year: (new Date()).getFullYear() - 1, selected: false},
            {year: (new Date()).getFullYear(), selected: false},
            {year: (new Date()).getFullYear() + 1, selected: false}
        ];

        $scope.currentYear = (new Date()).getFullYear();
        $scope.calendarTooltip = "Click to set filter range";
        $scope.OppWasOpened = false;
        $scope.LeadTabWasOpened = false;

        //index of md-tab which is currently displayed. It's saved in purpose of showing the last opened md-tab.
        if ($rootScope.selectedTabIndex !== undefined && $rootScope.selectedTabIndex !== null)
            $scope.selectedTabIndex = $rootScope.selectedTabIndex;
        else
            $scope.selectedTabIndex = 0;

        //base url (first part of url up to # sign)
        $scope.baseUrl = $location.absUrl().split('#')[0];

        //received leads
        $scope.leads = [];
        //leads filtred in DataTable
        $scope.filtredLeadIds = [];
        //object containing data used in handling Leads Data Table
        $scope.leadsDT = {};
        //Instance of leads Data Table
        $scope.leadsDT.dtInstance = {};
        //leads selected by user in Data Table
        $scope.leadsDT.selected = {};
        //flag whether system should select all leads in Data Table
        $scope.leadsDT.selectAll = false;
        //Html code containing select all checkbox in header of Data Table
        var selectAllLeadHtml = '<input ng-if="leadsEditMode" type="checkbox" ng-model="leadsDT.selectAll" ng-click="toggleAllLeads()">';
        var canceller = null;
        //flag indicating if system should load all leads in Data Table or only owner's
        $scope.allLeads = false;
        //flag indicating if leads the Data Tables are in edit mode or only view mode.
        $scope.leadsEditMode = false;


        //received opportunities
        $scope.opportunities = [];
        //opportunities filtred in DataTable
        $scope.filtredOppIds = [];
        //object containing data used in handling Opportunities Data Table
        $scope.oppDT = {};
        //Instance of opp Data Table
        $scope.oppDT.dtInstance = {};
        //Opps selected by user in Data Table
        $scope.oppDT.selected = {};
        //flag whether system should select all opps in Data Table
        $scope.oppDT.selectAll = false;
        //Html code containing select all checkbox in header of Data Table
        var selectAllOppHtml = '<input ng-if="oppEditMode" type="checkbox" ng-model="oppDT.selectAll" ng-click="toggleAllOpp()">';
        //flag indicating if opps the Data Tables are in edit mode or only view mode.
        $scope.oppEditMode = false;

        //new owner selected by the user which will be used in changing opp and leads owner process.
        $scope.newOwner = null;

        SelectPersonCtrl.$inject = ['$rootScope', '$scope', '$uibModalInstance', '$state'];
        function SelectPersonCtrl($rootScope, $scope, $uibModalInstance, $state) {

            $scope.search = {
                custom: ""
            };

            $scope.loggedUser = $rootScope.loggedUser;
            $scope.info = "Loading...";
            var lastClickedTime = undefined;
            $scope.customFilterInterval = undefined;
            var getLeadsDelay = 500;

            var canceller = null;

            $scope.ownerSelected = function () {
                $scope.$parent.selectedOwners = $scope.selectedOwners;
                $scope.getPersons();
            };

            function checkCustomFilterReady() {
                var currentTime = new Date().getTime();
                var diff = (currentTime - lastClickedTime);
                if ((($scope.search.custom.length > 2) && (diff >= getLeadsDelay)) || (($scope.search.custom.length === 0) && (diff >= getLeadsDelay * 2))) {
                    if ($scope.customFilterInterval)
                        $interval.cancel($scope.customFilterInterval);

                    $scope.getPersons();
                }
            }

            $scope.$on('$destroy', function () {
                // Make sure that the interval is destroyed
                if ($scope.customFilterInterval)
                    $interval.cancel($scope.customFilterInterval);
            });

            $scope.$watch(
                function () {
                    return $scope.search.custom;
                },
                function (newValue, oldValue) {
                    if (canceller != null) {
                        canceller.resolve();
                    }
                    lastClickedTime = new Date().getTime();
                    if ($scope.search.custom.length == 0)
                        $scope.customFilterInterval = $interval(checkCustomFilterReady, getLeadsDelay * 2, 1);
                    else
                        $scope.customFilterInterval = $interval(checkCustomFilterReady, getLeadsDelay, 1);

                },
                true);

            $scope.getPersons = function () {
                var filter = $scope.selectedOwners.map(function (x) {
                    return x.fullName;
                });
                //adding custom filter to the whole filter string
                if ($scope.search.custom && $scope.search.custom !== "" && $scope.search.custom !== undefined)
                    filter.push('custom:' + $scope.search.custom);

                canceller = $q.defer();
                $scope.persons = [];
                $scope.info = "Loading...";
                personResource.getPersonsWithFilter(filter, canceller).then(function (xhr) {
                    $scope.persons = xhr;
                    if (!xhr || xhr.length === 0) {
                        $scope.info = "No data";
                    }
                });
            };

            $scope.addGuest = function (guest) {
                crmResource.createOpportunityLocation(null, null, guest.id);
            };

            $scope.createLead = function () {
                $state.go('main.person/create', {id: null});
            };

            $scope.ok = function () {
                $uibModalInstance.close();
            };

            $scope.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };

        };

        SetRange.$inject = ['$scope', '$uibModalInstance'];
        function SetRange($scope, $uibModalInstance) {

            $scope.selectedMembers = $rootScope.selectedMembers;

            $scope.memberSelected = function (members) {
                $rootScope.selectedMembers = members;
            };

            $scope.ok = function () {
                var ids = $rootScope.selectedMembers.map(function (x) {
                    return x.id;
                });

                crmResource.getOpenedOpportunities(ids, $rootScope.crmDateFilter).then(function (xhr) {
                    updateOpenedOpportunities(xhr);
                });
                if ($scope.OppWasOpened) {
                    reloadOppData();
                }
                if ($scope.LeadTabWasOpened) {
                    reloadLeadsData();
                }

                //reset show all leads funtionality in Data Tables.
                $scope.allLeads = false;
                $scope.allLeadsClicked = false;

                $uibModalInstance.dismiss('ok');
            };

            $scope.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };

            $scope.addYear = function (year) {
                if (!year.selected) {
                    if ($rootScope.crmDateFilter.years.indexOf(year.year) === -1) {
                        $rootScope.crmDateFilter.years.push(year.year);
                    }
                    year.selected = true;
                } else {
                    if ($rootScope.crmDateFilter.years.indexOf(year.year) !== -1) {
                        $rootScope.crmDateFilter.years.splice($rootScope.crmDateFilter.years.indexOf(year.year), 1);
                    }
                    year.selected = false;
                }
            };

            $scope.addMonth = function (month) {
                if (!month.selected) {
                    if ($rootScope.crmDateFilter.months.indexOf(month.id) === -1) {
                        $rootScope.crmDateFilter.months.push(month.id);
                    }
                    month.selected = true;
                } else {
                    if ($rootScope.crmDateFilter.months.indexOf(month.id) !== -1) {
                        $rootScope.crmDateFilter.months.splice($rootScope.crmDateFilter.months.indexOf(month.id), 1);
                    }
                    month.selected = false;
                }

                checkMonthsSelection();
            };

            $scope.addQuarter = function (quarter) {

                quarter.selected = quarter.selected === false || quarter.selected === undefined;

                angular.forEach(quarter.months,
                    function (month) {
                        if (quarter.selected) {
                            if ($rootScope.crmDateFilter.months.indexOf(month.id) === -1) {
                                $rootScope.crmDateFilter.months.push(month.id);
                                $scope.months.filter(function (m) {
                                    return m.id === month.id;
                                })[0].selected = true;
                            }
                        } else {
                            if ($rootScope.crmDateFilter.months.indexOf(month.id) !== -1) {
                                $rootScope.crmDateFilter.months.splice($rootScope.crmDateFilter.months.indexOf(month.id), 1);
                                $scope.months.filter(function (m) {
                                    return m.id === month.id;
                                })[0].selected = false;
                            }
                        }
                    });
            };

            $scope.resetFilters = function () {
                $rootScope.selectedMembers = [];
                $scope.selectedMembers = [];

                $rootScope.crmDateFilter = {
                    years: [],
                    months: []
                };

                $scope.$parent.quarters = dictionaries.calendar().quarters;
                $scope.$parent.months = dictionaries.calendar().months;
                $scope.$parent.years = [
                    {year: (new Date()).getFullYear() - 2, selected: false},
                    {year: (new Date()).getFullYear() - 1, selected: false},
                    {year: (new Date()).getFullYear(), selected: false},
                    {year: (new Date()).getFullYear() + 1, selected: false}
                ];

                $scope.ok();
            };

            function checkMonthsSelection() {

                $scope.quarters[0].selected =
                    !!($scope.months[0].selected && $scope.months[1].selected && $scope.months[2].selected);

                $scope.quarters[1].selected =
                    !!($scope.months[3].selected && $scope.months[4].selected && $scope.months[5].selected);

                $scope.quarters[2].selected =
                    !!($scope.months[6].selected && $scope.months[7].selected && $scope.months[8].selected);

                $scope.quarters[3].selected =
                    !!($scope.months[9].selected && $scope.months[10].selected && $scope.months[11].selected);

            }

            function setStartFilters() {
                angular.forEach($scope.years, function (it) {
                    if ($rootScope.crmDateFilter.years.indexOf(it.year) !== -1)
                        it.selected = true;
                });
                angular.forEach($scope.months, function (it) {
                    if ($rootScope.crmDateFilter.months.indexOf(it.id) !== -1) {
                        it.selected = true;
                        checkMonthsSelection();
                    }
                });
            }

            setStartFilters();
        }

        $scope.setNewOwner = function () {
            selectUserPopup.open($scope, $scope.selectUserCallback);
        };

        $scope.selectUserCallback = function (user) {
            $scope.newOwner = user;
        };


        if ($location.search().date) {
            $scope.form.selectedInboxDate = new Date($location.search().date);
            $scope._today = {
                day: moment($location.search().date).format('D'),
                day_name: moment($location.search().date).format('dddd'),
                month: moment($location.search().date).format('MMMM')
            };
            $scope.calendarCss = "icon_primary";

        } else {
            $scope._today = {
                day: moment().format('D'),
                day_name: moment().format('dddd'),
                month: moment().format('MMMM')
            };
            $scope.calendarCss = "icon_primary";
        }

        $scope.goToSettings = function () {
            $state.go('main.profile/crm');
        };

        $scope.viewOpportunity = function (id) {
            $state.go('main.opportunity/view', {id: id});
        };

        $scope.viewLead = function (id) {
            $state.go("main.person/view", {id: id});
        };


        $scope.getAllLeads = function () {
            $scope.allLeadsClicked = true;
            $scope.allLeads = true;
            reloadLeadsData();
            // $scope.allLeadsClicked = false;
            // $scope.allLeads = false;
        };

        $scope.getLoggedUserLeads = function () {
            $scope.allLeadsClicked = false;
            $scope.allLeads = false;
            reloadLeadsData();
            // $scope.allLeadsClicked = true;
            // $scope.allLeads = true;
        };

        $scope.translateOpportunityStatus = function (status) {
            return dictionaries.opportunity().opportunityStatusToString(status);
        };

        $scope.setRange = function () {
            var modalInstance = $uibModal.open({
                templateUrl: 'views/main/popups/crm-range.popup.tpl.html?v=' + new Date(),
                controller: SetRange,
                scope: $scope
            });
        };
        $scope.planNewMeeting = function () {
            $state.go('main.planMeeting', {meetingId: null});
        };
        $scope.createOpportunity = function () {
            $scope.OpenModalSelectPerson();
        };
        $scope.OpenModalSelectPerson = function () {
            var modalInstance = $uibModal.open({
                templateUrl: 'views/main/popups/opportunity-person.popup.tpl.html?v=' + new Date(),
                controller: SelectPersonCtrl,
                scope: $scope
            });
        };
        $scope.createLead = function () {
            $state.go('main.person/create', {id: null});
        };

        var ownersIds = null;
        if ($rootScope.selectedMembers.length > 0)
            ownersIds = $rootScope.selectedMembers.map(function (x) {
                return x.id;
            });
        crmResource.getOpenedOpportunities(ownersIds, $rootScope.crmDateFilter).then(function (xhr) {
            updateOpenedOpportunities(xhr);
        });

        workgroupResource.getMembers().then(function (xhr) {
            $scope.members = xhr;
        });


        $scope.ActiveOpportunitiesTabSelect = function () {
            $rootScope.selectedTabIndex = $scope.selectedTabIndex;

        };

        $scope.OpportunitiesTabSelect = function () {
            $rootScope.selectedTabIndex = $scope.selectedTabIndex;
            if (!$scope.OppWasOpened) {

                $scope.OppWasOpened = true;

                loadOppTableData();

                $(document).ready(function () {
                    $('#opportunities').DataTable({
                        ordering: true,
                    });
                });
            }
        };

        $scope.LeadsTabSelect = function () {
            $rootScope.selectedTabIndex = $scope.selectedTabIndex;
            if (!$scope.LeadTabWasOpened) {

                $scope.LeadTabWasOpened = true;

                LoadLeadsDataTable();

                $(document).ready(function () {
                    $('#leads').DataTable({
                        ordering: true,
                    });
                });
            }
        };

        function loadLastSelectedTab() {
            switch ($scope.selectedTabIndex) {
                case 0: {
                    $scope.ActiveOpportunitiesTabSelect();
                    break;
                }
                case 1: {
                    $scope.OpportunitiesTabSelect();
                    break;
                }
                case 2: {
                    $scope.LeadsTabSelect();
                    break;
                }
            }
        }

        function loadOppTableData() {

            $scope.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
                var defer = $q.defer();
                //get ids of selected users to show their opportunities
                var ids = $rootScope.selectedMembers.map(function (x) {
                    return x.id;
                });

                //get opportunities by dates and owners.
                crmResource.getOpportunities(ids, $rootScope.crmDateFilter).then(function (response) {

                    response.sort(function(a,b) { 
                        return new Date(b.start) - new Date(a.start);
                    }); 

                    for (var i = 0; i < response.length; i++) {
                        response[i].status = $scope.translateOpportunityStatus(response[i].status);
                        if (!response[i].name)
                            response[i].name = "";

                        if (response[i].person) {
                            if (!response[i].person.name)
                                response[i].person.name = "";
                        }
                        else response[i].person = {name: ""};

                        if (response[i].end)
                            response[i].end = response[i].end.split('T')[0];
                        else
                            response[i].end = 'None';
                    }
                    $scope.opportunities = response;
                    defer.resolve(response);
                });
                return defer.promise;
            })
            //additional option with a header creation callback used to recompile html content. It's necessary to include angular commands in html.
                .withOption('headerCallback', function (header) {
                    if (!$scope.oppHeaderCompiled) {
                        // Use this headerCompiled field to only compile header once
                        $scope.oppHeaderCompiled = true;
                        $compile(angular.element(header).contents())($scope);
                    }
                })
                //additional option with a row creation callback used to recompile html content. It's necessary to include angular commands in html.
                .withOption('rowCallback', function (row, data, displayNum, displayIndex, dataIndex ) {
                    if(!row.compiled){
                        row.compiled = true;
                        // Recompiling so we can bind Angular directive to the DT
                        $compile(angular.element(row).contents())($scope);
                    }
                })
                .withOption('fnFooterCallback', function ( nRow, aaData, iStart, iEnd, aiDisplay) {
                    $scope.filtredOppIds = []
                    angular.forEach(aiDisplay, function (it) {
                        if(aaData[it] !== null && aaData[it] !== undefined)
                            $scope.filtredOppIds.push(aaData[it].id);
                    });
                })
                .withPaginationType('full_numbers')
                // .withOption('rowCallback', rowCallback)
                .withButtons([
                    {
                        extend: 'pdfHtml5',
                        text: '<i class="fa fa-print" aria-hidden="true"></i> PDF',
                        titleAttr: 'pdf',
                        filename: 'Opportunities_' + moment().format('MM.DD.YYYY_HH:mm:ss'),
                        title: 'Opportunities ' + moment().format('MM.DD.YYYY HH:mm'),
                        CharSet: "utf8",
                        exportOptions: {
                            columns: [1,2,3,4,5,6,7]
                        },
                        exportData: {decodeEntities: true}
                    },
                    {
                        extend: "excelHtml5",
                        titleAttr: 'Excel',
                        text: '<i class="fa fa-file-text-o"></i> Excel',
                        filename: 'Opportunities_' + moment().format('MM.DD.YYYY_HH:mm:ss'),
                        title: 'Opportunities ' + moment().format('MM.DD.YYYY HH:mm'),
                        CharSet: "utf8",
                        exportOptions: {
                            columns: [1,2,3,4,5,6,7]
                        },
                        exportData: {decodeEntities: true}
                    }
                ]).withOption('drawCallback', function() {
                    var searchKeyword = '';                    
                    if ($scope.oppDT.dtInstance.DataTable) {
                        searchKeyword = $scope.oppDT.dtInstance.DataTable.search();
                    }
                    var matchedOpps = [];
                    if (searchKeyword) {
                        searchKeyword = searchKeyword.toLowerCase();
                        for (var i = 0; i < $scope.opportunities.length; i++) {
                            var opp = $scope.opportunities[i];
                            if (opp.opportunityName.toLowerCase().indexOf(searchKeyword) !== -1) { matchedOpps.push(opp); continue; }
                            if (opp.person.name.toLowerCase().indexOf(searchKeyword) !== -1) { matchedOpps.push(opp); continue; }
                            if (opp.salesProcess.title.toLowerCase().indexOf(searchKeyword) !== -1) { matchedOpps.push(opp); continue; }
                            if (opp.stage.title.toLowerCase().indexOf(searchKeyword) !== -1) { matchedOpps.push(opp); continue; }
                            if (opp.end.toString().toLowerCase().indexOf(searchKeyword) !== -1) { matchedOpps.push(opp); continue; }
                            if (opp.status.toLowerCase().indexOf(searchKeyword) !== -1) { matchedOpps.push(opp); continue; }
                            if (opp.amount.toString().toLowerCase().indexOf(searchKeyword) !== -1) { matchedOpps.push(opp); continue; }
                            if (opp.owner.userName.toLowerCase().indexOf(searchKeyword) !== -1) { matchedOpps.push(opp); continue; }
                            if (opp.isExpired.toString().toLowerCase().indexOf(searchKeyword) !== -1) { matchedOpps.push(opp); continue; }
                        }
                    } else {
                        matchedOpps = $scope.opportunities;
                    }
                    
                    var groupedByStatus = {};
                    for (var i = 0; i < matchedOpps.length; i++) {
                        var opp = matchedOpps[i];
                        var status = opp.status;
                        if (groupedByStatus[status]) {
                            groupedByStatus[status] = groupedByStatus[status] + opp.amount;
                        }
                        else {
                            groupedByStatus[status] = opp.amount;
                        }
                    }
                  
                    $scope.$apply(function () {
                        if (Object.keys(groupedByStatus).length > 0)
                            $scope.groupedByStatus = groupedByStatus;
                        else
                            $scope.groupedByStatus = null;
                    });

                });

            //creating columns in Data Table
            $scope.dtColumns = [
                //First column with checkboxes to select the rows. Rows are rendered with injected html containing specific data.
                DTColumnBuilder.newColumn(null).withTitle(selectAllOppHtml).notSortable().renderWith(function (data, type, full, meta) {
                    if(!$scope.oppDT.selected.hasOwnProperty(full.id)) {
                        $scope.oppDT.selected[full.id] = {
                            id: full.id,
                            selected: false,
                            opp: data,
                            rendered: true
                        };
                    }
                    return '<input ng-if="oppEditMode" type="checkbox" ng-model="oppDT.selected[' + data.id + '].selected" ng-click="toggleOneOpp()">';
                }),
                DTColumnBuilder.newColumn('opportunityName').withTitle('Title').renderWith(opportunityColumnRender),
                DTColumnBuilder.newColumn('person.name').withTitle('Organization').renderWith(opportunityColumnRender),
                DTColumnBuilder.newColumn('salesProcess.title').withTitle('Sales Process').renderWith(opportunityColumnRender),
                DTColumnBuilder.newColumn('stage.title').withTitle('Sales Stage').renderWith(opportunityColumnRender),
                DTColumnBuilder.newColumn('end').withTitle('Closed Date').renderWith(opportunityColumnRender),
                DTColumnBuilder.newColumn('status').withTitle('Status').renderWith(opportunityColumnRender),
                DTColumnBuilder.newColumn('amount').withTitle('Value').renderWith(opportunityColumnRender),
                DTColumnBuilder.newColumn('owner.userName').withTitle('Owner').renderWith(opportunityColumnRender),
                DTColumnBuilder.newColumn('isExpired').withTitle('Expired').renderWith(function (data, type, full) {
                    var text = data ? "Yes" : "No";
                    return '<a href="' + $scope.baseUrl + '#/opportunity/view/' + full.id + '" target="_blank">' + text + '</a>';
                }),
                DTColumnBuilder.newColumn('id').withTitle('Id').notVisible()
            ];

            //new promise is used to reload the data
            $scope.newPromise = newPromise;
            //column render used during creation of every row. In this function the html code with specific is injected to every cell in Data Table.
            $scope.opportunityColumnRender = opportunityColumnRender;


            function newPromise() {
                return crmResource.getOpportunities(ids, $rootScope.crmDateFilter)
            }
        }

        //function called when user click checkbox in header to select/unselect all rows.
        $scope.toggleAllOpp = function () {
            var selectAll = $scope.oppDT.selectAll;
            var selectedItems = $scope.oppDT.selected;

            for (var id in selectedItems) {
                if (selectedItems.hasOwnProperty(id)) {
                    selectedItems[id].selected = selectAll;
                }
            }
        };
        //function called when user click any row's checkbox. Function checks if all rows are select or not and changes
        // the SelectAll checkbox in header of Data Table
        $scope.toggleOneOpp = function () {
            var selectedItems = $scope.oppDT.selected;

            for (var id in selectedItems) {
                if (selectedItems.hasOwnProperty(id)) {
                    if (!selectedItems[id].selected) {
                        $scope.oppDT.selectAll = false;
                        return;
                    }
                }
            }
            $scope.oppDT.selectAll = true;
        };

        //column render used during creation of every row. In this function the html code with specific is injected to every cell in Data Table.
        function opportunityColumnRender(data, type, full) {
            return '<a href="' + $scope.baseUrl + '#/opportunity/view/' + full.id + '" target="_blank">' + data + '</a>';
        }

        //updating all selected opportunities
        $scope.updateSelectedOpportunities = function () {
            if ($scope.newOwner == null || $scope.newOwner === undefined || $scope.newOwner === '')
                return;

            //get all selected opportunities
            var data = $scope.oppDT.selected;
            if (data.length === 0) return;

            var oppDtos = [];

            //prepare opportunity data to be sent.
            angular.forEach(data, function (it) {
                if(it.selected && ($scope.filtredOppIds.indexOf(it.id) !== -1)){
                    oppDtos.push({
                        id: it.id,
                        ownerId: $scope.newOwner.id
                    });
                }
            });

            crmResource.updateOwner(oppDtos).then(function () {
                if ($scope.OppWasOpened) {
                    reloadOppData();
                }
            });
        };

        //deleting all selected opportunities
        $scope.deleteSelectedOpportunities = function () {
            //get all selected opportunities
            var data = $scope.oppDT.selected;
            if (data.length === 0) return;

            var oppIdsString = '';

            //creating string with ids of opportunities to delete.
            angular.forEach(data, function (it) {
                if(it.selected && ($scope.filtredOppIds.indexOf(it.id) !== -1))
                    oppIdsString += 'oppIds=' + it.id + '&';
            });
            //return if string is empty.
            if(oppIdsString.length <= 0) return;
            //deleting the last '&' sign
            oppIdsString = oppIdsString.slice(0, -1);

            crmResource.deleteOpportunities(oppIdsString).then(function (response) {
                if ($scope.OppWasOpened) {
                    reloadOppData();
                }
            });
        };

        //reloading opportunity data table with new data. Rerendering all table.
        function reloadOppData() {
            $scope.oppHeaderCompiled =  false;
            var instance = $scope.oppDT.dtInstance;
            instance._renderer.rerender();
        }

        function clickHandler(data) {
            $scope.viewOpportunity(data.id);
        }

        //function handling clicks on rows of the table
        function rowCallback(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
            // Unbind first in order to avoid any duplicate handler (see https://github.com/l-lin/angular-datatables/issues/87)
            $('td', nRow).unbind('click');
            $('td', nRow).bind('click',
                function () {
                    $scope.$apply(function () {
                        clickHandler(aData);
                    });
                });
            return nRow;
        }


        function LoadLeadsDataTable() {

            $scope.dtOptions2 = DTOptionsBuilder.fromFnPromise(function () {
                var names = null;
                if ($scope.allLeads) {
                    names = getAllMembersNames();
                }
                else {
                    names = $rootScope.selectedMembers.map(function (x) {
                        return x.userName;
                    });
                }

                var defer = $q.defer();
                canceller = $q.defer();
                personResource.getPersonsWithFilter(names, canceller).then(function (response) {
                    $scope.leads = response;
                    for (var i = 0; i < response.length; i++) {
                        if (!response[i].email)
                            response[i].email = "";
                        if (!response[i].position)
                            response[i].position = "";
                        if (!response[i].name)
                            response[i].name = '';
                        if (!response[i].leadSource)
                            response[i].leadSource = '';
                        if (!response[i].contactPerson)
                            response[i].contactPerson = "";

                        if (response[i].status && response[i].status > 0)
                            response[i].status = dictionaries.lead().leadStatusToString(response[i].status);
                        else
                            response[i].status = 'No status';

                        if (response[i].lastContact)
                            response[i].lastContact = response[i].lastContact.split('T')[0];
                        else
                            response[i].lastContact = 'None';
                    }
                    defer.resolve(response);
                });
                return defer.promise;
            }).withPaginationType('full_numbers')
            // .withOption('rowCallback', onLeadRowClick)
                .withOption('headerCallback', function (header) {
                    if (!$scope.leadsHeaderCompiled) {
                        // Use this headerCompiled field to only compile header once
                        $scope.leadsHeaderCompiled = true;
                        $compile(angular.element(header).contents())($scope);
                    }
                })
                .withOption('rowCallback', function (row, data, displayNum, displayIndex, dataIndex ) {
                    if(!row.compiled){
                        row.compiled = true;
                        // Recompiling so we can bind Angular directive to the DT
                        $compile(angular.element(row).contents())($scope);
                    }
                })
                .withOption('fnFooterCallback', function ( nRow, aaData, iStart, iEnd, aiDisplay) {
                    $scope.filtredLeadIds = []
                    angular.forEach(aiDisplay, function (it) {
                        if(aaData[it] !== null && aaData[it] !== undefined)
                            $scope.filtredLeadIds.push(aaData[it].id);
                    });
                })
                .withButtons([
                    {
                        extend: 'pdfHtml5',
                        text: '<i class="fa fa-print" aria-hidden="true"></i> PDF',
                        titleAttr: 'pdf',
                        filename: 'Leads_' + moment().format('MM.DD.YYYY_HH:mm:ss'),
                        title: 'Leads ' + moment().format('MM.DD.YYYY HH:mm'),
                        CharSet: "utf8",
                        exportOptions: {
                            columns: [1,2,3,4,5,6,7,8,9,10]
                        },
                        exportData: {decodeEntities: true}
                    },
                    {
                        extend: "excelHtml5",
                        titleAttr: 'Excel',
                        text: '<i class="fa fa-file-text-o"></i> Excel',
                        filename: 'Leads_' + moment().format('MM.DD.YYYY_HH:mm:ss'),
                        title: 'Leads ' + moment().format('MM.DD.YYYY HH:mm'),
                        CharSet: "utf8",
                        exportOptions: {
                            columns: [1,2,3,4,5,6,7,8,9,10]
                        },
                        exportData: {decodeEntities: true}
                    }
                ]);

            $scope.dtColumns2 = [
                DTColumnBuilder.newColumn(null).withTitle(selectAllLeadHtml).notSortable().renderWith(function (data, type, full, meta) {
                    if(!$scope.leadsDT.selected.hasOwnProperty(full.id)){
                        $scope.leadsDT.selected[full.id] = {
                            owner: full.owner,
                            id: full.id,
                            selected: false,
                            opp: data,
                            rendered: true
                        };
                    }
                    return '<input type="checkbox" ng-if="leadsEditMode" ng-model="leadsDT.selected[' + data.id + '].selected" ng-click="toggleOneLead()">';
                }),
                DTColumnBuilder.newColumn('name').withTitle('Name').renderWith(leadColumnRender),
                DTColumnBuilder.newColumn('contactPerson').withTitle('Contact Person').renderWith(leadColumnRender),
                DTColumnBuilder.newColumn('email').withTitle('Email').renderWith(leadColumnRender),
                DTColumnBuilder.newColumn('position').withTitle('Position').renderWith(leadColumnRender),
                DTColumnBuilder.newColumn('leadSource').withTitle('Lead Source').renderWith(leadColumnRender),
                DTColumnBuilder.newColumn('lastContact').withTitle('Last Contact').renderWith(leadColumnRender),
                DTColumnBuilder.newColumn('status').withTitle('Status').renderWith(leadColumnRender),
                DTColumnBuilder.newColumn('owner.userName').withTitle('Creator').renderWith(leadColumnRender),
                DTColumnBuilder.newColumn('location').withTitle('Location').renderWith(leadColumnRender),
                DTColumnBuilder.newColumn('phone').withTitle('Phone').renderWith(leadColumnRender),
                DTColumnBuilder.newColumn('id').withTitle('Id').notVisible()
            ];

            $scope.newLeadsPromise = newLeadsPromise;

            // $scope.reloadData = reloadLeadsData;

            function newLeadsPromise() {
                return personResource.getPersons();
            }
        }

        $scope.toggleAllLeads = function () {
            var selectAll = $scope.leadsDT.selectAll;
            var selectedItems = $scope.leadsDT.selected;

            for (var id in selectedItems) {
                if (selectedItems.hasOwnProperty(id)) {
                    selectedItems[id].selected = selectAll;
                }
            }
        };
        $scope.toggleOneLead = function () {
            var selectedItems = $scope.leadsDT.selected;

            for (var id in selectedItems) {
                if (selectedItems.hasOwnProperty(id)) {
                    if (!selectedItems[id].selected) {
                        $scope.leadsDT.selectAll = false;
                        return;
                    }
                }
            }
            $scope.leadsDT.selectAll = true;
        };

        function leadColumnRender(data, type, full) {
            return '<a href="' + $scope.baseUrl + '#/person/view/' + full.id + '" target="_blank">' + data + '</a>';
        }

        $scope.handleSelectedLeads = function () {
            if ($scope.newOwner == null || $scope.newOwner === undefined || $scope.newOwner === '')
                return;

            var data = $scope.leadsDT.selected;
            if (data.length === 0) return;

            //LEAD NOTES OWNER
            //prepare leadDtos with information neccessary to change lead's notes owner.
            var leadDtos = [];
            angular.forEach(data, function (it) {
                if(it.selected && ($scope.filtredLeadIds.indexOf(it.id) !== -1)){
                    leadDtos.push({
                        owner: it.owner,
                        id: it.id,
                    });
                }
            });

            //update lead notes owner.
            noteResource.updatePersonNotesOwner(leadDtos, $scope.newOwner.id).then(function (response) {
                //LEAD OWNER
                //prepare leadDtos with information neccessary to change lead's owner
                leadDtos = [];
                angular.forEach(data, function (it) {
                    if(it.selected && ($scope.filtredLeadIds.indexOf(it.id) !== -1)){
                        leadDtos.push({
                            owner: {
                                id: $scope.newOwner.id,
                                userName: $scope.newOwner.userName,
                                userEmail: $scope.newOwner.userEmail
                            },
                            id: it.id,
                        });
                    }
                });

                personResource.updateOwner(leadDtos).then(function () {
                    if ($scope.LeadTabWasOpened) {
                        reloadLeadsData();
                    }
                });
            });
        };

        $scope.deleteSelectedLeads = function () {
            var data = $scope.leadsDT.selected;
            if (data.length === 0) return;
            var leadIdsString = '';

            angular.forEach(data, function (it) {
                if(it.selected && ($scope.filtredLeadIds.indexOf(it.id) !== -1))
                    leadIdsString += 'leadIds=' + it.id + '&';
            });
            if(leadIdsString.length <= 0) return;

            leadIdsString = leadIdsString.slice(0, -1);

            personResource.deleteLeads(leadIdsString).then(function (response) {
                if ($scope.LeadTabWasOpened) {
                    reloadLeadsData();
                }
            });
        };

        function leadClickHandler(data) {
            $scope.viewLead(data.id);
        }

        function onLeadRowClick(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
            // Unbind first in order to avoid any duplicate handler (see https://github.com/l-lin/angular-datatables/issues/87)
            $('td', nRow).unbind('click');
            $('td', nRow).bind('click',
                function () {
                    $scope.$apply(function () {
                        leadClickHandler(aData);
                    });
                });
            return nRow;
        }

        function reloadLeadsData() {
            $scope.leadsHeaderCompiled = false;
            $scope.leadsDT.dtInstance._renderer.rerender();
        }

        function getAllMembersNames() {
            return $scope.members.map(function (x) {
                return x.userName;
            });
        }

        $scope.formatNumber = function(number) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }

        $scope.selectSalesProcess = function() {
            $scope.crmStats.opportunitiesCount = 0;
            $scope.crmStats.opportunitiesAmount = 0;
            $scope.crmStats.opportunitiesPipeAmount = 0;

            if ($scope.salesProcesses === undefined || $scope.salesProcesses === null || $scope.salesProcesses.length === 0)
                return;

            $scope.salesProcess = $scope.salesProcesses.filter(x=>x.id === $scope.selectedSalesProcess.id)[0];
            $scope.stages = $scope.salesProcess.stages;

            angular.forEach($scope.stages,
                function (stage) {
                    $scope.crmStats.opportunitiesCount = $scope.crmStats.opportunitiesCount + stage.opportunityCount;
                    $scope.crmStats.opportunitiesAmount = $scope.crmStats.opportunitiesAmount + stage.amountSum;
                    $scope.crmStats.opportunitiesPipeAmount = Math.round($scope.crmStats.opportunitiesPipeAmount) +
                        Math.round(stage.amountPipeSum)
                });

            $scope.crmStats.opportunitiesPipeAmountDisplay = $scope.formatNumber($scope.crmStats.opportunitiesPipeAmount);
            $scope.crmStats.opportunitiesAmountDisplay = $scope.formatNumber($scope.crmStats.opportunitiesAmount);

            var chartData = [];

            angular.forEach($scope.stages,
                function (stage) {
                    var dto = [stage.completeness + '% ' + stage.knowledgeObject.title, stage.amountSum];

                    chartData.push(dto);
                });

            loadLastSelectedTab();


            Highcharts.chart('container',
                {
                    credits: {
                        enabled: false
                    },
                    chart: {
                        type: 'funnel',
                        marginRight: 100
                    },
                    title: {
                        text: '',
                        x: -50
                    },
                    plotOptions: {
                        series: {
                            dataLabels: {
                                enabled: true,
                                format: '<b>{point.name}</b> ({point.y:,.0f})',
                                color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
                                softConnector: true
                            },
                            neckWidth: '30%',
                            neckHeight: '25%'
                        }
                    },
                    legend: {
                        enabled: false
                    },
                    series: [
                        {
                            name: 'Opportunites',
                            data: chartData
                        }
                    ]
                });
        }

        function updateOpenedOpportunities(xhr) {
            $scope.salesProcesses = xhr.salesProcessDtos;

            if (!$scope.selectedSalesProcess)
                $scope.selectedSalesProcess = xhr.salesProcessDtos[0];

            $scope.crmStats.closedOppAmountSum = xhr.closedOppAmountSum;
            $scope.crmStats.salesTargetAmountSum = xhr.salesTargetAmountSum;

            $scope.crmStats.opportunitiesTotalPipeAmount = 0;

            angular.forEach($scope.salesProcesses,
                function (process) {
                    angular.forEach(process.stages,
                        function (stage) {
                            $scope.crmStats.opportunitiesTotalPipeAmount = $scope.crmStats.opportunitiesTotalPipeAmount + stage.amountPipeSum;
                        });
                });

            Math.round($scope.crmStats.opportunitiesTotalPipeAmount);

            $scope.crmStats.opportunitiesTotalPipeAmountDisplay = $scope.formatNumber($scope.crmStats.opportunitiesTotalPipeAmount);

            $scope.selectSalesProcess();
        }
    }
})();
