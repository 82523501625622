/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';
    angular.module('meetingMinutes.main.knowledge')
        .config(routesConfig);

    routesConfig.$inject = ['$stateProvider', 'authorizationProvider'];

    function routesConfig($stateProvider, authorizationProvider) {
        $stateProvider
            .state('main.knowledge',
                {
                    url: '/knowledge',
                    templateUrl: 'views/main/knowledge/knowledge.tpl.html?v=' + new Date(),
                    controller: 'KnowledgeController',
                    resolve: {
                        
                    },
                    data: {
                        pageTitle: 'Knowledge'
                    }
                }).state('main.knowledge/access',
                {
                    url: '/knowledge/access/:id',
                    templateUrl: 'views/main/knowledge/access.tpl.html?v=' + new Date(),
                    controller: 'AccessController',
                    resolve: {
                        
                    },
                    data: {
                        pageTitle: 'Access'
                    }
                });
    }
})();
