/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.repository.remote')
        .factory('meetingHelper', meetingHelper);

    meetingHelper.$inject = ['httpResource'];

    function meetingHelper(httpResource) {

        var instantTypes = [
            {
                id: 0,
                title: "Phone",
            }, {
                id: 1,
                title: "Face2face",
            }, {
                id: 2,
                title: "Email",
            }, {
                id: 3,
                title: "Skype",
            }
        ];

        return {
            "instantTypes": instantTypes
        };
    }

})();
