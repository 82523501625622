/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.knowledge')
        .controller('AccessController', AccessController);

    AccessController.$inject = ['$stateParams', '$rootScope', '$scope', 'knowledgeObjectResource', 'workgroupResource'];

    function AccessController($stateParams, $rootScope, $scope, knowledgeObjectResource, workgroupResource) {
        $scope.koId = $stateParams.id;
        $scope.permissions = [];
        $scope.members = [];
        $rootScope.$context.$set('/knowledgeObject/access', $scope);
        $scope.counterWithPermission = 0;
        $scope.counterWithoutPermission = 0;

        $scope.permissionTypes = [
            { id: 0, name: 'Owner' },
            { id: 1, name: 'Can edit' },
            { id: 2, name: 'Can only read' },
            { id: -1, name: 'Remove permission' }
        ];

        $scope.setPermission = function(userId, permissionType) {
            if (permissionType == -1) {
                knowledgeObjectResource.removePermission($scope.koId, userId).then(function(response) {
                    $scope.refreshLists(response);
                });
            } else {
                var dto = {
                    user: { id: userId },
                    permissionType: permissionType,
                    knowledgeObjectId: $scope.koId
                };
                knowledgeObjectResource.updatePermission(dto).then(function(response) {
                    $scope.refreshLists(response);
                });
            }
        };

        $scope.addPermission = function(member) {
            var dto = {
                user: member,
                permissionType: 2,
                knowledgeObjectId: $scope.koId
            };

            $scope.counterWithoutPermission = 1;
            knowledgeObjectResource.addPermission(dto).then(function(response) {
                $scope.refreshLists(response);
            });
        };

        $scope.hasAccess = function(userId) {
            for (var i = 0; i < $scope.permissions.length; i++) {
                if ($scope.permissions[i].user.id == userId) {
                    return true;
                }
            }
            return false;
        };

        $scope.refreshLists = function(permissions) {
            $scope.counterWithPermission = 0;
            $scope.counterWithoutPermission = 1;

            for (var i = 0; i < permissions.length; i++) {
                permissions[i].typeTxt = knowledgeObjectResource.translatePermissionType(permissions[i].permissionType);
            }

            $scope.permissions = permissions;
            $scope.counterWithPermission = permissions.length;

            workgroupResource.getMembers().then(function(xhr) {
                for (var i = 0; i < xhr.length; i++) {
                    xhr[i].hasAccess = $scope.hasAccess(xhr[i].id);
                    if (xhr[i].hasAccess == false) {
                        $scope.counterWithoutPermission = $scope.counterWithoutPermission + 1;
                    }
                }

                $scope.members = xhr;
            });
        };


        knowledgeObjectResource.getPermissions($scope.koId).then(function(response) {
            for (var i = 0; i < response.length; i++) {
                response[i].typeTxt = knowledgeObjectResource.translatePermissionType(response[i].permissionType);
            }
            $scope.permissions = response;
            workgroupResource.getMembers().then(function(xhr) {
                for (var i = 0; i < xhr.length; i++) {
                    xhr[i].hasAccess = $scope.hasAccess(xhr[i].id);
                }

                $scope.members = xhr;
            });
        });
    }

})();
