/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.library')
        .config(routesConfig);

    routesConfig.$inject = ['$stateProvider', 'authorizationProvider'];

    function routesConfig($stateProvider, authorizationProvider) {
        $stateProvider
            .state('main.library/list',
                {
                    url: '/library',
                    templateUrl: 'views/main/library/library-list.tpl.html?v=' + new Date(),
                    controller: 'LibraryListController',
                    resolve: {
                        
                    },
                    data: {
                        pageTitle: 'Library'
                    }
                });
    }

})();
