/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.repository.remote')
        .factory('meetingResource', remoteMeetingResourceFactory);

    remoteMeetingResourceFactory.$inject = ['httpResource', '$location'];

    function remoteMeetingResourceFactory(httpResource, $location) {

        function planNewMeeting(prevMeetingId, tags, backUrl, mode) {
            $location.path('/meeting/plan/').search({ backUrl: backUrl, tags: tags, prevId: prevMeetingId, mode: mode })
        }

        function CreateMeetingBasedOnTimelineItemExecute(dto, backUrl) {
            CreateMeetingBasedOnTimelineItem(dto).then(function(xhr) {
                $location.path('/meeting/plan/' + xhr.id).search({ backUrl: backUrl })
            });
        }

        function meetingDetailsUrl(id) {
            return '/meeting/view/' + id;
        }


        function translateStatus(status) {
            if (status == 0)
                return "Pending";
            if (status == 1)
                return "Running";
            if (status == 2)
                return "Closed";
            if (status == 3)
                return "Cancelled";
        }

        function createDraft(id) {
            return httpResource.post('/meeting?prevId=' + id);
        }

        function createInstantMeeting(type, tags) {
            return httpResource.post('/meeting/instant/' + type, tags);
        }

        function saveGoal(meetingId, goal, related) {
            return httpResource.post('/meeting/' + meetingId + '/goal?related=' + related, goal);
        }

        function saveAgendaItem(meetingId, agendaItem) {
            return httpResource.post('/meeting/' + meetingId + '/agendaItem', agendaItem);
        }

        function attachMeetingToActionItem(actionItemId, meetingId) {
            return httpResource.post('/meeting/' + meetingId + '/action-item/' + actionItemId);
        }

        function getDrafts() {
            return httpResource.get('/meeting/drafts');
        }

        function getMeetings() {
            return httpResource.get('/meetings');
        }

        function getNotPlannedMeetings(crmview) {
            return httpResource.get('/meetings/not-planned?crmMode=' + crmview);
        }

        function getMeetingById(meetingId, guid) {
            return httpResource.get('/meeting/' + meetingId + '?guid=' + encodeURIComponent(guid));
        }

        function checkIfMeetingRefreshNeeded(meetingId, dateTime) {
            return httpResource.get('/meeting/' + meetingId + '/check-if-refresh-needed?refreshDate=' + dateTime);
        }

        function checkIfTimelineRefreshNeeded(meetingId, dateTime) {
            return httpResource.get('/meeting/' +
                meetingId +
                '/timeline/check-if-refresh-needed?refreshDate=' +
                dateTime);
        }

        function getMeetingsByDay(year, month, day) {
            return httpResource.get('/meetings/filterByDay?y=' + year + '&m=' + month + '&d=' + day);
        }

        function getFutureFollowUps(id) {
            return httpResource.get('/meetings/future-follow-ups?meetingId=' + id);
        }

        function getPastFollowUps(id) {
            return httpResource.get('/meetings/past-follow-ups?meetingId=' + id);
        }

        function getSuggestions(meetingId) {
            return httpResource.get('/meeting/' + meetingId + "/suggestions");
        }


        function getAvailableMembers(meetingId) {
            return httpResource.get('/meeting/' + meetingId + "/members");
        }

        function $delete(meetingId) {
            return httpResource.delete('/meeting/' + meetingId);
        }

        function deleteGoal(goalId) {
            return httpResource.delete('/meeting/goal/' + goalId);
        }

        function deleteAgendaItem(agendaItemId) {
            return httpResource.delete('/meeting/agendaItem/' + agendaItemId);
        }

        function moveUpAgendaItem(agendaItem) {
            return httpResource.post('/meeting/agendaItem/' + agendaItem.id + '/moveUp');
        }

        function moveDownAgendaItem(agendaItem) {
            return httpResource.post('/meeting/agendaItem/' + agendaItem.id + '/moveDown');
        }

        function assignGoalToAgendaItem(agendaItemId, goalId) {
            return httpResource.post('/meeting/agendaItem/' + agendaItemId + '/goal/' + goalId);
        }

        function unassignGoalFromAgendaItem(agendaItemId, goalId) {
            return httpResource.delete('/meeting/agendaItem/' + agendaItemId + '/goal/' + goalId);
        }

        function formatDate(date) {
            return date.getFullYear()+'-'+date.getMonth()+1+'-'+date.getDate()+'T'+date.getHours()+':'+date.getMinutes()+':'+date.getSeconds();
        }

        function save(meeting) {
            if (!moment.isMoment(meeting.plannedStartTime)) meeting.plannedStartTime = moment(meeting.plannedStartTime);
            if (!moment.isMoment(meeting.plannedEndTime)) meeting.plannedEndTime = moment(meeting.plannedEndTime);

            meeting.plannedStartTime = meeting.plannedStartTime.format('YYYY-MM-DD HH:mm:ss');
            meeting.plannedEndTime = meeting.plannedEndTime.format('YYYY-MM-DD HH:mm:ss');

            return httpResource.put('/meeting/' + meeting.id, meeting);
        }

        function saveDayPlan(id, dto) {
            return httpResource.put('/meeting/plan-the-day/' + id, dto);
        }


        function estimate(request) {
            return httpResource.post('/meeting/estimate', request);
        }

        function suggestion(request) {
            return httpResource.post('/meeting/suggestion', request);
        }

        function addInternalParticipant(meetingId, userId) {
            return httpResource.post('/meeting/' + meetingId + '/internalParticipant/' + userId);
        }

        function addExternalParticipant(meetingId, personId) {
            return httpResource.post('/meeting/' + meetingId + '/externalParticipant/' + personId);
        }


        function deleteInternalParticipant(meetingId, userId) {
            return httpResource.delete('/meeting/' + meetingId + '/internalParticipant/' + userId);
        }

        function deleteExternalParticipant(meetingId, personId) {
            return httpResource.delete('/meeting/' + meetingId + '/externalParticipant/' + personId);
        }


        function publish(id) {
            return httpResource.patch('/meeting/' + id + '/published');
        }

        function start(meetingStartRequest) {
            return httpResource.post('/meeting/start', meetingStartRequest);
        }

        function endTime(request) {
            return httpResource.post('/meeting/end-time', request);
        }

        function close(meetingCloseRequest) {
            return httpResource.post('/meeting/close', meetingCloseRequest);
        }

        function cancel(meetingCancelRequest) {
            return httpResource.post('/meeting/cancel', meetingCancelRequest);
        }

        function draft(id) {
            return httpResource.patch('/meeting/' + id + '/draft');
        }

        function remove(id) {
            return httpResource.patch('/meeting/' + id + '/removed');
        }

        function addTimelineItem( request) {
            return httpResource.post('/meeting/add/timelineItem', request);
        }

        // function addTimelineNotice( request) {
        //     return httpResource.post('/meeting/' + request.meetingId + '/notice', request);
        // }
        //
        // function addTimelineAction( request) {
        //     return httpResource.post('/meeting/' + request.meetingId + '/action', request);
        // }
        //
        // function addTimelineActionItem(request) {
        //     return httpResource.post('/meeting/' + request.meetingId + '/actionItem', request);
        // }
        //
        // function addTimelineEmotion(request) {
        //     return httpResource.post('/meeting/' + request.meetingId + '/emotion', request);
        // }

        function editTimelineItem(request) {
            return httpResource.put('/meeting/timelineItem', request)
        }

        function getTimeline(meetingId, guid) {
            return httpResource.get('/meeting/' + meetingId + '/timeline?guid=' + encodeURIComponent(guid));
        }

        function setCurrentAgendaItem(meetingId, request) {
            return httpResource.post('/meeting/' + meetingId + '/currentAgendaItem', request);
        }

        function getMeetingMinutes(id) {
            return httpResource.get('/minutes/' + id);
        }

        function getPublicMeetingMinutes(request) {
            return httpResource.post('/public/minutes', request);
        }

        function finishMeeting(meetingId, dto) {
            return httpResource.post('/meeting/' + meetingId + '/finish', dto);
        }


        function markAgendaItemAsDone(id) {
            return httpResource.post('/meeting/agenda/' + id + '/done');
        }

        function markAgendaItemAsNotDone(id) {
            return httpResource.post('/meeting/agenda/' + id + '/notdone');
        }

        function markGoalAsDone(id) {
            return httpResource.post('/meeting/goal/' + id + '/done');
        }

        function markGoalAsNotDone(id) {
            return httpResource.post('/meeting/goal/' + id + '/notdone');
        }

        function getPersonMeetings(id) {
            return httpResource.get('/meetings/person/' + id);
        }

        function getUserMeetings(id) {
            return httpResource.get('/meetings/user/' + id);
        }

        function CreateMeetingBasedOnTimelineItem(dto) {
            return httpResource.post('/meeting/based-on-timeline-item', dto);
        }

        function markToDoAsDone(todoDto){
            return httpResource.put('/todo/done', todoDto);
        }

        function shareMeeting(permissionDto) {
            return httpResource.put('/meeting/share', permissionDto);
        }

        return {
            getFutureFollowUps: getFutureFollowUps,
            getPastFollowUps: getPastFollowUps,
            attachMeetingToActionItem: attachMeetingToActionItem,
            checkIfTimelineRefreshNeeded: checkIfTimelineRefreshNeeded,
            checkIfMeetingRefreshNeeded: checkIfMeetingRefreshNeeded,
            CreateMeetingBasedOnTimelineItemExecute: CreateMeetingBasedOnTimelineItemExecute,

            getPersonMeetings: getPersonMeetings,
            getUserMeetings: getUserMeetings,

            getMeetingsByDay: getMeetingsByDay,
            markAgendaItemAsDone: markAgendaItemAsDone,
            markAgendaItemAsNotDone: markAgendaItemAsNotDone,
            markGoalAsDone: markGoalAsDone,
            markGoalAsNotDone: markGoalAsNotDone,
            getMeetingMinutes: getMeetingMinutes,
            getPublicMeetingMinutes: getPublicMeetingMinutes,
            createDraft: createDraft,
            createInstantMeeting: createInstantMeeting,
            save: save,
            saveDayPlan: saveDayPlan,
            estimate: estimate,
            "delete": $delete,
            getMeetingById: getMeetingById,
            getDrafts: getDrafts,
            getMeetings: getMeetings,

            saveGoal: saveGoal,
            deleteGoal: deleteGoal,
            finishMeeting: finishMeeting,
            saveAgendaItem: saveAgendaItem,

            deleteAgendaItem: deleteAgendaItem,
            moveUpAgendaItem: moveUpAgendaItem,
            moveDownAgendaItem: moveDownAgendaItem,

            assignGoalToAgendaItem: assignGoalToAgendaItem,
            unassignGoalFromAgendaItem: unassignGoalFromAgendaItem,

            addInternalParticipant: addInternalParticipant,
            addExternalParticipant: addExternalParticipant,
            deleteInternalParticipant: deleteInternalParticipant,
            deleteExternalParticipant: deleteExternalParticipant,
            getAvailableMembers: getAvailableMembers,
            suggestion: suggestion,
            getSuggestions: getSuggestions,
            publish: publish,
            start: start,
            close: close,
            cancel: cancel,
            draft: draft,
            remove: remove,
            translateStatus: translateStatus,
            getTimeline: getTimeline,

            // addTimelineNotice: addTimelineNotice,
            // addTimelineAction: addTimelineAction,
            // addTimelineEmotion: addTimelineEmotion,
            // addTimelineActionItem: addTimelineActionItem,
            addTimelineItem: addTimelineItem,
            editTimelineItem: editTimelineItem,

            setCurrentAgendaItem: setCurrentAgendaItem,

            getNotPlannedMeetings: getNotPlannedMeetings,
            endTime: endTime,

            planNewMeeting: planNewMeeting,
            meetingDetailsUrl: meetingDetailsUrl,

            markToDoAsDone: markToDoAsDone,
            shareMeeting: shareMeeting
        };

    }

})();
