/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    function routesConfig($stateProvider, authorizationProvider) {
        $stateProvider
            .state('main.users',
                {
                    url: '/users',
                    templateUrl: 'views/main/workgroup/user-list.tpl.html',
                    controller: 'UserListController',
                    resolve: {
                        
                    },
                    data: {
                        pageTitle: 'Workgroup'
                    }
                }).state('main.user/edit',
                {
                    url: '/user/edit/:id',
                    templateUrl: 'views/main/workgroup/user.tpl.html?v=' + new Date(),
                    controller: 'UserController',
                    resolve: {
                        
                    },
                    data: {
                        pageTitle: 'Workgroup User'
                    }
                }).state('main.user/view',
                {
                    url: '/user/view/:id',
                    templateUrl: 'views/main/workgroup/user.tpl.html?v=' + new Date(),
                    controller: 'UserController',
                    resolve: {
                        
                    },
                    data: {
                        pageTitle: 'Workgroup User'
                    }
                }).state('main.user/create',
                {
                    url: '/user/create',
                    templateUrl: 'views/main/workgroup/user.tpl.html?v=' + new Date(),
                    controller: 'UserController',
                    resolve: {
                        
                    },
                    data: {
                        pageTitle: 'Workgroup User'
                    }
                }).state('main.workgroup/view',
                {
                    url: '/workgroup/view/:id',
                    templateUrl: 'views/main/workgroup/workgroup.tpl.html?v=' + new Date(),
                    controller: 'WorkgroupController',
                    resolve: {
                        
                    },
                    data: {
                        pageTitle: 'Workgroup User'
                    }
                }).state('main.workgroups',
                {
                    url: '/workgroups',
                    templateUrl: 'views/main/workgroup/workgroup-list.tpl.html?v=' + new Date(),
                    controller: 'WorkgroupListController',
                    resolve: {
                        
                    },
                    data: {
                        pageTitle: 'Workgroups'
                    }
                });

    }

    routesConfig.$inject = ['$stateProvider', 'authorizationProvider'];

    angular.module('meetingMinutes.main.workgroup')
        .config(routesConfig);

})();
