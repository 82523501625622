/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.repository.remote')
        .factory('authenticationResource', remoteAuthenticationResourceFactory);

    remoteAuthenticationResourceFactory.$inject = ['httpResource'];

    function remoteAuthenticationResourceFactory(httpResource) {

        function signIn(usernamePasswordAuthentication) {
            return httpResource.post('/authentication',
                $.param(usernamePasswordAuthentication),
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    }
                });
        }

        function getAuthentication() {
            return httpResource.get('/authentication');
        }

        function signOut() {
            return httpResource.delete('/authentication');
        }

        return {
            signIn: signIn,
            getAuthentication: getAuthentication,
            signOut: signOut
        };
    }

})();
