import { FSAWithPayload, FSA } from "flux-standard-action";


export interface TimelineUiState {
    timelineModalOpen: boolean;
    timelineModalMeetingId: number;
}

export const UI_TIMELINE_OPEN_MODAL = 'spinme/ui/timeline/OPEN_MODAL';
export const uiTimelineOpenModal = (meetingId: number): FSAWithPayload<string, number> => {
    return {
        type: UI_TIMELINE_OPEN_MODAL,
        payload: meetingId
    }
}

export const UI_TIMELINE_CLOSE_MODAL = 'spinme/ui/timeline/CLOSE_MODAL';
export const uiTimelineCloseModal = (): FSA => {
    return {
        type: UI_TIMELINE_CLOSE_MODAL
    }
}

export const INTIAL_TIMELINE_UI_STATE: TimelineUiState = {
    timelineModalOpen: false,
    timelineModalMeetingId: null
}

export function uiTimelineReducers(state: TimelineUiState = INTIAL_TIMELINE_UI_STATE, action: FSAWithPayload<string, number>): TimelineUiState {
    switch (action.type) {
        case UI_TIMELINE_OPEN_MODAL:
            const meetingId = action.payload as number;
            return {
                ...state,
                timelineModalMeetingId: meetingId,
                timelineModalOpen: true
            }
        case UI_TIMELINE_CLOSE_MODAL:
                return {
                        ...state,
                        timelineModalMeetingId: null,
                        timelineModalOpen: false
                }
        default: 
            return state;
    }
}