/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.knowledge')
        .controller('NoteController', NoteController);

    NoteController.$inject = [
        'accessPopup', '$state', '$rootScope', '$scope', '$location', '$stateParams', 'knowledgeObjectResource',
        'toastr', 'userResource', 'noteResource', 'popupHelper', 'meetingResource', 'dateHelper', '$uibModal',
        'workgroupResource', 'removePopup'
    ];

    function NoteController(accessPopup,
        $state,
        $rootScope,
        $scope,
        $location,
        $stateParams,
        knowledgeObjectResource,
        toastr,
        userResource,
        noteResource,
        popupHelper,
        meetingResource,
        dateHelper,
        $uibModal,
        workgroupResource,
        removePopup) {
        $scope.attachmentUploadUrl = "";

        if ($state.$current.self.name == 'main.note/edit')
            $scope.mode = 'edit';
        if ($state.$current.self.name == 'main.note/create')
            $scope.mode = 'create';
        if ($state.$current.self.name == 'main.note/view')
            $scope.mode = 'view';

        $scope.editMode = function() {
            $scope.mode = 'edit';
        };

        $scope.xxx;
        $scope.$context = $rootScope.$context.$set('/notes/create');
        $scope.backUrl = $location.$$search.backUrl;
        $scope.tags = $location.$$search.tags;

        $scope.searchRelatedNotes = function() {
            var request = {
                from: 0,
                to: 100,
                take: 100,
                tagFilter: []
            };
            request.tagFilter.push('n:' + $scope.formData.item.id);

            noteResource.getNotes(request).then(function(result) {
                $scope.formData.item.relatedNotes = result;
            });
        };

        $scope.createRelatedNote = function() {
            popupHelper.CreateKoNote($scope, "n:" + $scope.formData.item.id);
        }

        activate();

        $scope.$on('fileuploaddone',
            function(event, data) {
                if (data.paramName == 'profilePicture') {
                    $scope.obj.src = data.result;
                }
            });

        $scope.cancel = function() {
            $location.path($scope.backUrl);
        }

        $scope.readonly = false;
        $scope.selectedItem = null;
        $scope.searchText = null;
        $scope.availableProfiles = [];
        $scope.availableTags = [];

        function createFilterFor(query) {
            var lowercaseQuery = angular.lowercase(query);
            return function filterFn(vegetable) {
                vegetable = angular.lowercase(vegetable);
                return (vegetable.indexOf(lowercaseQuery) === 0);
            };
        }

        $scope.querySearchTags = function(query) {
            var results = query ? $scope.availableTags.filter(createFilterFor(query)) : [];
            return results;
        }

        $scope.access = function() {
            accessPopup.open($scope, $scope.formData.item.knowledgeObject);
            //$location.path("/knowledge/access/" + $scope.formData.item.knowledgeObject.id);
        }

        $scope.delete = function() {
            removePopup.open($scope, $scope.formData.item.knowledgeObject);
            //$location.path("/knowledge/access/" + $scope.formData.item.knowledgeObject.id);
        }

        $scope.save = function() {
            noteResource.save($scope.formData.item).then(function(response) {
                $scope.mode = 'view';
                $scope.publish();
                toastr.success("Note have been succesfully saved");
            });
        }

        $scope.publish = function() {
            $scope.formData.item.knowledgeObject.status = 1; //published
            knowledgeObjectResource.publish($scope.formData.item.knowledgeObject.id).then(function() {
            });
        }

        $scope.draft = function() {
            $scope.formData.item.knowledgeObject.status = 0; //draft
            knowledgeObjectResource.draft($scope.formData.item.knowledgeObject.id);
        }


        $scope.hideUrlUploadPanel = function() {
            $scope.xxx.showUrlPanel = false;
        }

        $scope.refresh = function() {
            noteResource.getNote($scope.formData.item.id).then(function(response) {
                $scope.formData = { item: response };
            });
        }

        $scope.permissions = [];

        $scope.prepare = function(response) {
            response.knowledgeObject.createTimeTxt = moment(response.knowledgeObject.createTime).format('YYYY-MM-DD');

            $scope.formData = { item: response };

            knowledgeObjectResource.getPermissions($scope.formData.item.knowledgeObject.id).then(function(response2) {
                for (var i = 0; i < response2.length; i++) {
                    response2[i].permissionTypeTxt =
                        angular.globalSettingsConst.permissions[response2[i].permissionType];
                }
                $scope.permissions = response2;
            });

            $scope.searchRelatedNotes();
            $scope.attachmentUploadUrl =
                '/api/knowledgeObject/' + $scope.formData.item.knowledgeObject.id + '/attachment';
        }

        function activate() {
            if ($stateParams.id) {
                noteResource.getNote($stateParams.id).then(function(response) {
                    if ($scope.tags) {
                        var result = JSON.parse($scope.tags);
                        response.knowledgeObject.tags = result.tags;
                    }
                    $scope.prepare(response);
                });
            } else {
                noteResource.createNote().then(function(response) {
                    var tags = JSON.stringify($rootScope.contextTags);
                    noteResource.editNoteLocation(response.id, $scope.backUrl, tags);
                });
            }

            knowledgeObjectResource.getTags().then(function(response) {
                $scope.availableTags = response;
            });

        }

        $scope.$on('fileuploadadd',
            function(e, data) {
                if (data.paramName == 'attachments') {
                    $scope.attachmentsOptions.url = $scope.attachmentUploadUrl;
                    data.url = $scope.attachmentUploadUrl;
                    data.submit().then(function() {

                        $scope.refresh();
                    });
                }
            });

        $scope.fileUploadOptions = {
            url: '',
            autoUpload: false,
            xhrFields: {
                withCredentials: true
            }
        };

        $scope.attachmentsOptions = {
            autoUpload: false,
            url: '',
            xhrFields: {
                withCredentials: true
            }
        };

        $scope.onAttachmentDelete = function(data) {
            knowledgeObjectResource.deleteAttachment(data.id).then(function() {
                activate();
            });
        }
    }
})();
