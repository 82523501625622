/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.directives.homer')
        .directive('pageTitle', pageTitleDirectiveFactory);

    pageTitleDirectiveFactory.$inject = ['$rootScope', '$timeout'];

    /**
     * Directive for set Page title - mata title.
     */
    function pageTitleDirectiveFactory($rootScope, $timeout) {
        return {
            link: function(scope, element) {
                var listener = function(event, toState) {
                    var title = 'SpinMe';
                    if (toState.data && toState.data.pageTitle) {
                        title = 'SpinMe | ' + toState.data.pageTitle;
                    }
                    $timeout(function() {
                        element.text(title);
                    });
                };
                $rootScope.$on('$stateChangeStart', listener);
            }
        }
    }

})();
