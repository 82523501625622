(function() {
        'use strict';

angular.module('meetingMinutes.config',
    [])
    .constant('REMOTE_API_PREFIX', process.env.REMOTE_API_SERVICE)
    .constant('REMOTE_MSG_PREFIX', process.env.REMOTE_MSG_SERVICE)
    .config(configLogger);

    configLogger.$inject = ['$logProvider', '$httpProvider'];
    
    function configLogger($logProvider, $httpProvider) {
            $logProvider.debugEnabled(process.env.DEBUG_ENABLED);
            $httpProvider.defaults.withCredentials = true;
        }
})();