import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { List, ListItem, ListItemText, IconButton, ListItemSecondaryAction, ListItemIcon } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
    input: {
      display: 'none !important',
    },
  }),
);

export interface IUploadProps {
    onFilesAdded(files: FileList): void;
    deleteFile(file: string): void;
    files: string[]
}

export default function Upload(props: IUploadProps) {
  const classes = useStyles();

  return (
    <div>
      <input
        accept="*"
        className={classes.input}
        id="contained-button-file"
        multiple
        type="file"
        onChange={(event)=> { 
            props.onFilesAdded(event.target.files);
       }}
      />
      <label htmlFor="contained-button-file">
        <Button variant="contained" component="span" className={classes.button}>
          Upload
        </Button>
      </label>
<div>
        <List dense>
              {
                  props.files.map(file =>
                <ListItem key={file}>
                <ListItemIcon>
                    <AttachFileIcon />
                </ListItemIcon>
                  <ListItemText
                    primary={file}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="Delete" onClick={()=>props.deleteFile(file)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                )
              }
        </List>
</div>
    </div>
  );
}