/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.public')
        .controller('SignUpConfirmationController', SignUpConfirmationController);

    SignUpConfirmationController.$inject = ['$state', '$stateParams', 'toastr', 'userResource'];

    function SignUpConfirmationController($state, $stateParams, toastr, userResource) {
        var confirmPromise = userResource.confirmUser($stateParams.requestId);
        confirmPromise.then(transitionToSignInState);
        confirmPromise.then(displayNotification);

        function transitionToSignInState() {
            $state.go('public.dispatch');
        }

        function displayNotification() {
            toastr.success('Your email address was verified. Please sign in and start having fun!');
        }
    }

})();
