/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.knowledge.meeting')
        .controller('MeetingTimelineController', MeetingTimelineController);

    MeetingTimelineController.$inject = [
        '$timeout', '$cookies', '$cookieStore', 'dateHelper', '$location', 'popupHelper', '$rootScope', '$scope',
        '$state', 'meetingResource', '$stateParams', '$uibModal', 'toastr', '$interval', 'attachmentsHelper',
        'guestParticipantsPopup', 'userParticipantsPopup', 'meetingTimePopup', 'agendaItemPopup', 'goalItemPopup',
        'crmResource', 'meetingDurationPopup', 'textAngularManager', '$localStorage', '$sessionStorage', 'authenticationResource'
    ];

    function MeetingTimelineController($timeout,
        $cookies,
        $cookieStore,
        dateHelper,
        $location,
        popupHelper,
        $rootScope,
        $scope,
        $state,
        meetingResource,
        $stateParams,
        $uibModal,
        toastr,
        $interval,
        attachmentsHelper,
        guestParticipantsPopup,
        userParticipantsPopup,
        meetingTimePopup,
        agendaItemPopup,
        goalItemPopup,
        crmResource,
        meetingDurationPopup,
        textAngularManager,
        $localStorage,
        $sessionStorage, authenticationResource) {

        $rootScope.meetingId = $stateParams.meetingId;
        $scope.meetingGuid = null;
        $scope.timelineGuid = null;
        $scope.showMeetingDesc = false;

        $scope.appSettings = { 'application': $rootScope.authentication };


        //timeline types controls//
        $scope.timelineType = 0;
        $scope.storySeekersModeVisible = $scope.appSettings.application.userSettings.hasStorySeekersMode;
        if($scope.storySeekersModeVisible) $scope.enableStorySeekersMode = true;
        else $scope.enableStorySeekersMode = false;
        ////

        $scope.setTimelineType = function(type){
            $scope.timelineType = type;
        }

        $scope.$watch('enableStorySeekersMode',
            function() {
                if($scope.enableStorySeekersMode) $scope.setTimelineType(1);
                else $scope.setTimelineType(0);
            });


        $scope.findSettings = function(itemType, variantType) {
            if (itemType == 1) {
                for (var i = 0; i < $scope.appSettings.application.actions.length; i++) {
                    if ($scope.appSettings.application.actions[i].id == variantType)
                        return $scope.appSettings.application.actions[i];
                }
            }
            //due to TimelineItemType
            if (itemType == 2) {
                for (var i = 0; i < $scope.appSettings.application.emotions.length; i++) {
                    if ($scope.appSettings.application.emotions[i].id == variantType)
                        return $scope.appSettings.application.emotions[i];
                }
            }

            if (itemType == 3) {

            }
        };

        $scope.getDefault = function(itemType) {
            if (itemType == 1) {
                return $scope.appSettings.application.actions[0];
            }

            if (itemType == 2) {
                return $scope.appSettings.application.emotions[0];
            }
        };

        var meetingId = $stateParams.meetingId;

        $scope.showAllInformation = false;

        $scope.openMeetingDurationPopup = function() {
            meetingDurationPopup.open($scope, $scope.meeting, null);
        };

        $scope.openGuestParticipantsPopup = function() {
            guestParticipantsPopup.open($scope, $scope.meeting);
        };

        $scope.openUserParticipantsPopup = function() {
            userParticipantsPopup.open($scope, $scope.meeting);
        };

        $scope.openMeetingTimePopup = function() {
            meetingTimePopup.open($scope, $scope.meeting, $scope.refreshMeetingData);
        };

        $scope.openAgendaItemPopup = function() {
            agendaItemPopup.open($scope, $scope.meeting, null, null);
        };

        $scope.openGoalItemPopup = function() {
            goalItemPopup.open($scope, $scope.meeting, null, null);
        };

        TimelineOptionsCtrl.$inject = ['$scope', '$uibModalInstance', '$stateParams', 'meetingResource', '$uibModal', '$state'];
        function TimelineOptionsCtrl($scope, $uibModalInstance, $stateParams, meetingResource, $uibModal, $state) {

            $scope.meetingCloseRequest = {
                makeFollowUp: false,
                attachSummary: true,
                attachUnreachedAgendaItems: true,
                attachUnreachedGoals: true
            };

            // $scope.selectedEndHour = moment($scope.meeting.endTime).format("HH:mm");
            $scope.selectedEndHour = moment($scope.meeting.plannedEndTime).format("HH:mm");

            $scope.$watch('selectedEndHour',
                function() {
                    $scope.estimateMeeting();
                });

            $scope.estimateMeeting = function() {
            };

            $scope.updateMeetingTime = function() {
                meetingDurationPopup.open($scope, $scope.meeting, null);
            };

            $scope.realStartTime = moment(Date()).format("HH:mm");

            $scope.updated = function() {

            };

            $scope.ok = function() {
                meetingResource.finishMeeting(meetingId, $scope.meetingCloseRequest).then(function(xhr) {
                    if (xhr.id != meetingId) {
                        $rootScope.setEntryMessage(
                            "New stage reached - continue selling process using created new activity");
                    }
                    $state.go('main.meetingDetails', { meetingId: xhr.id });
                });
            };

            $scope.cancel = function() {
                $uibModalInstance.dismiss('cancel');
            };
        }

        EditItemCtrl.$inject = ['$scope', '$uibModalInstance'];
        function EditItemCtrl($scope, $uibModalInstance) {
            function refreshAttachments() {
                var id = $scope.editingItem.id;

                $scope.readTimeline();
            }

            $scope.editingTxt = $scope.editingItem.knowledgeObject.descriptionTxt;

            attachmentsHelper.setUrl($scope, $scope.editingItem.knowledgeObject.id);
            attachmentsHelper.initialize($scope, refreshAttachments);

            $scope.ok = function() {
                $scope.editAttachmentCallback();
                $uibModalInstance.dismiss('cancel');
            };

            $scope.cancel = function() {
                $uibModalInstance.dismiss('cancel');
            };
        }

        $scope.showOptions = function() {
            var modalInstance = $uibModal.open({
                templateUrl: 'views/main/meeting/timeline.options.tpl.html?v=' + new Date(),
                controller: TimelineOptionsCtrl,
                scope: $scope
            });
        };

        $scope.editItem = function(item) {
            $scope.editingItem = item;
            $scope.editingMode = "revision";
            var modalInstance = $uibModal.open({
                templateUrl: 'views/main/meeting/timeline.edit.tpl.html?v=' + new Date(),
                controller: EditItemCtrl,
                scope: $scope
            });
        };

        $scope.editAttachments = function(item) {
            $scope.editingItem = item;
            $scope.editingMode = "attachments";
            $scope.editAttachmentCallback = function () {
              $scope.readTimeline();
            };
            var modalInstance = $uibModal.open({
                templateUrl: 'views/main/meeting/timeline.edit.tpl.html',
                controller: EditItemCtrl,
                scope: $scope
            });
        };


        $scope.editMeeting = function() {
            $location.path('/meeting/plan/' + $scope.meeting.id)
                .search({ backUrl: '/meeting/timeline/' + $scope.meeting.id })
        };

        $scope.contextTags = [];

        $scope.addContextTag = function(tag) {
            $scope.contextTags.push(tag);
            $cookieStore.put('mtgCtxTags', $scope.contextTags);
        };

        $scope.refreshTimeline = function() {
            meetingResource.getTimeline(meetingId, $scope.timelineGuid).then(function(response) {
                if (response) {
                    $scope.timeline = response;
                    $scope.timelineGuid = response.cacheGuid;
                    for (var i = 0; i < $scope.timeline.length; i++) {
                        if ($scope.timeline[i].idx)
                            if ($scope.timeline[i].idx.deadline) {
                                $scope.timeline[i].idx.deadlineEx =
                                    dateHelper.enchance($scope.idx.timeline[i].deadline);
                            }
                        if ($scope.timeline[i].id == id) {
                            $scope.editingItem = $scope.timeline[i];
                        }
                    }
                }
            });
        };
        $scope.removeContextTag = function(tag) {
            var temp = [];
            for (var i = 0; i < $scope.contextTags.length; i++) {
                if ($scope.contextTags[i] != tag)
                    temp.push($scope.contextTags[i]);
            }
            $scope.contextTags = temp;
        };

        $scope.recalculateStats = function() {
            $scope.goalStat.data[0] = 0;
            $scope.goalStat.data[1] = 0;
            $scope.agendaStat.data[0] = 0;
            $scope.agendaStat.data[1] = 0;

            for (var i = 0; i < $scope.meeting.agendaItems.length; i++) {
                if ($scope.meeting.agendaItems[i].status === 1)
                    $scope.agendaStat.data[0]++;
                else
                    $scope.agendaStat.data[1]++;
            }

            if ($scope.meeting.agendaItems.length > 0)
                $scope.agendaStat.data[2] = $scope.agendaStat.data[0] * 100 / $scope.meeting.agendaItems.length;

            for (var i = 0; i < $scope.meeting.goals.length; i++) {
                if ($scope.meeting.goals[i].status === 1)
                    $scope.goalStat.data[0]++;
                else
                    $scope.goalStat.data[1]++;
            }
            if ($scope.meeting.goals.length > 0)
                $scope.goalStat.data[2] = $scope.goalStat.data[0] * 100 / $scope.meeting.goals.length;
        };


        $scope.selectDayCallback = function(deadline) {
            $scope.deadline = deadline;
            $scope.deadlineEx = dateHelper.enchance($scope.deadline);
        };

        $scope.selectDay = function() {
            meetingTimePopup.open($scope, null, $scope.selectDayCallback);
        };

        $scope.goalStat =
        {
            data: [0, 0, 0],
            options: {
                fill: ["#1ab394", "#edf0f5"],
                height: '50px',
                width: '50px',
            }
        };

        $scope.agendaStat =
        {
            data: [0, 0, 0],
            options: {
                fill: ["#1ab394", "#edf0f5"],
                height: '50px',
                width: '50px',
            }
        };

        $scope.timelineStat =
        {
            data: [0, 100],
            options: {
                fill: ["#265a88", "#edf0f5"],
                height: '50px',
                width: '50px',
            }
        };

        $scope.agendaDurationStat =
        {
            data: [0, 100],
            options: {
                fill: ["#265a88", "#edf0f5"],
                height: '50px',
                width: '50px',
            }
        };


        $scope.checkIfPasswordDirty = function(item) {
            if (item['password'].$dirty == true)
                return true;
            else
                return false;
        };

        $scope.timelineTags = [];

        $scope.readTimeline = function() {
            meetingResource.getTimeline(meetingId, $scope.timelineGuid).then(function(response) {
                if (response) {
                    $scope.timeline = response.items;
                    $scope.timelineTags = [];

                    for (var i = 0; i < response.tags.length; i++) {
                        if (response.tags[i].indexOf(':') === -1) {
                            $scope.timelineTags.push(response.tags[i]);
                        }
                    }

                    $scope.timelineGuid = response.cacheGuid;

                    for (var i = 0; i < $scope.timeline.length; i++) {
                        if ($scope.timeline[i].idx.deadline) {
                            $scope.timeline[i].idx.deadlineEx = dateHelper.enchance($scope.timeline[i].idx.deadline);
                        }
                        $scope.timeline[i].editMode = false;
                        $scope.timeline[i].executeTimeLocal =
                            moment($scope.timeline[i].executeTime).format('HH:mm:ss DD.MM.YYYY')
                    }
                }
            });
        };

        $scope.agendaStatusCss = function(agendaItem) {
            if (agendaItem.status == 0)
                return "btn-default";
            if (agendaItem.status == 1)
                return "btn-success";
        };

        $scope.readTimeline();

        $scope.$context = $rootScope.$context.$set('/meetings/timeline');

        $scope.timeline = [];
        $scope.meeting = {};

        $scope.addOrganizatorToContext = function(organizator) {
            organizator.InContext = true;
            $cookieStore.put('internalParticipants', $scope.meeting.sponsor);
        };

        $scope.addUserToContext = function(user) {
            user.InContext = true;
            $cookieStore.put('internalParticipants', $scope.meeting.internalParticipants);
        };

        $scope.addGuestToContext = function(person) {
            person.InContext = true;
            $cookieStore.put('enternalParticipants', $scope.meeting.externalParticipants);
        };

        $scope.contextTags = [];

        $scope.selectedItemType = 0;

        $scope.settings = true;
        $scope.selectedVariant = null;

        $scope.selectItemType = function(itemType) {
            $scope.selectedItemType = itemType;
            // $scope.setItemVariant($scope.getDefault(itemType));
        };

        $scope.markAgendaItemAsDone = function(item) {
            if ($scope.meeting.isSponsor == true) {
                item.status = 1;
                meetingResource.markAgendaItemAsDone(item.id);
            }
        }

        $scope.markAgendaItemAsNotDone = function(item) {
            if ($scope.meeting.isSponsor == true) {
                item.status = 0;
                meetingResource.markAgendaItemAsNotDone(item.id);
            }
        };

        $scope.markGoalAsDone = function(item) {
            if ($scope.meeting.isSponsor == true) {
                item.status = 1;
                meetingResource.markGoalAsDone(item.id).then(function() {
                });
            }
        };

        $scope.markGoalAsNotDone = function(item) {
            if ($scope.meeting.isSponsor == true) {
                item.status = 0;
                meetingResource.markGoalAsNotDone(item.id).then(function() {
                });
            }
        };

        $scope.markMilestoneAsDone = function(item, stage) {
            if ($scope.meeting.isSponsor == true) {
                item.status = 1;
                openAccordeon();
                crmResource.setMilestoneDone($scope.meeting.id, stage.id, item.name).then(function() {
                });
            }
        };

        $scope.markMilestoneAsNotDone = function(item, stage) {
            if ($scope.meeting.isSponsor == true) {
                item.status = 0;
                crmResource.setMilestoneNotDone($scope.meeting.opportunity.id, stage.id, item.name).then(function() {
                });
            }
        };

        $scope.switchAgendaStatus = function(item) {
            if ($scope.meeting.isSponsor == true) {
                if (item.status == 0)
                    $scope.markAgendaItemAsDone(item);
                else
                    $scope.markAgendaItemAsNotDone(item);
                $scope.recalculateStats();
            }
        };

        $scope.switchGoalStatus = function(item) {
            if ($scope.meeting.isSponsor == true) {
                if (item.status == 0)
                    $scope.markGoalAsDone(item);
                else
                    $scope.markGoalAsNotDone(item);
                $scope.recalculateStats();
            }
        };

        $scope.switchMilestoneStatus = function(item, stage) {
            if ($scope.meeting.isSponsor == true) {
                if (item.status == 0) {
                    $scope.markMilestoneAsDone(item, stage);
                }
                else
                    $scope.markMilestoneAsNotDone(item, stage);
                $scope.recalculateStats();
            }
        };

        $scope.setCurrentAgendaItem = function(item) {
            if ($scope.meeting.isSponsor == true)
                meetingResource.setCurrentAgendaItem($scope.meeting.id, item).then(function(response) {
                    $scope.refreshMeetingData();

                });
        };

        $scope.selectTag = function(tag) {
            tag.InContext = true;
        };

        $scope.setItemVariant = function(itemType, item) {
            $scope.selectItemType(itemType);
            $scope.selectedVariant = item;
        };

        authenticationResource.getAuthentication().then(function (authentication) {
            $scope.loggedUser = authentication;
            $rootScope.authentication = authentication;
        });

        $scope.removeFromContext = function(item) {
            item.InContext = false;
        };
        $scope.actionItemRelatedUsers = [];

        $scope.updateTimelineText = "";
        $scope.changeEditMode = function(item) {
            item.editMode = !item.editMode;
            // $scope.updateTimelineText = item.idx.html;
        };

        $scope.update = function(item) {
            item.knowledgeObject.descriptionHtml = item.idx.html;
            meetingResource.editTimelineItem(item).then(function(response) {
                $scope.updateTimelineText = '';
                $scope.readTimeline();
                item.editMode = false;
            });
        };

        function createTimelineItemDto(relatedUsers, relatedGuests){
            var dto = {
                meetingId: $scope.meeting != null ? $scope.meeting.id : null,
                agendaId: $scope.currentAgendaId,
                knowledgeObject: {
                    descriptionHtml: $scope.$storage.timelineTxt,
                    tags: $scope.contextTags,
                },
                executionDeadline: $scope.deadline,
                timelineType: $scope.timelineType,
                type: $scope.selectedItemType,
                timelineItemTypeVariant: $scope.selectedVariant != null ? $scope.selectedVariant.id : null,
                relatedUsers: relatedUsers,
                relatedGuests: relatedGuests
            };
            return dto;
        }

        $scope.send = function() {

            $scope.isSending = true;

            var relatedUsers = [];
            for (var i = 0; i < $scope.meeting.internalParticipants.length; i++) {
                if ($scope.meeting.internalParticipants[i].InContext == true) {
                    relatedUsers.push($scope.meeting.internalParticipants[i])
                }
            }

            var relatedGuests = [];
            for (var i = 0; i < $scope.meeting.externalParticipants.length; i++) {
                if ($scope.meeting.externalParticipants[i].InContext == true) {
                    relatedGuests.push($scope.meeting.externalParticipants[i])
                }
            }

            var contextTags = [];
            for (var i = 0; i < $scope.meeting.knowledgeObject.tags.length; i++) {
                if ($scope.meeting.knowledgeObject.tags[i].InContext == true) {
                    contextTags.push($scope.meeting.knowledgeObject.tags[i]);
                }
            }

            var dto = createTimelineItemDto(relatedUsers, relatedGuests);
            meetingResource.addTimelineItem(dto).then(function(response) {
                $scope.$storage.timelineTxt = "";
                $scope.readTimeline();
                $scope.isSending = false;
            });

            
        }

        $scope.sendRevision = function() {
            var relatedUsers = $scope.editingItem.idx.relatedUsers;
            var relatedGuests = $scope.editingItem.idx.relatedGuests;
            var contextTags = $scope.editingItem.idx.tags;

            var dto = {
                revisionOfId: $scope.editingItem.id,
                agendaId: $scope.editingItem.agendaId,
                text: $scope.editingTxt,
                tags: $scope.contextTags,
                relatedUsers: relatedUsers,
                relatedGuests: relatedGuests
            };

            if ($scope.selectedItemType == "notice") {
                meetingResource.addTimelineNotice($scope.meeting.id, dto).then(function(response) {
                    $scope.cancel();
                    $scope.readTimeline();
                });
            }

            if ($scope.selectedItemType == "emotion") {
                var dto = {
                    tags: $scope.contextTags,
                    agendaId: $scope.currentAgendaId,
                    emotionType: $scope.selectedVariant.id,
                    text: $scope.$storage.timelineTxt,
                    relatedUsers: relatedUsers,
                    relatedGuests: relatedGuests
                };
                meetingResource.addTimelineEmotion($scope.meeting.id, dto).then(function(response) {
                    $scope.$storage.timelineTxt = "";
                    $scope.readTimeline();
                });
            }

            if ($scope.selectedItemType == "action") {
                var dto = {
                    agendaId: $scope.currentAgendaId,
                    actionType: $scope.selectedVariant.id,
                    text: $scope.$storage.timelineTxt,
                    relatedUsers: relatedUsers,
                    tags: $scope.contextTags,
                    relatedGuests: relatedGuests
                };
                meetingResource.addTimelineAction($scope.meeting.id, dto).then(function(response) {
                    $scope.$storage.timelineTxt = "";
                    $scope.readTimeline();
                });
            }

            if ($scope.selectedItemType == "actionItem") {
                var dto = {
                    agendaId: $scope.currentAgendaId,
                    deadline: $scope.deadline,
                    text: $scope.$storage.timelineTxt,
                    relatedUsers: relatedUsers,
                    relatedGuests: relatedGuests,
                    tags: $scope.contextTags
                };

                meetingResource.addTimelineActionItem($scope.meeting.id, dto).then(function(response) {
                    $scope.$storage.timelineTxt = "";
                    $scope.readTimeline();
                });
            }
        }

        $scope.view = function() {
            $state.go('main.meetingDetails', { meetingId: $scope.meeting.id });
        }

        $scope.anyInContext = function() {
            var cnt = 0;
            if($scope.meeting.internalParticipants)
                for (var i = 0; i < $scope.meeting.internalParticipants.length; i++) {
                    if ($scope.meeting.internalParticipants[i].InContext == true)
                        cnt++;
                }
            return cnt > 0;
        }

        $scope.agendaDuration = 0;

        $scope.refreshTimes = function() {
            var nowDate = moment();
            var startDate = moment($scope.meeting.realStartTime);
            var endDate = moment($scope.meeting.plannedEndTime);

            var diff1 = endDate.diff(startDate);
            var diff2 = endDate.diff(nowDate);
            $scope.completeness = 100 - diff2 * 100 / diff1;

            $scope.meeting.realStartTimeEx = dateHelper.enchance($scope.meeting.realStartTime);
            $scope.meeting.plannedEndTimeEx = dateHelper.enchance($scope.meeting.plannedEndTime);
            // $scope.meeting.plannedEndTimeEx = dateHelper.enchance($scope.meeting.endTime);

            var teamCost = 0;


            if ($scope.meeting.plannedEndTime)
                $scope.meeting.plannedEndTimeLocal =
                    moment($scope.meeting.plannedEndTime).format("YYYY-MM-DD HH:MM");

            var prog = 100 - diff2 * 100 / diff1;

            $scope.timelineStat.data[0] = prog;
            $scope.timelineStat.data[1] = 100 - prog;

            var minutesPassed = (new Date().getTime() - new Date($scope.meeting.realStartTime)) / 60000;

            angular.forEach($scope.meeting.internalParticipants,
                function(user) {
                    teamCost = teamCost + user.cost;
                });

            $scope.meeting.teamCost = Math.round(minutesPassed * teamCost / 60);

            angular.forEach($scope.meeting.agendaItems,
                function(item) {
                    if (item.isHot == true) {
                        $scope.agendaDuration = item.absoluteDuration;
                    }
                });


            var val1 = 100 - minutesPassed * 100 / $scope.agendaDuration;

            $scope.agendaDurationStat.data[1] = val1;
            $scope.agendaDurationStat.data[0] = 100 - val1;


            if (val1 < 100) {
                $scope.agendaDurationStat.options.fill[0] = "#286090";
            }
            if (val1 < 40) {
                $scope.agendaDurationStat.options.fill[0] = "#eb9316";
            }
            if (val1 < 0) {
                $scope.agendaDurationStat.options.fill[0] = "#AAAAAA";
            }
        }

        $scope.refreshMeetingData = function() {
            meetingResource.getMeetingById(meetingId, $scope.meetingGuid).then(function(meeting) {
                if (meeting) {
                    //if meeting is closed (by the owner)
                    // var difference = moment().diff(meeting.meetingUpdateTime);
                    // var twoHoursPlus30s = 30000 * 2 * 60 * 2 + 30000;
                    //close meeting only if iT has status 'closed' and was closed not earlier than 31 sec ago (2 * refresh time + 1 sec)
                    // if (meeting.status === 2 && (difference < 31000))
                    //     $state.go('main.meetingDetails', { meetingId: meeting.id });

                    $scope.meeting = meeting;
                    $scope.meetingGuid = meeting.cacheGuid;
                    $scope.meeting.realStartTimeEx = dateHelper.enchance($scope.meeting.realStartTime);
                    $scope.meeting.realEndTimeEx = dateHelper.enchance($scope.meeting.realEndTime);
                    // $scope.meeting.plannedEndTimeEx = dateHelper.enchance($scope.meeting.endTime);
                    $scope.meeting.plannedEndTimeEx = dateHelper.enchance($scope.meeting.plannedEndTime);
                    $scope.meeting.meetingUpdateTime = $scope.meeting.realStartTime;
                    $scope.meeting.timelineUpdateTime = $scope.meeting.realStartTime;

                    if ($scope.meeting.plannedEndTime)
                        $scope.meeting.plannedEndTimeLocal =
                            moment($scope.meeting.plannedEndTime).format("YYYY-MM-DD HH:MM");

                    for (var i = 0; i < $scope.meeting.internalParticipants.length; i++) {
                        $scope.meeting.internalParticipants[i].InContext = false;
                    }

                    for (var i = 0; i < $scope.meeting.externalParticipants.length; i++) {
                        $scope.meeting.externalParticipants[i].InContext = false;
                    }

                    var durationSum = 0;
                    for (var i = 0; i < $scope.meeting.agendaItems.length; i++) {
                        $scope.meeting.agendaItems[i].lp = i + 1;
                        durationSum = durationSum + $scope.meeting.agendaItems[i].duration;
                        $scope.meeting.agendaItems[i].absoluteDuration = durationSum;
                        if ($scope.meeting.agendaItems[i].status == -1) {
                            $scope.meeting.agendaItems[i].status = 0;
                        }
                        $scope.meeting.agendaItems[i].statusBoolean = $scope.meeting.agendaItems[i].status === 0 ? false : true;
                    }
                    $scope.recalculateStats();

                    openAccordeon();
                }
                // else {
                //     $state.go('main.meetingDetails', { meetingId: meeting.id });
                // }
            });
        };

        function openAccordeon() {
            if ($scope.meeting.salesStages != null || $scope.meeting.salesStages != undefined) {
                for (let index = 0; index < $scope.meeting.salesStages.length; index++) {
                    const stage = $scope.meeting.salesStages[index];
                    const allMilestonesDone = stage.milestones.reduce((x,y) => { return x && y.statusBoolean }, true);
                    if (!allMilestonesDone) {
                        stage.expanded = true;
                        $("#collapse"+stage.id).collapse('show');
                        return;
                    } 
                }
            }
        }

        $scope.openInNewTab = function() {
            var win = window.open("/client/#/meeting/minutes/" + $scope.meeting.id, '_blank');
            win.focus();
        };

        $scope.$storage = $localStorage.$default({
            timelineTxt: ''
        });
        // $scope.$storage.timelineTxt = "";
        // $scope.timelineTxt = "";
        $scope.refreshMeetingData();
        $scope.currentTime = {};

        var intervalPromise1 = $interval(function() {
                $scope.currentTime.time = moment().format('HH:mm:ss');
            },
            10000);

        var intervalPromise2 = $interval(function() {
                $scope.refreshTimes();
            },
            1000);

        var intervalPromise3 = $interval(function() {
                meetingResource
                    .checkIfMeetingRefreshNeeded($scope.meeting.id, encodeURIComponent($scope.lastRefreshMeeting)).then(
                    function(xhr) {
                        if (xhr === true) {
                            $scope.refreshMeetingData();
                            $scope.lastRefreshMeeting = moment().format();
                        }
                    });
            },
            15000);

        $scope.lastRefreshTimeline = moment().format();
        $scope.lastRefreshMeeting = moment().format();

        var intervalPromise = $interval(function() {
                meetingResource
                    .checkIfTimelineRefreshNeeded($scope.meeting.id, encodeURIComponent($scope.lastRefreshTimeline))
                    .then(
                        function(xhr) {

                            if (xhr === true) {
                                $scope.readTimeline();
                                $scope.lastRefreshTimeline = moment().format();
                            }
                        });
                //$scope.readTimeline();
            },
            5000);


        $scope.editAgenda = function() {
            popupHelper.EditAgenda($scope, $scope.meeting);
        }

        $scope.editGoals = function() {
            popupHelper.EditGoals($scope, $scope.meeting);
        }

        $scope.editUserParticipants = function() {
            popupHelper.EditUserParticipants($scope, $scope.meeting);
        }

        $scope.editGuestParticipants = function() {
            popupHelper.EditGuestParticipants($scope, $scope.meeting);
        }

        $scope.timelineExtended = false;
        $scope.switchGuestParticipantsMode = false;

        $scope.switchTimelineMode = function() {
            $scope.timelineExtended = !$scope.timelineExtended;
            $scope.showAllInformation = !$scope.showAllInformation;
        }

        $scope.switchGuestParticipantsMode = function() {
            $scope.guestParticipantsExtended = !$scope.guestParticipantsExtended;
        }

        $scope.switchUserParticipantsMode = function() {
            $scope.userParticipantsExtended = !$scope.userParticipantsExtended;
        }

        $scope.createRelatedNote = function(item) {
            popupHelper.CreateTimelineNote($scope, item.id);
        }

        $scope.openNote = function(item) {
            popupHelper.OpenNote($scope, item);
        }

        $scope.$on("$destroy",
            function() {
                $interval.cancel(intervalPromise);
                $interval.cancel(intervalPromise1);
                $interval.cancel(intervalPromise2);
                $interval.cancel(intervalPromise3);
            });
    }
})();

