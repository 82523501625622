import {combineReducers } from 'redux'
import todoReducers from './models/todo';
import workgroupReducers from './models/workgroup';
import uiReducers from './uistate';
import { AppState } from './appstate';

const appReducers = combineReducers<AppState>(
    {
        todos: todoReducers,
        workgroup: workgroupReducers,
        ui: uiReducers
    }
)

export default appReducers