/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.profile')
        .controller('ProfileController', ProfileController);

    ProfileController.$inject = [
        '$rootScope', '$scope', 'userResource', 'ngJcropConfig', 'knowledgeObjectResource', 'toastr', '$filter'
    ];

    function ProfileController($rootScope,
        $scope,
        userResource,
        ngJcropConfig,
        knowledgeObjectResource,
        toastr,
        $filter) {
        $scope.onSetNewProfilePicture = onSetNewProfilePicture;
        $scope.onCancelProfilePicture = onCancelProfilePicture;

        $rootScope.$context.$set('/profile', $scope);

        activate();

        $scope.settings = {
            workStartHour: "9:00",
            workEndHour: "16:00",
            saturdaysWorking: true,
            sundaysWorking: true,
            costModifiers: [85, 60, 35, 20, 5],
            afterHours: [0, 0, 0, 0, 0],
            ATaskLimitation: 20,
            ETaskLimitation: 20,
            utilizationMinutes: 40
        }

        $scope.setAfterHoursOption = function(id, value) {
            $scope.settings.afterHours[id] = value;
        }

        $scope.translateTaskBehavior = function(id) {
            return $scope.tasksBehaviors[id].name;
        }

        $scope.tasksBehaviors = [
            { value: 0, name: "Do nothing" },
            { value: 1, name: "Cancel" },
            { value: 0, name: "Postpone till next day" },
            { value: 3, name: "Pospone till next day with lower priority" }
        ]

        $scope.updateAllSettings = function() {
            //2do
            $scope.updateSettings(0, $scope.settings.workStartHour);
        }


        $scope.updateProfile = function() {
            userResource.updateSignedUser($scope.formData.profile).then(function() {
                toastr.success("Profile updated");
            });
        }


        $scope.options = {
            skin: {
                type: 'tron',
                width: 2,
                color: '#494B52',
                spaceWidth: 1
            },
            barColor: '#494B52',
            trackWidth: 2,
            barWidth: 10,
            textColor: '#494B52',
            step: 1,
            max: 0,
            size: 50
        };

        $scope.utilizationOptions = {
            skin: {
                type: 'tron',
                width: 2,
                color: '#494B52',
                spaceWidth: 1
            },
            barColor: '#494B52',
            trackWidth: 2,
            barWidth: 10,
            textColor: '#494B52',
            step: 1,
            max: 480,
            size: 50
        };

        $scope.ionSliderOptions1 = {
            min: 0,
            max: 5000,
            type: 'double',
            prefix: "$",
            maxPostfix: "+",
            prettify: false,
            hasGrid: true
        };
        $scope.ionSliderOptions2 = {
            min: 0,
            max: 10,
            type: 'single',
            step: 0.1,
            postfix: " carats",
            prettify: false,
            hasGrid: true
        };
        $scope.ionSliderOptions3 = {
            min: -50,
            max: 50,
            from: 0,
            postfix: "�",
            prettify: false,
            hasGrid: true
        };
        $scope.ionSliderOptions4 = {
            values: [
                "January", "February", "March",
                "April", "May", "June",
                "July", "August", "September",
                "October", "November", "December"
            ],
            type: 'single',
            hasGrid: true
        };
        $scope.ionSliderOptions5 = {
            min: 10000,
            max: 100000,
            step: 100,
            postfix: " km",
            from: 55000,
            hideMinMax: true,
            hideFromTo: false
        };

        $scope.inputTextToTag = function(newTag) {
            return { text: newTag };
        };

        $scope.onTagSelect = function(selectedTagRef) {
            knowledgeObjectResource.saveTag($scope.formData.profile.knowledgeObjectDto.id, selectedTagRef.text)
                .then(overrideTags);
        };

        $scope.onTagDelete = function(deletedTagRef) {
            knowledgeObjectResource.deleteTag(deletedTagRef.id)
                .then(overrideTags);
        };

        function overrideTags(tags) {
            $scope.formData.profile.knowledgeObjectDto.tags = tags;
        }


        function activate() {
            userResource.getProfile().then(function(profile) {
                $scope.formData = { profile: profile };
                knowledgeObjectResource.getTags().then(function(tags) {
                    $scope.formData.availableTags = tags;
                });
            });

            $scope.$on('fileuploaddone',
                function(event, data) {
                    $scope.obj.src = data.result;
                });

            var url = '/api/user/profile/picture/upload';
            $scope.fileUploadOptions = {
                autoUpload: true,
                url: url,
                xhrFields: {
                    withCredentials: true
                }
            };
        }

        function onSetNewProfilePicture() {
            var coords = $scope.obj.coords;
            userResource.saveProfilePicture({
                cropPointX: coords[0],
                cropPointY: coords[1],
                cropWidth: coords[4],
                cropHeight: coords[5]
            }).then(function() {
                $scope.obj.src = null;
                var tmp = $scope.formData.profile.avatarUrl;
                $scope.formData.profile.avatarUrl = tmp + "?_ts=" + new Date().getTime();
                $rootScope.refreshHeader();
            });
        }

        function onCancelProfilePicture() {
            $scope.obj.src = null;
        }

        // do kropa config
        $scope.obj = {};

        // The url or the data64 for the image
        $scope.obj.src = null; //'images/test_crop.jpg';

        // Must be [x, y, x2, y2, w, h]
        $scope.obj.coords = [100, 100, 200, 200, 100, 100];

        // You can add a thumbnail if you want
        $scope.obj.thumbnail = false;
    }

})();
