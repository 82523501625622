/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'using strict';

    angular.module('meetingMinutes.main.knowledge.meeting')
        .controller('MinutesController', MinutesController);

    MinutesController.$inject = [
        '$scope', '$rootScope', '$state', '$stateParams', 'meetingResource', 'dateHelper', 'minutesTemplateResource',
        '$window', 'userResource'
    ];

    function MinutesController($scope,
        $rootScope,
        $state,
        $stateParams,
        meetingResource,
        dateHelper,
        minutesTemplateResource,
        $window,
        userResource) {

        $scope.minutes;
        $scope.formData = {};

        $scope.appSettings = { 'application': $rootScope.authentication };


        $scope.findSettings = function(itemType, variantType) {
            if (itemType == 1) {
                for (var i = 0; i < $scope.appSettings.application.actions.length; i++) {
                    if ($scope.appSettings.application.actions[i].id == variantType)
                        return $scope.appSettings.application.actions[i];
                }
            }
            //due to TimelineItemType
            if (itemType == 2) {
                for (var i = 0; i < $scope.appSettings.application.emotions.length; i++) {
                    if ($scope.appSettings.application.emotions[i].id == variantType)
                        return $scope.appSettings.application.emotions[i];
                }
            }

            if (itemType == 3) {

            }
        };

        $scope.loadTemplates = function() {
            minutesTemplateResource.get().then(function(xhr) {

                $scope.formData = { templates: xhr };
            });
        };

        $scope.print = function() {
            $window.print();
        };

        $scope.$context = $rootScope.$context.$set('/minutes');
        meetingResource.getMeetingMinutes($stateParams.meetingId).then(function(response) {

            response.realEndTimeEx = dateHelper.enchance(response.realEndTime);
            response.realStartTimeEx = dateHelper.enchance(response.realStartTime);

            if (!angular.globalSettings.Application)
                userResource.getApplicationSettings().then(function(response) {
                    angular.globalSettings.Application = response;
                });


            $rootScope.meetingId = $stateParams.meetingId;
            for (var i = 0; i < response.timeline.length; i++) {
                if (response.timeline[i].idx.deadline) {
                    response.timeline[i].idx.deadlineTxt =
                        moment(response.timeline[i].idx.deadline).format('YYYY-MM-DD');
                }
                if(response.timeline[i].executeTime)
                    response.timeline[i].executeTimeLocal = moment(response.timeline[i].executeTime).format('HH:mm:ss DD.MM.YYYY');

                if (response.timeline[i].idx.variant) {
                    if (response.timeline[i].type === 2)
                        response.timeline[i].idx.variantName =
                            angular.globalSettings.Application.emotions[response.timeline[i].idx.variant].Label;
                    if (response.timeline[i].type === 1)
                        response.timeline[i].idx.variantName =
                            angular.globalSettings.Application.actions[response.timeline[i].idx.variant].Label;
                }
            }

            $scope.loadTemplates();

            $scope.minutes = response;

            $scope.minutes.realStartTimeTxt = moment($scope.minutes.realStartTime).format('YYYY.MM.DD HH:mm:ss');
            $scope.minutes.realEndTimeTxt = moment($scope.minutes.realEndTime).format('HH:mm:ss');

        });
    };

})();
