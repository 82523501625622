/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular
        .module('meetingMinutes.directives.domain')
        .directive('mmMembers', membersDirectiveFactory);

    function membersDirectiveFactory() {
        return {
            restrict: 'E',
            templateUrl: 'views/directives/domain/members.tpl.html',
            replace: true,
            scope: {
                mmSelectedMembers: '=',
                mmAvailableMembers: '=',
                mmOnMemberSelect: '&',
                mmOnMemberClick: '&',
                mmOnMemberDelete: '&'
            },
            link: link
        };

        function link(scope, element, attrs) {
            scope.$onMemberSelect = $onMemberSelect;
            scope.$onMemberDelete = $onMemberDelete;
            scope.$onMemberClick = $onMemberClick;

            scope.$inputTextToTag = function(newMember) {
                return { userName: newMember };
            };

            function $onMemberSelect(memberRef) {
                scope.mmOnMemberSelect({ member: memberRef });
            }

            function $onMemberDelete(memberRef) {
                scope.mmOnMemberDelete({ member: memberRef });
            }

            function $onMemberClick(memberRef) {
                scope.mmOnMemberClick({ member: memberRef });
            }
        }
    }

})();
