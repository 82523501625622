/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.repository.remote')
        .factory('dashboardResource', remoteDashboardResourceFactory);

    remoteDashboardResourceFactory.$inject = ['httpResource'];

    function remoteDashboardResourceFactory(httpResource) {

        function getDashboardRepositoryState() {
            return httpResource.get('/dashboard/respository/state');
        }

        function getDashboardCosts() {
            return httpResource.get('/dashboard/costs');
        }

        function getDashboardCollaboration() {
            return httpResource.get('/dashboard/collaboration');
        }

        function getWeekPlan(year, weekNumber, tags) {
            var dto = {
                year: year,
                weekNumber: weekNumber,
                tags: tags
            };


            return httpResource.post('/tasks/weekplan', dto);
        }

        return {
            "getDashboardRepositoryState": getDashboardRepositoryState,
            "getDashboardCosts": getDashboardCosts,
            "getDashboardCollaboration": getDashboardCollaboration,
            "getWeekPlan": getWeekPlan
        };
    }
})();
