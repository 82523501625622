(function() {
    'use strict';

    angular.module('meetingMinutes.repository.remote')
        .factory('todoResource', remoteTodoResourceFactory);

        remoteTodoResourceFactory.$inject = ['httpResource'];

    function remoteTodoResourceFactory(httpResource) {
        function deleteTodo(id) {
            return httpResource.delete('/todo/' + id);
        }

        return {
            deleteTodo: deleteTodo
        };
    }

})();