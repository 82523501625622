/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.knowledge')
        .factory('goalItemPopup', goalItemPopup);

    goalItemPopup.$inject = [
        'attachmentsHelper', '$uibModal', 'meetingResource'
    ];

    function goalItemPopup(attachmentsHelper, $uibModal, meetingResource) {
        GoalItemPopupCtrl.$inject = ['$scope', '$uibModalInstance'];
        function GoalItemPopupCtrl($scope, $uibModalInstance) {

            attachmentsHelper.initialize($scope, null);

            $scope.selectedItem;
            $scope.selectedText;
            $scope.duration;


            $scope.options = {
                skin: {
                    type: 'tron',
                    width: 2,
                    color: '#1ab394',
                    spaceWidth: 1
                },
                barColor: '#1ab394',
                trackWidth: 2,
                barWidth: 5,
                textColor: '#1ab394',
                step: 1,
                max: 480,
                size: 100
            };

            if ($scope.item) {

                attachmentsHelper.setUrl($scope, $scope.item.knowledgeObject.id);
            } else {
                $scope.item = {
                    relatedAgenda: {
                        duration: 0
                    }
                };

                meetingResource.saveGoal($scope.meetingId, null, $scope.doRelatedAgenda).then(function(xhr1) {
                    $scope.item = xhr1;
                    if ($scope.item.relatedAgenda)
                        $scope.item.relatedAgenda.duration = 0;
                    attachmentsHelper.setUrl($scope, xhr1.knowledgeObject.id);
                });
            };

            $scope.selectTemplate = function(template) {

                $scope.item.goalTemplateId = template.id;
                $scope.item.knowledgeObject.title = template.knowledgeObject.title;
            }

            $scope.selectContext = function(tag) {
                if (!$scope.item.activityProfile) {
                    $scope.item.activityProfile = [];
                }
                var idx = $scope.item.activityProfile.indexOf(tag);

                if (idx >= 0) {
                    var temp = [];
                    angular.forEach($scope.item.activityProfile,
                        function(_tag) {
                            if (_tag.toLowerCase() != tag.toLowerCase()) {
                                temp.push(_tag);
                            }
                        });

                    $scope.item.activityProfile = [];
                    angular.forEach(temp,
                        function(_x) {
                            $scope.item.activityProfile.push(_x);
                        });
                } else {
                    $scope.item.activityProfile.push(tag);
                }


            }

            $scope.addMinutes = function(minutes) {
                $scope.item.relatedAgenda.duration = $scope.item.relatedAgenda.duration + minutes;
            }

            $scope.selectedGoal = {
                priority: 1,
                priorityTxt: "B"
            };

            $scope.priority = function(id) {
                $scope.selectedGoal.priority = id;
                if (id == 0)
                    $scope.selectedGoal.priorityTxt = 'A';
                if (id == 1)
                    $scope.selectedGoal.priorityTxt = 'B';
                if (id == 2)
                    $scope.selectedGoal.priorityTxt = 'C';
                if (id == 3)
                    $scope.selectedGoal.priorityTxt = 'D';
                if (id == 4)
                    $scope.selectedGoal.priorityTxt = 'E';
            }

            $scope.ok = function() {
                $scope.item.priority = $scope.selectedGoal.priority;

                meetingResource.saveGoal($scope.meetingId, $scope.item, $scope.doRelatedAgenda).then(function(xhr3) {

                    $scope.item = xhr3;

                    if ($scope.goalItemPopup.mode == 'add') {
                        xhr3.priorityTxt = $scope.selectedGoal.priorityTxt;
                        $scope.meeting.goals.push(xhr3);
                    }
                    attachmentsHelper.setUrl($scope, xhr3.knowledgeObject.id);
                    $scope.goalItemPopup = null;
                    $uibModalInstance.dismiss('ok');
                });
            };

            $scope.planTheDayOk = function() {
                $scope.saving = true;
                $scope.item.priority = $scope.selectedGoal.priority;
                $scope.item.priorityTxt = angular.globalSettingsConst.priorities[$scope.selectedGoal.priority].name;
                meetingResource.saveGoal($scope.meetingId, $scope.item, true).then(function(xhr) {
                    xhr.priorityTxt = angular.globalSettingsConst.priorities[xhr.priority].name;
                    $scope.item = xhr;
                    if (xhr.relatedAgenda) {
                        xhr.relatedAgenda.timeBuffer =
                            Math.round(angular.globalSettings.User.timeBuffer / 100 * xhr.relatedAgenda.duration);
                        xhr.relatedAgenda.durationWithBuffer =
                            Math.round(xhr.relatedAgenda.duration + xhr.relatedAgenda.timeBuffer);
                    }

                    if ($scope.goalItemPopup.mode == 'add') {
                        $scope.meeting.goals.push(xhr);
                    }

                    $scope.meeting.plannedMinutes = 0;

                    for (var i = 0; i < $scope.meeting.goals.length; i++) {
                        $scope.meeting.plannedMinutes = $scope.meeting.plannedMinutes +
                            $scope.meeting.goals[i].relatedAgenda.durationWithBuffer;
                    }
                    $scope.saving = false;
                    $scope.goalItemPopup = null;
                    $uibModalInstance.dismiss('ok');
                });
            };


            $scope.delete = function() {
                meetingResource.deleteGoal($scope.item.id).then(function(xhr) {
                    $scope.meeting.goals = xhr;
                    $scope.cancel();
                });
            };

            $scope.cancel = function() {
                $uibModalInstance.dismiss('cancel');
            };
        }

        var open = function($scope, $rootScope, meeting, item, callback, doRelatedAgenda) {

            var tags = $scope.formData.meeting.knowledgeObject.tags;

            $scope.availableAcitivityContexts = tags;
            $scope.goalItemPopup = {};
            $scope.doRelatedAgenda = doRelatedAgenda;

            if (item) {
                $scope.item = item;
                $scope.goalItemPopup.mode = 'edit';
            } else {
                $scope.item = null;
                $scope.goalItemPopup.mode = 'add';
            }


            $scope.goalItemPopup.callback = callback;
            $scope.meetingId = meeting.id;
            $scope.meeting = meeting;

            var modalInstance = $uibModal.open({
                templateUrl: 'views/main/popups/goal-item.popup.tpl.html?v=' + new Date(),
                controller: GoalItemPopupCtrl,
                scope: $scope
            });
        };

        return {
            "open": open
        };
    }
})();
