/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.repository.remote')
        .factory('libraryResource', remoteLibraryResourceFactory);

        remoteLibraryResourceFactory.$inject = ['httpResource', '$location'];

    function remoteLibraryResourceFactory(httpResource, $location) {

        // function createNoteLocation(tags, backUrl) {
        //     $location.path('/note/create').search({ backUrl: backUrl, tags: tags })
        // }

        // function editNoteLocation(id, backUrl, tags) {

        //     $location.path('/note/edit/' + id).search({ backUrl: backUrl, tags: tags })
        // }

        // function viewNoteLocation(id, backUrl) {
        //     $location.path('/note/edit/' + id).search({ backUrl: backUrl })
        // }

        // function viewNoteList(type) {
        //     $location.path('/note/list').search({ type: type })
        // }


        // function createNote() {
        //     return httpResource.post('/note');
        // }

        // function createTypedNote(type) {
        //     return httpResource.post('/note/' + type);
        // }

        // function getDrafts() {
        //     return httpResource.get('/note/drafts');
        // }

        // function getNotes(request) {
        //     return httpResource.post('/notes', request);
        // }

        // function getNotesLibrary(request) {
        //     return httpResource.post('/notes/library', request);
        // }

        // function getNotesStats(request, canceller) {
        //     return httpResource.post('/notes/stats', request, { timeout: canceller });
        // }

        // function getNote(noteId) {
        //     return httpResource.get('/note/' + noteId);
        // }

        // function getFavourites() {
        //     return httpResource.get('/notes/favourites');
        // }

        // function favourite(koId) {
        //     return httpResource.post('/note/knowledgeObject/' + koId + '/favourite');
        // }

        // function $delete(noteId) {
        //     return httpResource.delete('/note/' + noteId);
        // }

        // function save(note) {
        //     return httpResource.put('/note/', note);
        // }

        // function getTagCloud() {
        //     return httpResource.get('/notes/tag-cloud');
        // }

        // function updatePersonNotesOwner(leads, newOwnerId){
        //     return httpResource.put("/note/person/owner/" + newOwnerId, leads);
        // }

        function search(text, tags, type, offset) {
            return httpResource.post('/library/search', {
                 text: text,
                 tags: tags,
                 objectType: type,
                 offset: offset 
                });
        }

        function getReferenceId(koId) {
            return httpResource.get('/library/'+koId+'/referenceId');
        }


        function getAllTags(koId) {
            return httpResource.get('/library/alltags');
        }

        return {
            "search": search,
            "getReferenceId": getReferenceId,
            "getAllTags": getAllTags
            // "getFavourites": getFavourites,
            // "createNote": createNote,
            // "getDrafts": getDrafts,
            // "getNotes": getNotes,
            // "getNotesLibrary": getNotesLibrary,
            // "getNotesStats": getNotesStats,
            // "getNote": getNote,
            // "delete": $delete,
            // "save": save,
            // "createTypedNote": createTypedNote,
            // "getTagCloud": getTagCloud,
            // "createNoteLocation": createNoteLocation,
            // "editNoteLocation": editNoteLocation,
            // "viewNoteLocation": viewNoteLocation,
            // "viewNoteList": viewNoteList,
            // "updatePersonNotesOwner":updatePersonNotesOwner
        };
    }

})();
