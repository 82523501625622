/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.profile')
        .controller('ProfileMinutesTemplatesController', ProfileMinutesTemplatesController);

    ProfileMinutesTemplatesController.$inject = [
        '$rootScope', '$scope', 'knowledgeObjectResource', 'minutesTemplateResource', 'notePopup'
    ];

    function ProfileMinutesTemplatesController($rootScope,
        $scope,
        knowledgeObjectResource,
        minutesTemplateResource,
        notePopup) {

        $scope.formData = {};

        $rootScope.$context.$set('/profile/templates', $scope);

        activate();

        $scope.callback = function() {
            activate();
        }

        $scope.open = function(item) {
            notePopup.open($scope, item, $scope.callback);
        }

        $scope.createTyped = function() {
            notePopup.createTyped($scope, 25, [], $scope.callback);
        }

        function activate() {
            minutesTemplateResource.get().then(function(xhr) {
                $scope.formData = { templates: xhr };
            });
        }
    }

})();
