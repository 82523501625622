/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.knowledge.person')
        .controller('PersonListController', PersonListController);

    PersonListController.$inject = ['$rootScope', '$scope', '$location', 'personResource'];

    function PersonListController($rootScope, $scope, $location, personResource) {
        $scope.person = {};
        $scope.persons = [];
        $scope.filteredPersons = [];

        $scope.rowsPerPage = 20;

        activate();

        $scope.filter = "";

        function activate() {
            $rootScope.$context.$set('/person/list', $scope);
            getPersons();
        }

        $scope.customFilter = {
            user: null,
            tag: null,
            person: null,
        };

        $scope.selectTag = function(tag) {
            if (tag == $scope.customFilter.tag)
                $scope.customFilter.tag = null
            else
                $scope.customFilter.tag = tag;
            $scope.filterPersons();
        }

        $scope.isTag = function(tag, meeting) {
            for (var i = 0; i < meeting.knowledgeObject.tags.length; i++) {
                if (meeting.knowledgeObject.tags[i].text == tag)
                    return true
            }
            return false;
        }

        $scope.filterPersons = function() {
            $scope.filteredPersons = [];
            for (var i = 0; i < $scope.persons.length; i++) {
                if ($scope.persons[i].knowledgeObject.title.toLowerCase().indexOf($scope.filter.toLowerCase()) >= 0) {
                    if ($scope.customFilter.tag) {
                        if (!$scope.isTag($scope.customFilter.tag, $scope.persons[i])) {
                            continue;
                        }
                    }

                    $scope.filteredPersons.push($scope.persons[i]);
                    for (var p = 0; p < $scope.persons[i].knowledgeObject.tags.length; p++) {
                        $rootScope.uniquePush($scope.persons[i].knowledgeObject.tags[p].text, $scope.tags);
                    }
                }
            }
        }

        function getPersons() {
            withProgressIndicator(personResource.getPersons()).then(function(persons) {
                $scope.persons = persons;
                $scope.filterPersons();
                $scope.person = $scope.persons[0];
            });
        }


        $scope.onCreate = function() {
            $location.path('/person/create');
        }

        $scope.onEdit = function() {
            $location.path('/person/edit/' + $scope.person.id);
        }

        $scope.onView = function(person) {
            $scope.person = person;
        }

        function withProgressIndicator(promise) {
            $scope.loadInProgressMessage = 'Loading...';
            promise.finally(function() {
                $scope.loadInProgressMessage = null;
            });
            return promise;
        }
    }
})();
