import { Dialog } from "@material-ui/core";
import * as React from "react";
import TodoPopupLink from "../../containers/TodoPopupLink";
import { AppState } from "../../store/appstate";
import { bindActionCreators, AnyAction, Dispatch } from "redux";
import { uiTodoCloseModal } from "../../store/uimodels/uitodo";
import { connect } from "react-redux";

interface ITodoPopupContainerProps {
    modalOpen: boolean;
    
}
interface ITodoPopupContainerActions {
    uiTodoCloseModal: () => void;
}

/// to consider: this class should somehow be generic and be the base class for all popups
const TodoPopupContainer: React.FC<ITodoPopupContainerProps> = (props: ITodoPopupContainerProps & ITodoPopupContainerActions) => {

    const handleClose = () => {
        props.uiTodoCloseModal();
    }

    return (
        <Dialog
            open={props.modalOpen}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
        >
            <TodoPopupLink handleClose={handleClose}></TodoPopupLink>
        </Dialog>
    );
}

const mapStateToProps = (state: AppState): ITodoPopupContainerProps => {
    return {
      modalOpen: state.ui.todos.todoModalOpen
    };
  }
  
  const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({ uiTodoCloseModal, }, dispatch);
  }
  
  const TodoPopupContainerLink = connect(
    mapStateToProps,
    mapDispatchToProps
  )(TodoPopupContainer)
  
  export default TodoPopupContainerLink