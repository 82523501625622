/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

import {uiTimelineOpenModal} from '../../../src/store/uimodels/uitimeline';

(function() {
    'use strict';

    angular.module('meetingMinutes.main.crm')
        .controller('OpportunityController', OpportunityController);

    OpportunityController.$inject = [
        '$cookies', '$cookieStore', '$q', 'personResource', 'crmResource', 'accessPopup', '$state', '$rootScope',
        '$scope', '$location', '$stateParams', 'knowledgeObjectResource', 'toastr', 'userResource', 'noteResource',
        'popupHelper', 'meetingResource', 'dateHelper', '$uibModal', 'workgroupResource', 'removePopup',
        'selectTagsPopup', 'dictionaries', 'selectUserPopup', 'salesResource', 'reduxStore'
    ];

    function OpportunityController($cookies,
                                    $cookieStore,
                                    $q,
                                    personResource,
                                    crmResource,
                                    accessPopup,
                                    $state,
                                    $rootScope,
                                    $scope,
                                    $location,
                                    $stateParams,
                                    knowledgeObjectResource,
                                    toastr,
                                    userResource,
                                    noteResource,
                                    popupHelper,
                                    meetingResource,
                                    dateHelper,
                                    $uibModal,
                                    workgroupResource,
                                    removePopup,
                                    selectTagsPopup,
                                    dictionaries,
                                    selectUserPopup,
                                    salesResource,
                                    reduxStore) {

        var store = reduxStore.get();

        $scope.attachmentUploadUrl = "";
        $scope.changeMode = false;
        $scope.printedOwner = ""

        if ($state.$current.self.name == 'main.opportunity/edit')
            $scope.mode = 'edit';
        if ($state.$current.self.name == 'main.opportunity/create')
            $scope.mode = 'create';
        if ($state.$current.self.name == 'main.opportunity/view')
            $scope.mode = 'view';

        $scope.openTimelineModal = function($event, meetingId) {
            $event.stopPropagation();
            store.dispatch(uiTimelineOpenModal(meetingId));
        }

        $scope.editMode = function() {
            $scope.mode = 'edit';
        };

        workgroupResource.getOpportunityUnits().then(function (units) {
            $scope.formData.opportunityUnits = ["", ...units];            
        });

        userResource.getUsers().then(function (members) {
            $scope.formData.members = members;
        });
        $scope.formData.coowners = [];

        $scope.viewMeeting = function(id) {
            $state.go('main.meetingDetails', { meetingId: id });
        };

        $scope.selectUserCallback = function (user) {
            $scope.printedOwner = user.userName;
            $scope.formData.item.ownerId = user.id;
        };

        $scope.setOpportunityOwner = function () {
            selectUserPopup.open($scope, $scope.selectUserCallback);
        };

        $scope.setTypeAsTag = function() {
            var specialTagsDeleted = false;
            var opportunityStatuses = dictionaries.opportunity().statuses;
            for (var i = 0; i < $scope.formData.item.knowledgeObject.tags.length; i++) {
                $scope.formData.item.knowledgeObject.tags[i] =
                    $scope.formData.item.knowledgeObject.tags[i].toLowerCase();
            }

            while (!specialTagsDeleted) {
                for (i = 0; i < opportunityStatuses.length; i++) {
                    if ($scope.formData.item.knowledgeObject.tags.indexOf(opportunityStatuses[i].name.toLowerCase()) !==
                        -1) {
                        $scope.formData.item.knowledgeObject.tags.splice(
                            $scope.formData.item.knowledgeObject.tags.indexOf(opportunityStatuses[i].name
                                .toLowerCase()),
                            1);
                    }
                }

                specialTagsDeleted = true;

                for (i = 0; i < opportunityStatuses.length; i++) {
                    if ($scope.formData.item.knowledgeObject.tags.indexOf(opportunityStatuses[i].name.toLowerCase()) !==
                        -1) {
                        specialTagsDeleted = false;
                    }
                }
            }

            // $scope.formData.item.knowledgeObject.tags.push($scope.formData.item.opportunityType);
            $scope.formData.item.knowledgeObject.tags.push($scope.opportunityTypeToString($scope.formData.item.status));

        };

        $scope.opportunityTypeToString = function(type) {
            return dictionaries.opportunity().opportunityStatusToString(type);
        };

        $scope.$context = $rootScope.$context.$set('/opportunity/create');
        $scope.backUrl = $location.$$search.backUrl;
        $scope.tags = $location.$$search.tags;

        $scope.painTabs = [];
        $scope.painViewTab = 0;

        //hardcoded draft data in purpose of draft Opportunity template:

        $scope.draftData = {
            leadSource: dictionaries.lead().sources,
            roleInSalesProcess: [
                'Sponsor',
                'Power Sponsor',
                'Insider',
                'Inny'
            ],
            probability: [
                '0%',
                '10%',
                '25%',
                '50%',
                '75%',
                '90%',
                '100%'
            ],
            nextStep: [
                'Telefon',
                'Skype',
                'Email',
                'Spotkanie',
                'Prezentacja',
                'Inne'
            ]
        };
        //end of draft data

        $scope.tabs = [
            { name: 'Pain', description: '' },
            { name: 'Consequences', description: '' },
            { name: 'Reasons', description: '' },
            { name: 'Vision', description: '' },
            { name: 'Capabilities', description: '' },
        ];

        $scope.translateMeetingStatus = function(status) {
            if (status === 0) return 'Pending';
            if (status === 1) return 'Open';
            if (status === 2) return 'Closed';
        };

        activate();

        $scope.$on('fileuploaddone',
            function(event, data) {
                if (data.paramName === 'profilePicture') {
                    $scope.obj.src = data.result;
                }
                if (data.paramName === 'attachments') {
                    $scope.attachmentsOptions.url = $scope.attachmentUploadUrl;
                    data.url = $scope.attachmentUploadUrl;
                    data.submit().then(function() {
                        $scope.refresh();
                    });
                }
            });

        $scope.cancel = function() {
            if (!$scope.backUrl) {
                $rootScope.selectedTabIndex = 1;
                window.location.href = "index#/crm";
            }                
            else
                $location.path($scope.backUrl);
        };

        $scope.readonly = false;
        $scope.selectedItem = null;
        $scope.availableProfiles = [];
        $scope.availableTags = [];

        function createFilterFor(query) {
            var lowercaseQuery = angular.lowercase(query);
            return function filterFn(vegetable) {
                vegetable = angular.lowercase(vegetable);
                return (vegetable.indexOf(lowercaseQuery) === 0);
            };
        }

        $scope.querySearchTags = function(query) {
            var results = query ? $scope.availableTags.filter(createFilterFor(query)) : [];
            return results;
        };

        $scope.focusOn = function() {
            $rootScope.setOpportunityContext($scope.formData.item);
        };

        $scope.access = function() {
            accessPopup.open($scope, $scope.formData.item.knowledgeObject);
            //$location.path("/knowledge/access/" + $scope.formData.item.knowledgeObject.id);
        };

        $scope.delete = function() {
            removePopup.open($scope, function() {
                crmResource.deleteOpportunity($scope.formData.item.id).then(function() {
                    $state.go('main.crm');
                });
            });
            //$location.path("/knowledge/access/" + $scope.formData.item.knowledgeObject.id);
        };

        $scope.save = function() {
            //edition of opportunity automatically make it OPEN only if it's not WON.
            if ($scope.formData.item.status !== 3) $scope.formData.item.status = 1;

            $scope.formData.item.pain = $scope.tabs[0].description;
            $scope.formData.item.consequences = $scope.tabs[1].description;
            $scope.formData.item.reasons = $scope.tabs[2].description;
            $scope.formData.item.vision = $scope.tabs[3].description;
            $scope.formData.item.capabilities = $scope.tabs[4].description;
            $scope.formData.item.coownersIds = $scope.formData.coowners.map(x => x.id);
            if (!$scope.formData.item.end)
                $scope.formData.item.end = moment();
            $scope.setTypeAsTag();

            if ($scope.mode == 'create') {
                crmResource.createOpportunity($scope.formData.item).then(function(response) {
                    var tags = JSON.stringify($rootScope.contextTags);
                    $location.path('/opportunity/view/' + response.id).search();
                    toastr.success("Opportunity have been succesfully saved");
                });

            } else {
                crmResource.saveOpportunity($scope.formData.item).then(function(response) {
                    $scope.mode = 'view';
                    $scope.formData.item.knowledgeObject.tags = response.knowledgeObject.tags;
                    $scope.publish();
                    toastr.success("Opportunity have been succesfully saved");
                });
            }

            
        };

        //managing the status of opportunity
        $scope.activateOpportunity = function() {
            crmResource.setOpportunityStatusOpened($scope.formData.item).then(
                $scope.formData.item.status = 1
            );
            $scope.setTypeAsTag();
        };
        $scope.loseOpportunity = function() {
            crmResource.setOpportunityStatusLost($scope.formData.item).then(
                $scope.formData.item.status = 0
            );
            $scope.setTypeAsTag();
        };
        $scope.suspendOpportunity = function() {
            crmResource.setOpportunityStatusSuspended($scope.formData.item).then(
                $scope.formData.item.status = 2
            );
            $scope.setTypeAsTag();
        };
        //

        $scope.publish = function() {
            $scope.formData.item.knowledgeObject.status = 1; //published
            knowledgeObjectResource.publish($scope.formData.item.knowledgeObjectId).then(function() {
            });
        };

        $scope.draft = function() {
            $scope.formData.item.knowledgeObject.status = 0; //draft
            knowledgeObjectResource.draft($scope.formData.item.knowledgeObject.id);
        };


        $scope.hideUrlUploadPanel = function() {
            $scope.xxx.showUrlPanel = false;
        };

        $scope.refresh = function() {

            knowledgeObjectResource.getAttachments($scope.formData.item.knowledgeObject.id).then(function(xhr) {
                $scope.formData.item.knowledgeObject.attachments = xhr;
            });
        };

        $scope.selectTagsPopup = function() {
            var config = {};
            config.common = true;
            selectTagsPopup.open($scope, $scope.formData.item.knowledgeObject.tags, config, null);
        };

        $scope.permissions = [];

        $scope.querySearchForMembers = function (query) {
            var results = query ? $scope.formData.members.filter(createFilterForMember(query)) : [];
            return results;
        }

        function createFilterForMember(query) {
            var lowercaseQuery = angular.lowercase(query);

            return function filterFn(vegetable) {
                return vegetable.id !== $scope.formData.item.ownerId && ((vegetable.userEmail.toLowerCase().indexOf(lowercaseQuery) === 0) ||
                    (vegetable.userEmail.toLowerCase().indexOf(lowercaseQuery) === 0));
            };
        }

        $scope.prepare = function(response) {
            response.knowledgeObject.createTimeTxt = moment(response.knowledgeObject.createTime).format('YYYY-MM-DD');

            if(response.owner)
                $scope.printedOwner = response.owner.userName;
            else {
                $scope.printedOwner = response.knowledgeObject.creator.userName;
            }
            // $scope.formData = {item: response};
            $scope.formData.item = response;
            // $scope.formData.item.opportunityType = opportunityTypeToString(response.opportunityType);
            $scope.tabs[0].description = response.pain;
            $scope.tabs[1].description = response.consequences;
            $scope.tabs[2].description = response.reasons;
            $scope.tabs[3].description = response.vision;
            $scope.tabs[4].description = response.capabilities;

            if (!$scope.formData.item.unit) $scope.formData.item.unit = "";
            $scope.formData.item.endEx = dateHelper.enchance($scope.formData.item.end);
            $scope.formData.item.startEx = dateHelper.enchance($scope.formData.item.start);
            $scope.formData.item.closedEx = dateHelper.enchance($scope.formData.item.closed);

            

            knowledgeObjectResource.getPermissions($scope.formData.item.knowledgeObject.id).then(function(response2) {
                if (response2 != null) {
                    for (var i = 0; i < response2.length; i++) {
                        response2[i].permissionTypeTxt =
                            angular.globalSettingsConst.permissions[response2[i].permissionType];
                    }
                    $scope.permissions = response2;
                }
            });

            knowledgeObjectResource.getAttachments($scope.formData.item.knowledgeObject.id).then(function(xhr) {
                $scope.formData.item.knowledgeObject.attachments = xhr;
            });

            crmResource.getOpportunityRelatedMeetings(response.id).then(function(meetings) {
                $scope.relatedMeetings = meetings;
                for (var i = 0; i < meetings.length; i++) {
                    $scope.relatedMeetings[i] = dateHelper.enchanceMeeting(meetings[i]);
                }
            });

            var _tmp = [];
            for (var i = 0; i < response.coownersIds.length; i++) {
                var person = $scope.formData.members.filter((v) => {
                    return v.id === response.coownersIds[i];
                });
                _tmp.push(person[0]);
            }
            $scope.formData.coowners = _tmp;            

            // $scope.searchRelatedNotes();
            $scope.attachmentUploadUrl =
                '/api/knowledgeObject/' + $scope.formData.item.knowledgeObject.id + '/attachment';
        };

        $scope.handleCoownersChange = function(scope) {
            if (scope.formData.coowners.length === 2) {
                var input = document.getElementById('coownerInput');
                if (input.value.length > 0) {
                    setTimeout(function() {
                        input.value = '';
                    }, 0);
                    toastr.warning("Maximum of 10 coowners can be set");
                }
            }
        }


        SelectTemplateCtrl.$inject = ['$scope', '$uibModalInstance'];
        function SelectTemplateCtrl($scope, $uibModalInstance) {

            $scope.search = {
                userName: ""
            };

            var canceller = null;

            $scope.$watch(
                function() {
                    return $scope.search.userName;
                },
                function(newValue, oldValue) {
                    if (canceller !== null) {
                        canceller.resolve();
                    }
                    $scope.getPersons();
                },
                true);

            $scope.getPersons = function() {
                canceller = $q.defer();
                $scope.persons = [];
                personResource.getPersonsWithFilter($scope.search.userName, canceller).then(function(xhr) {
                    $scope.persons = xhr;
                });
            };

            $scope.addGuest = function(guest) {
                $scope.formData.item.person = guest;
            };

            $scope.ok = function() {
                $uibModalInstance.close();
            };

            $scope.cancel = function() {
                $uibModalInstance.dismiss('cancel');
            };
        }

        $scope.OpenModalSelectExternalParticipant = function() {
            var modalInstance = $uibModal.open({
                templateUrl: 'views/main/popups/opportunity-person.popup.tpl.html?v=' + new Date(),
                controller: SelectTemplateCtrl,
                scope: $scope
            });
        };

        $scope.$watch(
            function() {
                if ($scope.formData.item)
                    return $scope.formData.item.end;
            },
            function(newValue, oldValue) {
                if ($scope.formData.item)
                    $scope.formData.item.endEx = dateHelper.enchance($scope.formData.item.end);
            },
            true);

        $scope.$watch(
            function() {
                if ($scope.formData.item)
                    return $scope.formData.item.closed;
            },
            function(newValue, oldValue) {
                if ($scope.formData.item)
                    $scope.formData.item.closedEx = dateHelper.enchance($scope.formData.item.closed);
            },
            true);

        function activate() {
            if ($stateParams.id) {
                crmResource.getOpportunity($stateParams.id).then(function(response) {
                    if ($scope.tags) {
                        var result = JSON.parse($scope.tags);
                        response.knowledgeObject.tags = result.tags;
                    }
                    $scope.prepare(response);

                });
            } else {

                salesResource.processes().then(function (data) {
                    $scope.salesProcesses = data;
                 });

                $scope.formData = {};
                $scope.formData.item = {};
                $scope.formData.item.person = {};
                $scope.formData.item.stage = {
                    completeness: 0
                };
                $scope.formData.coowners = [];
                $scope.formData.item.knowledgeObject = {};
                $scope.formData.item.knowledgeObject.tags = [];

                var personId = $location.$$search.personId;

                if (personId) {
                    personResource.getPerson(personId).then(function (xhr) {

                        $scope.formData.item.person = xhr;
                        $scope.formData.item.opportunityName = xhr.name;

                        if (xhr.leadSource) $scope.formData.item.person.leadSource = xhr.leadSource;
                        if (xhr.location) $scope.formData.item.person.location = xhr.location;
                        if (xhr.contactPerson) $scope.formData.item.person.contactPerson = xhr.contactPerson;
                        if (xhr.position) $scope.formData.item.person.positionInCompany = xhr.position;
                    });
                }
            }

            knowledgeObjectResource.getTags().then(function(response) {
                $scope.availableTags = response;
            });
        }

        $scope.$on('fileuploadadd',
            function(e, data) {
                if (data.paramName === 'attachments') {
                    $scope.attachmentsOptions.url = $scope.attachmentUploadUrl;
                    data.url = $scope.attachmentUploadUrl;
                    data.submit().then(function() {
                        $scope.refresh();
                    });
                }
            });

        $scope.fileUploadOptions = {
            url: '',
            autoUpload: false,
            xhrFields: {
                withCredentials: true
            }
        };

        $scope.attachmentsOptions = {
            autoUpload: false,
            url: '',
            xhrFields: {
                withCredentials: true
            }
        };

        $scope.onAttachmentDelete = function(data) {
            knowledgeObjectResource.deleteAttachment(data.id).then(function() {
                activate();
            });
        }
    }
})();
