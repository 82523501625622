/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.knowledge.meeting')
        .factory('tagsPopup', tagsPopup);

    tagsPopup.$inject = ['httpResource', '$uibModal'];

    function tagsPopup(httpResource, $uibModal) {

        TagsPopupCtrl.$inject = ['$scope', '$uibModalInstance'];
        function TagsPopupCtrl($scope, $uibModalInstance) {
            $scope.ok = function() {
                $uibModalInstance.close();
            };

            $scope.cancel = function() {
                $uibModalInstance.dismiss('cancel');
            };
        }

        var open = function($scope, tags, config, callback) {
            $scope.tags = tags;
            $scope.config = config;
            $scope.callback = callback;

            var modalInstance = $uibModal.open({
                templateUrl: 'views/main/popups/tags.popup.tpl.html?v=' + new Date(),
                controller: TagsPopupCtrl,
                scope: $scope
            });
        }

        return {
            "open": open,
        };
    }
})();
