import { connect } from 'react-redux'
import Link from '../components/TodoList/TodoList'
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import { listTodoRequest, getTodos, doneTodoRequest } from '../store/models/todo';
import { AppState } from '../store/appstate';
import { uiTodoOpenModal } from '../store/uimodels/uitodo';

const mapStateToProps = (state: AppState, ownProps: any) => {
  return {
    items: getTodos(state),
    onInstantAngular: ownProps.handleInstantTodo
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return bindActionCreators({ listTodoRequest, uiTodoOpenModal, doneTodoRequest } , dispatch)
}

const TodoListLink = connect(
  mapStateToProps,
  mapDispatchToProps
)(Link)

export default TodoListLink