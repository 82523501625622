/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular
        .module('meetingMinutes.directives.domain')
        .directive('openMenuByClick',
            function($timeout) {
                return {
                    link: function(scope, element, attrs) {
                        element.bind('click',
                            function() {

                                $timeout(function() {
                                    $("#" + attrs.openMenuByClick).find('button').click();
                                });
                            });
                    }
                };
            });

})();
