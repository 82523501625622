/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    /**
     * Directive for minimalize sidebar.
     */
    function minimalizaMenuDirectiveFactory() {
        return {
            restrict: 'EA',
            template: '<div class="header-link hide-menu" ng-click="minimalize()"><i class="fa fa-bars"></i></div>',
            controller: function($scope) {
                $scope.minimalize = function() {
                    if ($(window).width() < 769) {
                        $("body").toggleClass("show-sidebar");
                    } else {
                        $("body").toggleClass("hide-sidebar");
                    }
                }
            }
        };
    }

    angular.module('meetingMinutes.directives.homer')
        .directive('minimalizaMenu', minimalizaMenuDirectiveFactory);

})();
