//Require all the necessary libraries
window.jQuery = window.$ = require('jquery');
window.$ = window.jQuery;
require('jquery-ui-bundle');
window.jQuery.Jcrop = require('jquery-jcrop');
window.angular = require('angular');
window.moment = require('moment');

require('ng-jcrop');
require('metismenu');
require('bootstrap');
require('@uirouter/angularjs');
require('ngstorage');
require('angular-datatables');
require('datatables.net');
require('datatables.net-dt');
require('datatables.net-buttons');
require('datatables.net-buttons/js/buttons.html5');
window.JSZip = require('jszip');
window.pdfmake = require('pdfmake');
var vfs = require('pdfmake/build/vfs_fonts');

// require('ng-material-datetimepicker');

window.pdfmake.vfs = vfs.pdfMake.vfs;
// require('datatables.net-buttons-bs');
// require('datatables.net-buttons-dt');
// require('datatables.net-select');
// require('datatables.net-select-dt');
require('angular-cookies');
require('angular-ui-calendar');
require('../scripts/vendor/textAngular/textAngular-sanitize.min');
require('../scripts/vendor/textAngular/textAngular.min');
require('angular-ladda');
require('angular-ui-bootstrap');
require('ui-select');
require('angular-datepicker');
require('angularjs-datepicker');
require('pikaday-angular');
require('angular-toastr');
require('angular-xeditable');
require('angular-material');
require('ion-range-slider-angularjs');
// require('ng-ui-knob');
require('mdPickers');
require('peity');
var highcharts = require('highcharts');
window.Highcharts = highcharts;
require('highcharts/modules/funnel')(highcharts);
require('highcharts/modules/exporting')(highcharts);

require('imports-loader?define=>false&exports=>false!fullcalendar/dist/fullcalendar.js');
require('imports-loader?define=>false&exports=>false!ng-ui-knob/dist/ng-knob.js');
require('imports-loader?define=>false&exports=>false!signalr/jquery.signalR.min.js');

require('imports-loader?define=>false&exports=>false!ng-switchery/dist/ng-switchery.js');
require('imports-loader?define=>false&exports=>false!time-to/jquery.time-to.min.js');

/* The jQuery UI widget factory); can be omitted if jQuery UI is already included */
require('imports-loader?define=>false&exports=>false!blueimp-file-upload/js/vendor/jquery.ui.widget.js');
/* The Iframe Transport is required for browsers without support for XHR file uploads */
require('imports-loader?define=>false&exports=>false!blueimp-file-upload/js/jquery.iframe-transport.js');
/* The basic File Upload plugin */
require('imports-loader?define=>false&exports=>false!blueimp-file-upload/js/jquery.fileupload.js');
/* The File Upload processing plugin */
require('imports-loader?define=>false&exports=>false!blueimp-file-upload/js/jquery.fileupload-process.js');
/* The File Upload validation plugin */
require('imports-loader?define=>false&exports=>false!blueimp-file-upload/js/jquery.fileupload-validate.js');
/* The File Upload Angular JS module */
require('imports-loader?define=>false&exports=>false!blueimp-file-upload/js/jquery.fileupload-angular.js');

require('../scripts/vendor/slimScroll');
require('../scripts/vendor/angles');
require('../scripts/vendor/ng-peity');
require('../scripts/vendor/ng-switchery');
require('../scripts/vendor/d3/d3.min');
window.humanizeDuration = require('../scripts/vendor/humanizeDuration/humanize-duration');

window.clockpicker = require('imports-loader?define=>false&exports=>false!clockpicker/dist/bootstrap-clockpicker');

//All the required modules are loaded); now loading application

// require('imports-loader?define=>false&exports=>false!../scripts/homer.js'); //Homer theme main file

//Require modules
require('../scripts/common/common.module');
require('../scripts/directives/directives.module');
require('../scripts/public/public.module');
require('../scripts/layout/layout.module');

require('../scripts/directives/homer/homer-directives.module');
require('../scripts/directives/form/form-directives.module');
require('../scripts/directives/domain/domain-directives.module');
require('../scripts/config/config.module');
require('../scripts/authorization/authorization.module');

require('imports-loader?define=>false&exports=>false!../scripts/authorization/authorization.provider.js');
require('imports-loader?define=>false&exports=>false!../scripts/authorization/authorized.service.js');

require('../scripts/main/crm/crm.module');
require('../scripts/main/dashboard/dashboard.module');
require('../scripts/main/draft/draft.module');
require('../scripts/main/knowledge/knowledge.module');
require('../scripts/main/main.module');
require('../scripts/main/meeting/meeting.module');
require('../scripts/main/note/note.module');
require('../scripts/main/library/library.module');
require('../scripts/main/person/person.module');
require('../scripts/main/profile/profile.module');
require('../scripts/main/workgroup/workgroup.module');

require('../scripts/repository/repository.module');
require('../scripts/repository/remote/remote-repository.module');

require('../scripts/admin/admin.module');
require('../scripts/meeting-minutes.module');

//Require other files

 require('imports-loader?define=>false&exports=>false!../scripts/homer.js');
 require('imports-loader?define=>false&exports=>false!../scripts/charts.funnel.js');
 require('imports-loader?define=>false&exports=>false!../scripts/public/public.routes.js');
 require('imports-loader?define=>false&exports=>false!../scripts/public/dispatch.controller.js');
 require('imports-loader?define=>false&exports=>false!../scripts/public/welcome.controller.js');
 require('imports-loader?define=>false&exports=>false!../scripts/public/sign-up.controller.js');
 require('imports-loader?define=>false&exports=>false!../scripts/public/sign-up-confirmation.controller.js');
 require('imports-loader?define=>false&exports=>false!../scripts/public/sign-up-completion.controller.js');
 require('imports-loader?define=>false&exports=>false!../scripts/public/sign-in.controller.js');
 require('imports-loader?define=>false&exports=>false!../scripts/public/sign-out.controller.js');
 require('imports-loader?define=>false&exports=>false!../scripts/public/reset-password.controller.js');
 require('imports-loader?define=>false&exports=>false!../scripts/public/change-password.controller.js');
 require('imports-loader?define=>false&exports=>false!../scripts/public/public-minutes.controller.js');
 require('imports-loader?define=>false&exports=>false!../scripts/layout/header.controller.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/main.routes.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/main.controller.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/dashboard/dashboard.routes.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/dashboard/dashboard.controller.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/crm/crm.routes.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/crm/crm.controller.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/crm/opportunity.controller.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/knowledge/knowledge.routes.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/knowledge/knowledge.controller.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/knowledge/access.controller.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/meeting/meeting.routes.js');
require('../scripts/main/meeting/meetings.controller.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/meeting/timeline.controller.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/meeting/start.controller.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/meeting/plan-meeting-wizard.controller.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/meeting/meeting-details.controller.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/meeting/minutes.controller.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/draft/draft.routes.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/draft/draft-meetings.controller.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/draft/draft-notes.controller.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/note/note.routes.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/note/note-list.controller.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/note/note.controller.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/note/pin-board.controller.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/library/library-list.controller.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/library/library.routes.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/person/person.routes.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/person/person.controller.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/person/person-list.controller.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/workgroup/workgroup.routes.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/workgroup/user.controller.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/workgroup/user-list.controller.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/workgroup/workgroup.controller.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/workgroup/workgroup-list.controller.js');
require('imports-loader?define=>false&exports=>false!../scripts/directives/homer/side-navigation.directive.js');
require('imports-loader?define=>false&exports=>false!../scripts/directives/homer/minimaliza-menu.directive.js');
require('imports-loader?define=>false&exports=>false!../scripts/directives/homer/scrollspy-on.directive.js');
require('imports-loader?define=>false&exports=>false!../scripts/directives/homer/page-title.directive.js');
require('imports-loader?define=>false&exports=>false!../scripts/directives/homer/page-scroll.directive.js');
require('imports-loader?define=>false&exports=>false!../scripts/directives/homer/icheck.directive.js');
require('imports-loader?define=>false&exports=>false!../scripts/directives/form/validate-equals.directive.js');
require('imports-loader?define=>false&exports=>false!../scripts/directives/form/confirm-button.directive.js');
require('imports-loader?define=>false&exports=>false!../scripts/directives/domain/tags.directive.js');
require('imports-loader?define=>false&exports=>false!../scripts/directives/domain/enterpress.directive.js');
require('imports-loader?define=>false&exports=>false!../scripts/directives/domain/members.directive.js');
require('imports-loader?define=>false&exports=>false!../scripts/directives/domain/toggleHelper.directive.js');
require('imports-loader?define=>false&exports=>false!../scripts/directives/domain/clockpicker.directive.js');
require('imports-loader?define=>false&exports=>false!../scripts/directives/domain/attachments.directive.js');
require('imports-loader?define=>false&exports=>false!../scripts/common/preconditions.service.js');
require('imports-loader?define=>false&exports=>false!../scripts/common/numbers.service.js');
require('imports-loader?define=>false&exports=>false!../scripts/common/ui.factory.js');
require('imports-loader?define=>false&exports=>false!../scripts/common/ui.paginator.js');
require('imports-loader?define=>false&exports=>false!../scripts/common/parsUrl.filter.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/profile/profile.routes.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/profile/profile.controller.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/profile/profile.crm.controller.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/profile/profile.minutes.templates.controller.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/profile/profile.tasks.controller.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/profile/profile.account.controller.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/profile/profile.google-contacts.controller.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/profile/profile.tokenclients.controller.js');
require('imports-loader?define=>false&exports=>false!../scripts/main/profile/profile.refreshtokens.controller.js');
require('imports-loader?define=>false&exports=>false!../scripts/admin/admin.routes.js');
require('imports-loader?define=>false&exports=>false!../scripts/admin/logs.controller.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/ajax-error.interceptor.service.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/http-resource.service.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/signalrHub.service.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/remote-google-calendar-resource.service.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/remote-msgraph-calendar-resource.service.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/remote-authentication-resource.service.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/remote-account-resource.service.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/remote-user-resource.service.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/remote-dashboard-resource.service.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/remote-person-resource.service.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/remote-meeting-resource.service.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/remote-workgroup-resource.service.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/remote-task-resource.service.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/remote-knowledge-object-resource.service.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/remote-note-resource.service.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/remote-library-resource.service.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/remote-sales-resource.service.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/remote-minutes-template-resource.service.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/remote-token-resource.service.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/remote-contact-resource.service.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/remote-crm-resource.service.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/functional/app-settings.helper.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/functional/date.helper.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/functional/chips.helper.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/functional/tags.resource.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/functional/attachments.helper.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/functional/task.helper.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/functional/meeting.helper.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/functional/dictionaries.service.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/popups/meeting-time.popup.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/popups/meeting-duration.popup.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/popups/meeting-quick-plan.popup.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/popups/remove.popup.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/popups/access.popup.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/popups/goals.popup.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/popups/goal-item.popup.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/popups/agenda.popup.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/popups/agenda-item.popup.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/popups/attachments.popup.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/popups/note.popup.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/popups/guest.participants.popup.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/popups/select-opportunity.popup.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/popups/user.participants.popup.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/popups/tags.popup.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/popups/select-tags.popup.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/popups/select-user.popup.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/popups/popup.helper.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/popups/user.popup.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/popups/person.popup.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/popups/sticky-note-transform.popup.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/popups/sales.popup.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/popups/sales.stage.popup.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/popups/token-client.popup.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/popups/meeting-quick-plan.popup.js');
require('imports-loader?define=>false&exports=>false!../scripts/repository/remote/popups/select-user.popup.js');

import "../scripts/vendor/timeTo/timeTo.css";
import "../vendor/angular-material.css";
import "../vendor/font-awesome.css";
import "../vendor/angular-datepicker.css";
import "../vendor/select.css";
import "../vendor/select2.css";
import "../vendor/select2-bootstrap.css";
import "../vendor/awesome-bootstrap-checkbox.css";
import "../vendor/xeditable.css";
import "../vendor/angular-toastr.css";
import "../vendor/switchery.css";
import "../vendor/ladda.min.css";
import "../vendor/ion.rangeSlider.css";
import "../vendor/ion.rangeSlider.skinFlat.css";
import "../vendor/bootstrap-clockpicker.min.css";
import "../fonts/pe-icon-7-stroke/css/pe-icon-7-stroke.css";
import "../fonts/pe-icon-7-stroke/css/helper.css";
import "../styles/clock.css";
import "../styles/clock-bigger.css";
import "../styles/chips.css";
import "../styles/plugins/style.css";
import "../styles/plugins/animate.css";
import "../styles/plugins/bootstrap.css";
import "../styles/style.css";
import "../styles/chips.css";
import "../styles/tasks.css";
import "../styles/importants.css";
import "../styles/plugins/angular-datepicker.min.css";
import "../styles/plugins/fullcalendar/fullcalendar.css";
import "../styles/calendar.css";
import "../styles/textAngular.css";
// import "../node_modules/ng-material-datetimepicker/dist/material-datetimepicker.min.css"
// import "node_modules/datatables.net-select-dt/css/select.dataTables.css";