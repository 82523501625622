/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular
        .module('meetingMinutes.directives.form', [])
        .directive('mmConfirmButton', confirmButtonDirectiveFactory);

    function confirmButtonDirectiveFactory() {
        return {
            restrict: 'EA',
            templateUrl: 'views/directives/form/confirm-button.tpl.html',
            replace: true,
            link: link
        };

        function link(scope, element, attrs) {

        }

    }

})();
