/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.public')
        .controller('SignUpCompletionController', SignUpCompletionController);

    SignUpCompletionController.$inject = ['$scope', '$state', 'accountResource', 'userResource', '$stateParams'];

    function SignUpCompletionController($scope, $state, accountResource, userResource, $stateParams) {
        $scope.formData = {
            submitButtonLabel: 'Update an account',
            submitInProgress: false,
            account: {
            }
        };

        userResource.getTimezones().then((response) => {
            $scope.timezones = response;
        });

        $scope.submitForm = function() {
            if ($scope.signUpCompletionForm.$valid) {
                submitInProgressOn();

                var guid = "test_test_test";
                var confirm = userResource.confirmUser($stateParams.requestId, $scope.formData.account, guid);

                confirm.finally(submitInProgressOff);
                confirm.catch(displayErrorMessage);
                confirm.then(transitionToSignInState);

            }

            function transitionToSignInState() {
                $state.go('public.signIn');
            }

            function displayErrorMessage(clientError) {
                $scope.formData.authenticationFailedMessage = "Authentication error. Contact your team leader.";
                $scope.signUpForm.$submitted = false;
            }

            function submitInProgressOn() {
                $scope.formData.submitButtonLabel = 'Creating an account...';
                $scope.formData.submitInProgress = true;
            }

            function submitInProgressOff() {
                $scope.formData.submitButtonLabel = 'Create an account';
                $scope.formData.submitInProgress = false;
            }
        };
    }
})();
