/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular
        .module('meetingMinutes.directives.domain')
        .directive('clockPicker', clockPicker);

    function clockPicker() {
        return {
            restrict: 'A',
            link: function(scope, element) {
                element.clockpicker();
            }
        };
    };

})();
