/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.knowledge.meeting')
        .factory('userPopup', userPopup);

    userPopup.$inject = ['$uibModal', 'userResource', 'numbers'];

    function userPopup($uibModal, userResource, numbers) {


        UserPopupCtrl.$inject = ['$scope', '$uibModalInstance'];
        function UserPopupCtrl($scope, $uibModalInstance) {
            $scope.ok = function() {
                userResource.createUser($scope.formData.item, numbers.uuidv4()).then(function(result) {
                    $uibModalInstance.close();
                    $scope.callback();
                }).catch(displayErrorMessage);
            };

            $scope.cancel = function() {
                $uibModalInstance.dismiss('cancel');
            };

            function displayErrorMessage(clientError) {
                $scope.authenticationFailedMessage = clientError.message + ". Deactivate any user to add another.";
                $scope.formData.item = null;
                $scope.signUpForm.$submitted = false;
            }
        }

        var open = function($scope, item) {
            if (item) {
                $scope.formData.item = item;
            } else {
                $scope.formData.item = {};
            }
            // $scope.formData.item.knowledgeObject = { tags: [] };
            // $scope.formData.item.profiles = [];

            var modalInstance = $uibModal.open({
                templateUrl: 'views/main/popups/user.popup.tpl.html?v=' + new Date(),
                controller: UserPopupCtrl,
                scope: $scope
            });

        };

        return {
            "open": open
        };
    }
})();
