/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.knowledge.person')
        .controller('PersonController', PersonController);

    PersonController.$inject = [
        '$cookieStore', 'tagsResource', 'selectTagsPopup', '$state', '$rootScope', '$scope', '$location',
        '$stateParams', 'personResource', 'knowledgeObjectResource', 'toastr', 'ui.factory',
        'userResource', 'noteResource', 'popupHelper', 'meetingResource', 'dateHelper', 'dictionaries', 'workgroupResource', 'signalrHub'
    ];

    function PersonController($cookieStore,
                            tagsResource,
                            selectTagsPopup,
                            $state,
                            $rootScope,
                            $scope,
                            $location,
                            $stateParams,
                            personResource,
                            knowledgeObjectResource,
                            toastr,
                            ui,
                            userResource,
                            noteResource,
                            popupHelper,
                            meetingResource,
                            dateHelper,
                            dictionaries,
                              workgroupResource,
                              signalrHub
    ) {
								  
                    
        $scope.attachmentUploadUrl = "";
        $scope.$context = $rootScope.$context.$set('/persons/create');
        $scope.back = $stateParams.back;

        // $scope.readonly = false;
        $scope.selectedItem = null;
        $scope.searchText = null;
        $scope.availableProfiles = [];
        // $scope.availableTags = [];
        $scope.leadStatuses = dictionaries.lead().statuses;
        $scope.selectedLeadSource = "";
        $scope.statusObject = null;
        $scope.creatingPerson = false;

        $scope.draftData = {
            status: [
                { id: 1, value: 'Active' }
            ]
        };

        $scope.selectedStatus = 0;

        // do kropa config
        $scope.obj = {
        };

        // The url or the data64 for the image
        $scope.obj.src = null; //'images/test_crop.jpg';

        // Must be [x, y, x2, y2, w, h]
        $scope.obj.coords = [100, 100, 200, 200, 100, 100];

        $scope.fileUploadOptions = {
            url: '',
            autoUpload: false,
            xhrFields: {
                withCredentials: true
            }
        };

        $scope.permissions = [];
        $scope.specialTags = [];
        
        $scope.fileUploadOptions = {
            url: '',
            autoUpload: false,
            xhrFields: {
                withCredentials: true
            }
        };

        $scope.attachmentsOptions = {
            autoUpload: false,
            url: '',
            xhrFields: {
                withCredentials: true
            }
        };

		//Initialize object required for form
		$scope.formData = {
			item: {
                organizations: [],
                tags: [],
                emails: []
			}
		};
		
        if ($state.$current.self.name == 'main.person/edit')
            $scope.mode = 'edit';
        if ($state.$current.self.name == 'main.person/create')
            $scope.mode = 'create';
        if ($state.$current.self.name == 'main.person/view')
            $scope.mode = 'view';

        function createFilterFor(query) {
            var lowercaseQuery = angular.lowercase(query);
            return function filterFn(vegetable) {
                vegetable = angular.lowercase(vegetable);
                return (vegetable.indexOf(lowercaseQuery) === 0);
            };
        }
        function activate() {
            if ($stateParams.id) {
                personResource.getPerson($stateParams.id).then(function(response) {


                    $scope.prepare(response);
                });
            } else {
            }

            knowledgeObjectResource.getAvailableTags_Organization().then(function(response) {
                $scope.availableOrganizations = response;

            });
            knowledgeObjectResource.getAvailableTags_Profile().then(function(response) {
                $scope.availableProfiles = response;

            });


            $scope.$on('fileuploaddone',
                function(event, data) {
                    if (data.paramName == 'profilePicture') {
                        $scope.obj.src = data.result;
                    }
                });
        }
        activate();

        workgroupResource.getLeadSources().then(function (response) {
            $scope.leadSources = response;
        });


        $scope.$on('fileuploaddone',
            function(event, data) {
                if (data.paramName == 'profilePicture') {
                    $scope.obj.src = data.result;
                }
            });

        $scope.$on('fileuploadadd',
            function(e, data) {
                if (data.paramName == 'profilePicture') {
                    $scope.fileUploadOptions.url = $scope.pictureUploadUrl;
                    data.url = $scope.pictureUploadUrl;
                    data.submit();
                }
                if (data.paramName == 'attachments') {
                    $scope.attachmentsOptions.url = $scope.attachmentUploadUrl;
                    data.url = $scope.attachmentUploadUrl;
                    data.submit().then(function() {
                        $scope.refresh();
                    });

                }
        });

        $scope.$on('fileuploadadd',
        function(e, data) {
            if (data.paramName == 'attachments') {
                $scope.attachmentsOptions.url = $scope.attachmentUploadUrl;
                data.url = $scope.attachmentUploadUrl;
                data.submit().then(function() {
                    $scope.refresh();
                });
            }
        });

        $scope.$watch(
            function() {
                if ($scope.formData.item)
                    return $scope.formData.item.lastContact;
            },
            function(newValue, oldValue) {
                if ($scope.formData.item)
                    if ($scope.formData.item.lastContact !== undefined)
                        $scope.lastContactEx = dateHelper.enchance($scope.formData.item.lastContact);
            },
            true);
            
        $scope.searchRelatedNotes = function() {
            var request = {
                from: 0,
                to: 100,
                take: 100,
                tagFilter: []
            };
            request.tagFilter.push('p:' + $scope.formData.item.id);

            noteResource.getNotes(request).then(function(result) {
                $scope.relatedNotes = result;
            });

            meetingResource.getPersonMeetings($scope.formData.item.id).then(function(result) {
                for (var i = 0; i < result.length; i++) {
                    result[i].plannedStartTime = dateHelper.enchance(result[i].plannedStartTime);
                }
                $scope.relatedMeetings = result;
            });
        };

        function createRelatedNoteCallback(){
            personResource.updateLeadLastContactDate($scope.formData.item.id);
            $state.reload();
        }

        $scope.createRelatedNote = function() {
            popupHelper.CreateKoNote($scope, "p:" + $scope.formData.item.id, createRelatedNoteCallback);
        };

        $scope.onSetUserPicture = function onSetUserPicture() {
            var coords = $scope.obj.coords;
            userResource.saveKnowledgeObjectPicture($scope.formData.item.koId,
                {
                    cropPointX: coords[0],
                    cropPointY: coords[1],
                    cropWidth: coords[4],
                    cropHeight: coords[5]
                }).then(function() {
                $scope.obj.src = null;
                var tmp = $scope.formData.item.avatarUrl;
                $scope.formData.item.avatarUrl = tmp + "?_ts=" + new Date().getTime();
            });
        };

        $scope.cancel = function() {
            $state.go('main.library/list');
        };

        $scope.openRelatedMeeting = function (meetingId) {
            $state.go('main.meetingDetails', { meetingId: meetingId });
        };

        $scope.translateStatusToString = function(statusId) {
            return dictionaries.lead().leadStatusToString(statusId);
        };
        
        $scope.querySearchOrganizations = function(query) {
            var results = query ? $scope.availableOrganizations.filter(createFilterFor(query)) : [];
            return results;
        };

        $scope.querySearchProfiles = function(query) {
            var results = query ? $scope.availableProfiles.filter(createFilterFor(query)) : [];
            return results;
        };

        function uuidv4() {
            return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
              (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            );
          }

        $scope.save = function() {
            $scope.creatingPerson = true;
            toastr.success("We are creating the lead...")
            $scope.formData.item.leadSource = $scope.selectedLeadSource;
            $scope.formData.item.status = $scope.statusObject.id;

            var guid = uuidv4();

            var leadCreated = function(event, data) {
                var response = JSON.parse(data);
                if (event === "LeadCreatedEvent" && response.Guid == guid){
                    toastr.success("Person have been succesfully created");
                    $state.go('main.person/view', { id: response.LeadId });
                }
            };

            var leadUpdated = function(event, data) {
                var response = JSON.parse(data);
                if (event === "LeadCreatedEvent" && response.Guid == guid){
                    toastr.success("Person have been succesfully updated");
                    $state.go('main.person/view', { id: response.LeadId });
                }
            };


			if ($scope.mode == 'create') {
				personResource.createPerson($scope.formData.item, guid).then(function(leadId) {
                    $scope.creatingPerson = false;
                    toastr.success("Person have been succesfully updated");
                    $state.go('main.person/view', { id: leadId}, {reload: true});
                });
			};

            if ($scope.mode == 'edit') {
                // hub.onEvent(leadUpdated);
                personResource.save($scope.formData.item, guid).then(function(leadId) {
                    $scope.creatingPerson = false;
                    toastr.success("Person have been succesfully updated");
                    $state.go('main.person/view', { id: leadId}, {reload: true});
                });
			};

        };

        $scope.editMode = function() {
            $scope.mode = 'edit';
        };

        // $scope.draft = function() {
        //     $scope.formData.item.knowledgeObject.status = 0; //draft
        //     knowledgeObjectResource.draft($scope.formData.item.knowledgeObject.id);
        // };


        // $scope.hideUrlUploadPanel = function() {
        //     $scope.xxx.showUrlPanel = false;
        // };

        //Loads lead once again and its attachments
        $scope.refresh = function() {
            personResource.getPerson($scope.formData.item.id).then(function(response) {
                $scope.formData = { item: response };
                $scope.selectedStatus = response.status;
            });
        };

        //Create opp from this Lead
        $scope.opportunity = function() {
            $location.path('/opportunity/create').search({ personId: $scope.formData.item.id.toString() });
        };

        $scope.checkIfSpecial = function(tag) {
            if ($scope.specialTags[tag])
                return $scope.specialTags[tag].translated;
        };

        $scope.viewNote = function(id) {
            $state.go('main.note/view', { id: id });
        };

        $scope.prepare = function(response) {
            $scope.formData = { item: response };
            if ($scope.formData.item) {
                if ($scope.formData.item.lastContact !== undefined)
                    $scope.lastContactEx = dateHelper.enchance($scope.formData.item.lastContact);
                if($scope.formData.item.createDateTime !== undefined)
                    $scope.createDateEx = dateHelper.enchance($scope.formData.item.createDateTime);
            }
            $scope.specialTags = tagsResource.translateTags(response.tags);

            // $scope.formData.item.profile = ["Lead of " + $scope.formData.item.knowledgeObject.creator.userName];
            $scope.selectedLeadSource = response.leadSource;
            
            $scope.statusObject = $scope.leadStatuses.filter(function(status) {
                return status.id == response.status;
            })[0];

            $scope.pictureUploadUrl = '/api/knowledgeObject/' +
                $scope.formData.item.koId +
                '/profile/picture/upload';

            // knowledgeObjectResource.getPermissions($scope.formData.item.knowledgeObject.id).then(function(response2) {
            //     for (var i = 0; i < response2.length; i++) {
            //         response2[i].permissionTypeTxt =
            //             angular.globalSettingsConst.permissions[response2[i].permissionType];
            //     }
            //     $scope.permissions = response2;
            // });

            
            $scope.searchRelatedNotes();
            $scope.attachmentUploadUrl =
                '/api/knowledgeObject/' + $scope.formData.item.koId + '/attachment';
        };

        $scope.selectTagsPopup = function(tags, type) {
            var config = [];
            config[type] = "true";
            selectTagsPopup.open($scope, tags, config, null);
        };

        $scope.onAttachmentDelete = function(data) {
            knowledgeObjectResource.deleteAttachment(data.id).then(function() {
                activate();
            });
        };
    }
})();
