/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.knowledge.person')
        .config(routesConfig);

    routesConfig.$inject = ['$stateProvider', 'authorizationProvider'];

    function routesConfig($stateProvider, authorizationProvider) {
        $stateProvider
            .state('main.person/list',
                {
                    url: '/person/list',
                    templateUrl: 'views/main/knowledge/person/person-list.tpl.html?v=' + new Date(),
                    controller: 'PersonListController',
                    resolve: {
                        
                    },
                    data: {
                        pageTitle: 'Persons'
                    }
                }).state('main.person/view',
                {
                    url: '/person/view/:id',
                    templateUrl: 'views/main/knowledge/person/person.tpl.html?v=' + new Date(),
                    controller: 'PersonController',
                    resolve: {
                        
                    },
                    data: {
                        pageTitle: 'Person'
                    }
                }).state('main.person/edit',
                {
                    url: '/person/edit/:id',
                    templateUrl: 'views/main/knowledge/person/person.tpl.html?v=' + new Date(),
                    controller: 'PersonController',
                    resolve: {
                        
                    },
                    data: {
                        pageTitle: 'Person'
                    }
                }).state('main.person/create',
                {
                    url: '/person/create',
                    templateUrl: 'views/main/knowledge/person/person.tpl.html?v=' + new Date(),
                    controller: 'PersonController',
                    resolve: {
                        
                    },
                    data: {
                        pageTitle: 'Person'
                    }
                });
    }

})();
