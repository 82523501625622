/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.knowledge.meeting')
        .factory('agendaPopup', agendaPopup);

    agendaPopup.$inject = ['httpResource', '$uibModal', 'meetingResource'];

    function agendaPopup(httpResource, $uibModal, meetingResource) {

        AgendaPopupCtrl.$inject = ['$scope', '$uibModalInstance'];
        function AgendaPopupCtrl($scope, $uibModalInstance) {
            meetingResource.getMeetingById($scope.meeting.id).then(function(meeting) {
                $scope.meeting = meeting;
            });

            $scope.ok = function() {
                $uibModalInstance.close();
            };

            $scope.cancel = function() {
                $uibModalInstance.dismiss('cancel');
            };

            $scope.onAddAgendaItem = function() {
                meetingResource.saveAgendaItem($scope.meeting.id, {}).then(function(savedAgendaItem) {
                    $scope.meeting.agendaItems.push(savedAgendaItem);
                });
            };

            $scope.onSaveAgendaItem = function(agendaItemRef, item) {
                meetingResource.saveAgendaItem($scope.meeting.id, agendaItemRef)
                    .then(function(savedAgendaItem) {
                        agendaItemRef = savedAgendaItem;
                        $scope.onAddAgendaItem();
                        item['agendaItem_' + item.agendaItem.id].$setPristine();
                    });
            };

            $scope.onDeleteAgendaItem = function(agendaItem) {
                meetingResource.deleteAgendaItem(agendaItem.id).then(overrideAgendaItems);
            };

            $scope.moveUpAgendaItem = function(agendaItem) {
                meetingResource.moveUpAgendaItem(agendaItem)
                    .then(overwriteAgendaItems);
            };

            $scope.moveDownAgendaItem = function(agendaItem) {
                meetingResource.moveDownAgendaItem(agendaItem)
                    .then(overwriteAgendaItems);
            };

            $scope.canMoveAgendaItemUp = function(agendaItemRef) {
                return agendaItemRef.number > 0;
            };

            function overrideAgendaItems(agendaItems) {
                $scope.meeting.agendaItems = agendaItems;
            }

            $scope.checkIfAgendaItemDirty = function(item) {
                return item['agendaItem_' + item.agendal.id].$dirty;
            }

        }

        var open = function($scope, meeting) {
            $scope.meeting = meeting;

            var modalInstance = $uibModal.open({
                templateUrl: 'views/main/popups/agenda.popup.tpl.html',
                controller: AgendaPopupCtrl,
                scope: $scope
            });
        }

        return {
            "open": open,
        };
    }
})();
