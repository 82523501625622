import { FSAWithPayload, FSA, ErrorFSA } from 'flux-standard-action';
import { put, call, takeEvery } from '@redux-saga/core/effects';
import { AttachmentDto } from './attachment';
import { ITodoModalModel } from '../uimodels/uitodo';
import { AppState } from '../appstate';

export class TodoDto {
    id: number;
    name: string;
    description: string;
    ownerId: number;
    creatorId: number;
    createdAt: Date;
    duration: number;
    deadline: string;
    koId: number;
    tags: string[];
    isSaving: boolean;
    attachments: AttachmentDto[];
}

export interface TodoState {
    items: TodoDto[];
    isLoading: boolean;
    exception: string;
    isAdding: boolean;
}

export const INITIAL_TODO_STATE: TodoState = {
    items: [],
    isLoading: false,
    exception: null,
    isAdding: false
}

export const TODO_LIST_REQUEST = 'spinme/todo/LIST_REQUEST';
export const listTodoRequest = (): FSA => {
    return {
        type: TODO_LIST_REQUEST
    }
}

export const TODO_LIST_SUCCESS = 'spinme/todo/LIST_SUCCESS';
export const listTodoSuccess = (items: TodoDto[]): FSAWithPayload<string, TodoDto[]> => {
    return {
        type: TODO_LIST_SUCCESS,
        payload: items
    }
}

export const TODO_LIST_ERROR = 'spinme/todo/LIST_ERROR';

export const TODO_ADD_REQUEST = 'spinme/todo/ADD_REQUEST';
export const addTodoRequest = (todo: TodoDto, attachments: File[], loggedUserId: number): FSAWithPayload<string, TodoDto | any> => {
    return {
        type: TODO_ADD_REQUEST,
        payload: {todo, attachments, loggedUserId}
    }
}

export const TODO_ADD_SUCCESS = 'spinme/todo/ADD_SUCCESS';
export const addTodoSuccess = (todo: TodoDto, loggedUserId: number): FSAWithPayload<string, any> => {
    return {
        type: TODO_ADD_SUCCESS,
        payload: {todo, loggedUserId}
    }
}

export const TODO_ADD_ERROR = 'spinme/todo/ADD_ERROR';
export const addTodoError = (): ErrorFSA => {
    return {
        type: TODO_ADD_ERROR,
        error: true
    }
}

export const TODO_CONVERT_NOTE_REQUEST = 'spinme/todo/CONVERT_NOTE_REQUEST';
export const convertNoteTodoRequest = (data: ITodoModalModel): FSAWithPayload<string, ITodoModalModel> => {
    return {
        type: TODO_CONVERT_NOTE_REQUEST,
        payload: data
    }
}

export const TODO_CONVERT_NOTE_SUCCESS = 'spinme/todo/CONVERT_NOTE_SUCCESS';
export const convertNoteTodoSuccess = (data: ITodoModalModel): FSAWithPayload<string, ITodoModalModel> => {
    return {
        type: TODO_CONVERT_NOTE_SUCCESS,
        payload: data
    }
}

export const TODO_CONVERT_NOTE_ERROR = 'spinme/todo/CONVERT_NOTE_ERROR';
export const convertNoteTodoError = (): ErrorFSA => {
    return {
        type: TODO_CONVERT_NOTE_ERROR,
        error: true
    }
}

export const TODO_UPDATE_REQUEST = 'spinme/todo/UPDATE_REQUEST';
export const updateTodoRequest = (todo: TodoDto, attachments: File[], toDelete: AttachmentDto[], loggedUserId: number): FSAWithPayload<string, any> => {
    return {
        type: TODO_UPDATE_REQUEST,
        payload: {
            todo, attachments, toDelete, loggedUserId
        }
    }
}

export const TODO_UPDATE_SUCCESS = 'spinme/todo/UPDATE_SUCCESS';
export const updateTodoSuccess = (todo: TodoDto, loggedUserId: number): FSAWithPayload<string, any> => {
    return {
        type: TODO_UPDATE_SUCCESS,
        payload: {todo, loggedUserId} 
    }
}

export const TODO_UPDATE_ERROR = 'spinme/todo/UPDATE_ERROR';
export const updateTodoError = (todoId: number): FSAWithPayload<string, number> => {
    return {
        type: TODO_UPDATE_ERROR,
        error: true,
        payload: todoId
    }
}

export const TODO_DONE_REQUEST = 'spinme/todo/DONE_REQUEST';
export const doneTodoRequest = (todoId: number): FSAWithPayload<string, number> => {
    return {
        type: TODO_DONE_REQUEST,
        payload: todoId
    }
}

export const TODO_DONE_SUCCESS = 'spinme/todo/DONE_SUCCESS';
export const doneTodoSucess = (todoId: number): FSAWithPayload<string, number> => {
    return {
        type: TODO_DONE_SUCCESS,
        payload: todoId
    }
}

export const TODO_DONE_ERROR = 'spinme/todo/DONE_ERROR';
export const doneTodoError = (todoId: number): FSAWithPayload<string, number> => {
    return {
        type: TODO_DONE_ERROR,
        error: true,
        payload: todoId
    }
}

export default function todoReducers(state: TodoState = INITIAL_TODO_STATE,
    action: FSAWithPayload<string, TodoDto[] | TodoDto | number | ITodoModalModel | any>): TodoState {
    switch (action.type) {
        case TODO_LIST_REQUEST: {
            return {
                ...state,
                isLoading: true
            }
        }
        case TODO_LIST_SUCCESS: {
            const items = action.payload as TodoDto[];
            var newState = {
                ...state,
                items: items,
                isLoading: false,
            }
            return newState;
        }
        case TODO_ADD_REQUEST: {
            return {
                ...state,
                isAdding: true
            }
        }
        case TODO_ADD_SUCCESS: {
            const todo = action.payload.todo as TodoDto;
            const loggedUserId = action.payload.loggedUserId as number;

            var items = [...state.items];

            if (todo.ownerId === loggedUserId)
                items.push(todo);

            return {
                ...state,
                isAdding: false,
                items: items
            }
        }
        case TODO_ADD_ERROR: {
            return {
                ...state,
                isAdding: false
            }
        }
        case TODO_CONVERT_NOTE_REQUEST: {
            return {
                ...state,
                isAdding: true
            }
        }
        case TODO_CONVERT_NOTE_SUCCESS: {
            const data = action.payload as ITodoModalModel;
            return {
                ...state,
                isAdding: false,
                items: [...state.items, data.todo]
            }
        }
        case TODO_CONVERT_NOTE_ERROR: {
            return {
                ...state,
                isAdding: false
            }
        }
        case TODO_UPDATE_REQUEST: {
            const todo = action.payload.todo as TodoDto;

            return {
                ...state,
                items: state.items.map((element) => {
                    if (element.id === todo.id) {
                        return {
                            ...element,
                            isSaving: true
                        }
                    } else {
                        return element;
                    }
                })
            }
        }
        case TODO_UPDATE_SUCCESS: {
            const todo = action.payload.todo as TodoDto;
            const loggedUserId = action.payload.loggedUserId as number;

            return {
                ...state,
                items: state.items.map((element) => {
                    if (element.id === todo.id) {
                        return {
                            ...todo,
                            isSaving: false
                        };
                    } else {
                        return element;
                    }
                }).filter(x=>x.ownerId === loggedUserId)
            }
        }
        case TODO_UPDATE_ERROR: {
            const todoId = action.payload as number;
            return {
                ...state,
                items: state.items.map((element) => {
                    if (element.id === todoId) {
                        return {
                            ...element,
                            isSaving: false
                        }
                    } else {
                        return element;
                    }
                })
            }
        }
        case TODO_DONE_REQUEST: {
            const todoId = action.payload as number;
            return {
                ...state,
                items: state.items.map((element) => {
                    if (element.id === todoId) {
                        return {
                            ...element,
                            isSaving: true
                        }
                    } else {
                        return element;
                    }
                })
            }
        }
        case TODO_DONE_SUCCESS: {
            const todoId = action.payload as number;
            return {
                ...state,
                items: state.items.filter((element) => {
                    return element.id !== todoId
                })
            }
        }
        case TODO_DONE_ERROR: {
            const todoId = action.payload as number;
            return {
                ...state,
                items: state.items.map((element) => {
                    if (element.id === todoId) {
                        return {
                            ...element,
                            isSaving: false
                        }
                    } else {
                        return element;
                    }
                })
            }
        }
        default:
            return state;
    }
}

export const getTodos = (state: AppState): TodoDto[] => state.todos.items;

function* todoListRequestSaga() {
    try {
        const data = yield call(fetch, '/api/todo/list', { headers: { "Content-Type": "application/json; charset=utf-8" } });
        if (data.status !== 200) throw Error();
        const body = yield data.json();
        yield put(listTodoSuccess(body));
    } catch (error) {
        // yield put(listTodoError());
    }
}


export function* watchTodoListRequest() {
    yield takeEvery(TODO_LIST_REQUEST, todoListRequestSaga)
}

function* todoAddRequestSaga(action: FSAWithPayload<string, any>) {
    try {
        const data = yield call(fetch, `/api/todo`, { 
            method: 'POST',
            body: JSON.stringify(action.payload.todo),
            headers: { "Content-Type": "application/json; charset=utf-8" }
        });
        if (data.status !== 200) throw Error();
        const body = yield data.json() as TodoDto;

        var attachments = action.payload.attachments as File[];
        uploadFiles(attachments, body.koId);

        yield put(addTodoSuccess(body, action.payload.loggedUserId as number));

    } catch (error) {
        yield put(addTodoError());
    }
}
		
async function uploadFiles(files: File[], koid: number) {
    const promises = files.map(file => sendRequest(file, koid))
    try {
        await Promise.all(promises);
        // setState({ ...state, successfullUploaded: true, uploading: false });
        } catch (e) {
        // Not Production ready! Do some error handling here instead...
        // setState({ ...state, successfullUploaded: true, uploading: false });
        }
}
function sendRequest(file: File, koid: number): Promise<any> {
    return new Promise((resolve, reject) => {
        const req = new XMLHttpRequest();
    
        const formData = new FormData();
        formData.append("file", file, file.name);
    
        req.open("POST", "/api/knowledgeObject/" + koid + "/attachment");
        req.send(formData);
    });
    }

async function deleteFiles(files: AttachmentDto[]) {
    const promises = files.map(file => sendDeleteFileRequest(file))
    try {
        await Promise.all(promises);
        } catch (e) {
        }
}

function sendDeleteFileRequest(file: AttachmentDto): Promise<any> {
return new Promise((resolve, reject) => {
    const req = new XMLHttpRequest();

    req.open("DELETE", "/api/knowledgeObject/attachment/"+file.id);
    req.send();
});
}

export function* watchTodoAddRequest() {
    yield takeEvery(TODO_ADD_REQUEST, todoAddRequestSaga)
}

function* todoUpdateRequestSaga(action: FSAWithPayload<string, any>) {
    try {
        const data = yield call(fetch, `/api/todo`, { 
            method: 'PUT',
            body: JSON.stringify(action.payload.todo),
            headers: { "Content-Type": "application/json; charset=utf-8" }
        });
        if (data.status !== 200) throw Error();
        const body = yield data.json();

        var attachments = action.payload.attachments as File[];
        uploadFiles(attachments, body.koId);

        var toDelete = action.payload.toDelete as AttachmentDto[];
        deleteFiles(toDelete);

        yield put(updateTodoSuccess(body, action.payload.loggedUserId as number));
    } catch (error) {
        yield put(updateTodoError(action.payload.id));
    }
}

export function* watchTodoUpdateRequest() {
    yield takeEvery(TODO_UPDATE_REQUEST, todoUpdateRequestSaga)
}

function* todoDoneRequestSaga(action: FSAWithPayload<string, number>) {
    try {
        const data = yield call(fetch, `/api/todo/${action.payload}/done`);
        if (data.status !== 200) throw Error();
        const body = yield data.json();
        yield put(doneTodoSucess(body));
    } catch (error) {
        yield put(doneTodoError(action.payload));
    }
}

export function* watchTodoDoneRequest() {
    yield takeEvery(TODO_DONE_REQUEST, todoDoneRequestSaga)
}

function* todoConvertNoteRequestSaga(action: FSAWithPayload<string, ITodoModalModel>) {
    try {
        const data = yield call(fetch, `/api/note/${action.payload.convertedFromNoteId}/convert/todo`,  { 
            method: 'PUT',
            body: JSON.stringify(action.payload.todo),
            headers: { "Content-Type": "application/json; charset=utf-8" }
        });
        if (data.status !== 200) throw Error();
        const body = yield data.json();
        yield put(convertNoteTodoSuccess({
            todo: body,
            convertedFromNoteId: action.payload.convertedFromNoteId
        }));
    } catch (error) {
        yield put(convertNoteTodoError());
    }
}

export function* watchTodoConvertNoteRequest() {
    yield takeEvery(TODO_CONVERT_NOTE_REQUEST, todoConvertNoteRequestSaga)
}

