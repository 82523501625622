/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.knowledge.meeting')
        .controller('MeetingStartController', MeetingStartController);

    MeetingStartController.$inject = ['$rootScope', '$scope', '$state', 'meetingResource'];

    function MeetingStartController($rootScope, $scope, $state, meetingResource) {

        activate();
        $scope.meetings = [];

        $scope.$context = $rootScope.$context.$set('/meetings');
        $scope.rowsPerPage = 15;

        $scope.viewMeeting = function(meeting) {
            $scope.$context.$object = meeting;
            $scope.$context.viewMeeting.actions.go();
        }

        function activate() {

            $scope.calendars = [[]];
            $scope.calendarConfig = {
                height: 550,
                editable: true,
                header: {
                    left: 'prev,next',
                    center: 'title',
                    right: 'month,agendaWeek,agendaDay'
                },
                eventClick: onEventClick
            };

            getMeetings();
        }

        $scope.filter = "";
        $scope.tags = [];
        $scope.persons = [];
        $scope.users = [];
        $scope.overallCost = 0;
        $scope.overallTime = 0;

        var uniquePush = function(item, array) {
            for (var i = 0; i < array.length; i++) {
                if (array[i].id && array[i].id == item.id) {
                    return;
                }

                if (!array[i].id && array[i] == item) {
                    return
                }
            }
            array.push(item);
        }

        $scope.customFilter = {
            user: null,
            tag: null,
            person: null,
        };

        $scope.selectTag = function(tag) {
            if (tag == $scope.customFilter.tag)
                $scope.customFilter.tag = null
            else
                $scope.customFilter.tag = tag;
            $scope.filterMeetings();
        }

        $scope.isTag = function(tag, meeting) {
            for (var i = 0; i < meeting.knowledgeObject.tags.length; i++) {
                if (meeting.knowledgeObject.tags[i].text == tag)
                    return true
            }
            return false;
        }


        $scope.selectUser = function(user) {
            if (user == $scope.customFilter.user)
                $scope.customFilter.user = null
            else
                $scope.customFilter.user = user;
        }

        $scope.selectPerson = function(person) {
            if (user == $scope.customFilter.person)
                $scope.customFilter.person = null
            else
                $scope.customFilter.person = person;
        }

        $scope.filterMeetings = function() {
            $scope.filteredMeetings = [];
            $scope.users = [];
            $scope.persons = [];
            $scope.tags = [];
            $scope.overallCost = 0;
            $scope.overallTime = 0;

            for (var i = 0; i < $scope.meetings.length; i++) {
                if ($scope.meetings[i].knowledgeObject.title &&
                    $scope.meetings[i].knowledgeObject.title.toLowerCase().indexOf($scope.filter.toLowerCase()) >= 0 ||
                    $scope.filter.toLowerCase() == '') {
                    $scope.meetings[i].relativeTimeTxt =
                        moment($scope.meetings[i].plannedStartTime).endOf('day').fromNow();
                    $scope.meetings[i].plannedStartTimeTxt = moment($scope.meetings[i].plannedStartTime).calendar();

                    if ($scope.customFilter.tag) {
                        if (!$scope.isTag($scope.customFilter.tag, $scope.meetings[i])) {
                            continue;
                        }
                    }

                    $scope.overallCost = $scope.overallCost + $scope.meetings[i].cost;
                    $scope.overallTime = $scope.overallTime + $scope.meetings[i].duration;

                    if (!$scope.meetings[i].knowledgeObject.title) {
                        $scope.meetings[i].knowledgeObject.title = "No title";
                    }

                    if (!$scope.meetings[i].location) {
                        $scope.meetings[i].location = "Location unknown";
                    }

                    $scope.filteredMeetings.push($scope.meetings[i]);

                    for (var p = 0; p < $scope.meetings[i].internalParticipants.length; p++) {
                        uniquePush($scope.meetings[i].internalParticipants[p], $scope.users);
                    }

                    for (var p = 0; p < $scope.meetings[i].knowledgeObject.tags.length; p++) {
                        uniquePush($scope.meetings[i].knowledgeObject.tags[p].text, $scope.tags);
                    }

                    for (var p = 0; p < $scope.meetings[i].externalParticipants.length; p++) {
                        uniquePush($scope.meetings[i].externalParticipants[p], $scope.persons);
                    }
                }

            }
        }

        function getMeetings() {
            meetingResource.getMeetings().then(function(meetings) {

                var events = [];

                angular.forEach(meetings,
                    function(meeting) {
                        meeting.showDetails = false;
                        $scope.meetings.push(meeting);
                        events.push(newEvent(meeting));
                    });
                $scope.filterMeetings(meetings);
                $scope.calendars[0] = events;
            });
        }

        function newEvent(meeting) {
            var start = moment(meeting.technicalStartTime).toDate(),
                end = moment(meeting.technicalStartTime).add(meeting.duration, 'minutes').toDate();
            return {
                id: meeting.id,
                start: start,
                end: end,
                title: meeting.knowledgeObject.title
            };
        }

        function onEventClick(calendarEvent) {
            meetingResource.getMeetingById(calendarEvent.id).then(function(meeting) {
                $state.go('main.planMeeting', { meetingId: meeting.id });
            });
        }

        $scope.planNewMeeting = function() {
            $state.go('main.planMeeting', { meetingId: null });
        }

        function onCreateNoteClick() {
            $state.go('main.createNote', { noteId: null });
        }
    }

})();
