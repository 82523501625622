/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    function preconditionsFactory() {

        function checkArgument(expression, errorMessage) {
            if (!expression) {
                throw new Error('IllegalArgument' + (angular.isDefined(errorMessage) ? ': ' + errorMessage : ''));
            }
        }

        return {
            checkArgument: checkArgument
        };
    }

    angular.module('meetingMinutes.common')
        .factory('preconditions', preconditionsFactory);

})();
