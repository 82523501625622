/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    function routesConfig($stateProvider, $urlRouterProvider) {

        $stateProvider
            .state('public',
                {
                    abstract: true,
                    templateUrl: 'views/layout/public/content.tpl.html?v=' + new Date(),
                })
            .state('public.dispatch',
                {
                    url: '/dispatch',
                    controller: 'DispatchController'
                })
            .state('public.signIn',
                {
                    url: '/sign-in?appId&returnUrl&forwardUrl',
                    templateUrl: 'views/public/sign-in.tpl.html?v=' + new Date(),
                    controller: 'SignInController',
                    data: {
                        pageTitle: 'Sign in'
                    }
                })
            .state('public.signUp',
                {
                    url: '/sign-up?appId&returnUrl&forwardUrl',
                    templateUrl: 'views/public/sign-up.tpl.html?v=' + new Date(),
                    controller: 'SignUpController',
                    data: {
                        pageTitle: 'Sign Up'
                    }
                })
            .state('public.signOut',
                {
                    url: '/sign-out',
                    controller: 'SignOutController'
                })
            .state('public.signUpCompletion',
                {
                    url: '/sign-up-completion/:requestId',
                    templateUrl: 'views/public/sign-up-completion.tpl.html?v=' + new Date(),
                    controller: 'SignUpCompletionController'
                })
            .state('public.signUpConfirmation',
                {
                    url: '/sign-up-confirmation/:requestId',
                    controller: 'SignUpConfirmationController'
                })
            .state('public.resetPassword',
                {
                    url: '/reset-password',
                    templateUrl: 'views/public/reset-password.tpl.html?v=' + new Date(),
                    controller: 'ResetPasswordController',
                    data: {
                        pageTitle: 'Reset password'
                    }
                })
            .state('public.resetPasswordConfirmation',
                {
                    url: '/reset-password-confirmation',
                    templateUrl: 'views/public/reset-password-confirmation.tpl.html?v=' + new Date(),
                    data: {
                        pageTitle: 'Reset password confirmation'
                    }
                })
            .state('public.changePassword',
                {
                    url: '/change-password/:requestId',
                    templateUrl: 'views/public/change-password.tpl.html?v=' + new Date(),
                    controller: 'ChangePasswordController',
                    data: {
                        pageTitle: 'Change password'
                    }
                })
            .state('public.error',
                {
                    url: '/error',
                    templateUrl: 'views/public/error.tpl.html?v=' + new Date(),
                    data: {
                        pageTitle: 'Error'
                    }
                })
            .state('public.termsOfService',
                {
                    url: '/terms-of-service',
                    templateUrl: 'views/public/terms-of-service.tpl.html?v=' + new Date()
                })
            .state('public.privacyPolicy',
                {
                    url: '/privacy-policy',
                    templateUrl: 'views/public/privacy-policy.tpl.html?v=' + new Date()
                });

        $urlRouterProvider.otherwise('/dispatch');
    }

    routesConfig.$inject = ['$stateProvider', '$urlRouterProvider'];

    angular.module('meetingMinutes.public')
        .config(routesConfig);

})();
