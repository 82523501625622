/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function () {
    'use strict';
    angular.module('meetingMinutes.main.knowledge.note')
        .controller('NoteListController', NoteListController);

    NoteListController.$inject = [
        '$cookies', '$cookieStore', '$rootScope', '$scope', '$state', '$location', 'noteResource', 'crmResource',
        'knowledgeObjectResource', 'dateHelper', 'dictionaries'
    ];

    function NoteListController($cookies,
                                $cookieStore,
                                $rootScope,
                                $scope,
                                $state,
                                $location,
                                noteResource,
                                crmResource,
                                knowledgeObjectResource,
                                dateHelper,
                                dictionaries) {
        $rootScope.meetingId = null;
        $scope.formData = {
            commonTags: [],
            timelines: [],
            autoTags: []
        };
        $scope.note = {};
        $scope.notes = [];
        $scope.filteredNotes = [];
        $scope.currentTypeFilter = null;
        $scope.rowsPerPage = 20;
        $scope.tags = [];
        $scope.type = $location.$$search.type;

        $scope.statuses = [
            {id: 0, name: 'Lost'},
            {id: 1, name: 'Active'},
            {id: 2, name: 'Suspended'},
            {id: 3, name: 'Won'}
        ];

        $scope.opportunityTypeToString = function (type) {
            if (type > -1) {
                var names = $scope.statuses.filter(function (el) {
                    return el.id === type;
                });
                return names[0].name;
            }
        };

        $scope.leadStatusToString = function (status) {
            if (status > -1 && status != undefined && status != null) {
                var statuses = dictionaries.lead().statuses.filter(function (el) {
                    return el.id === status;
                });
                if (statuses)
                    return statuses[0].value;
            }
        };
        $scope.loading = false;

        $scope.appSettings = $rootScope.settings;

        activate();

        $scope.showMore = function (note) {
            note.more = true;
        };

        $scope.showLess = function (note) {
            note.more = undefined;
        };

        $scope.search = function () {
            getNotes();
        };

        $scope.createNote = function () {
            noteResource.createNoteLocation(null, '/note/list');
        };

        $scope.createMeeting = function (type) {
            $state.go('main.planMeeting', {meetingId: null});
        };

        $scope.createPerson = function () {
            $state.go('main.person/create', {id: null});
        };

        $scope.createOpportunity = function () {
            crmResource.createOpportunityLocation(null, '/note/list');
        };

        $scope.favourite = function (item) {
            if (item.isFavourite == 0) {
                item.isFavourite = 1;
            } else {
                item.isFavourite = 0;
            }

            noteResource.favourite(item.id).then(function (xhr) {
                $scope.formData.favourites = xhr;
            });
        };

        $scope.customFilter = {
            user: null,
            tag: null,
            person: null,
        };

        $scope.addToContext = function (tag) {
            $rootScope.addToContext(tag);
        };

        $scope.removeFromContext = function (tag) {
            $rootScope.removeFromContext(tag);
        };

        $scope.selectTag = function (tag) {
            $scope.addToContext(tag);
        };

        $scope.countContextTags = function () {
            if ($rootScope.contextTags.tags)
                return $rootScope.contextTags.tags.length;
            else
                return 0;
        };

        $scope.isTag = function (tag, note) {
            for (var i = 0; i < note.knowledgeObject.tags.length; i++) {
                if (note.knowledgeObject.tags[i].text == tag)
                    return true
            }
            return false;
        };

        $scope.textFilter;
        $scope.currentFrom = 0;
        $scope.currentTo = 20;

        $scope.previous = function () {
            getNotes($scope.currentFrom - 20);
        };
        $scope.previousVisible = false;

        $scope.next = function () {
            getNotes($scope.currentFrom + 20);
        };

        $scope.nextVisible = false;

        $scope.filterByType = function (type) {
            $scope.currentTypeFilter = type;
            $scope.currentTypeFilterDef = knowledgeObjectResource.knowledgeObjectDefinition(type);
            getNotes();
        };

        $scope.focusOnOpportunity = function (item) {
            $rootScope.setOpportunityContext(item);
        }

        $scope.clearFilterByType = function () {
            $scope.currentTypeFilter = null;
            $scope.currentTypeFilterDef = null;
            $rootScope.contextTags.tags = [];
            getNotes();
        };

        function activate() {
            $rootScope.$context.$set('/note/list', $scope);

            if ($scope.type) {
                $scope.currentTypeFilter = 26;
            }


            $scope.$watch(
                function () {
                    return $rootScope.contextTags;
                },
                function (newValue, oldValue) {
                    if ($scope.loading == false)
                        getNotes();
                },
                true);


            // noteResource.getTagCloud().then(function (xhr) {
            //     $scope.formData.tags = [];
            //     for (var i = 0; i < xhr.length; i++) {
            //         if (xhr[i].text.indexOf(':') == -1) {
            //             $scope.formData.tags.push(xhr[i]);
            //         }
            //     }
            // });
        }

        // getNotes();

        // noteResource.getFavourites().then(function (xhr) {
        //     $scope.formData.favourites = xhr;
        // });

        // knowledgeObjectResource.getTagsByType(15).then(function (xhr) {
        //     $scope.formData.timelines = [];
        //     angular.forEach(xhr, function (tag) {
        //         tag = $rootScope.converted(tag);
        //         $scope.formData.timelines.push(tag);
        //     });
        // });
        //
        // knowledgeObjectResource.getTagsByType(10).then(function (xhr) {
        //     $scope.formData.autoTags = [];
        //
        //     angular.forEach(xhr, function (tag) {
        //         tag = $rootScope.converted(tag);
        //         $scope.formData.autoTags.push(tag);
        //     });
        // });
        //
        // knowledgeObjectResource.getTagsByType(7).then(function (xhr) {
        //     $scope.formData.activityContexts = xhr;
        // });
        //
        // knowledgeObjectResource.getTagsByType(0).then(function (xhr) {
        //     $scope.formData.commonTags = [];
        //     angular.forEach(xhr, function (tag) {
        //         // if (tag.indexOf(':') == -1 && !$scope.formData.commonTags.indexOf(tag) == -1)
        //         if (tag.indexOf(':') == -1 && ($scope.formData.commonTags.indexOf(tag) == -1))
        //             $scope.formData.commonTags.push(tag);
        //     });
        // });


        function getNotes(skip) {
            if (!skip)
                skip = 0;

            if ($scope.currentFilter != $scope.textFilter) {
                skip = 0;
            }

            var dto = {
                tagFilter: $rootScope.contextTags.tags,
                textFilter: $scope.textFilter,
                typeFilter: $scope.currentTypeFilter,
                take: 20,
                skip: skip,
                metricsEnabled: true
            };

            noteResource.getNotesLibrary(dto).then(function (notes) {
                $scope.loading = true;
                for (var i = 0; i < notes.results.length; i++) {
                    notes.results[i].createTime = dateHelper.enchance(notes.results[i].createTime + 'Z');
                    notes.results[i].createTimeTxt = moment(notes.results[i].createTime).format('YYYY-MM-DD HH:mm');

                    if (notes.results[i].dto) {
                        if (notes.results[i].dto.plannedStartTime != null && notes.results[i].dto.plannedStartTime !== '')
                            notes.results[i].startTime = dateHelper.enchance(notes.results[i].dto.plannedStartTime);
                        if (notes.results[i].dto.realStartTime != null && notes.results[i].dto.realStartTime !== '')
                            notes.results[i].startTime = dateHelper.enchance(notes.results[i].dto.realStartTime);
                        if (notes.results[i].dto.plannedEndTime != null && notes.results[i].dto.plannedEndTime !== '')
                            notes.results[i].endTime = dateHelper.enchance(notes.results[i].dto.plannedEndTime);
                        if (notes.results[i].dto.realEndTime != null && notes.results[i].dto.realEndTime !== '')
                            notes.results[i].endTime = dateHelper.enchance(notes.results[i].dto.realEndTime);


                        notes.results[i].definition =
                            knowledgeObjectResource.knowledgeObjectCustomedDefinition(notes.results[i]);
                        notes.results[i].priority =
                            angular.globalSettingsConst.priorities[notes.results[i].dto.priority];
                        notes.results[i].statusTxt =
                            angular.globalSettingsConst.meetingStatus[notes.results[i].dto.status];

                        if (notes.results[i].dto.status == 0)
                            notes.results[i].priorityClass = 'btn-success';
                        if (notes.results[i].dto.status == 1)
                            notes.results[i].priorityClass = 'btn-success';
                        if (notes.results[i].dto.status == 2)
                            notes.results[i].priorityClass = 'btn-default';
                    }

                }
                $scope.currentFrom = notes.from;
                $scope.currentTo = notes.to;
                if (notes.count < 20) {
                    $scope.currentTo = notes.count;
                }
                $scope.currentFilter = $scope.textFilter;
                $scope.notes = notes;

                $scope.note = $scope.notes.results[0];
                $scope.previousVisible = $scope.currentFrom > 0;
                $scope.nextVisible = $scope.currentTo < $scope.notes.count;
                $scope.loading = false;

                //otherTags and timelineTags drillDown
                var tagsText = [];
                var timelineItemsText = [];
                var timelineItemsTags = [];
                for (var j = 0; j < notes.otherTags.length; j++) {
                    if ((tagsText.indexOf(notes.otherTags[j].text) === -1) &&
                        (notes.otherTags[j].text.indexOf(':') === -1)) {
                        tagsText.push(notes.otherTags[j].text);
                    }
                    //type = 15 means timeline items like emotions and assignments
                    if ((timelineItemsText.indexOf(notes.otherTags[j].text) === -1) &&
                        (notes.otherTags[j].type === 15)) {
                        timelineItemsText.push((notes.otherTags[j].text));
                        timelineItemsTags.push($rootScope.converted((notes.otherTags[j].text)));
                    }
                }
                $scope.formData.commonTags = tagsText;
                $scope.formData.timelines = timelineItemsTags;
            });
        }

        $scope.showMeeting = function (id) {
            $state.go('main.meetingDetails', {meetingId: id});
        };

        $scope.sortOptions = [
            {id: 0, name: 'Create time'},
            {id: 1, name: 'Name'},
            {id: 2, name: 'Type'}
        ];

        $scope.view = function (note) {
            $scope.note = note;

            if (note.objectType == 1) //meeting
            {
                note.dto.plannedStartTimeTxt = moment(note.dto.plannedStartTime).format('YYYY.MM.DD HH:mm');
            }
        }
    }
})();
