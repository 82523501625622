/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    function ResetPasswordController($scope, $state, accountResource) {
        $scope.formData = {
            submitButtonLabel: 'Reset password',
            submitInProgress: false
        };

        $scope.submitForm = function() {
            if ($scope.resetPasswordForm.$valid) {
                submitInProgressOn();
                accountResource.resetPassword($scope.formData.email)
                    .then(transitionToResetPasswordConfirmationState, handleResetPasswordFailure)
                    .finally(submitInProgressOff);
            }

            function transitionToResetPasswordConfirmationState() {
                $state.go('public.resetPasswordConfirmation');
            }

            function handleResetPasswordFailure(clientError) {
                $scope.formData.authenticationFailedMessage = clientError.message;
                $scope.resetPasswordForm.$submitted = false;
            }

            function submitInProgressOn() {
                $scope.formData.submitButtonLabel = 'Resetting password...';
                $scope.formData.submitInProgress = true;
            }

            function submitInProgressOff() {
                $scope.formData.submitButtonLabel = 'Reset password';
                $scope.formData.submitInProgress = false;
            }

        };
    }

    ResetPasswordController.$inject = ['$scope', '$state', 'accountResource'];

    angular.module('meetingMinutes.public')
        .controller('ResetPasswordController', ResetPasswordController);

})();
