/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.public')
        .controller('ChangePasswordController', ChangePasswordController);

    ChangePasswordController.$inject = ['$scope', '$state', '$stateParams', 'accountResource'];

    function ChangePasswordController($scope, $state, $stateParams, accountResource) {

        var requestId = $stateParams.requestId;

        $scope.formData = {
            submitButtonLabel: 'Change password',
            submitInProgress: false
        };

        accountResource.changePasswordFor(requestId).then(function(email) {
            $scope.formData.email = email;
        });

        $scope.submitForm = function() {
            if ($scope.changePasswordForm.$valid) {
                submitInProgressOn();
                accountResource.changePassword({ requestId: requestId, password: $scope.formData.password })
                    .then(transitionToSignInState)
                    .finally(submitInProgressOff);
            }

            function transitionToSignInState() {
                $state.go('public.signIn');
            }

            function submitInProgressOn() {
                $scope.formData.submitButtonLabel = 'Changing password...';
                $scope.formData.submitInProgress = true;
            }

            function submitInProgressOff() {
                $scope.formData.submitButtonLabel = 'Change password';
                $scope.formData.submitInProgress = false;
            }
        };
    }

})();
