import { FSA, FSAWithPayload, ErrorFSA, ErrorFSAWithPayload } from "flux-standard-action";
import { put, call, takeEvery } from "@redux-saga/core/effects";
import { UserDto } from "./user";

export interface WorkgroupState {
    members: UserDto[];
    isLoading: boolean;
    error: string;
}

export const INITIAL_WORKGROUP_STATE: WorkgroupState = {
    error: '',
    isLoading: false,
    members: []
}

export const MEMBERS_LIST_REQUEST = 'spinme/workgroup/MEMBERS_LIST_REQUEST';
export const listWorkgroupMembersRequest = (): FSA => {
    return {
        type: MEMBERS_LIST_REQUEST
    }
}

export const MEMBER_LIST_SUCCESS = 'spinme/workgroup/MEMBER_LIST_SUCCESS';
export const listWorkgroupMembersSuccess = (items: UserDto[]): FSAWithPayload<string, UserDto[]> => {
    return {
        type: MEMBER_LIST_SUCCESS,
        payload: items
    }
}

export const MEMBER_LIST_ERROR = 'spinme/workgroup/MEMBER_LIST_ERROR';
export const listWorkgroupMembersError = (message: string = 'error'): FSAWithPayload<string, string> => {
    return {
        type: MEMBER_LIST_ERROR,
        error: true,
        payload: message
    }
}

export default function reducers(state: WorkgroupState = INITIAL_WORKGROUP_STATE,
    action: FSAWithPayload<string, UserDto[]>): WorkgroupState {
    switch (action.type) {
        case MEMBERS_LIST_REQUEST: {
            return {
                ...state,
                isLoading: true
            }
        }
        case MEMBER_LIST_SUCCESS: {
            const items = action.payload as UserDto[];
            var newState = {
                ...state,
                members: items,
                isLoading: false,
            }
            return newState;
        }
        case MEMBER_LIST_ERROR: {
            return {
                ...state,
                isLoading: false,
                error: 'true'
            }
        }
        default:
            return state;
    }
}

function* wokrgroupMembersList() {
    try {
        const data = yield call(fetch, '/api/workgroup/members', { headers: { "Content-Type": "application/json; charset=utf-8" } });
        if (data.status !== 200) throw Error();
        const body = yield data.json();
        yield put(listWorkgroupMembersSuccess(body));
    } catch (error) {
        yield put(listWorkgroupMembersError());
    }
}


export function* watchWorkgroupMeberListRequest() {
    yield takeEvery(MEMBERS_LIST_REQUEST, wokrgroupMembersList)
}
