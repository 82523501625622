import React from 'react';
import {render, unmountComponentAtNode} from 'react-dom';
import TodoListLink from '../../src/containers/TodoListLink';
import TodoPopupContainerLink from '../../src/components/TodoPopup/TodoPopupContainer';
import { TimelinePopupContainerLink } from '../../src/components/TimelinePopup/TimelinePopupContainer';
import DateTimePicker from '../../src/components/shared/DateTimePicker';
import { Provider } from 'react-redux';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core';

(function() {
    'use strict';

    const defaultMaterialTheme = createMuiTheme({
        palette: {
          primary: {
              main: '#1ab394',
              contrastText: '#fff'
            }
        },
        typography: {
            fontSize: 11.5,
          }
      });

    // var reactDict = {};

    angular
        .module('meetingMinutes.reactRedux')
        .directive('react', ['reduxStore', reactDirectiveFactory]);

    function reactDirectiveFactory(reduxStore) {
        let store = reduxStore.get();
        
        return {
            restrict: 'E',
            scope: {
                onChange: '=',
                type: '=',
                model: '='
            },
            link: link
        };

        function renderElement(item, element) {
            if(item == null) throw new Error('Could not find component type: ' + scope.type);
            
            var timepickerProvider = React.createElement(MuiPickersUtilsProvider, { utils: MomentUtils }, item);

            var themeProvider = React.createElement(ThemeProvider, {theme: defaultMaterialTheme}, timepickerProvider);

            var storeProvider = React.createElement(Provider, {store: store}, themeProvider);
            render(storeProvider, element[0]);
        }

        function link(scope, element, attrs) {
            scope.$watch('model', function(newValue, oldValue) {
                var item = null;

                switch (scope.type) {
                    case 'TodoListLink':
                            item = React.createElement(TodoListLink, scope.model);
                        break;
                    case 'TodoPopupContainerLink':
                            item = React.createElement(TodoPopupContainerLink, scope.model);
                        break;
                    case 'TimelinePopupContainerLink':
                            item = React.createElement(TimelinePopupContainerLink, scope.model);
                        break;
                    case 'DateTimePicker':
                        
                            var model = null;
                            if (scope.model !== undefined && scope.model !== null) model = scope.model.toISOString();

                            item = React.createElement(DateTimePicker, {"fullWidth":true,"clearable":true, "value":model, "onChange": scope.onChange});

                        break;
                }

                renderElement(item, element);
            });

            scope.$watch('onChange', function(newValue, oldValue) {
            });

            scope.$on('$destroy', function() {
                unmountComponentAtNode(element[0]);
            });
        }
    }
})();