/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.admin')
        .config(routesConfig);

    routesConfig.$inject = ['$stateProvider', 'authorizationProvider'];

    function routesConfig($stateProvider, authorizationProvider) {
        $stateProvider
            .state('public.adminLongs',
                {
                    url: '/admin/logs',
                    templateUrl: 'views/admin/logs.tpl.html',
                    controller: 'LogsController',
                    resolve: {
                        
                    },
                    data: {
                        pageTitle: 'Admin | Logs'
                    }
                });
    }

})();
