/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.draft')
        .controller('DraftNotesController', DraftNotesController);

    DraftNotesController.$inject = ['$scope', 'noteResource'];

    function DraftNotesController($scope, noteResource) {
        $scope.onDelete = onDelete;
        $scope.onConfirmDelete = onConfirmDelete;
        $scope.onCancelDelete = onCancelDelete;
        $scope.isDeleteConfirmationVisible = isDeleteConfirmationVisible;

        activate();

        function activate() {
            getNotes();
        }

        function getNotes() {
            noteResource.getDrafts().then(function(drafts) {
                $scope.drafts = drafts;
                $scope.displayDeleteConfirmation = {};
                angular.forEach(drafts,
                    function(draft) {
                        $scope.displayDeleteConfirmation[draft.id] = false;
                    });
            });
        }

        function onDelete(note) {
            $scope.displayDeleteConfirmation[note.id] = true;
        }

        function isDeleteConfirmationVisible(note) {
            return $scope.displayDeleteConfirmation[note.id];
        }

        function onConfirmDelete(note) {
            noteResource.delete(note.id).then(getNotes);
        }

        function onCancelDelete(note) {
            $scope.displayDeleteConfirmation[note.id] = false;
        }

    }

})();
