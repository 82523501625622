/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

import {uiTodoOpenModal} from '../../../../src/store/uimodels/uitodo';

(function () {
    'use strict';

    angular.module('meetingMinutes.main.knowledge')
        .factory('stickyNoteTransformPopup', stickyNoteTransformPopup);

    stickyNoteTransformPopup.$inject = [
        'notePopup', 'attachmentsHelper', 'httpResource', '$uibModal', 'noteResource', 'removePopup', 'reduxStore'
    ];

    function stickyNoteTransformPopup(notePopup,
        attachmentsHelper,
        httpResource,
        $uibModal,
        noteResource,
        removePopup,
        reduxStore) {

        var store = reduxStore.get();

        StickyNoteTransformPopupCtrl.$inject = ['$scope', '$uibModalInstance'];
        function StickyNoteTransformPopupCtrl($scope, $uibModalInstance) {
            $scope.ok = function() {
                if ($scope.callback)
                    $scope.callback();

                if ($scope.$parent.item) {
                    $scope.$parent.item = undefined;
                }

                $uibModalInstance.dismiss('ok');
            };

            $scope.deleteAnClose = function () {
                if ($scope.callback)
                    $scope.callback();

                if ($scope.$parent.item) {
                    $scope.$parent.item = undefined;
                }

                $uibModalInstance.dismiss('cancel');
            }

            var callback = function () {
                $uibModalInstance.dismiss('ok');
            }

            $scope.delete = function() {
                removePopup.open($scope, function() {
                    noteResource.delete($scope.item.dto.id).then(function(xhr){
                        $scope.$parent.formData.notes = 
                            $scope.$parent.formData.notes
                            .filter(function(elem) {
                                     return elem.dto.id !== $scope.item.dto.id
                                 });
                                 if ($scope.$parent.item) {
                                    $scope.$parent.item = undefined;
                                }
                        callback();
                    });
                });
            }

            $scope.cancel = function () {

                if ($scope.$parent.item) {
                    $scope.$parent.item = undefined;
                }

                $uibModalInstance.dismiss('cancel');
            };


            $scope.edit = function () {

                notePopup.open($scope, { id: $scope.item.dto.id }, () => {
                    $scope.$parent.searchRelatedNotes();
                    callback();
                });
            };

            $scope.transform = function () {


                var model = {
                    id: 0,
                    name: $scope.item.title,
                    description: $scope.item.descriptionTxt,
                    ownerId: 0,
                    creatorId: 0,
                    createdAt: null,
                    deadline: null,
                    duration: $scope.item.dto.duration || 15,
                    tags: [],
                    isSaving: false,                    
                    koId: $scope.item.id
                }

                store.dispatch(uiTodoOpenModal({
                    todo: model,
                    convertedFromNoteId: $scope.item.dto.id
                }));

                
                var unsubscribe = store.subscribe(function() {
                    var state = store.getState();
                    if (state.ui.todos.todoModalOpen === false) {
                        unsubscribe();
                        setTimeout(function() {
                            $scope.$parent.searchRelatedNotes();
                            callback();   
                        }, 1000);                     
                    }
                });
                
            };


        }

        var open = function ($scope, item, callback) {
            if (item) {
                $scope.item = item;
            }

            $scope.callback = callback;
            var modalInstance = $uibModal.open({
                templateUrl: 'views/main/popups/sticky-note-transform.popup.tpl.html?v=' + new Date(),
                controller: StickyNoteTransformPopupCtrl,
                scope: $scope
            });
        };

        return {
            "open": open
        };
    }
})();
