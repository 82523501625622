/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function () {
    'use strict';

    angular.module('meetingMinutes.repository.remote')
        .factory('msgraphCalendarResource', msgraphCalendarResourceFactory);

    msgraphCalendarResourceFactory.$inject = ['$http', '$q', 'userResource', 'REMOTE_MSG_PREFIX'];

    function msgraphCalendarResourceFactory($http, $q, userResource, REMOTE_MSG_PREFIX) {

        let path = REMOTE_MSG_PREFIX;
        var token = null;

        function authorizeAgainstMsg() {
            return $q(function(resolve, reject) {
                getAuthToken().then(function (authToken) {
                    let req = {
                        method: 'GET',
                        url: path+'microsoft/login',
                        headers: {
                            'Authorization': 'Bearer '+authToken
                        }
                    };

                    $http(req).then(function (data) {
                        let status = data.data;

                        resolve(status);
                        // return status;
                    }, function (error) {
                        reject(error);
                    });
                }, function (error) {reject(error);});
            });
        }

        function clearAuthToken() {
            token = null;
        }

        function getAuthToken() {
            return $q(function (resolve, reject) {
                if (token === null) {
                    userResource.getToken("msg").then(function(data) {
                        $http.get(path + 'authorize/'+data.token).then(function (data) { token = data.data; resolve(token); }, function (error) { reject(error) });
                    }, function (error) {reject(error)});
                } else {
                    resolve(token);
                }
            });
        }

        function checkCalendarStatus() {
            return $q(function(resolve, reject) {
                getAuthToken().then(function (authToken) {
                    let req = {
                        method: 'GET',
                        url: path+'microsoft/status',
                        headers: {
                            'Authorization': 'Bearer '+authToken
                        }
                    };

                    $http(req).then(function (data) {
                        let status = data.data;

                        resolve(status);
                        // return status;
                    }, function (error) {
                        reject(error);
                    });
                }, function (error) {reject(error);});

            });
        }

        function getCalendarsList() {
            return $q(function(resolve, reject) {
                getAuthToken().then(function (authToken) {
                    let req = {
                        method: 'GET',
                        url: path+'microsoft/calendars',
                        headers: {
                            'Authorization': 'Bearer '+authToken
                        }
                    };

                    $http(req).then(function (data) {
                        let status = data.data;

                        resolve(status);
                        // return status;
                    }, function (error) {
                        reject(error);
                    });
                }, function (error) {reject(error);});
            });
        }

        function createCalendar(calendarName) {
            return $q(function(resolve, reject) {
                getAuthToken().then(function (authToken) {
                    let req = {
                        method: 'PUT',
                        url: path+'microsoft/calendars?name='+calendarName,
                        headers: {
                            'Authorization': 'Bearer '+authToken
                        }
                    };

                    $http(req).then(function (data) {
                        let status = data.data;

                        resolve(status);
                        // return status;
                    }, function (error) {
                        reject(error);
                    });
                }, function (error) {reject(error);});
            });
        }

        function selectCalendar(calendar) {
            return $q(function(resolve, reject) {
                getAuthToken().then(function (authToken) {
                    let req = {
                        method: 'GET',
                        url: path+'microsoft/calendar/'+calendar.id+'',
                        headers: {
                            'Authorization': 'Bearer '+authToken
                        }
                    };

                    $http(req).then(function (data) {
                        let status = data.data;

                        resolve(status);
                        // return status;
                    }, function (error) {
                        reject(error);
                    });
                }, function (error) {reject(error);});
            });
        }

        function disconnectFromCalendar() {
            return $q(function(resolve, reject) {
                getAuthToken().then(function (authToken) {
                    let req = {
                        method: 'GET',
                        url: path+'microsoft/delete',
                        headers: {
                            'Authorization': 'Bearer '+authToken
                        }
                    };

                    $http(req).then(function (data) {
                        let status = data.data;

                        resolve(status);
                        // return status;
                    }, function (error) {
                        reject(error);
                    });
                }, function (error) {reject(error);});
            });
        }

        return {
            'selectCalendar': selectCalendar,
            'getCalendarsList': getCalendarsList,
            'checkCalendarStatus': checkCalendarStatus,
            'authorizeAgainstMsg': authorizeAgainstMsg,
            'disconnectFromCalendar': disconnectFromCalendar,
            'createCalendar': createCalendar,
            'clearAuthToken': clearAuthToken
        }
    }
})();

