/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.knowledge.meeting')
        .factory('goalsPopup', goalsPopup);

    goalsPopup.$inject = ['httpResource', '$uibModal', 'meetingResource'];

    function goalsPopup(httpResource, $uibModal, meetingResource) {

        GoalsPopupCtrl.$inject = ['$scope', '$uibModalInstance'];
        function GoalsPopupCtrl($scope, $uibModalInstance) {
            meetingResource.getMeetingById($scope.meeting.id).then(function(meeting) {
                $scope.meeting = meeting;
            });

            $scope.ok = function() {
                $uibModalInstance.close();
            };

            $scope.cancel = function() {
                $uibModalInstance.dismiss('cancel');
            };

            $scope.onAddGoal = function() {
                $scope.class = "saving";
                meetingResource.saveGoal($scope.meeting.id, {}).then(function(savedGoal) {
                    $scope.meeting.goals.push(savedGoal);
                    $scope.class = "done";
                });
            };

            $scope.onSaveGaol = function(goalRef, item) {
                meetingResource.saveGoal($scope.meeting.id, goalRef)
                    .then(function(savedGoal) {
                        goalRef = savedGoal;
                        item['goal_' + item.goal.id].$setPristine();
                        $scope.onAddGoal();
                    });

            };

            $scope.onDeleteGoal = function(goal) {
                meetingResource.deleteGoal(goal.id).then(overrideGoals);
            };

            function overrideGoals(goals) {
                $scope.meeting.goals = goals;
            }

            $scope.checkIfGoalDirty = function(item) {
                return item['goal_' + item.goal.id].$dirty;
            }

        }

        var open = function($scope, meeting) {
            $scope.meeting = meeting;

            var modalInstance = $uibModal.open({
                templateUrl: 'views/main/popups/goals.popup.tpl.html?v=' + new Date(),
                controller: GoalsPopupCtrl,
                scope: $scope
            });
        }

        return {
            "open": open,
        };
    }
})();
