import { TodoUiState, INTIAL_TODO_UI_STATE, uiTodoReducers } from "./uimodels/uitodo";
import { INTIAL_CURRENTUSER_UI_STATE, CurrentUserUiState, currentUserUireducers } from "./uimodels/uicurrentuser";
import { combineReducers } from "redux";
import { TimelineUiState, INTIAL_TIMELINE_UI_STATE, uiTimelineReducers } from "./uimodels/uitimeline";

export interface UiState {
    todos: TodoUiState
    currentUser: CurrentUserUiState,
    timeline: TimelineUiState
}

export const INITIAL_UI_STATE: UiState = {
    todos: INTIAL_TODO_UI_STATE,
    currentUser: INTIAL_CURRENTUSER_UI_STATE,
    timeline: INTIAL_TIMELINE_UI_STATE
}

const reducers = combineReducers<UiState>({
    currentUser: currentUserUireducers, todos: uiTodoReducers, timeline: uiTimelineReducers
});

export default reducers;
