/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function () {
    'use strict';

    angular.module('meetingMinutes.main.profile')
        .controller('ProfileTokenClientsController', ProfileTokenClientsController);

    ProfileTokenClientsController.$inject = [
        '$stateParams', '$state', '$rootScope', '$scope', 'userPopup', 'userResource',
        'authenticationResource', 'tokenResource', 'tokenClientPopup'
    ];

    function ProfileTokenClientsController($stateParams,
        $state,
        $rootScope,
        $scope,
        userPopup,
        userResource,
        authenticationResource,
        tokenResource,
        tokenClientPopup) {

        $scope.formData = {};

        $rootScope.$context.$set('/profile/tokenclients', $scope);

        activate();

        $scope.callback = function () {
            activate();
        };

        $scope.openPopup = function(item) {
            tokenClientPopup.open($scope, item);
        }


        function activate() {
            tokenResource.listTokenClients()
                    .then(function (response) {
                        $scope.formData.tokenClients = response;
                    });
        }
    }
})();
