/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.repository.remote')
        .factory('chipsHelper', chipsHelper);

    chipsHelper.$inject = ['httpResource', 'knowledgeObjectResource', 'tagsResource'];

    function chipsHelper(httpResource, knowledgeObjectResource, tagsResource) {

        var availableTags = tagsResource.availableTags;

        function createFilterFor(query) {
            var lowercaseQuery = angular.lowercase(query);
            return function filterFn(vegetable) {
                vegetable = angular.lowercase(vegetable);
                return (vegetable.indexOf(lowercaseQuery) === 0);
            };
        }

        var querySearch = function(query, type) {
            var results = query ? availableTags.type.filter(createFilterFor(query)) : [];
            return results;
        }

        return {
            "querySearch": querySearch,
        };
    }

})();
