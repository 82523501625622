import * as React from 'react';

export interface ButtonProps { style: string, text: string, onClick:()=>void }

export default class ButtonView extends React.PureComponent<ButtonProps, {}> {
    public static defaultProps = {
        style: 'small'
    };

    render() {
        let cssStyle = 'btn btn-default';
        if (this.props.style === 'small')
            cssStyle += ' btn-xs';

        return (
<button className={cssStyle} onClick={this.props.onClick}>{this.props.text}</button>
    );
    }
}