/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.knowledge')
        .factory('accessPopup', accessPopup);

    accessPopup.$inject = [
        '$uibModal', 'userResource', 'knowledgeObjectResource', '$timeout',
        'workgroupResource', 'meetingResource', 'numbers'
    ];

    function accessPopup(
                        $uibModal,
                        userResource,
                        knowledgeObjectResource,
                        $timeout,
                        workgroupResource,
                         meetingResource,
                         numbers) {

            AccessPopupCtrl.$inject = ['$scope', '$uibModalInstance'];
        function AccessPopupCtrl($scope, $uibModalInstance) {
            if($scope.item.knowledgeObject)
                $scope.koId = $scope.item.knowledgeObject.id;
            else
                $scope.koId = $scope.item.id;

            $scope.permissions = [];
            $scope.members = [];
            $scope.counterWithPermission = 0;
            $scope.counterWithoutPermission = 0;

            $scope.setPermission = function(userId, permissionType) {
                if (permissionType == -1) {
                    knowledgeObjectResource.removePermission($scope.koId, userId).then(function(response) {
                        $scope.refreshLists(response);
                    });
                } else {
                    var dto = {
                        user: { id: userId },
                        permissionType: permissionType,
                        knowledgeObjectId: $scope.koId,
                        relatedWithProcess: $scope.relatedWithProcess
                    };
                    knowledgeObjectResource.updatePermission(dto).then(function(response) {
                        $scope.refreshLists(response);
                    });
                }
            };

            $scope.addPermission = function(member) {
                var dto = {
                    user: { id : member.id },
                    permissionType: 2,
                    knowledgeObjectId: $scope.koId,
                    relatedWithProcess: $scope.relatedWithProcess
                };

                if($scope.item){
                    dto.objectId = $scope.item.id;
                    meetingResource.shareMeeting(dto).then(function (response) {
                        if(response)
                            $scope.refreshLists(response);
                    })
                }else {
                    knowledgeObjectResource.addPermission(dto).then(function(response) {
                        $scope.refreshLists(response);
                    });
                }



            };

            $scope.hasAccess = function(userId) {
                for (var i = 0; i < $scope.permissions.length; i++) {
                    if ($scope.permissions[i].user.id == userId) {
                        return true;
                    }
                }
                $scope.counterWithoutPermission = $scope.counterWithoutPermission + 1;
                return false;
            };

            $scope.refreshLists = function(permissions) {
                $scope.counterWithPermission = 0;
                $scope.counterWithoutPermission = 0;

                for (var i = 0; i < permissions.length; i++) {
                    permissions[i].typeTxt =
                        knowledgeObjectResource.translatePermissionType(permissions[i].permissionType);
                }
                $scope.permissions = permissions;
                $scope.counterWithPermission = permissions.length;

                workgroupResource.getMembers().then(function(xhr) {
                    for (var i = 0; i < xhr.length; i++) {
                        xhr[i].hasAccess = $scope.hasAccess(xhr[i].id);
                        if (xhr[i].hasAccess == false) {
                            $scope.counterWithoutPermission = $scope.counterWithoutPermission + 1;
                        }
                    }

                    $scope.members = xhr;
                });
            };

            var id;
            if($scope.item.koId)
                id = $scope.item.koId;
            else if($scope.item.knowledgeObject)
                id = $scope.item.knowledgeObject.id;
            else id = $scope.item.id;

            knowledgeObjectResource.getPermissions(id).then(function(response) {
                for (var i = 0; i < response.length; i++) {
                    response[i].typeTxt = knowledgeObjectResource.translatePermissionType(response[i].permissionType);
                }
                $scope.permissions = response;
                workgroupResource.getMembers().then(function(xhr) {
                    for (var i = 0; i < xhr.length; i++) {
                        xhr[i].hasAccess = $scope.hasAccess(xhr[i].id);
                    }

                    $scope.members = xhr;
                });
            });

            $scope.ok = function() {
                userResource.updateUser($scope.item.id, $scope.item, numbers.uuidv4()).then(function(result) {
                    $scope.callback();
                    $uibModalInstance.close();
                });

            };

            $scope.cancel = function() {
                $uibModalInstance.dismiss('cancel');
            };
        }

        var open = function($scope, item, callback, relatedWithProcess) {
            $scope.callback = callback;
            $scope.relatedWithProcess = relatedWithProcess;
            if (item) {
                $scope.item = item;
            }

            var callAtTimeout = function() {
                var modalInstance = $uibModal.open({
                    templateUrl: 'views/main/popups/access.popup.tpl.html',
                    controller: AccessPopupCtrl,
                    scope: $scope
                });
            }
            $timeout(callAtTimeout, 100);

        }

        return {
            "open": open
        };
    }
})();
