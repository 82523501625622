/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.repository.remote')
        .factory('dateHelper', dateHelper);

    dateHelper.$inject = ['httpResource'];

    function dateHelper(httpResource) {

        function enchance(date) {
            var nowDate = moment();
            var funcDate = moment(date);

            var diff = funcDate.diff(nowDate);
            var diffM = diff / 60000;


            var relative;
            if (diff < 0) {
                relative = moment(date).startOf('minutes').fromNow();
            } else {
                relative = moment(date).endOf('minutes').fromNow();
            }


            var result = {
                isPassed: diff < 0,
                base: date,
                hour: moment(date).format('HH'),
                minutes: moment(date).format('mm'),
                hour_minutes: moment(date).format('HH:mm'),
                calendarDate: moment(date).calendar(),
                formatDate: moment(date).format('YYYY-MM-DD'),
                formatDateTime: moment(date).format('YYYY-MM-DD HH:mm'),
                relativeTime: relative,
                relativeMinutes: diffM
            };

            return result;
        }

        function enchanceMeeting(meeting) {
            if (meeting.plannedStartTime)
                meeting.plannedStartTime = enchance(meeting.plannedStartTime);
            // if (meeting.endTime)
            //     meeting.endTime = enchance(meeting.endTime);
            if (meeting.plannedEndTime)
                meeting.plannedEndTime = enchance(meeting.plannedEndTime);
            if (meeting.realStartTime)
                meeting.realStartTime = enchance(meeting.realStartTime);
            if (meeting.realEndTime)
                meeting.realEndTime = enchance(meeting.realEndTime);
            if (meeting.duration)
                meeting.duration = friendlyDuration(meeting.duration);
            if (meeting.realDuration)
                meeting.realDuration = friendlyDuration(meeting.realDuration);
            return meeting;
        }

        function friendlyDuration(minutes) {
            return humanizeDuration(60000 * minutes, { units: ['h', 'm'], round: true });
        }

        return {
            "enchance": enchance,
            "enchanceMeeting": enchanceMeeting,
            "friendlyDuration": friendlyDuration
        };
    }

})();
