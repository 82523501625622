/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.repository.remote')
        .factory('crmResource', remoteCrmResourceFactory);

    remoteCrmResourceFactory.$inject = ['httpResource', '$location'];

    function remoteCrmResourceFactory(httpResource, $location) {

        function createOpportunityLocation(tags, backUrl, personId) {
            $location.path('/opportunity/create').search({ backUrl: backUrl, tags: tags, personId: personId })
        }

        function editOpportunityLocation(id, backUrl, tags) {
            $location.path('/opportunity/edit/' + id).search({ backUrl: backUrl, tags: tags })
        }

        function createOpportunity(dto) {
            return httpResource.post('/opportunity', dto);
        }

        function getOpenedOpportunities(ids, dateFilter) {
            if ((ids === undefined || ids === null || ids.length === 0) && (dateFilter === undefined))
                return httpResource.get('/opportunities/opened');

            if ((ids === undefined || ids === null || ids.length === 0) && (dateFilter !== undefined))
                return httpResource.get('/opportunities/opened?years=' +
                    dateFilter.years +
                    '&months=' +
                    dateFilter.months);

            if (ids && ids.length > 0 && (dateFilter !== undefined)) {
                return httpResource.get('/opportunities/opened?ownerIds=' +
                    ids +
                    '&years=' +
                    dateFilter.years +
                    '&months=' +
                    dateFilter.months);
            }
        }

        function getOpenedOpportunitiesList() {
            return httpResource.get('/opportunities/opened/list');
        }

        function getOpportunities(ids, dateFilter) {
            if ((ids === undefined || ids === null || ids.length === 0) && (dateFilter === undefined))
                return httpResource.get('/opportunities');

            if ((ids === undefined || ids === null || ids.length === 0) && (dateFilter !== undefined))
                return httpResource.get('/opportunities?years=' + dateFilter.years + '&months=' + dateFilter.months);


            if (ids && ids.length > 0 && (dateFilter !== undefined)) {
                return httpResource.get('/opportunities?ownerIds=' +
                    ids +
                    '&years=' +
                    dateFilter.years +
                    '&months=' +
                    dateFilter.months);
            }
        }

        function getOpportunity(id) {
            return httpResource.get('/opportunity/' + id);
        }

        function getOpportunityRelatedMeetings(id) {
            return httpResource.get('/opportunity/' + id + '/meetings')
        }

        function deleteOpportunity(id) {
            return httpResource.delete('/opportunity/' + id);
        }

        function saveOpportunity(dto) {
            return httpResource.put('/opportunity/', dto);
        }

        function updateOwner(dtos) {
            return httpResource.put('/opportunities/owner', dtos);
        }

        function deleteOpportunities(oppIdsString) {
            return httpResource.delete('/opportunities?' + oppIdsString);
        }

        function setMilestoneDone(meetingId, stageId, milestone) {
            return httpResource.post('/opportunity/milestone/done?meetingId=' +
                meetingId +
                '&salesStageId=' +
                stageId +
                '&milestone=' +
                encodeURIComponent(milestone));
        }

        function setMilestoneNotDone(opportunityId, stageId, milestone) {
            return httpResource.post('/opportunity/milestone/notdone?salesStageId=' +
                stageId +
                '&milestone=' +
                encodeURIComponent(milestone)
                + '&opportunityId=' + opportunityId);
        }

        function setOpportunityStatusOpened(dto) {
            return httpResource.put('/opportunity/open', dto);
        }

        //not used yet
        // function setOpportunityStatusClosed(dto){
        //     return httpResource.put('/opportunity/close', dto);
        // }
        function setOpportunityStatusSuspended(dto) {
            return httpResource.put('/opportunity/suspend', dto);
        }

        function setOpportunityStatusLost(dto) {
            return httpResource.put('/opportunity/lose', dto);
        }

        return {
            "createOpportunityLocation": createOpportunityLocation,
            "editOpportunityLocation": editOpportunityLocation,
            "createOpportunity": createOpportunity,
            "getOpportunities": getOpportunities,
            "getOpportunity": getOpportunity,
            "deleteOpportunity": deleteOpportunity,
            "saveOpportunity": saveOpportunity,
            "updateOwner": updateOwner,
            "deleteOpportunities": deleteOpportunities,
            "getOpenedOpportunities": getOpenedOpportunities,
            "getOpenedOpportunitiesList": getOpenedOpportunitiesList,
            "setMilestoneDone": setMilestoneDone,
            "setMilestoneNotDone": setMilestoneNotDone,
            "getOpportunityRelatedMeetings": getOpportunityRelatedMeetings,
            "setOpportunityStatusOpened": setOpportunityStatusOpened,
            "setOpportunityStatusSuspended": setOpportunityStatusSuspended,
            "setOpportunityStatusLost": setOpportunityStatusLost
        };
    }

})();
