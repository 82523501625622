/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular
        .module('meetingMinutes.directives.form', [])
        .directive('validateEquals', validateEqualsDirectiveFactory);

    function validateEqualsDirectiveFactory() {
        return {
            require: 'ngModel',
            link: link
        };

        function link(scope, element, attrs, ngModelController) {
            ngModelController.$parsers.push(validateEqual);
            ngModelController.$formatters.push(validateEqual);

            scope.$watch(attrs.validateEquals,
                function() {
                    ngModelController.$setViewValue(ngModelController.$viewValue);
                });

            function validateEqual(myValue) {
                var valid = (myValue === scope.$eval(attrs.validateEquals));
                ngModelController.$setValidity('equal', valid);
                return valid ? myValue : undefined;
            }
        }
    }

})();
