/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.profile')
        .controller('ProfileGoogleContactsController', ProfileGoogleContactsController);

    ProfileGoogleContactsController.$inject = [
        '$state', '$rootScope', '$scope', 'knowledgeObjectResource', 'userPopup', 'userResource',
        'authenticationResource'
    ];

    function ProfileGoogleContactsController($state,
        $rootScope,
        $scope,
        knowledgeObjectResource,
        userPopup,
        userResource,
        authenticationResource) {

        $scope.formData = {};

        $rootScope.$context.$set('/profile/google-contacts', $scope);

        activate();

        $scope.callback = function() {
            $scope.item = null;
            activate();
        }

        $scope.open = function(item) {
            $state.go('main.user/edit', { id: item.id });

            userPopup.open($scope, item, $scope.callback);
        }

        $scope.create = function() {
            userPopup.open($scope, null, $scope.callback);
        }

        $scope.redirectToGoogleContacts = function() {
            authenticationResource.redirectToGoogleContacts().then(function(result) {
                if (result != '')
                    angular.globalSettings.location = result;
            });
        }

        $scope.search = function() {
            authenticationResource.GetGoogleContacts($scope.textFilter).then(function(xhr) {
                $scope.contacts = xhr;
            });
        }

        $scope.contacts = null;
        $scope.textFilter = null;

        function activate() {
            authenticationResource.redirectToGoogleContacts().then(function(result) {
                if (result != '')
                    angular.globalSettings.location = result;
                else {
                    authenticationResource.GetGoogleContacts($scope.textFilter).then(function(xhr) {
                        $scope.contacts = xhr;
                    });
                }
            });
        }
    }

})();
