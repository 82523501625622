/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.repository.remote')
        .factory('tagsResource', tagsResource);

    tagsResource.$inject = ['httpResource', 'knowledgeObjectResource'];

    function tagsResource(httpResource, knowledgeObjectResource) {

        var availableTags = {
            communications: [],
            persons: [],
            profiles: [],
            tags: [],
        };

        function getAvailableTags(type, collection) {
            return httpResource.get('/tags/' + type), function(result) {
                collection = result;

            };
        }

        var truncateBefore = function(str, pattern) {
            return str.slice(str.indexOf(pattern) + pattern.length);
        };
        var truncateAfter = function(str, pattern) {
            return str.slice(0, str.indexOf(pattern));
        }

        var translateTag = function(tag) {
            if (tag.startsWith('p:'))
                return '/api/person/' + tag.substring(2) + '/profile/picture';
            if (tag.startsWith('o:'))
                return tag.substring(2);
            if (tag.startsWith('m:'))
                return tag.substring(2);
            if (tag.startsWith('n:'))
                return tag.substring(2);
            if (tag.startsWith('u:'))
                return '/api/user/' + tag.substring(2) + '/profile/picture';


        }

        var getPersonDetails = function(tag) {
        }

        var translateTags = function(tags) {
            var specialTags = [];

            for (var i = 0; i < tags.length; i++) {
                var special = translateTag(tags[i]);
                if (special != null) {
                    specialTags[tags[i]] = {
                        tag: tags[i],
                        translated: special
                    };
                }
            }

            return specialTags;
        }

        return {
            "availableTags": availableTags,
            "translateTag": translateTag,
            "translateTags": translateTags,
            "getPersonDetails": getPersonDetails
        };
    }

})();
