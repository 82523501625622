/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    WelcomeController.$inject = [
        '$interval', '$uibModal', '$scope', 'toastr', 'contactResource', 'accountResource', 'authenticationResource',
        '$location'
    ];

    function WelcomeController($interval,
        $uibModal,
        $scope,
        toastr,
        contactResource,
        accountResource,
        authenticationResource,
        $location) {

        $scope.loading = false;
        //registration form
        $scope.formData = {
            submitButtonLabel: 'Create an account',
            submitInProgress: false,
            account: {
                knowledgeObject: {
                    tags: []
                }
            }
        };
        $scope.isBetaPeriod = false;
        $interval(function() {
                //$scope.isBetaPeriod = new Date() > new Date('Wed Feb 01 2017 00:00:00 GMT+0100 (�rodkowoeuropejski czas stand.)');
            },
            1000);


        $scope.openRegisterResultPopup = function(title, txt) {
            $scope.txt = txt;
            $scope.title = title;

            var modalInstance = $uibModal.open({
                templateUrl: 'views/public/registration-confirmed.html',
                controller: RegisterResultCtrl,
                scope: $scope
            });
        }

        RegisterResultCtrl.$inject = ['$scope', '$uibModalInstance'];
        function RegisterResultCtrl($scope, $uibModalInstance) {

            $scope.ok = function() {
                $uibModalInstance.close();
            };

            $scope.cancel = function() {
                $uibModalInstance.dismiss('cancel');
            };
        };


        $scope.redirectToGoogle = function() {
            authenticationResource.redirectTo().then(function(result) {
                window.location = result;
            });
        }

        $scope.redirectToOffice365 = function() {
            authenticationResource.redirectToOffice365().then(function(result) {
                window.location = result;
            });
        }

        $scope.redirectToLinkedIn = function() {
            authenticationResource.redirectToLinkedIn().then(function(result) {
                window.location = result;
            });
        }

        $scope.formData.account.password = "beta_with_no_password";

        $scope.formData.passwordConfirmation = "beta_with_no_password";

        $scope.formData.account.password = "beta_with_no_password";

        $scope.submit = function() {

            $scope.loading = true;

            accountResource.create($scope.formData.account).then(function(xhr) {
                $scope.openRegisterResultPopup('Thanks for registration',
                    'Your request has been received succesfully. You will be informed at the point when your account will be created.<br/><br/>Keep in touch,<br/>SpinMinutes team <img src="/Client/images/exclamation1.png"/>');
                $scope.loading = false;
            });
            //$state.go('public.signIn');
        }

        function transitionToSignInState() {
            //$state.go('public.signIn');
            $scope.openRegisterResultPopup();
        }

        function submitInProgressOn() {
            $scope.formData.submitButtonLabel = 'Creating an account...';
            $scope.formData.submitInProgress = true;
        }

        function submitInProgressOff() {
            $scope.formData.submitButtonLabel = 'Create an account';
            $scope.formData.submitInProgress = false;
        }

        $scope.contactForm = {
            email: "",
            fullName: "",
            message: ""
        }

        $scope.submitForm2 = function() {
            var dto = {
                fullName: $scope.contactForm.fullName,
                email: $scope.contactForm.email,
                message: $scope.contactForm.message
            }
            $scope.loading = true;
            contactResource.sendMessage(dto).then(function(xhr) {
                $scope.openRegisterResultPopup('Thank you for contacting us',
                    'We have received your message. Please wait for our answer.<br/><br/>Keep in touch,<br/>SpinMinutes team <img src="/Client/images/exclamation1.png"/>');
                $scope.loading = false;
                $scope.contactForm.fullName = "";
                $scope.contactForm.email = "";
                $scope.contactForm.message = "";
            });
        }

    };

    angular.module('meetingMinutes.public')
        .controller('WelcomeController', WelcomeController);

})();
