/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.profile')
        .factory('tokenClientPopup', tokenClientPopup);

        tokenClientPopup.$inject = [
        'meetingResource', 'attachmentsHelper', 'httpResource', '$uibModal', 'salesResource', '$rootScope', 'tokenResource'
    ];

    function tokenClientPopup(meetingResource, attachmentsHelper, httpResource, $uibModal, salesResource, $rootScope, tokenResource) {
        TokenClientPopupCtrl.$inject = ['$scope', '$uibModalInstance'];
        function TokenClientPopupCtrl($scope, $uibModalInstance) {

            if ($scope.item) {
                $scope.formData = $scope.item;
            } else {
                $scope.formData = {
                    clientId: "",
                    description: "",
                    applicationType: 1,
                    active: true,
                    allowedOrigin: "",
                    refreshTokenLifeTime: '',
                };
            }
                      
            $scope.save = function() {
                if ($scope.formData.id) {
                    tokenResource.updateTokenClient($scope.formData);            
                } else {
                    tokenResource.saveTokenClient($scope.formData);            
                }
                
                $scope.item = null;
                $uibModalInstance.dismiss('cancel');
                setTimeout($scope.callback, 500);
                
            };

            $scope.delete = function() {
                tokenResource.deleteTokenClient($scope.formData.id);  
                $scope.item = null;
                $uibModalInstance.dismiss('cancel');
                setTimeout($scope.callback, 500);
            };

            $scope.cancel = function() {
                $scope.item = null;
                $uibModalInstance.dismiss('cancel');
            };
        }

        var open = function($scope, item) {
            if (item) {
                $scope.item = item;
            } else {
                $scope.item = null;
            }
            
            var modalInstance = $uibModal.open({
                templateUrl: 'views/main/popups/token-client.popup.tpl.html?v=' + new Date(),
                controller: TokenClientPopupCtrl,
                scope: $scope
            });
        };

        return {
            "open": open
        };
    }
})();
