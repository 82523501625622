/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular
        .module('meetingMinutes.directives.domain')
        .directive('enterPress', enterPressDirectiveFactory);

    function enterPressDirectiveFactory() {
        return function(scope, element, attrs) {
            element.bind("keydown keypress",
                function(event) {
                    if (event.which === 13) {
                        scope.$apply(function() {
                            scope.$eval(attrs.enterPress);
                        });

                        event.preventDefault();
                    }
                });
        };
    }
})();
