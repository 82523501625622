/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.repository.remote')
        .factory('httpResource', httpResourceFactory);

    httpResourceFactory.$inject = ['$http', '$q', 'REMOTE_API_PREFIX'];

    function httpResourceFactory($http, $q, REMOTE_API_PREFIX) {

        function get(url, config) {
            return $http.get(REMOTE_API_PREFIX + url, config).then(resolveWithData, rejectWithData);
        }

        function post(url, data, config) {
            return $http.post(REMOTE_API_PREFIX + url, data, config).then(resolveWithData, rejectWithData);
        }

        function patch(url, data, config) {
            return $http.patch(REMOTE_API_PREFIX + url, data, config).then(resolveWithData, rejectWithData);
        }

        function put(url, data, config) {
            return $http.put(REMOTE_API_PREFIX + url, data, config).then(resolveWithData, rejectWithData);
        }

        function $delete(url, config) {
            return $http.delete(REMOTE_API_PREFIX + url, config).then(resolveWithData, rejectWithData);
        }

        function resolveWithData(httpResponse) {
            return httpResponse.data;
        }

        function rejectWithData(httpResponse) {
            return $q.reject(httpResponse.data);
        }

        return {
            "get": get,
            "post": post,
            "put": put,
            "delete": $delete,
            "patch": patch
        };
    }

})();
