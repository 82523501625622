/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.repository.remote')
        .factory('personResource', remotePersonResourceFactory);

    remotePersonResourceFactory.$inject = ['httpResource'];

    function remotePersonResourceFactory(httpResource) {

        function createPerson(person, guid) {
            return httpResource.post('/person?guid='+guid, person);
        }

        function getPersonDrafts() {
            return httpResource.get('/person/drafts');
        }

        function getPersons() {
            return httpResource.get('/persons');
        }

        function getPersonsWithFilter(filter, canceller) {
            return httpResource.get('/persons?filter=' + filter, { timeout: canceller });
        }

        function getPerson(personId) {
            return httpResource.get('/person/' + personId);
        }

        function $delete(personId) {
            return httpResource.delete('/person/' + personId);
        }

        function save(person, guid) {

            person.guid = guid;

            return httpResource.put('/person?guid='+guid, person);
        }

        function updateOwner(dtos) {
            return httpResource.put('/persons/owner', dtos);
        }

        function deleteLeads(leadIdsString) {
            return httpResource.delete('/persons?' + leadIdsString);
        }

        function updateLeadLastContactDate(personId) {
            return httpResource.put('/person/' + personId + '/lastContact');
        }

        return {
            "createPerson": createPerson,
            "getPersonDrafts": getPersonDrafts,
            "getPersons": getPersons,
            "getPersonsWithFilter": getPersonsWithFilter,
            "getPerson": getPerson,
            "delete": $delete,
            "save": save,
            "updateOwner": updateOwner,
            "deleteLeads": deleteLeads,
            "updateLeadLastContactDate": updateLeadLastContactDate,
        };
    }

})();
