(function() {
    'use strict';

    angular.module('meetingMinutes.main.knowledge.meeting')
        .controller('MeetingsController', MeetingsController);

    MeetingsController.$inject = [
        'appSettingsHelper', 'meetingTimePopup', '$cookieStore', '$rootScope', '$scope',
        '$state', '$location', 'meetingResource', 'taskResource', 'userResource',
        'knowledgeObjectResource', '$interval', 'taskHelper', 'dateHelper', 'noteResource', 'toastr', 'notePopup', 
        'reduxStore', 'meetingQuickPlan'
    ];

    function MeetingsController(appSettingsHelper,
                                meetingTimePopup,
                                $cookieStore,
                                $rootScope,
                                $scope,
                                $state,
                                $location,
                                meetingResource,
                                taskResource,
                                userResource,
                                knowledgeObjectResource,
                                $interval,
                                taskHelper,
                                dateHelper,
                                noteResource,
                                toastr,
                                notePopup,
                                reduxStore,
                                meetingQuickPlan) {

        var store = reduxStore.get();

        $scope.meetings = [];
        $rootScope.meetingId = null;
        $scope.statsNeedToBeRefreshed = true;

        $scope.form = {};

        $scope.formSettings = {
            sm_crmMode: appSettingsHelper.getCookie('sm_crmMode'),
            sm_runningActivitiesView: appSettingsHelper.getCookie('sm_runningActivitiesView'),
            sm_unasignedActivitiesView: appSettingsHelper.getCookie('sm_unasignedActivitiesView'),
            sm_contextsView: appSettingsHelper.getCookie('sm_contextsView'),
            sm_todaysActivitiesView: appSettingsHelper.getCookie('sm_todaysActivitiesView')
        };

       $scope.settings = $rootScope.authentication.userSettings;

        $scope.switchSettings = function(type) {
            appSettingsHelper.switchVal(type);
            appSettingsHelper.getCookie(type);
            $scope.formSettings[type] = appSettingsHelper.getCookie(type);
        };

        $scope.label_otherday = false;

        $scope.callback = function(newDate) {
            $scope.form.selectedInboxDate = new Date(newDate);

            $cookieStore.put("sm_selectedDay", null);
            $cookieStore.put("sm_selectedDay", newDate);

            $scope.label_otherday = true;

            $scope.refreshTaskList();

            $scope._today = {
                day: moment(newDate).format('D'),
                day_name: moment(newDate).format('dddd'),
                month: moment(newDate).format('MMMM')
            };

            var today = moment();
            if(today.isSame(newDate, 'day'))
                $scope.calendarCss = "icon";
            else
                $scope.calendarCss = "icon_grey";
        };

        $scope.searchToDos = function() {
            var request = {
                from: 0,
                to: 100,
                take: 100,
                tagFilter: []
            };
            request.tagFilter.push('todo');
            request.typeFilter = 0;

           
           
        };

        $scope.editToDo = function (toDo) {
            toDo.dto.knowledgeObject = {
                "id": toDo.id,
                "typeName": toDo.typeName,
                "status": toDo.status,
                "objectUrl": toDo.objectUrl,
                "createTime": toDo.createTime,
                "creator": toDo.creator,
                "descriptionHtml": toDo.descriptionHtml,
                "descriptionTxt": toDo.descriptionTxt,
                "title": toDo.title,
                "shortDescription": toDo.shortDescription
            };

            notePopup.open($scope, toDo.dto, $scope.searchToDos);
        };

        $scope.markDoToAsDone = function (toDo) {
            toDo.dto.knowledgeObject = {
                "id": toDo.id,
                "typeName": toDo.typeName,
                "status": toDo.status,
                "objectUrl": toDo.objectUrl,
                "createTime": toDo.createTime,
                "creator": toDo.creator,
                "descriptionHtml": toDo.descriptionHtml,
                "descriptionTxt": toDo.descriptionTxt,
                "title": toDo.title,
                "shortDescription": toDo.shortDescription
            };
            meetingResource.markToDoAsDone(toDo.dto).then(function (result) {
                if (result) {
                    toastr.success('To Do Done!');
                    $scope.searchToDos();
                }
                else {
                    toastr.warning('There is a problem with closing a To Do')
                }
            })
        };

        $scope.selectDay = function() {
            meetingTimePopup.open($scope, $scope.form.selectedInboxDate, $scope.callback);
        };


        $scope.$context = $rootScope.$context.$set('/meetings');
        $scope.rowsPerPage = 15;

        $scope.inboxStats = {};

        $scope.selectTag = function(tag) {
            $rootScope.addToContext(tag);
        }


        $scope.getNotPlannedMeetings = function() {
            meetingResource.getNotPlannedMeetings($scope.form.crmview).then(function(xhr) {
                for (var i = 0; i < xhr.length; i++) {
                    xhr[i].knowledgeObject.createTimeEx = dateHelper.enchance(xhr[i].knowledgeObject.createTime);
                }

                $scope.form.notplannedMeetings = xhr;
            });
        };

        $scope.getNotPlannedMeetings();
        $scope.$watch(
            function() {
                if ($scope.form)
                    return $scope.form.crmview;
            },
            function(newValue, oldValue) {
                if ($scope.form && $scope.form)
                    $cookieStore.put('crmview', $scope.form.crmview);
                $scope.getInboxStat();
            },
            true);


        function getInboxStatPeriodicaly() {
            $scope.statsNeedToBeRefreshed = true;
            $scope.getInboxStat();
        }
        $scope.inboxStats.minutesAvailable = true;
        $scope.getInboxStat = function() {
            if($scope.statsNeedToBeRefreshed) {
                // var tags = JSON.stringify($rootScope.contextTags);
                $scope.searchToDos();

                userResource.getDesktop($scope.form.selectedInboxDateTxtLocal).then(
                    function (response) {
                        $scope.inboxStats = response;
                        $scope.inboxStats.warnings = [];

                        $scope.inboxStats.dayName = moment($scope.form.selectedInboxDate).format('dddd');
                
                        if (response.all > 0) {
                            $scope.inboxStats.percE = Math.round(response.allE * 100 / response.all);
                            $scope.inboxStats.percA = Math.round(response.allA * 100 / response.all);
                        }
                
                        if ($scope.inboxStats.percE > response.limitationE) {
                            $scope.inboxStats.warnings.push('Too many E tasks (' +
                                $scope.inboxStats.percE +
                                '%). Should be lower than ' +
                                $scope.inboxStats.limitationE +
                                '%');
                        }
                
                        if ($scope.inboxStats.percA > response.limitationA) {
                            $scope.inboxStats.warnings.push('Too many A tasks (' +
                                $scope.inboxStats.percA +
                                '%). Should be lower than ' +
                                $scope.inboxStats.limitationA +
                                '%');
                        }
                
                        $scope.meetingStat.data[0] = response.meetingsDone;
                        $scope.meetingStat.data[1] = response.meetingsNotDone;
                
                        $scope.goalStat.data[0] = response.meetingGoalsDone;
                        $scope.goalStat.data[1] = response.meetingGoalsNotDone;
                
                        $scope.dayStat.data[0] = $scope.inboxStats.dayPassedPerc;
                        $scope.dayStat.data[1] = 100 - $scope.inboxStats.dayPassedPerc;
                
                        response.potentialLosesTxt = Math.round(response.potentialLoses);
                        $scope.inboxStats.dayPassedPerc = Math.round($scope.inboxStats.dayPassedPerc);
                        $scope.inboxStats.minutesTillEndOfWorkday = Math.round($scope.inboxStats.minutesTillEndOfWorkday);
                        $scope.inboxStats.minutesAvailable =
                            Math.round($scope.inboxStats.minutesAtWork -
                                $scope.inboxStats.meetingsNotDoneMinutes -
                                $scope.inboxStats.meetingsDoneMinutes -
                                $scope.inboxStats.minutesPassedFromWorkdayStart);
                });

                $scope.statsNeedToBeRefreshed = false;
            }
        };



        $scope.viewMeeting = function(meeting) {
            $scope.$context.$object = meeting;
            $scope.$context.viewMeeting.actions.go();
        };

        $scope.filter = "";
        $scope.tags = [];
        $scope.persons = [];
        $scope.users = [];
        $scope.overallCost = 0;
        $scope.overallTime = 0;

        $scope.selectedItem = null;
        $scope.searchText = null;

        $scope.inbox = [];

        $scope.meetingStat =
        {
            data: [0, 0],
            options: {
                fill: ["#1ab394", "#edf0f5"],
                height: '50px',
                width: '50px',
            }
        }

        $scope.goalStat =
        {
            data: [0, 0],
            options: {
                fill: ["#1ab394", "#edf0f5"],
                height: '50px',
                width: '50px',
            }
        }

        $scope.dayStat =
        {
            data: [$scope.inboxStats.dayPassedPerc, 100 - $scope.inboxStats.dayPassedPerc],
            options: {
                fill: ["#1ab394", "#edf0f5"],
                height: '50px',
                width: '50px',
            }
        }

        $scope.inboxStat = {
            meetingsCount: 0,
            meetingsDone: 0,
            agendaCount: 0,
            agendaDone: 0,
            goalsCount: 0,
            goalsDone: 0,
            minutesPlanned: 0,
            minutesDone: 0,
            minutesLeft: 0
        };

        $scope.inboxDynamicStat = {
            workStart: 0,
            workEnd: 0,
            minutesTillWorkEnd: 0
        };

        $scope.taskPriorities = taskHelper.priorities;

        $scope.form.selectedInboxDate = null;
        $scope.calendarCss = "icon";

        $scope.initializeDate = function() {
            if ($location.search().date) {
                $scope.form.selectedInboxDate = new Date($location.search().date);
                $scope._today = {
                    day: moment($location.search().date).format('D'),
                    day_name: moment($location.search().date).format('dddd'),
                    month: moment($location.search().date).format('MMMM')
                };
                $scope.calendarCss = "icon_grey";

            } else {
                $scope.form.selectedInboxDate = new Date();
                $scope.calendarCss = "icon";
            }

            var date = $scope.form.selectedInboxDate;
            $scope.form.selectedInboxDateTxt = moment($scope.form.selectedInboxDate).format("MMM Do YY");
            $scope.form.selectedInboxDateTxtLocal = moment($scope.form.selectedInboxDate).format("YYYY-MM-DD");

            $scope.refreshTaskList();
        };

        $scope.selectTask = function(id) {
            taskResource.get(id).then(function(xhr) {
                if (xhr.knowledgeObject.objectType == 17) {
                    $scope.selectedTask = xhr;
                    $scope.selectedTask.isToday =
                        moment(xhr.deadline).format('YYYYMMDD') == moment().format('YYYYMMDD');
                    $scope._today.day = moment(xhr.deadline).format('D');
                    $scope._today.day_name = moment(xhr.deadline).format('dddd');
                    $scope._today.month = moment(xhr.deadline).format('MMMM');
                } else {
                    $state.go('main.meetingDetails', { meetingId: id });
                }
            });
        };

        if ($location.search().taskId) {
            $scope.selectTask($location.search().taskId);
        }

        $scope._today = {
            day: moment().format('D'),
            day_name: moment().format('dddd'),
            month: moment().format('MMMM')
        };

        $scope.viewMeeting = function(id) {
            $state.go('main.meetingDetails', { meetingId: id });
        };



        $scope.loading = false;

        $scope.visitPage = function(link) {
            window.location = link;
        };

        $scope.todoListModel = {
            handleInstantTodo: function(toDo) {
                var newTodo = {
                    id: toDo.id,
                    koId: toDo.koId,
                    title: toDo.name,
                    descriptionTxt: toDo.description,
                    shortDescription: toDo.description,
                    dto: {
                        duration: toDo.duration
                    }
                };
                $rootScope.meetingToDo = newTodo;
                meetingQuickPlan.open($scope, null, null);
            }    
        }

      
        $scope.refreshTaskList = function() {
            $scope.loading = true;
            $scope.inbox = [];
            $scope.tags = [];

            taskResource
                .list(new Date($scope.form.selectedInboxDate).toISOString().slice(0,10),
                    $scope.form.crmview).then(function(response) {

                    $scope.runningMeetingsCount = 0;
                    $scope.thisDayMeetingsCount = 0;

                    for (var i = 0; i < response.length; i++) {

                        if(response[i].knowledgeObject.tags)
                            for (var j = 0; j < response[i].knowledgeObject.tags.length; j++) {
                                response[i].knowledgeObject.tags[j] =
                                    $rootScope.converted(response[i].knowledgeObject.tags[j].text);
                            }

                        if (response[i].externalLink && response[i].externalLink.indexOf('google.com') > 0) {
                            response[i].externalIcon = '<i class="fa fa-googleplus"></i>';
                        }

                        if (response[i].status == 1)
                            $scope.runningMeetingsCount++;
                        else
                            $scope.thisDayMeetingsCount++;

                        response[i] = dateHelper.enchanceMeeting(response[i]);
                        response[i].priorityTxt = angular.globalSettingsConst.priorities[response[i].priority];

                        if (response[i].status == 0 || response[i].status == 1)
                            response[i].statusBoolean = false;
                        else
                            response[i].statusBoolean = true;

                            $scope.inbox.push(response[i]);
                    }
                    $scope.loading = false;
                });
        };

        $scope.initializeDate();

        $scope.selectInboxDate = function(item) {
            var date = $scope.form.selectedInboxDate;
            $scope.form.selectedInboxDateTxt = moment($scope.form.selectedInboxDate).format("MMM Do YY");
            $scope.form.selectedInboxDateTxtLocal = moment($scope.form.selectedInboxDate).format("l");

            $scope._today.day = moment($scope.form.selectedInboxDate).format('D');
            $scope._today.day_name = moment($scope.form.selectedInboxDate).format('dddd');
            $scope._today.month = moment($scope.form.selectedInboxDate).format('MMMM');

            $scope.refreshTaskList();
            $scope.getInboxStat();
        };

        $scope.getTaskPriorityClass = function(i) {
            return "taskB";
            // switch (i) {
            // case 0:
            //     return "taskA";
            // case 1:
            //     return "taskB";
            // case 2:
            //     return "taskC";
            // case 3:
            //     return "taskD";
            // case 4:
            //     return "taskE";
            // default:
            //     return null;
            // }
        };

        $scope.getTaskStatusClass = function(i) {
            if (i == 2)
                return "done";
            else
                return "";
        };

        $scope.planNewMeeting = function() {
            $state.go('main.planMeeting', { meetingId: null });
        };

        $scope.planMeeting = function(task) {
            if (task.planConfirmed == true)
                $cookieStore.put("sm_planMeeting_calendar_active", null);
            else
                $cookieStore.put("sm_planMeeting_calendar_active", "true");
            $state.go('main.planMeeting', { meetingId: task.id });
        }

        $scope.planNewActionItemMeeting = function(actionItem) {
            $cookieStore.put("ctx_actionItem", actionItem.id);

            $cookieStore.put("ctx_actionItem_title", actionItem.knowledgeObject.descriptionHtml);
            $cookieStore.put("ctx_actionItem_tags", "action-item");

            $state.go('main.planMeeting', { meetingId: null });
        }

        $scope.planTheDay = function() {
            $location.path('/meeting/plan/').search({ backUrl: null, tags: null, prevId: null, mode: 'plantheday' })
        }

        function onCreateNoteClick() {
            $state.go('main.createNote', { noteId: null });
        }

        $scope.formData.actionItems = [];

        $interval(getInboxStatPeriodicaly, 60000);
    }
})();