/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

angular.module('meetingMinutes.authorization')
.service('authInterceptor', ['$q', function ($q) {
        const service = this;

        service.responseError = function (response) {
            if (response.status === 401) {
                window.location = "/client/index#/sign-in";
            }
            if (response.status === 403) {
                window.location = "/client/index#/expired";
            }
            return $q.reject(response);
        };
    }])
    .config(['$httpProvider', function ($httpProvider) {
        $httpProvider.interceptors.push('authInterceptor');
    }]);
