/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.workgroup')
        .controller('WorkgroupController', WorkgroupController);

    WorkgroupController.$inject = ['$scope', '$rootScope', '$location', '$stateParams', 'userResource'];

    function WorkgroupController($scope, $rootScope, $location, $stateParams, userResource) {

        $scope.user = {};
        $scope.users = [];
        $scope.filteredUsers = [];

        $scope.rowsPerPage = 20;

        activate();

        $scope.filter = "";

        function activate() {
            getUsers();
            $rootScope.$context.$set('/workgroup', $scope);
        }

        $scope.filterUsers = function() {
            $scope.filteredUsers = [];
            for (var i = 0; i < $scope.users.length; i++) {
                if ($scope.users[i].userName.toLowerCase().indexOf($scope.filter.toLowerCase()) >= 0) {
                    $scope.filteredUsers.push($scope.users[i]);
                }
            }
        };

        function getUsers() {
            withProgressIndicator(userResource.getUsers()).then(function(users) {
                $scope.users = users;
                $scope.filterUsers();
                $scope.user = $scope.users[0];

                if ($stateParams.id) {
                    for (var i = 0; i < users.length; i++) {
                        if (users[i].id == $stateParams.id) {
                            $scope.user = $scope.users[i];
                        }
                    }
                }
            });
        }


        $scope.onCreateNewUser = function() {
            $location.path('/user/create');
        };

        $scope.onEdit = function() {
            $location.path('/user/edit/' + $scope.user.id);
        };

        $scope.viewUser = function(_user) {
            $scope.user = _user;
        };

        function withProgressIndicator(promise) {
            $scope.loadInProgressMessage = 'Loading...';
            promise.finally(function() {
                $scope.loadInProgressMessage = null;
            });
            return promise;
        }

    }
})();
