/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.knowledge.meeting')
        .factory('selectOpportunityPopup', selectOpportunityPopup);

    selectOpportunityPopup.$inject = [
        '$rootScope', 'httpResource', '$uibModal', 'meetingResource', 'personResource', 'crmResource'
    ];

    function selectOpportunityPopup($rootScope, httpResource, $uibModal, meetingResource, personResource, crmResource) {

        SelectOpportunityPopupCtrl.$inject = ['$scope', '$uibModalInstance'];
        function SelectOpportunityPopupCtrl($scope, $uibModalInstance) {
            $scope.opportunities = [];

            crmResource.getOpportunities().then(function(response) {
                $scope.opportunities = response;
            });

            $scope.addGuest = function(item) {
                $rootScope.crmCtx.opportunity = item;

            };

            $scope.ok = function() {
                $uibModalInstance.close();
            };

            $scope.cancel = function() {
                $uibModalInstance.dismiss('cancel');
            };
        }

        var open = function($scope, meeting) {
            $scope.meeting = meeting;

            var modalInstance = $uibModal.open({
                templateUrl: 'views/main/popups/select-opportunity.popup.tpl.html?v=' + new Date(),
                controller: SelectOpportunityPopupCtrl,
                scope: $scope
            });
        }

        return {
            "open": open
        };
    }
})();
