import * as React from "react";
import { AppState } from "../../store/appstate";
import { AnyAction, Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { uiTimelineCloseModal } from '../../store/uimodels/uitimeline';
import { DialogTitle, DialogContent, DialogActions, Button, Box } from "@material-ui/core";
import { TimelineElement, ITimelineElement } from "../shared/timeline/TimelineElement";
import { TimelineDtoToElement } from "../shared/timeline/TimelineElement";
import { styled } from "@material-ui/styles";

interface ITimelinePopupProps {
  meetingId: number;
}

interface ITimelinePopupOwnProps {
  handleClose: () => void;
}

interface ITimelinePopupState {
  timelineData: any;
  isLoading: boolean;
}

class TimelinePopup extends React.PureComponent<ITimelinePopupProps & ITimelinePopupOwnProps, ITimelinePopupState> {

  /**
   *
   */
  constructor(props: ITimelinePopupProps & ITimelinePopupOwnProps) {
    super(props);

    this.state = {
      timelineData: null,
      isLoading: true
    }
  }

  componentDidMount() {
    fetch(`/api/meeting/${this.props.meetingId}/timeline?guid=`, { headers: { "Content-Type": "application/json; charset=utf-8" } })
            .then((response) => {
                return response.json()
            })
            .then((json) => {
              this.setState({
                timelineData: json,
                isLoading: false
              });              
            });
  }

  render() {

    const StyledBox = styled(Box)({
      '&:before': {
        content: "''",
        position: 'absolute',
        top: 88,
        left: 58,
        height: 'calc(100% - 166px)',
        width: '4px',
        background: '#e8ebf0',
      }
    });

    let content;
    if (this.state.isLoading) {
      content = (<Box textAlign="center">Please wait...</Box>);
    } else {
      content = (<StyledBox>
        
          {this.state.timelineData.items.map((item: any) => {
              let model: ITimelineElement = TimelineDtoToElement(item);
              return <TimelineElement key={model.id} {...model}></TimelineElement>
          })}
        
      </StyledBox>);
    }

    return (
      <React.Fragment>
        <DialogTitle>
          Meeting timeline
        </DialogTitle>
        <DialogContent>
          {content}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={this.props.handleClose}>
            Close
          </Button>
        </DialogActions>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: ITimelinePopupOwnProps): ITimelinePopupProps & ITimelinePopupOwnProps => {
  return {
    meetingId: state.ui.timeline.timelineModalMeetingId,
    handleClose: ownProps.handleClose
  };
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return bindActionCreators({ uiTimelineCloseModal }, dispatch);
}

export const TimelinePopupLink = connect(
  mapStateToProps,
  mapDispatchToProps
)(TimelinePopup)
