import * as React from "react";
import { Box, Grid, Avatar } from "@material-ui/core";
import * as moment from 'moment';
import { string } from "prop-types";

export interface ITimelineElement {
    id: number;
    type: number;
    created: Date;
    deadline: Date;
    creator: {
        userName: string;
        id: number;
    };
    relatedUser: {
        userName: string;
        id: number;
    }
    content: string;
    variant: number;
}

export function TimelineDtoToElement(dto: any): ITimelineElement {

    let relatedUser = null;

    if (dto.idx.relatedUsers.length > 0)
        relatedUser = {
            userName: dto.idx.relatedUsers[0].userName,
            id: dto.idx.relatedUsers[0].userName
        }

    return {
        id: dto.id,
        type: dto.type,
        created: dto.executeTime,
        content: dto.knowledgeObject.descriptionTxt,
        creator: {
            userName: dto.user.userName,
            id: dto.user.id
        },
        variant: dto.idx.variant,
        deadline: dto.idx.deadline,
        relatedUser: relatedUser
    }
}


function getAction(actionType: number): { label: string, iconUrl: string } {
    switch (actionType) {
        case 0:
            return {
                label: 'Approval',
                iconUrl: 'Images/landing/a-approval-48.png'
            }
        case 1:
            return {
                label: 'Rejection',
                iconUrl: 'Images/landing/a-rejection-48.png'
            }
        case 2:
            return {
                label: 'To discuss on next meeting',
                iconUrl: 'Images/landing/a-ToDiscussOnNextMeeting-48.png'
            }
        case 3:
            return {
                label: 'Assumption',
                iconUrl: 'Images/landing/a-suspicious-48.png'
            }
    }
}


function getEmotion(emotionType: number): { label: string, iconUrl: string } {

    switch (emotionType) {
        case 0:
            return {
                label: 'Pleased',
                iconUrl: 'Images/landing/e-Pleased-48.png'
            }
        case 1:
            return {
                label: 'Having no opinion',
                iconUrl: 'Images/landing/e-noopinion-48.png'
            }
        case 2:
            return {
                label: 'Distrustful',
                iconUrl: 'Images/landing/e-Distrustful-48.png'
            }
        case 3:
            return {
                label: 'Content',
                iconUrl: 'Images/landing/e-Content-48.png'
            }
        case 4:
            return {
                label: 'Surprised',
                iconUrl: 'Images/landing/e-Surprised-48.png'
            }
        case 5:
            return {
                label: 'Suspicious',
                iconUrl: 'Images/landing/e-suspicious-48.png'
            }
        case 6:
            return {
                label: 'Sarcastic',
                iconUrl: 'Images/landing/e-Sarcastic-48.png'
            }
        case 7:
            return {
                label: 'Fascinated',
                iconUrl: 'Images/landing/e-NotInterested-48.png'
            }
        case 8:
            return {
                label: 'NotInterested',
                iconUrl: 'Images/landing/e-suspicious-48.png'
            }
    }
}

export const TimelineElement: React.FC<ITimelineElement> = (props: ITimelineElement) => {

    let typeElement;
    switch (props.type)
    {
        default:
            typeElement = null;
            break;
        case 1:
            const action = getAction(+props.variant);
            typeElement = (<Box my={1}><img src={action.iconUrl}></img> Assignment ({action.label})</Box>);
            break;
        case 2:
            const emotion = getEmotion(+props.variant);
            typeElement = (<Box my={1}><img src={emotion.iconUrl}></img> Emotion ({emotion.label})</Box>);
            break;
        case 3:
            typeElement = (
            <Box my={1}>
                <Box>Todo for: {props.relatedUser.userName}</Box>
                <Box>Deadline: {moment(props.deadline).format()}</Box>                
            </Box>
            );
            break;
    }

    return (
        <Box>
            <Grid container>
                <Grid item xs={2}>
                     <Box p={2}>
                        <Avatar alt={props.creator.userName} src={`/api/user/${props.creator.id}/profile/picture`} />
                     </Box>
                </Grid>
                <Grid item xs={10}>
                    <Box border={1} borderColor='grey.500' borderRadius={5} mb={2} p={2}>
                        <Box display='flex' justifyContent='space-between'>
                            <Box fontWeight='bold'>
                                {props.creator.userName}
                            </Box>
                            <Box>
                                <Box textAlign='right' >
                                    {moment(props.created).format()}
                                </Box>
                            </Box>
                        </Box>
                        {typeElement}
                        <Box pt={2}>                            
                            {props.content}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}