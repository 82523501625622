import { FSAWithPayload } from "flux-standard-action";

export const UI_CURRENTUSER_SET = 'spinme/ui/currentuser/SET';
export const uiCurrentUserSet = (currentUserId: number): FSAWithPayload<string, number> => {
    return {
        type: UI_CURRENTUSER_SET,
        payload: currentUserId
    }
}


export function currentUserUireducers(state: CurrentUserUiState = INTIAL_CURRENTUSER_UI_STATE, action: FSAWithPayload<string, number>): CurrentUserUiState {
    switch (action.type) {
        case UI_CURRENTUSER_SET:
            return {
                ...state,
                currentUserId: action.payload as number
            }
        default: 
            return state;
    }
}

export interface CurrentUserUiState {
    currentUserId: number;
}

export const INTIAL_CURRENTUSER_UI_STATE: CurrentUserUiState = {
    currentUserId: null
}