/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

import {uiTodoOpenModal} from '../../src/store/uimodels/uitodo';

(function() {
    'use strict';

    angular.module('meetingMinutes.layout')
        .controller('HeaderController', HeaderController);

    HeaderController.$inject = [
        '$cookies', '$cookieStore', 'noteResource', 'popupHelper', 'tagsResource', '$stateParams', '$state',
        '$rootScope', '$scope', '$location', 'userResource', 'knowledgeObjectResource', 'meetingHelper',
        'meetingResource', 'minutesTemplateResource', 'reduxStore', 'meetingQuickPlan'
    ];

    function HeaderController($cookies,
        $cookieStore,
        noteResource,
        popupHelper,
        tagsResource,
        $stateParams,
        $state,
        $rootScope,
        $scope,
        $location,
        userResource,
        knowledgeObjectResource,
        meetingHelper,
        meetingResource,
        minutesTemplateResource,
        reduxStore,
        meetingQuickPlan) {

        var store = reduxStore.get();

        $scope.formData = {};

        $scope.repository = {};

        $scope.instantActivity = false;
        $scope.pinNotesCount = 0;

        $scope.createRelatedNote = function() {
            popupHelper.CreateKoNote($scope, "pin-note");
        }

        $scope.createToDoNote = function() {
            store.dispatch(uiTodoOpenModal());
        }

        $scope.goProfile = function() {
            $state.go("main.profile");
        }

        $scope.goSettings = function() {
            $state.go("main.profile/tasks");
        }

        $scope.goUsers = function() {
            $state.go("main.users");
        }

        $scope.goSaasPortal = function() {
            userResource.getToken("saas").then(function(data) {


                window.location.href = "https://portal.spinme.io/login?token="+data.token+"&returnUrl=/account";
            });
        }

        $rootScope.setPinNotesCount();

        $scope.doInstant = function(type) {

            var oppMatch = $scope.$root.location.path().match(/\/opportunity\/view\/(\d+)/);
            var oppId = null;
            if (oppMatch) {
                oppId = oppMatch[1];
            }

            var leadMatch = $scope.$root.location.path().match(/\/person\/view\/(\d+)/);
            var leadId = null;
            if (leadMatch) {
                leadId = leadMatch[1];
            }


            meetingQuickPlan.open($scope, $scope.formData.meeting, null, oppId, leadId);
        }

        // $scope.getTags_PersonEx = function() {
        //     knowledgeObjectResource.getTags_PersonEx().then(function(xhr) {
        //         $scope.repository.personDetails = xhr;
        //     });
        //     knowledgeObjectResource.getTags_UserEx().then(function(xhr) {
        //         $scope.repository.userDetails = xhr;
        //     });
        // }

        // $scope.getTags_PersonEx();

        $scope.$watch(
            function() {
                return $rootScope.meetingId;
            },
            function(newValue, oldValue) {
                $scope.formData.meetingId = $rootScope.meetingId;
            },
            true);

        $scope.$watch(
            function() {
                return $rootScope.pinNotesCount;
            },
            function(newValue, oldValue) {
                $scope.formData.pinNotesCount = $rootScope.pinNotesCount;
                if ($scope.formData.pinNotesCount > 12) {
                    $scope.formData.pinNotesCount = 12;
                }
            },
            true);

        $scope.$watch(
            function() {
                return $rootScope.crmCtx.opportunity;
            },
            function(newValue, oldValue) {
                $scope.formData.crmOpportunityCtx = $rootScope.crmCtx.opportunity
            },
            true);

        $scope.formData.crmOpportunityCtx = $rootScope.crmCtx.opportunity;


        var getPersonDetails = function(tag) {
            for (var i = 0; i < $scope.repository.personDetails.length; i++) {
                if (tag == 'p:' + $scope.repository.personDetails[i].id) {
                    return $scope.repository.personDetails[i].knowledgeObject.title;
                }
            }

            return null;
        }

        var getUserDetails = function(tag) {
            for (var i = 0; i < $scope.repository.userDetails.length; i++) {
                if (tag == 'u:' + $scope.repository.userDetails[i].id) {
                    return $scope.repository.userDetails[i].fullName;
                }
            }

            return null;
        }

        $scope.converted = function(tag) {
            var tagEx = {
                tag: tag,
                isSpecial: false
            }

            if (tag.startsWith('u:')) {
                tagEx.loading = true;
                tagEx.facade = null;
                tagEx.isSpecial = true;

                tagEx.translatedAvatarUrl = tagsResource.translateTag(tag);
                tagEx.details = getUserDetails(tag);
            }

            if (tag.startsWith('p:')) {
                tagEx.loading = true;
                tagEx.isSpecial = true;

                tagEx.translatedAvatarUrl = tagsResource.translateTag(tag);
                tagEx.details = getPersonDetails(tag);
            }

            return tagEx;
        }

        $scope.userName = "";
        $scope.avatarUrl = "";
        $scope.weeknumber = moment().week();
        $scope.context = $rootScope.context;
        $scope.contextTags = $rootScope.contextTags;

        $scope.translations = tagsResource.availableTags;

        // $scope.loadTemplates = function() {
        //     minutesTemplateResource.get().then(function(xhr) {
        //         $scope.formData = {
        //             templates: xhr,
        //             crmOpportunityCtx: $rootScope.crmCtx.opportunity
        //         };
        //     });
        // }

        // $scope.loadTemplates();
        $scope.removeFromContext = function(tag) {
            $rootScope.removeFromContext(tag);
        }

        $scope.instantMeetingTypes = meetingHelper.instantTypes;

        userResource.getProfile().then(function(profile) {
            $scope.avatarUrl = profile.avatarUrl;
            $scope.userName = profile.userName;
            $scope.surname = profile.surname;
            $scope.firstName = profile.firstName;
        });

        $scope.createNewInstantMeeting = function(type) {
            meetingResource.createInstantMeeting(type, $rootScope.contextTags.tags).then(function(response) {
                $state.go('main.meetingTimeline', { meetingId: response.id });
            });
        }

        $scope.focusOpportunity = function() {
            noteResource.viewNoteList(26);
        }

        $scope.unfocusOpportunity = function() {
            $rootScope.crmCtx.opportunity = null;
            $cookieStore.put('crmCtx', null);
        }


        function initLocalClocks() {
            // Get the local time using JS            
            var date = new Date;
            var seconds = date.getSeconds();
            var minutes = date.getMinutes();
            var hours = date.getHours();

            // Create an object with each hand and it's angle in degrees
            var hands = [
                {
                    hand: 'hours',
                    angle: (hours * 30) + (minutes / 2)
                },
                {
                    hand: 'minutes',
                    angle: (minutes * 6)
                },
                {
                    hand: 'seconds',
                    angle: (seconds * 6)
                }
            ];
            // Loop through each of these hands to set their angle
            for (var j = 0; j < hands.length; j++) {
                var elements = document.querySelectorAll('.local .' + hands[j].hand);
                for (var k = 0; k < elements.length; k++) {
                    elements[k].style.transform = 'rotateZ(' + hands[j].angle + 'deg)';
                    // If this is a minute hand, note the seconds position (to calculate minute position later)
                    if (hands[j].hand === 'minutes') {
                        elements[k].parentNode.setAttribute('data-second-angle', hands[j + 1].angle);
                    }
                }
            }
        }

        initLocalClocks();
    }
})();
