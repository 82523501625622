/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.repository.remote')
        .factory('appSettingsHelper', appSettingsHelper);

    appSettingsHelper.$inject = ['httpResource', '$rootScope', '$cookies', '$cookieStore'];

    function appSettingsHelper(httpResource, $rootScope, $cookies, $cookieStore) {

        function switchVal(cookieType) {
            var value = $cookieStore.get(cookieType).toString();

            if (!value) {
                value = "true";
            }
            var newValue;
            if (value == "false")
                value = "true";
            else
                newValue = "false";


            $cookieStore.remove(cookieType);
            $cookieStore.put(cookieType, newValue);


            return value;
        }

        function getCookie(cookieType) {
            var val = $cookieStore.get(cookieType);
            if (!val) {
                $cookieStore.put(cookieType, "true");
            }

            return $cookieStore.get(cookieType);
        }

        return {
            getCookie: getCookie,
            switchVal: switchVal
        };
    }

})();
