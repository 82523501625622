/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.admin')
        .controller('LogsController', LogsController);

    LogsController.$inject = ['$scope'];

    function LogsController($scope) {

        activate();

        function activate() {
        }

    }

})();
