/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';
    angular.module('meetingMinutes.main.workgroup')
        .controller('UserController', UserController);

    UserController.$inject = [
        'authenticationResource', 'selectTagsPopup', '$state', '$rootScope', '$scope', '$location', '$stateParams',
        'userResource', 'accountResource', 'ngJcropConfig', 'knowledgeObjectResource', 'toastr', 'ui.factory',
        'noteResource', 'meetingResource', 'dateHelper', 'popupHelper', 'numbers'
    ];

    function UserController(authenticationResource,
        selectTagsPopup,
        $state,
        $rootScope,
        $scope,
        $location,
        $stateParams,
        userResource,
        accountResource,
        ngJcropConfig,
        knowledgeObjectResource,
        toastr,
        ui,
        noteResource,
        meetingResource,
        dateHelper,
        popupHelper,
        numbers) {

        $scope.editMode = function() {
            $state.go('main.user/edit', { id: $scope.formData.user.id })
        }

        //$scope.linkedInId = $rootScope.authentication.linkedInId;

        $scope.connectToLinkedIn = function() {
            authenticationResource.redirectToLinkedIn().then(function(result) {
                window.location = result;
            });
        }

        $scope.selectTagsPopup = function() {
            var config = {};
            config.common = true;
            selectTagsPopup.open($scope, $scope.formData.user.knowledgeObject.tags, config, null);
        };

        if ($state.$current.self.name == 'main.user/edit')
            $scope.mode = 'edit';
        if ($state.$current.self.name == 'main.user/create')
            $scope.mode = 'create';
        if ($state.$current.self.name == 'main.user/view')
            $scope.mode = 'view';

        $scope.back = $stateParams.back;

        $scope.searchRelatedNotes = function() {
            var request = {
                from: 0,
                to: 100,
                take: 100,
                tagFilter: []
            };
            request.tagFilter.push('u:' + $scope.formData.user.id);

            noteResource.getNotes(request).then(function(result) {
                $scope.formData.user.relatedNotes = result;

            });

            meetingResource.getUserMeetings($scope.formData.user.id).then(function(result) {
                for (var i = 0; i < result.length; i++) {
                    result[i].plannedStartTime = dateHelper.enchance(result[i].plannedStartTime);
                }
                $scope.formData.user.relatedMeetings = result;
            });
        };

        $scope.createRelatedNote = function() {
            popupHelper.CreateKoNote($scope, "u:" + $scope.formData.user.id, $scope.searchRelatedNotes);
        }

        $scope.onSetUserPicture = onSetUserPicture;
        $scope.onCancelUserPicture = onCancelUserPicture;

        $scope.$context = $rootScope.$context.$set('/user/create');

        $scope.onSave = onSave;

        $scope.attachmentUploadUrl = "";
        $scope.lbl_save = "Save";

        $scope.xxx = {
            pictureToUpload: 'abc',
            showUrlPanel: false
        };


        $scope.btnSave = ui.Button('Save', $scope.onSave, true);
        activate();

        $scope.inputTextToTag = function(newTag) {
            return { text: newTag };
        };

        function onSave() {
            $scope.formData.user.knowledgeObject.status = 1;
            userResource.updateUser($scope.formData.user.id, $scope.formData.user, numbers.uuidv4()).then(function() {
                toastr.success("User's data have been succesfully saved");
                $state.go('main.user/view', { id: $scope.formData.user.id });
            });
        }

        $scope.resetPassword = function() {
            accountResource.resetPassword($scope.formData.user.userEmail).then(function() {
                toastr.success("Request for password change has been sent");
            });
        };

        $scope.status = {
            user: "Suspended",
            ko: "Visit card is not accessible"
        };

        $scope.suspend = function() {
            $scope.formData.user.status = 0; //suspended
            $scope.formData.user.knowledgeObject.status = 0; //suspendeds
            $scope.documentType = "Draft";
            userResource.updateUser($scope.formData.user.id, $scope.formData.user, numbers.uuidv4()).then(function() {
                toastr.success("User suspended");
                $scope.status.user = "Suspended";
            });
        };

        $scope.delete = function() {
            userResource.deleteUser($scope.formData.user.id, numbers.uuidv4()).then(function() {
                toastr.success("User deleted");
                $location.path('/profile');
            });
        };


        $scope.hideUrlUploadPanel = function() {
            $scope.xxx.showUrlPanel = false;

        };

        $scope.onTagSelect = function(selectedTagRef) {
            knowledgeObjectResource.saveTag($scope.formData.user.knowledgeObject.id, selectedTagRef.text)
                .then(overrideTags);
        };

        $scope.onTagDelete = function(deletedTagRef) {
            knowledgeObjectResource.deleteTag(deletedTagRef.id)
                .then(overrideTags);
        };

        function overrideTags(tags) {
            $scope.formData.user.knowledgeObject.tags = tags;
        }

        function refresh() {
            userResource.getUser($scope.formData.user.id).then(function(user) {

                $scope.formData = { user: user };
            });
            knowledgeObjectResource.getAttachments($scope.formData.user.knowledgeObject.id).then(function(xhr) {
                $scope.formData.user.knowledgeObject.attachments = xhr;
            });
        }

        $scope.documentType = "Draft";

        $scope.prepareUser = function(user) {
            $scope.formData = { user: user };

            if ($scope.formData.user.status == 1) {
                $scope.status.user = "Active";
            }
            if ($scope.formData.user.status == 0) {
                $scope.status.user = "Suspended";
            }

            if ($scope.formData.user.knowledgeObject.status == 0)
                $scope.status.ko = "Visit card is not accessible";
            if ($scope.formData.user.knowledgeObject.status == 1)
                $scope.documentType = "Visit card is visible";

            $scope.formData.user.knowledgeObject.tags = user.knowledgeObject.tags;

            $scope.pictureUploadUrl = '/api/user/' + $scope.formData.user.id + '/profile/picture/upload';
            $scope.attachmentUploadUrl =
                '/api/knowledgeObject/' + $scope.formData.user.knowledgeObject.id + '/attachment';
            $scope.searchRelatedNotes();
            knowledgeObjectResource.getTags().then(function(tags) {
                $scope.formData.availableTags = tags;
            });
            knowledgeObjectResource.getAttachments($scope.formData.user.knowledgeObject.id).then(function(xhr) {
                $scope.formData.user.knowledgeObject.attachments = xhr;
            });
        };


        function activate() {
            if ($stateParams.id) {
                userResource.getUser($stateParams.id).then(function(user) {
                    $scope.prepareUser(user);
                });
            } else {
                userResource.createUser().then(function(user) {
                    $state.go('main.user/edit', { id: user.id });
                });
            }

            $scope.$on('fileuploaddone',
                function(event, data) {
                    if (data.paramName == 'profilePicture') {
                        $scope.obj.src = data.result;
                    }
                });
        }

        $scope.$on('fileuploadadd',
            function(e, data) {

                if (data.paramName == 'profilePicture') {
                    $scope.fileUploadOptions.url = $scope.pictureUploadUrl;
                    data.url = $scope.pictureUploadUrl;
                    data.submit();
                }
                if (data.paramName == 'attachments') {
                    $scope.attachmentsOptions.url = $scope.attachmentUploadUrl;
                    data.url = $scope.attachmentUploadUrl;
                    data.submit().then(function() {

                        refresh();
                    });

                }
            });
        $scope.urlToUpload =
            encodeURIComponent(
                "https://fbcdn-sphotos-b-a.akamaihd.net/hphotos-ak-ash4/v/t1.0-9/180554_182771151763573_5640344_n.jpg?oh=6e5e1b59ed52c0df152c64d401654852&oe=57264B85&__gda__=1463014595_998d13d7732d2ec26d0f6adb6dd2bb00");

        $scope.uploadUrlPicture = function() {
            userResource.saveUserPictureUrl($scope.formData.user.id, encodeURIComponent($scope.xxx.pictureToUpload))
                .then(function(result) {

                    $scope.obj.src = result + "?t=" + new Date();
                });
        }

        function onSetUserPicture() {
            var coords = $scope.obj.coords;
            userResource.saveUserPicture($scope.formData.user.id,
                {
                    cropPointX: coords[0],
                    cropPointY: coords[1],
                    cropWidth: coords[4],
                    cropHeight: coords[5]
                }).then(function() {
                $scope.obj.src = null;
                var tmp = $scope.formData.user.avatarUrl;
                $scope.formData.user.avatarUrl = tmp + "?_ts=" + new Date().getTime();
            });
        }

        function onCancelUserPicture() {
            $scope.obj.src = null;
        }

        // do kropa config
        $scope.obj = {
        };

        // The url or the data64 for the image
        $scope.obj.src = null; //'images/test_crop.jpg';

        // Must be [x, y, x2, y2, w, h]
        $scope.obj.coords = [100, 100, 200, 200, 100, 100];

        $scope.fileUploadOptions = {
            url: '',
            autoUpload: false,
            xhrFields: {
                withCredentials: true
            }
        };

        $scope.attachmentsOptions = {
            autoUpload: false,
            url: '',
            xhrFields: {
                withCredentials: true
            }
        };

        // You can add a thumbnail if you want
        $scope.obj.thumbnail = false;

        $scope.onAttachmentDelete = function(data) {
            knowledgeObjectResource.deleteAttachment(data.id).then(function() {
                activate();
            });
        }

        // $scope.summernoteOpt = {
        //     focus: true,
        //     airMode: true,
        //     height: 200,
        //     toolbar: [
        //         ['headline', ['style']],
        //         ['style', ['bold', 'italic', 'underline', 'superscript', 'subscript', 'strikethrough', 'clear']],
        //         ['textsize', ['fontsize']],
        //         ['alignment', ['ul', 'ol', 'paragraph', 'lineheight']]
        //     ]
        // };
    }
})();
