/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.repository.remote')
        .factory('workgroupResource', remoteWorkgroupResourceFactory);

    remoteWorkgroupResourceFactory.$inject = ['httpResource'];

    function remoteWorkgroupResourceFactory(httpResource) {
        function getMembers() {
            return httpResource.get('/workgroup/members');
        }

        function getWorkgroups() {
            return httpResource.get('/workgroups');
        }

        function getWorkgroupMembers(id) {
            return httpResource.get('/workgroup/' + id + '/members');
        }

        function getWorkgroup(id) {
            return httpResource.get('/workgroup/' + id);
        }

        function updateLeadSources(leadSources) {
            return httpResource.put('/workgroup/leadSources', leadSources);
        }

        function getLeadSources() {
            return httpResource.get('/workgroup/leadSources');
        }

        function updateOpportunityUnits(opportunityUnits) {
            return httpResource.put('/workgroup/opportunityUnits', opportunityUnits);
        }

        function getOpportunityUnits() {
            return httpResource.get('/workgroup/opportunityUnits');
        }


        function createWorkgroup(data, guid) {
            return httpResource.post('/workgroup?guid='+guid, data);
        }

        return {
            createWorkgroup: createWorkgroup,
            getMembers: getMembers,
            getWorkgroup: getWorkgroup,
            getWorkgroups: getWorkgroups,
            getWorkgroupMembers: getWorkgroupMembers,
            getLeadSources: getLeadSources,
            updateLeadSources: updateLeadSources,
            updateOpportunityUnits: updateOpportunityUnits,
            getOpportunityUnits: getOpportunityUnits
        };
    }
})();
