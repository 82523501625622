import * as React from 'react';
import { TodoDto } from '../../store/models/todo';
import TextField from '@material-ui/core/TextField';
import { Button, Box, DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import { ReactSelect } from '../shared/ReactSelect';
import { UserDto } from '../../store/models/user';
import Upload from '../shared/Upload';
import TagsPicker from '../shared/TagsPicker';
import DateTimePicker from '../shared/DateTimePicker';
import { WorkgroupState } from '../../store/models/workgroup';
import { AttachmentDto } from '../../store/models/attachment';
import { ITodoModalModel } from '../../store/uimodels/uitodo';
import { Moment } from 'moment';

export interface ITodoOwnProps {
    internalParticipantsIds?: number[];
    handleClose: () => void;
}
export interface ITodoPopupProps {
    todoIsAdding: boolean;
    workgroup: WorkgroupState;
    todoModel?: ITodoModalModel;
    loggedUserId: number;
}

export interface ITodoPopupActions {
    addTodoRequest: (todo: TodoDto, attachments: File[], loggedUserId: number) => void;
    updateTodoRequest: (todo: TodoDto, attachments: File[], toDelete: AttachmentDto[], loggedUserId: number) => void;
    listUserRequest: () => void;
    convertNoteTodoRequest: (data: ITodoModalModel) => void;
}

interface FileWrapper {
    file: File;
    fileName: string;
    attachment: AttachmentDto;
}

interface ITodoPopupState {
    todo: TodoDto;
    durationString: string;
    nameError: boolean;
    descriptionError: boolean;
    durationError: boolean;
    ownerError: boolean;
    tagOptions: string[];
    files: FileWrapper[];
    deletedAttachments: AttachmentDto[];
}

export default class TodoPopup extends React.PureComponent<ITodoPopupProps & ITodoPopupActions & ITodoOwnProps, ITodoPopupState>{
    constructor(props: ITodoPopupProps & ITodoPopupActions & ITodoOwnProps) {
        super(props);

        this.state = {
            todo: {
                duration: 15,
                createdAt: null,
                creatorId: 0,
                deadline: null,
                id: 0,
                description: '',
                name: '',
                ownerId: this.props.loggedUserId,
                koId: 0,
                tags: [],
                isSaving: false,
                attachments: []
            },
            durationString: '15',
            nameError: false,
            descriptionError: false,
            durationError: false,
            ownerError: false,
            tagOptions: [],
            files: [],
            deletedAttachments: []
        };

        let attachments:AttachmentDto[] = [];
        if (this.props.todoModel && this.props.todoModel.todo !== null) {
            attachments = this.props.todoModel.todo.attachments;
        }

        if (attachments === null || attachments === undefined) attachments = [];

        if (this.props.todoModel) {
            this.state = {
                ...this.state,
                durationString: this.props.todoModel.todo.duration.toString(),
                todo: this.props.todoModel.todo,
                files: attachments.map(x=>({file: null, fileName: x.fileName, attachment: x}))
            }
        }
    }

   
    componentDidMount() {
        this.props.listUserRequest();

        fetch('/api/tags/common', { headers: { "Content-Type": "application/json; charset=utf-8" } })
            .then((response) => {
                response.json().then(
                    (value) => {
                        this.setState({
                            tagOptions: value
                        });
                    }
                );
            });
    }

    getTags = (tags: string[]) => {
        let selectedTags = tags.map((x: string) => ({
            label: x,
            value: x
        }));

        return selectedTags;
    }
    
    onDeadlineChange = (date: Moment, value?: string) => {
        this.setState((prevState) => ({
            ...prevState,
            todo: {
                ...prevState.todo,
                deadline: value
            }
        }));     
    }

    onOwnerChange = (selectedOption: any) => {
        const newOwnerId = (selectedOption && selectedOption.value) || 0;
        this.setState((prevState) => ({
            ...prevState,
            ownerError: newOwnerId === 0,
            todo: {
                ...prevState.todo,
                ownerId: newOwnerId,
            }
        }));
    }

    onDurationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const valString = event.currentTarget.value.trim();
            if (valString.length !== 0) {
                const pattern = /^[1-9]\d*$/;
                const isValid = pattern.test(valString);
                if (!isValid) return;
                this.setState((prevState) => ({
                    ...prevState,
                    durationString: valString,
                    durationError: false
                }));
            }
            else {
                this.setState((prevState) => ({
                    ...prevState,
                    durationString: valString,
                    durationError: true
                }));
            }


        }
        catch { }
    }

    onTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = event.currentTarget;
        const errorName = `${name}Error`
        this.setState((prevState) => ({
            ...prevState,
            [errorName]: value.trim().length === 0,
            todo: {
                ...prevState.todo,
                [name]: value
            }
        }));
    }

    onTagsChange = (newValue: string[]) => {
        this.setState((prevState: ITodoPopupState) => ({
            ...prevState,
            todo: {
                ...prevState.todo,
                tags: newValue
            }
        }));
    }

    handleSaveClick = () => {

        if (!this.validate()) return;

        this.setState((prevState: ITodoPopupState) => ({
            ...prevState,
            todo: {
                ...prevState.todo,
                duration: +prevState.durationString,
            }
        }), () => {
            const isUpdate = this.state.todo.id > 0;

            if (!isUpdate) {
                if (this.props.todoModel && this.props.todoModel.convertedFromNoteId) {
                    this.props.convertNoteTodoRequest({
                        todo: this.state.todo,
                        convertedFromNoteId: this.props.todoModel.convertedFromNoteId
                    });
                } else {
                    this.props.addTodoRequest(this.state.todo, this.state.files.map(x=>x.file).filter(x=>x !== null), this.props.loggedUserId);
                }
            }
            else {
                this.props.updateTodoRequest(this.state.todo, this.state.files.map(x=>x.file).filter(x=>x !== null), this.state.deletedAttachments, this.props.loggedUserId);
            }

            this.props.handleClose();
        });


    }

    validate = (): boolean => {
        const nameError = this.state.todo.name.trim().length === 0;
        const durationError = this.state.durationString.trim().length === 0;
        const ownerError = this.state.todo.ownerId === 0;

        this.setState({
            nameError,
            durationError,
            ownerError
        });

        return !(nameError || durationError || ownerError);
    }

   onFilesAdded = (files: FileList) => {
    const prevState = this.state;

        this.setState(
            {
                ...prevState,
                files: prevState.files.concat(Array.from(files).map(x=>({fileName: x.name, file: x, attachment: null})))
            }
        )
    }

    deleteFile = (file: string) => {
        this.setState(
            {
                ...this.state,
                files: this.state.files.filter(item => item.fileName !== file),
                deletedAttachments: this.state.deletedAttachments.concat(this.state.files
                    .filter(item => item.fileName === file && item.attachment !== null) //filter positions that are already saved in spinme and match name
                    .map(x=>x.attachment) //return only attachments
                    )

            }
        )
    }

    render() {
        const buttonsDisabled = this.props.todoIsAdding || (this.props.todoModel && this.props.todoModel.todo.isSaving);

        let applicableUsers = this.props.workgroup.members;

        if (this.props.internalParticipantsIds &&
            this.props.internalParticipantsIds.length > 0) {
            applicableUsers =
                applicableUsers
                    .filter(x => this.props.internalParticipantsIds.indexOf(x.id) !== -1);
        }

        const userOptions = applicableUsers.map((elem: UserDto) => {
            return {
                label: `${elem.userName}`,
                value: elem.id.toString()
            }
        });

        // const ownerSelectStyles = {
        //     control: (provided: any, state: any) => ({
        //         ...provided,
        //         borderColor: this.state.ownerError ? 'red' : provided.color
        //     }),
        //     menuPortal: (base: any) => ({ ...base, zIndex: 9999 })
        // }

        let ownerSelectDefaultValue: { label: string, value: string };

        const owner = this.props.workgroup.members.find(x => x.id === this.state.todo.ownerId);
        if (owner)
            ownerSelectDefaultValue = { label: owner.userName, value: owner.id.toString() };

        return (
            <React.Fragment>
                <DialogTitle>
                    {this.props.todoModel ? 'Edit' : 'Create'} Todo
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <TextField
                            id="title-input"
                            label="Title"
                            margin="normal"
                            fullWidth={true}
                            autoFocus={true}
                            value={this.state.todo.name}
                            name="name"
                            onChange={this.onTextFieldChange}
                            required
                            error={this.state.nameError}
                        />
                    </Box>
                    <Box>
                        <TextField
                            id="description-input"
                            label="Description"
                            multiline
                            rows="4"
                            margin="normal"
                            variant="outlined"
                            fullWidth={true}
                            value={this.state.todo.description}
                            name="description"
                            onChange={this.onTextFieldChange}
                        />
                    </Box>
                    <Box>
                        <TextField
                            id="duration-input"
                            label="Duration"
                            fullWidth={true}
                            type="text"
                            onChange={this.onDurationChange}
                            value={this.state.durationString}
                            required
                            error={this.state.durationError}
                        >
                        </TextField>
                    </Box>
                    <Box my={2}>
                        <DateTimePicker
                            label="Deadline"
                            value={this.state.todo.deadline}
                            onChange={this.onDeadlineChange}
                            showTodayButton
                            fullWidth
                            clearable
                            disablePast
                            ampm={false}
                        />
                    </Box>
                    <Box my={2}>
                        <ReactSelect
                            placeholder='Select owner...'
                            label='Owner'
                            onChange={this.onOwnerChange}
                            options={userOptions}
                            value={ownerSelectDefaultValue}
                        />
                    </Box>
                    {/* <Box my={2}>
                        <TagsPicker 
                        onChange={this.onTagsChange} 
                        options={this.state.tagOptions} 
                        tags={this.state.todo.tags} />
                    </Box> */}
                    <Box>
                        <Upload files={this.state.files.map(x=>x.fileName)} onFilesAdded={this.onFilesAdded} deleteFile={this.deleteFile} />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" disabled={buttonsDisabled} onClick={this.props.handleClose}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" disabled={buttonsDisabled} onClick={this.handleSaveClick}>
                        Save
                    </Button>
                </DialogActions>
            </React.Fragment>
        );
    }
}