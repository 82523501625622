angular.module('meetingMinutes.main.knowledge.meeting',
    [
        'meetingMinutes.common',
        'meetingMinutes.authorization',
        'meetingMinutes.repository',
        'ui.bootstrap',
        'ui.router',
        'ui.select',
        'datePicker',
        'toastr',
        'ui.calendar',
        'xeditable'
    ]).run(['editableOptions','$rootScope','$location',function(editableOptions, $rootScope, $location) {
    editableOptions.theme = 'bs3';
    $rootScope.location = $location;
}]);