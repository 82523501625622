/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.knowledge.meeting')
        .factory('userParticipantsPopup', userParticipantsPopup);

    userParticipantsPopup.$inject = ['httpResource', '$uibModal', 'meetingResource', 'userResource'];

    function userParticipantsPopup(httpResource, $uibModal, meetingResource, userResource) {

        UserParticipantsPopupCtrl.$inject = ['$scope', '$uibModalInstance'];
        function UserParticipantsPopupCtrl($scope, $uibModalInstance) {

            userResource.getUsers().then(function(members) {
                $scope.members = members;
            });

            $scope.deleteMember = function(user) {
                meetingResource.deleteInternalParticipant($scope.meeting.id, user.id);
            }

            $scope.addMember = function(member) {
                $scope.meeting.internalParticipants.push(member);
                meetingResource.addInternalParticipant($scope.meeting.id, member.id).then(function(xhr) {

                });
            }

            $scope.ok = function() {
                $uibModalInstance.close();
            };

            $scope.cancel = function() {
                $uibModalInstance.dismiss('cancel');
            };
        }

        var open = function($scope, meeting) {
            $scope.meeting = meeting;

            var modalInstance = $uibModal.open({
                templateUrl: 'views/main/popups/user-participants.popup.tpl.html?v=' + new Date(),
                controller: UserParticipantsPopupCtrl,
                scope: $scope
            });
        }

        return {
            "open": open,
        };
    }
})();
