/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    function DispatchController($state, authenticationResource) {

        authenticationResource.getAuthentication()
            .then(transitionToDashboardState, transitionToWelcomeState);

        function transitionToDashboardState() {
            $state.go('main.meetings');
        }

        function transitionToWelcomeState() {
            $state.go('public.signIn');
        }

    }

    DispatchController.$inject = ['$state', 'authenticationResource'];

    angular.module('meetingMinutes.public')
        .controller('DispatchController', DispatchController);

})();
