/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    /**
     * Directive for run metsiMenu on sidebar navigation
     */
    function sideNavigationDirectiveFactory($timeout) {
        return {
            restrict: 'A',
            link: function(scope, element) {
                // Call the metsiMenu plugin and plug it to sidebar navigation
                element.metisMenu();

                // Colapse menu in mobile mode after click on element
                var menuElement = $('#side-menu a:not([href$="\\#"])');
                menuElement.click(function() {

                    if ($(window).width() < 769) {
                        $("body").toggleClass("show-sidebar");
                    }
                });
            }
        };
    }

    angular.module('meetingMinutes.directives.homer')
        .directive('sideNavigation', sideNavigationDirectiveFactory);

})();
