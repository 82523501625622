import { connect } from 'react-redux'
import Link, { ITodoPopupProps, ITodoOwnProps } from '../components/TodoPopup/TodoPopup'
import { addTodoRequest,updateTodoRequest, convertNoteTodoRequest } from '../store/models/todo';
import { AppState } from '../store/appstate';
import { AnyAction, Dispatch, bindActionCreators } from 'redux';
import { uiTodoCloseModal } from '../store/uimodels/uitodo';
import { listWorkgroupMembersRequest } from '../store/models/workgroup';

const mapStateToProps = (state: AppState, ownProps: ITodoOwnProps): ITodoPopupProps & ITodoOwnProps => {
  return {
    todoIsAdding: state.todos.isAdding,
    todoModel: state.ui.todos.todoModalModel,
    workgroup: state.workgroup,
    handleClose: ownProps.handleClose,
    loggedUserId: state.ui.currentUser.currentUserId
  };
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return bindActionCreators({ addTodoRequest, updateTodoRequest, listUserRequest: listWorkgroupMembersRequest, uiTodoCloseModal, convertNoteTodoRequest }, dispatch);
}

const TodoPopupLink = connect(
  mapStateToProps,
  mapDispatchToProps
)(Link)

export default TodoPopupLink