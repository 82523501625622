/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    routesConfig.$inject = ['$stateProvider', 'authorizationProvider'];

    function routesConfig($stateProvider, authorizationProvider) {
        $stateProvider
            .state('main.crm',
                {
                    url: '/crm',
                    templateUrl: 'views/main/crm/crm.tpl.html?v=' + new Date(),
                    controller: 'CrmController',
                    resolve: {
                        
                    },
                    data: {
                        pageTitle: 'CRM'
                    }
                }).state('main.opportunity/view',
                {
                    url: '/opportunity/view/:id',
                    templateUrl: 'views/main/crm/opportunity.tpl.html?v=' + new Date(),
                    controller: 'OpportunityController',
                    resolve: {
                        
                    },
                    data: {
                        pageTitle: 'CRM opportunity view'
                    }
                }).state('main.opportunity/edit',
                {
                    url: '/opportunity/edit/:id',
                    templateUrl: 'views/main/crm/opportunity.tpl.html?v=' + new Date(),
                    controller: 'OpportunityController',
                    resolve: {
                        
                    },
                    data: {
                        pageTitle: 'CRM opportunity edit'
                    }
                }).state('main.opportunity/create',
                {
                    url: '/opportunity/create',
                    templateUrl: 'views/main/crm/opportunity.tpl.html?v=' + new Date(),
                    controller: 'OpportunityController',
                    resolve: {
                        
                    },
                    data: {
                        pageTitle: 'CRM opportunity creation'
                    }
                });
    }

    angular.module('meetingMinutes.main.crm')
        .config(routesConfig);

})();
