import * as React from "react";
import { Dialog } from "@material-ui/core";
import { AppState } from "../../store/appstate";
import { bindActionCreators, Dispatch, AnyAction } from "redux";
import { connect } from "react-redux";
import { uiTimelineCloseModal } from "../../store/uimodels/uitimeline";
import { TimelinePopupLink } from "./TimelinePopup";

interface ITimelinePopupContainerProps {
    modalOpen: boolean;
    
}
interface ITimelinePopupContainerActions {
    uiTimelineCloseModal: () => void;
}

/// to consider: this class should somehow be generic and be the base class for all popups
const TimelinePopupContainer: React.FC<ITimelinePopupContainerProps> = (props: ITimelinePopupContainerProps & ITimelinePopupContainerActions) => {

    const handleClose = () => {
        props.uiTimelineCloseModal();
    }

    return (
        <Dialog
            open={props.modalOpen}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
        >
            <TimelinePopupLink handleClose={handleClose}></TimelinePopupLink>
        </Dialog>
    );
}

const mapStateToProps = (state: AppState): ITimelinePopupContainerProps => {
    return {
      modalOpen: state.ui.timeline.timelineModalOpen
    };
  }
  
  const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({ uiTimelineCloseModal, }, dispatch);
  }
  
  export const TimelinePopupContainerLink = connect(
    mapStateToProps,
    mapDispatchToProps
  )(TimelinePopupContainer)
  