/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.knowledge')
        .factory('meetingDurationPopup', meetingDurationPopup);

    meetingDurationPopup.$inject = ['attachmentsHelper', 'httpResource', '$uibModal', 'dateHelper'];

    function meetingDurationPopup(attachmentsHelper, httpResource, $uibModal, dateHelper) {

        PopupMeetingDurationCtrl.$inject = ['$scope', '$uibModalInstance'];
        function PopupMeetingDurationCtrl($scope, $uibModalInstance) {

            $scope.popup = {
                duration: 0
            };

            $scope.start.time;
            $scope.start.date;
            $scope.startDateEx;
            $scope.end.time;
            $scope.end.date;
            $scope.endDateEx;

            $scope.$watch(
                function() {
                    if ($scope.start.date)
                        return $scope.start.date;
                },
                function(newValue, oldValue) {
                    if ($scope.start)
                        $scope.startDateEx = dateHelper.enchance($scope.start.date);
                },
                true);

            $scope.$watch(
                function() {
                    if ($scope.end.date)
                        return $scope.end.date;
                },
                function(newValue, oldValue) {
                    if ($scope.end)
                        $scope.endDateEx = dateHelper.enchance($scope.end.date);
                },
                true);


            $scope.predictedTime = moment($scope.item.endTime);

            $scope.predictedTimeHM = $scope.predictedTime.format('HH:mm');


            $scope.addMinutes = function(mins) {
                $scope.predictedTime = moment($scope.item.endTime).add(mins * 60, 'seconds');
                $scope.predictedTimeHM = $scope.predictedTime.format('HH:mm');
            };

            $scope.$watch(
                function() {
                    return $scope.popup.duration;
                },
                function(newValue, oldValue) {
                    var duration = $scope.addMinutes($scope.popup.duration);
                },
                true);


            $scope.options = {
                skin: {
                    type: 'tron',
                    width: 2,
                    color: '#1ab394',
                    spaceWidth: 1
                },
                barColor: '#1ab394',
                trackWidth: 2,
                barWidth: 10,
                textColor: '#1ab394',
                step: 1,
                max: 120,
                size: 150
            };

            $scope.ok = function() {
                $scope.meetingCloseRequest.isUpdated = true;
                $scope.meetingCloseRequest.updateStartTime =
                    moment($scope.startDateEx.formatDate + " " + $scope.start.time);
                $scope.meetingCloseRequest.updateEndTime = moment($scope.endDateEx.formatDate + " " + $scope.end.time);
                $uibModalInstance.close();
            };

            $scope.cancel = function() {
                $scope.meetingCloseRequest.isUpdated = false;
                $uibModalInstance.close();
            };
        }

        var open = function($scope, item, callback) {
            if (item) {
                $scope.item = item;
            }

            $scope.callback = callback;

            var modalInstance = $uibModal.open({
                templateUrl: 'views/main/popups/meeting-duration.popup.tpl.html?v=' + new Date(),
                controller: PopupMeetingDurationCtrl,
                scope: $scope
            });
            var currentDateTime = new Date();
            $scope.start = {
                time: dateHelper.enchance(currentDateTime).hour_minutes,
                date: currentDateTime
            };
            $scope.end = {
                time: dateHelper.enchance(currentDateTime).hour_minutes,
                date: currentDateTime
            };
        };

        return {
            "open": open
        };
    }
})();
