angular.module('meetingMinutes.main.knowledge.note',
        [
            'meetingMinutes.common',
            'meetingMinutes.authorization',
            'meetingMinutes.repository',
            'ngSanitize',
            'ui.router',
            'ui.select',
            'blueimp.fileupload'
        ])
    .config([
        '$httpProvider', 'fileUploadProvider',
        function($httpProvider, fileUploadProvider) {
            /* delete $httpProvider.defaults.headers.common['X-Requested-With'];
             fileUploadProvider.defaults.redirect = window.location.href.replace(
               /\/[^\/]*$/,
               '/cors/result.html?%s'
             );*/
        }
    ]);