/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.knowledge.note')
        .config(routesConfig);

    routesConfig.$inject = ['$stateProvider', 'authorizationProvider'];

    function routesConfig($stateProvider, authorizationProvider) {
        $stateProvider
            .state('main.note/list',
                {
                    url: '/note/list',
                    templateUrl: 'views/main/knowledge/note/note-list.tpl.html?v=' + new Date(),
                    controller: 'NoteListController',
                    resolve: {
                        
                    },
                    data: {
                        pageTitle: 'Notes'
                    }
                }).state('main.note/view',
                {
                    url: '/note/view/:id',
                    templateUrl: 'views/main/knowledge/note/note.tpl.html?v=' + new Date(),
                    resolve: {
                        
                    },
                    controller: 'NoteController',
                    data: {
                        pageTitle: 'Note'
                    }
                }).state('main.note/edit',
                {
                    url: '/note/edit/:id',
                    templateUrl: 'views/main/knowledge/note/note.tpl.html?v=' + new Date(),
                    controller: 'NoteController',
                    resolve: {
                        
                    },
                    data: {
                        pageTitle: 'Note'
                    }
                }).state('main.note/create',
                {
                    url: '/note/create',
                    templateUrl: 'views/main/knowledge/note/note.tpl.html?v=' + new Date(),
                    controller: 'NoteController',
                    resolve: {
                        
                    },
                    data: {
                        pageTitle: 'Note'
                    }
                }).state('main.pinBoard',
                {
                    url: '/pin-board',
                    templateUrl: 'views/main/knowledge/note/pin-board.tpl.html?v=' + new Date(),
                    controller: 'PinBoardController',
                    resolve: {
                        
                    },
                    data: {
                        pageTitle: 'Notes'
                    }
                });
    }

})();
