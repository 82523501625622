/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function () {
    'use strict';

    angular.module('meetingMinutes.repository.remote')
        .factory('googleCalendarResource', googleCalendarResourceFactory);

    googleCalendarResourceFactory.$inject = ['httpResource'];

    function googleCalendarResourceFactory(httpResource) {

        function connectWithGoogleCalendar() {
            return httpResource.get('/calendar/connect');
        }

        function disconnectWithGoogleCalendar() {
            return httpResource.get('/calendar/disconnect');
        }

        function checkIfUserCalendarSync() {
            return httpResource.get('/calendar/synchronized');
        }

        function getCalendarsList() {
            return httpResource.get('/calendar/list');
        }

        function selectCalendar(calendar) {
            return httpResource.put('/calendar/select', calendar);
        }

        function createNewCalendar(calendar, guid) {
            return httpResource.post('/calendar/create?guid='+guid, calendar);
        }

        function synchronizeActivities(calendar) {
            return httpResource.put('/calendar/synchronize', calendar);
        }

        return {
            'connectWithGoogleCalendar': connectWithGoogleCalendar,
            'disconnectWithGoogleCalendar': disconnectWithGoogleCalendar,
            'selectCalendar': selectCalendar,
            'getCalendarsList': getCalendarsList,
            'createNewCalendar': createNewCalendar,
            'synchronizeActivities': synchronizeActivities,
            'checkIfUserCalendarSync': checkIfUserCalendarSync,
        }
    }
})();
