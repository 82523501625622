/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.repository.remote')
        .factory('attachmentsHelper', attachmentsHelper);

    attachmentsHelper.$inject = ['httpResource', 'knowledgeObjectResource'];

    function attachmentsHelper(httpResource, knowledgeObjectResource) {

        function setUrl(scope, koid) {
            scope.attachmentUploadUrl = '/api/knowledgeObject/' + koid + '/attachment';
        }

        function initialize(scope, afterUploadFunc) {
            scope.fileUploadOptions = {
                url: '',
                autoUpload: false,
                xhrFields: {
                    withCredentials: true
                }
            };

            scope.attachmentsOptions = {
                autoUpload: false,
                url: '',
                xhrFields: {
                    withCredentials: true
                }
            };

            scope.onAttachmentDelete = function(data) {
                knowledgeObjectResource.deleteAttachment(data.id).then(function() {
                    afterUploadFunc();
                });
            };

            scope.$on('fileuploaddone',
                function(event, data) {
                    if (data.paramName == 'profilePicture') {
                        obj.src = data.result;
                    }
                });

            scope.$on('fileuploadadd',
                function(e, data) {
                    if (data.paramName == 'attachments') {
                        scope.attachmentsOptions.url = scope.attachmentUploadUrl;
                        data.url = scope.attachmentUploadUrl;
                        data.submit().then(function() {
                            afterUploadFunc();
                        });
                    }
                });


        }


        return {
            "initialize": initialize,
            "setUrl": setUrl
        };
    }

})();
