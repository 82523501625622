/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;


(function () {
    'use strict';

    angular.module('meetingMinutes.main.dashboard')
        .controller('DashboardController', DashboardController);

    DashboardController.$inject = [
        '$rootScope', '$state', '$scope', 'workgroupResource',
        'dashboardResource', 'dateHelper', 'meetingTimePopup'
    ];

    function DashboardController(
                                 $rootScope,
                                 $state,
                                 $scope,
                                 workgroupResource,
                                 dashboardResource,
                                 dateHelper,
                                 meetingTimePopup) {

        $scope._today = {
            day: moment().format('D'),
            day_name: moment().format('dddd'),
            month: moment().format('MMMM')
        };

        $scope.currentWeek = moment().week();
        $scope.selectedWeek = moment().week();
        $scope.selectedYear = moment().year();
        $scope.currentDate = moment().format('YYYY-MM-DD');
        $scope.calendarCss = "icon";
        $scope.$context = $rootScope.$context.$set('/dashboard');
        $scope.tasks = [];
        $scope.filteredTasks = [];

        $scope.tag = [];
        $scope.tags = [];

        $scope.contextTags = [];

        $scope.filter = "";

        $scope.weekPlan = {};

        $scope.showTeamStatistics = false;


        $scope.todayMeetings = [];

        $scope.color = 'blue';
        $scope.ctx_selected_class = 0;

        $scope.totalUtilization =
            {
                data: [0, 0],
                options: {
                    fill: ["#1ab394", "#edf0f5"],
                    height: '50px',
                    width: '50px'
                }
            };

        $scope.formData = {
            task: {
                knowledgeObject: {
                    title: null,
                    descriptionHtml: null
                },
                priority: 1,
                status: 0
            },
            availableTags: []
        };

        $scope.customFilter = {
            user: null,
            tag: null,
            person: null,
        };

        $scope._today = {
            day: moment().format('D'),
            day_name: moment().format('dddd'),
            month: moment().format('MMMM')
        }

        $scope.$watch(
            function () {
                return $rootScope.contextTags;
            },
            function (newValue, oldValue) {
                getWeekPlan();
            },
            true);


        $scope.callback = function (newDate) {
            var momentDate = moment(newDate);
            $scope.selectedWeek = momentDate.week();
            $scope.selectedYear = momentDate.year();

            $scope._today = {
                day: moment(newDate).format('D'),
                day_name: moment(newDate).format('dddd'),
                month: moment(newDate).format('MMMM')
            };
            if($scope.currentWeek !== $scope.selectedWeek)
                $scope.calendarCss = "icon_grey";
            getWeekPlan();
        };

        $scope.selectDay = function () {
            meetingTimePopup.open($scope, null, $scope.callback);
        };


        var getWeekPlan = function () {
            dashboardResource.getWeekPlan($scope.selectedYear, $scope.selectedWeek, $rootScope.contextTags.tags).then(function (response) {
                for (var i = 0; i < response.weekPlan.length; i++) {
                    response.weekPlan[i].warnings = [];
                    response.weekPlan[i].dateTxt = moment(response.weekPlan[i].date).format('YYYY-MM-DD');

                    response.weekPlan[i].totalTasksTimeTxt =
                        dateHelper.friendlyDuration(response.weekPlan[i].totalTasksTime);
                    response.weekPlan[i].doneTasksTimeTxt =
                        dateHelper.friendlyDuration(response.weekPlan[i].doneTasksTime);
                    response.weekPlan[i].totalTasksRealTimeTxt =
                        dateHelper.friendlyDuration(response.weekPlan[i].totalTasksRealTime);
                    response.weekPlan[i].doneTasksRealTimeTxt =
                        dateHelper.friendlyDuration(response.weekPlan[i].doneTasksRealTime);

                    if (response.weekPlan[i].plannedTasksCost)
                        response.weekPlan[i].costEffectiveness = Math.round(
                            (response.weekPlan[i].doneTasksRealCost * 100) / response.weekPlan[i].plannedTasksCost);
                    if (response.weekPlan[i].plannedTasksMyCost)
                        response.weekPlan[i].myCostEffectiveness = Math.round(
                            (response.weekPlan[i].doneTasksMyRealCost * 100) / response.weekPlan[i].plannedTasksMyCost);

                    if (response.weekPlan[i].totalTasksTime)
                        response.weekPlan[i].timeEffectiveness = Math.round((response.weekPlan[i].doneTasksRealTime * 100) /
                            response.weekPlan[i].totalTasksTime);

                    if (response.weekPlan[i].tasks)
                        response.weekPlan[i].effectiveness =
                            Math.round((response.weekPlan[i].tasksDone * 100) / response.weekPlan[i].tasks);

                    var today = moment().format('YYYY-MM-DD');
                    var planDate = response.weekPlan[i].dateTxt;
                    if (response.weekPlan[i].doneTasksTime < response.weekPlan[i].totalTasksTime && planDate < today) {
                        response.weekPlan[i].warnings.push(
                            'There are many tasks undone this day - click to correct them');
                    }

                    response.weekPlan[i].utilization =
                        {
                            data: [0, 0],
                            options: {
                                fill: ["#1ab394", "#edf0f5"],
                                height: '16px',
                                width: '16px',
                            }
                        };

                    if(response.weekPlan[i].tags)
                        for (var j = 0; j < response.weekPlan[i].tags.length; j++) {
                            response.weekPlan[i].tags[j] = $rootScope.converted(response.weekPlan[i].tags[j]);
                        }

                    response.weekPlan[i].utilization.data[0] = response.weekPlan[i].totalTasksTime;
                    response.weekPlan[i].utilization.data[1] =
                        response.minutesAtWorkPerDay - response.weekPlan[i].totalTasksTime;

                    if ($scope._today.day_name != response.weekPlan[i].dayName) {
                        response.weekPlan[i].utilization.options.fill[0] = "#777";
                    }
                }
                $scope.weekPlan = response;

            });

        };

        $scope.viewMeeting = function (id) {
            $state.go('main.meetingDetails', {meetingId: id});
        };

        $scope.planNewMeeting = function () {
            $state.go('main.planMeeting', {meetingId: null});
        };

        $scope.addToContext = function (tag) {
            $rootScope.addToContext(tag);
        };

        $rootScope.$context.$set('/dashboard', $scope);

        workgroupResource.getMembers().then(function (members) {
            $scope.formData.members = members;
        });
    }
})();
