/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function () {
    'use strict';

    angular.module('meetingMinutes.repository.remote')
        .factory('dictionaries', dictionaries);

    function dictionaries() {

        function opportunity() {
            var statuses = [
                {id: 0, name: 'Lost'},
                {id: 1, name: 'Active'},
                {id: 2, name: 'Suspended'},
                {id: 3, name: 'Won'}
            ];

            function opportunityStatusToString(status) {
                if (status > -1) {
                    var names = statuses.filter(function (el) {
                        return el.id === status;
                    });
                    return names[0].name;
                }
            }

            function opportunityStatusToInt(status) {
                var ids = statuses.filter(function (el) {
                    return el.name === status;
                });
                return ids[0].id;
            }

            return {
                'opportunityStatusToString': opportunityStatusToString,
                'opportunityStatusToInt': opportunityStatusToInt,
                'statuses': statuses
            }
        }

        function meeting() {
            var statuses = [];
        }

        function calendar() {
            var months = [
                {name: 'January', id: 1},
                {name: 'February', id: 2},
                {name: 'March', id: 3},
                {name: 'April', id: 4},
                {name: 'May', id: 5},
                {name: 'June', id: 6},
                {name: 'July', id: 7},
                {name: 'August', id: 8},
                {name: 'September', id: 9},
                {name: 'October', id: 10},
                {name: 'November', id: 11},
                {name: 'December', id: 12}
            ];

            var quarters = [
                {
                    name: 'Q1',
                    months:
                        [
                            {name: 'January', id: 1},
                            {name: 'February', id: 2},
                            {name: 'March', id: 3}
                        ]
                },
                {
                    name: 'Q2',
                    months:
                        [
                            {name: 'April', id: 4},
                            {name: 'May', id: 5},
                            {name: 'June', id: 6}
                        ]
                },
                {
                    name: 'Q3',
                    months:
                        [
                            {name: 'July', id: 7},
                            {name: 'August', id: 8},
                            {name: 'September', id: 9}
                        ]
                },
                {
                    name: 'Q4',
                    months:
                        [
                            {name: 'October', id: 10},
                            {name: 'November', id: 11},
                            {name: 'December', id: 12}
                        ]
                }
            ];

            return {
                'months': months,
                'quarters': quarters
            }
        }

        function lead() {
            var statuses = [
                {id: 1, value: 'Active'},
                {id: 2, value: 'Inactive'},
                {id: 3, value: 'Closed'},
                {id: 4, value: 'Converted'},
                {id: 5, value: 'On Hold'}
            ];

            // var sources = [
            //     'Marketing',
            //     'www',
            //     'Konferencja/Event',
            //     'Cold calling',
            //     'Kontakty zarząd',
            //     'Istniejący klient',
            //     'Business development',
            //     'Formularz',
            //     'FB Lead Ads',
            //     'LinkedIn Lead Ads',
            //     'Baza własna',
            //     'Right hello'
            // ];

            function leadStatusToString(statusId) {
                var statusObject = statuses.filter(function (status) {
                    return status.id === statusId;
                })[0];
                if (statusObject)
                    return statusObject.value;
                else return null;
            }

            return {
                'statuses': statuses,
                // 'sources': sources,
                'leadStatusToString': leadStatusToString
            }
        }

        function google(){
            var synchronizationStatus = [
                {id: 0, status: "None"},
                {id: 1, status: "Connected"},
                {id: 2, status: "Synchronized"}
            ];

            return{
                'synchronizationStatus': synchronizationStatus
            }
        }

        return {
            'meeting': meeting,
            'opportunity': opportunity,
            'calendar': calendar,
            'lead': lead,
            'google': google
        };
    }

})();
