import { FSAWithPayload, FSA } from "flux-standard-action";
import { TodoDto } from "../models/todo";

export interface ITodoModalModel {
    todo: TodoDto;
    convertedFromNoteId?: number;
}

export interface TodoUiState {
    todoModalOpen: boolean;
    todoModalModel: ITodoModalModel;
}

export const UI_TODO_OPEN_MODAL = 'spinme/ui/todo/OPEN_MODAL';
export const uiTodoOpenModal = (model: ITodoModalModel = null): FSAWithPayload<string, ITodoModalModel> => {
    return {
        type: UI_TODO_OPEN_MODAL,
        payload: model
    }
}

export const UI_TODO_CLOSE_MODAL = 'spinme/ui/todo/CLOSE_MODAL';
export const uiTodoCloseModal = (): FSA => {
    return {
        type: UI_TODO_CLOSE_MODAL
    }
}

export const INTIAL_TODO_UI_STATE: TodoUiState = {
    todoModalOpen: false,
    todoModalModel: null
}

export function uiTodoReducers(state: TodoUiState = INTIAL_TODO_UI_STATE, action: FSAWithPayload<string, ITodoModalModel | number>): TodoUiState {
    switch (action.type) {
        case UI_TODO_OPEN_MODAL:
            const model = action.payload as ITodoModalModel;
            return {
                ...state,
                todoModalModel: model,
                todoModalOpen: true
            }
        case UI_TODO_CLOSE_MODAL:
                return {
                        ...state,
                        todoModalModel: null,
                        todoModalOpen: false
                }
        default: 
            return state;
    }
}