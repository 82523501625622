/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.repository.remote')
        .factory('tokenResource', remoteTokenResourceFactory);

        remoteTokenResourceFactory.$inject = ['httpResource'];

    function remoteTokenResourceFactory(httpResource) {
        function listTokenClients() {
            return httpResource.get('/token/client/list');
        }

        function listRefreshTokens() {
            return httpResource.get('/token/list');
        }

        function saveTokenClient(dto) {
            return httpResource.post('/token/client/add', dto);
        }

        function updateTokenClient(dto) {
            return httpResource.post('/token/client/update', dto);
        }

        function deleteTokenClient(id) {
            return httpResource.post('/token/client/remove?tokenClientId=' + id);
        }

        function deleteRefeshToken(id) {
            var tokenId = window.encodeURIComponent(id);
            return httpResource.post('/token/remove?tokenId=' + tokenId);
        }

        function removeTokenCurrentUser() {
            return httpResource.post('/token/remove/user');
        }


        return {
            listTokenClients: listTokenClients,
            saveTokenClient: saveTokenClient,
            updateTokenClient: updateTokenClient,
            deleteTokenClient: deleteTokenClient,
            listRefreshTokens: listRefreshTokens,
            deleteRefeshToken: deleteRefeshToken,
            removeTokenCurrentUser: removeTokenCurrentUser
        };
    }

})();
