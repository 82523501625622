import { createStore, Store, applyMiddleware, compose } from 'redux'
import appReducers from './reducers';
import createSagaMiddleware from 'redux-saga'
import rootSaga from './sagas';
import { uiCurrentUserSet } from './uimodels/uicurrentuser';

let store: Store

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

export function getStore(currentUserId: number): Store {
    if (store === undefined) {
        if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === undefined) {
            const sagaMiddleware = createSagaMiddleware();

            store = createStore(
                appReducers, applyMiddleware(sagaMiddleware)
            );

            sagaMiddleware.run(rootSaga);
        } else {
            const sagaMiddleware = createSagaMiddleware();
            const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

            store = createStore(
                appReducers, composeEnhancers(
                    applyMiddleware(sagaMiddleware)
                )
            );

            sagaMiddleware.run(rootSaga);
        }

        store.dispatch(uiCurrentUserSet(currentUserId));
    }

    return store;
};