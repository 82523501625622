/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.knowledge')
        .factory('meetingTimePopup', meetingTimePopup);

    meetingTimePopup.$inject = [
        '$compile', 'dateHelper', 'httpResource', '$uibModal', 'meetingResource', 'taskResource', 'uiCalendarConfig'
    ];

    function meetingTimePopup($compile,
        dateHelper,
        httpResource,
        $uibModal,
        meetingResource,
        taskResource,
        uiCalendarConfig) {

        PopupMeetingTimeCtrl.$inject = ['$scope', '$uibModalInstance'];
        function PopupMeetingTimeCtrl($scope, $uibModalInstance) {
            $scope.$uibModalInstance = $uibModalInstance;

            $scope.ok = function() {
                meetingResource.endTime($scope.meeting).then(function(xhr) {
                    $scope.callback();
                });

                $scope.$uibModalInstance.close();
            };

            $scope.cancel = function() {
                $scope.$uibModalInstance.dismiss('cancel');
            };

            if ($scope.item) {
                $scope.meeting = $scope.item;
            }

            var isFirstTime = true;

            $scope.events = [];
            $scope.eventSources = [$scope.events];

            var date = new Date();
            var d = date.getDate();
            var m = date.getMonth();
            var y = date.getFullYear();
            var cal;

            $scope.loadSummary = function(intervalStart) {
                var date = new Date(intervalStart.local());
                var d = date.getDate();
                var m = date.getMonth();
                var y = date.getFullYear();
                $scope.events = [];
                taskResource.monthSummary(y, m + 1).then(function(xhr) {

                    angular.forEach(xhr,
                        function(value) {
                            var exists = false;
                            angular.forEach($scope.events,
                                function(it) {
                                    if (it.title === (value.title + " ( " + value.plannedDuration + " mins)")) {
                                        exists = true;
                                    }
                                });

                            if (!exists) {
                                $scope.events.push({
                                    title: value.title + " ( " + value.plannedDuration + " mins)",
                                    description: "",
                                    start: new Date(value.year, value.month - 1, value.day),
                                    end: new Date(value.year, value.month - 1, value.day),
                                    allDay: true,
                                    stick: false,
                                    backgroundColor: value.status === 2 ? '#c2c2d6' : '#1ab394',
                                    borderColor: value.status === 2 ? '#c2c2d6' : '#1ab394'
                                });
                            }
                        });
                    cal.renderEvents($scope.events, false);
                });
            };

            $scope.uiConfig = {
                allDaySlot: false,
                calendar: {
                    rendering: 'background',
                    height: 450,
                    editable: true,
                    weekNumberCalculation: 'ISO',
                    header: {
                        left: 'prev,next',
                        center: 'title'
                    },
                    viewRender: function(view, element) {
                        $scope.loadSummary(view.intervalStart);
                    },

                    dayClick: function(date, jsEvent, view) {
                        $scope.callback(date);
                        $scope.cancel();
                    },

                    eventAfterAllRender: function(view) {
                        cal = view.calendar;
                        if (isFirstTime === true) {
                            isFirstTime = false;
                            // $scope.loadSummary(view.intervalStart);
                        }
                    },
                    eventRender: function(event, element) {
                        {
                            //element.attr('class', '');
                            //element[0].innerHTML = "<span class='badge badge-success'>" + event.title + "</span>";
                            //$compile(element)($scope);
                        }
                    }
                }
            };


        }

        var open = function($scope, item, callback) {

            $scope.callback = callback;

            var modalInstance = $uibModal.open({
                templateUrl: 'views/main/popups/meeting-time.popup.tpl.html?v=' + new Date(),
                controller: PopupMeetingTimeCtrl,
                scope: $scope
            });
        }

        return {
            "open": open
        };
    }
})();
