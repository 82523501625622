/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.knowledge')
        .controller('KnowledgeController', KnowledgeController);

    KnowledgeController.$inject = ['$rootScope', '$scope', '$location'];

    function KnowledgeController($rootScope, $scope, $location) {
        $rootScope.$context.$set('/note/list', $scope);

        $scope.go = function(path) {
            $location.path(path);
        }
    }

})();
