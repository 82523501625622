/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.draft')
        .config(routesConfig);

    routesConfig.$inject = ['$stateProvider', 'authorizationProvider'];

    function routesConfig($stateProvider, authorizationProvider) {
        $stateProvider
            .state('main.draftMeetings',
                {
                    url: '/draft/meetings',
                    templateUrl: 'views/main/draft/meetings.tpl.html',
                    controller: 'DraftMeetingsController',
                    resolve: {
                        
                    },
                    data: {
                        pageTitle: 'Draft | Meetings'
                    }
                })
            .state('main.draftNotes',
                {
                    url: '/draft/notes',
                    templateUrl: 'views/main/draft/notes.tpl.html',
                    controller: 'DraftNotesController',
                    resolve: {
                        
                    },
                    data: {
                        pageTitle: 'Draft | Notes'
                    }
                });
    }

})();
