/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.workgroup')
        .controller('WorkgroupListController', WorkgroupListController);

    WorkgroupListController.$inject = [
        '$scope', '$rootScope', '$location', '$state', '$stateParams', 'userResource', 'workgroupResource', 'dateHelper'
    ];

    function WorkgroupListController($scope,
        $rootScope,
        $location,
        $state,
        $stateParams,
        userResource,
        workgroupResource,
        dateHelper) {

        $scope.workgroup = {};
        $scope.workgroups = [];
        $scope.filteredWorkgroups = [];

        $scope.rowsPerPage = 20;

        activate();
        $scope.filter = "";

        function activate() {
            workgroupResource.getWorkgroups().then(function(xhr) {
                angular.forEach(xhr,
                    function(workgroup) {
                        workgroup.createTimeEx = dateHelper.enchance(workgroup.createTime);
                        workgroup.idx = " ";
                        if (workgroup.idx && workgroup.workgroupName) {
                            workgroup.idx = workgroup.workgroupName.toLowerCase();
                        }
                        if (workgroup.owner && workgroup.owner.userName && workgroup.owner.userEmail) {
                            workgroup.idx =
                                workgroup.idx +
                                workgroup.owner.userName.toLowerCase() +
                                " " +
                                workgroup.owner.userEmail.toLowerCase();
                        }
                    });

                $scope.workgroups = xhr;

                $scope.filterWorkgroups();
            });

            $rootScope.$context.$set('/workgroup', $scope);
        }


        $scope.filterWorkgroups = function() {
            $scope.filteredWorkgroups = [];

            for (var i = 0; i < $scope.workgroups.length; i++) {
                if ($scope.filter == '' || $scope.workgroups[i].idx.indexOf($scope.filter.toLowerCase()) >= 0) {
                    $scope.filteredWorkgroups.push($scope.workgroups[i]);
                }
            }
        };

        $scope.viewWorkgroup = function(_workgroup) {
            $state.go('main.profile/otherAccount', { id: _workgroup.id });
            $scope.workgroup = _workgroup;
        };

        $scope.viewUser = function(_user) {
            $location.path('/user/edit/' + _user.id);
        };

        function withProgressIndicator(promise) {
            $scope.loadInProgressMessage = 'Loading...';
            promise.finally(function() {
                $scope.loadInProgressMessage = null;
            });
            return promise;
        }

    }
})();
