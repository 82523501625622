/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.repository.remote')
        .factory('minutesTemplateResource', remoteMinutesTemplateResourceFactory);

    remoteMinutesTemplateResourceFactory.$inject = ['httpResource'];

    function remoteMinutesTemplateResourceFactory(httpResource) {

        function post(request) {
            return httpResource.post('/minutes/template', request);
        }

        function put(request) {
            return httpResource.put('/minutes/template', request);
        }

        function get() {
            return httpResource.get('/minutes/templates');
        }

        function $delete(id) {
            return httpResource.delete('/minutes/template/' + id);
        }

        return {
            "post": post,
            "put": put,
            "get": get,
            "delete": $delete,
        };
    }

})();
