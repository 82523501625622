/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.repository.remote')
        .factory('attachmentsPopup', attachmentsPopup);

    function attachmentsPopup() {

        function x() {

        }

        return {
            "x": x
        };
    }
})();
