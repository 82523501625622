/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.repository.remote')
        .factory('knowledgeObjectResource', remoteKnowledgeObjectResourceFactory);

    remoteKnowledgeObjectResourceFactory.$inject = ['httpResource', '$rootScope', 'authenticationResource'];

    function remoteKnowledgeObjectResourceFactory(httpResource, $rootScope, authenticationResource) {

        function knowledgeObjectDefinition(objectType) {
//            authenticationResource.getAuthentication().then(data => {
//                for (var i = 0; i < data.knowledgeObjects.length; i++) {
//                    if ($rootScope.authentication.knowledgeObjects[i].Id == objectType)
//                        return $rootScope.authentication.knowledgeObjects[i];
//                }
//
//                return null;
//
//            });
        }

        function knowledgeObjectCustomedDefinition(knowledgeObject, backUrl) {

            var objectType = knowledgeObject.objectType;

            var definition = knowledgeObjectDefinition(objectType);

            var result = {};

            if (definition) {

                if (definition.Label == "Meeting") {
                    result.link = "#/meeting/view/" + knowledgeObject.dto.id;
                }

                if (definition.Label == "Agenda") {
                    result.link = "#/meeting/view/" + knowledgeObject.dto.meetingId;
                }

                if (definition.Label == "Custom") {
                    result.link = "#/note/view/" + knowledgeObject.dto.id;
                }

                if (definition.Label == "Person") {
                    result.link = "#/person/view/" + knowledgeObject.dto.id + "?backUrl=" + backUrl;
                }

                if (definition.Label == "Opportunity") {
                    result.link = "#/opportunity/view/" + knowledgeObject.dto.id + "?backUrl=" + backUrl;
                }

                if (definition.Label == "Timeline notice") {
                    result.link = "#/meeting/minutes/" + knowledgeObject.dto.meetingId;
                }

                if (definition.Label == "Emotion") {
                    result.link = "#/meeting/minutes/" + knowledgeObject.dto.meetingId;
                }

                if (definition.Label == "ActionItem") {
                    result.link = "#/meeting/minutes/" + knowledgeObject.dto.meetingId;
                }

                if (definition.Label == "Assignment") {
                    result.link = "#/meeting/minutes/" + knowledgeObject.dto.meetingId;
                }
            }
            return result;
        }


        function get(id) {
            return httpResource.get('/knowledgeObject/' + id);
        }

        function publish(id) {
            return httpResource.patch('/knowledgeObject/' + id + '/published');
        }

        function draft(id) {
            return httpResource.patch('/knowledgeObject/' + id + '/draft');
        }

        function deleteAttachment(id) {
            return httpResource.delete('/knowledgeObject/attachment/' + id);
        }

        function translateKnowledgeObjectStatus(type) {
            if (type == 0)
                return "Draft";
            if (type == 1)
                return "Published";
        }

        function translatePermissionType(type) {
            if (type == 0)
                return "Owner";
            if (type == 1)
                return "Contributor";
            if (type == 2)
                return "Reader";
            return type;
        }

        function translateKnowledgeObjectType(type) {
            if (type == 0)
                return "User";
            if (type == 1)
                return "Can modify";
            if (type == 2)
                return "Can only read";
            return type;
        }


        function getTags() {
            
            return httpResource.get('/tags/common');
        }

        function getTagsByType(id) {
            return httpResource.get('/tags/type/' + id);
        }


        function getAvailableTags_Index() {
            return httpResource.get('/tags/index');
        }

        function getAvailableTags_Profile() {
            return httpResource.get('/tags/profile');
        }

        function getAvailableTags_Organization() {
            
            return httpResource.get('/tags/organization');
        }

        function getAvailableTags_Person() {
            
            return httpResource.get('/tags/person');
        }

        function getTags_PersonEx() {
            return httpResource.get('/tags/person/extended');
        }

        function getTags_UserEx() {
            return httpResource.get('/tags/user/extended');
        }

        function translateTag(tag) {
            return httpResource.get('/tag/translate?tag=' + tag);
        }


        function getAvailableTags_Meeting() {
            return httpResource.get('/tags/meeting');
        }

        function getAttachments(id) {
            return httpResource.get('/knowledgeObject/' + id + '/attachments');
        }

        function getAvailableTags_Communication() {
            
            return httpResource.get('/tags/communication');
        }

        function getPermissions(id) {
            return httpResource.get('/permissions/' + id);
        }

        function addPermission(request) {
            return httpResource.post('/permission', request);
        }

        function updatePermission(request) {
            return httpResource.put('/permission', request);
        }

        function removePermission(koId, userId) {
            return httpResource.delete('/permission?userId=' + userId + '&koId=' + koId);
        }

        function deleteKnowledgeObject(koId) {
            return httpResource.delete('/notes/knowledgeObject/' + koId);
        }

        function transferAttachments(sourceKoId, targetKoId) {
            return httpResource.get(`/knowledgeObject/attachment/${sourceKoId}/transfer/${targetKoId}`);
        }

        function linkedInSearch(phrase) {
            return httpResource.get('/linkedin/companies/search?phrase=' + phrase);
        }

        return {
            knowledgeObjectDefinition: knowledgeObjectDefinition,
            knowledgeObjectCustomedDefinition: knowledgeObjectCustomedDefinition,
            linkedInSearch: linkedInSearch,
            get: get,
            translateTag: translateTag,
            getTags: getTags,
            getTags_UserEx: getTags_UserEx,
            getTags_PersonEx: getTags_PersonEx,
            getAvailableTags_Profile: getAvailableTags_Profile,
            getAvailableTags_Organization: getAvailableTags_Organization,
            getAvailableTags_Person: getAvailableTags_Person,
            getAvailableTags_Communication: getAvailableTags_Communication,
            getAvailableTags_Meeting: getAvailableTags_Meeting,
            getAvailableTags_Index: getAvailableTags_Index,
            deleteAttachment: deleteAttachment,
            translateKnowledgeObjectStatus: translateKnowledgeObjectStatus,
            translateKnowledgeObjectType: translateKnowledgeObjectType,
            translatePermissionType: translatePermissionType,
            publish: publish,
            draft: draft,
            deleteKnowledgeObject: deleteKnowledgeObject,
            transferAttachments: transferAttachments,
            addPermission: addPermission,
            updatePermission: updatePermission,
            removePermission: removePermission,
            getPermissions: getPermissions,
            getTagsByType: getTagsByType,
            getAttachments: getAttachments
        };
    }

})();
