/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular
        .module('meetingMinutes.directives.domain')
        .directive('mmAttachments', attachmentsDirectiveFactory)
        .directive('textAngular', ['$parse', '$timeout', 'textAngularManager', textAngular]);




    function textAngular($parse, $timeout, textAngularManager) {
        return {
            link: function(scope, element, attributes) {
                var shouldFocus = attributes.focus;

                if (!shouldFocus) return;

                $timeout(function() {
                        var editorScope = textAngularManager.retrieveEditor(attributes.name).scope;
                        editorScope.displayElements.text.trigger('focus');
                    },
                    0,
                    false);
            }
        };
    };


    function attachmentsDirectiveFactory() {
        return {
            restrict: 'E',
            templateUrl: 'views/directives/domain/attachments.tpl.html?v=' + new Date(),
            replace: true,
            scope: {
                mmAttachments: '=',
                mmClass: '&',
                mmAttachmentsOptions: '=',
                mmOnFileUpload: '&',
                mmOnFileDelete: '='
            },
            link: link
        };

        function link(scope, element, attrs) {

            scope.$fileUploadOptions = {
                autoUpload: false,
                url: scope.mmAttachmentsOptions.url,
                xhrFields: {
                    withCredentials: true
                }
            };

            scope.$on('fileuploaddone',
                function(event, data) {
                    scope.mmOnFileUpload({ data: data });
                });

            scope.$onAttachmentDelete = function(attachmentRef) {
                //scope.mmOnFileDelete(attachmentRef);
                scope.mmOnFileDelete(attachmentRef);
            }

        }

    }

})();
