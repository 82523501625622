/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {

    angular.module('meetingMinutes.directives.homer')
        .directive('pageScroll', pageScrollDirectiveFactory);

    function pageScrollDirectiveFactory() {
        return {
            restrict: 'A',
            link: function(scope, element, attrs) {
                element.on('click',
                    function(event) {
                        $('html, body').stop().animate({
                                scrollTop: $(attrs.href).offset().top - 50
                            },
                            500);
                        event.preventDefault();
                    });
            }
        }
    }

})();
