/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {

    angular.module('meetingMinutes.directives.homer')
        .directive('scrollspyOn', scrollspyOnDirectiveFactory);

    function scrollspyOnDirectiveFactory() {
        return {
            restrict: 'A',
            link: function(scope, element, attrs) {
                element.scrollspy({
                    target: attrs.scrollspyOn,
                    offset: 80
                });
            }
        }
    }

})();
