/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    routesConfig.$inject = ['$stateProvider', 'authorizationProvider'];

    function routesConfig($stateProvider, authorizationProvider) {
        $stateProvider
            .state('main',
                {
                    abstract: true,
                    templateUrl: 'views/layout/main/content.tpl.html?v=' + new Date(),
                    controller: 'MainController',
                    resolve: {
                        authentication2: authorizationProvider.isAuthenticated
                    }
                });
    }

    angular.module('meetingMinutes.main')
        .config(routesConfig);
})();
