/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'using strict';

    angular.module('meetingMinutes.public')
        .controller('SignInController', SignInController);

    SignInController.$inject = ['$scope', '$location', '$state', '$stateParams', 'authenticationResource'];

    function SignInController($scope, $location, $state, $stateParams, authenticationResource) {
        $scope.formData = {
            submitButtonLabel: 'Sign in',
            submitInProgress: false
        };
        $scope.authenticationFailedMessage = null;

        $scope.$stateParams = $stateParams;

        $scope.submitForm = function() {
            if ($scope.signInForm.$valid) {
                submitInProgressOn();

                var params = $location.search();

                authenticationResource.signIn({
                        username: $scope.formData.authentication.username,
                        password: $scope.formData.authentication.password,
                        appId: params.appId
                    })
                    .then(onAuthenticationSuccess, onAuthenticationFailure)
                    .then(submitInProgressOff);
            }
        };

        $scope.goToSignUp = function() {
            $state.go('public.signUp', {
                appId: $stateParams.appId,
                returnUrl: decodeURIComponent($stateParams.returnUrl),
                forwardUrl: decodeURIComponent($stateParams.forwardUrl)
            });
        };

        function submitInProgressOn() {
            $scope.formData.submitButtonLabel = 'Signing in...';
            $scope.formData.submitInProgress = true;
        }

        function submitInProgressOff() {
            $scope.formData.submitButtonLabel = 'Sign in';
            $scope.formData.submitInProgress = false;
        }

        function onAuthenticationSuccess(data) {
            if ($stateParams.returnUrl !== undefined && data.token !== undefined) {
                var location = decodeURIComponent($stateParams.returnUrl) + "?token=" + data.token;

                if ($stateParams.forwardUrl !== undefined){
                    location += "&returnUrl="+decodeURIComponent($stateParams.forwardUrl);
                }

                window.location.href = location;
            }
            else {
                $scope.authenticationFailedMessage = null;
                $state.go('public.dispatch');
            }
        }



        function onAuthenticationFailure(clientError) {
            $scope.formData.authenticationFailedMessage = clientError.message;
            $scope.signInForm.$submitted = false;
            $scope.formData.authentication = {};
        }
    }

})();
