/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.knowledge')
        .factory('agendaItemPopup', agendaItemPopup);

    agendaItemPopup.$inject = ['attachmentsHelper', '$uibModal', 'meetingResource'];

    function agendaItemPopup(attachmentsHelper, $uibModal, meetingResource) {
        AgendaItemPopupCtrl.$inject = ['$scope', '$uibModalInstance'];
        function AgendaItemPopupCtrl($scope, $uibModalInstance) {

            attachmentsHelper.initialize($scope, null);
            $scope.selectedItem;
            $scope.selectedText;

            $scope.addMinutes = function(minutes) {
                $scope.item.duration = $scope.item.duration + minutes;
            }

            $scope.options = {
                skin: {
                    type: 'tron',
                    width: 2,
                    color: '#1ab394',
                    spaceWidth: 1
                },
                barColor: '#1ab394',
                trackWidth: 2,
                barWidth: 5,
                textColor: '#1ab394',
                step: 1,
                max: 480,
                size: 100
            };

            if ($scope.item) {
                attachmentsHelper.setUrl($scope, $scope.item.knowledgeObject.id);
            } else {
                meetingResource.saveAgendaItem($scope.meetingId, {}).then(function(xhr) {
                    $scope.deleteVisible = xhr.knowledgeObject.status != 0;
                    $scope.item = xhr;
                    $scope.item.duration = 0;

                });
            };

            $scope.selectPresenter = function(presenter) {
                $scope.item.presenterId = presenter.id;
                $scope.item.presenterName = presenter.knowledgeObject.title;
            }

            $scope.ok = function() {
                meetingResource.saveAgendaItem($scope.meetingId, $scope.item).then(function(xhr) {
                    $scope.item = xhr;

                    if ($scope.agendaItemPopup.mode == 'add')
                        $scope.meeting.agendaItems.push(xhr);
                    attachmentsHelper.setUrl($scope, xhr.knowledgeObject.id);
                    $scope.agendaItemPopup = null;
                    $uibModalInstance.dismiss('ok');
                });
            };

            $scope.delete = function() {
                meetingResource.deleteAgendaItem($scope.item.id).then(function(xhr) {
                    $scope.meeting.agendaItems = xhr;
                    $scope.cancel();
                });
            };

            $scope.cancel = function() {
                $scope.agendaItemPopup = null;
                $uibModalInstance.dismiss('cancel');
            };

            $scope.deleteVisible = false;
        }

        var open = function($scope, meeting, item, callback) {
            $scope.agendaItemPopup = {};
            if (item) {
                $scope.item = item;
                $scope.agendaItemPopup.mode = 'edit';
                $scope.deleteVisible = true;

            } else {
                $scope.item = null;
                $scope.agendaItemPopup.mode = 'add';
                $scope.deleteVisible = false;
            }

            $scope.agendaItemPopup.callback = callback;
            $scope.meetingId = meeting.id;
            $scope.meeting = meeting;

            var modalInstance = $uibModal.open({
                templateUrl: 'views/main/popups/agenda-item.popup.tpl.html',
                controller: AgendaItemPopupCtrl,
                scope: $scope
            });
        }

        return {
            "open": open
        };
    }
})();
