import * as React from 'react';
import Button from '../shared/Button';
import { TodoDto } from '../../store/models/todo';

export interface TodoViewProps { 
    item: TodoDto;
    onEdit(item: TodoDto): void;
    onDone(item: TodoDto): void;
    onInstant(item: TodoDto): void;
}

export default class TodoView extends React.PureComponent<TodoViewProps, {}> {
    render() {
        return (
<div className="container-fluid">
    <div className="row" style={{marginBottom: '30px'}}>
        <div className="col-md-2">
            <i className="fa fa-2x fa-check-circle text-danger"></i>
        </div>
        <div className="col-md-10">
            <div>{this.props.item.name}</div>
            <br/>
            <span>({this.props.item.duration} mins)</span>
        </div>
    </div>
    <div className="row">
        <div className="col-md-4">
            <Button text='Done' onClick={()=>this.props.onDone(this.props.item)}/>
        </div>
        <div className="col-md-8 text-right">
            <Button text='Create Instant' onClick={()=>this.props.onInstant(this.props.item)}/>
            <Button text='Edit' onClick={()=>this.props.onEdit(this.props.item)}/>
        </div>
    </div>
    <hr/>
</div>
    );
    }
}