/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.knowledge.meeting')
        .config(routesConfig);

    routesConfig.$inject = ['$stateProvider', 'authorizationProvider'];

    function routesConfig($stateProvider, authorizationProvider) {
        $stateProvider
            .state('main.meetings',
                {
                    url: '/meetings',
                    templateUrl: 'views/main/meeting/meetings.tpl.html?v=' + new Date(),
                    controller: 'MeetingsController',
                    resolve: {

                    },
                    data: {
                        pageTitle: 'Meetings'
                    }
                })
            .state('main.planMeeting',
                {
                    url: '/meeting/plan/:meetingId',
                    templateUrl: 'views/main/meeting/plan-meeting-wizard.tpl.html?v=' + new Date(),
                    controller: 'PlanMeetingWizardController',
                    resolve: {
                        
                    }
                }).state('main.minutes',
                {
                    url: '/meeting/minutes/:meetingId',
                    templateUrl: 'views/main/meeting/minutes.tpl.html?v=' + new Date(),
                    controller: 'MinutesController',
                    resolve: {
                        
                    },
                    data: {
                        pageTitle: 'SpinMe'
                    }
                })
            .state('main.meetingDetails',
                {
                    url: '/meeting/view/:meetingId',
                    templateUrl: 'views/main/meeting/meeting-details.tpl.html?v=' + new Date(),
                    controller: 'MeetingDetailsController',
                    resolve: {
                        
                    }
                }).state('main.meetingTimeline',
                {
                    url: '/meeting/timeline/:meetingId',
                    templateUrl: 'views/main/meeting/timeline.tpl.html?v=' + new Date(),
                    controller: 'MeetingTimelineController',
                    resolve: {
                        
                    }
                });
    }

})();
