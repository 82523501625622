/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.knowledge')
        .factory('salesPopup', salesPopup);

    salesPopup.$inject = ['attachmentsHelper', 'httpResource', '$uibModal', 'salesResource'];

    function salesPopup(attachmentsHelper, httpResource, $uibModal, salesResource) {

        SalesPopupCtrl.$inject = ['$scope', '$uibModalInstance'];
        function SalesPopupCtrl($scope, $uibModalInstance) {

            // attachmentsHelper.initialize($scope, null);

            if ($scope.item) {
                // attachmentsHelper.setUrl($scope, $scope.item.knowledgeObject.id);
            } else {
                // salesResource.postProcess().then(function(xhr) {
                //     $scope.item = xhr;
                //     attachmentsHelper.setUrl($scope, xhr.knowledgeObject.id);
                // });
            }

            function copyData() {
                $scope.item.title = $scope.title;
                $scope.item.description = $scope.description;
            }

            function processResult(xhr) {
                if ($scope.callback)
                    $scope.callback();
                $uibModalInstance.close();
            }

            $scope.ok = function() {

                if ($scope.item === undefined) {
                    $scope.item = {};
                    copyData();
                    salesResource.postProcess($scope.item).then(processResult);
                }
                else {
                    copyData();
                    salesResource.putProcess($scope.item).then(processResult);
                }
            };

            $scope.cancel = function() {
                $uibModalInstance.dismiss('cancel');
            };
        }

        var open = function($scope, item, callback) {

            $scope.title = "";
            $scope.description = "";

            if (item) {
                $scope.item = item;
                $scope.title = item.title;
                $scope.description = item.description;
            }

            $scope.callback = callback;

            var modalInstance = $uibModal.open({
                templateUrl: 'views/main/popups/sales.popup.tpl.html?v=' + new Date(),
                controller: SalesPopupCtrl,
                scope: $scope
            });
        }

        return {
            "open": open
        };
    }
})();
