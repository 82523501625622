/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.profile')
        .controller('ProfileAccountController', ProfileAccountController);

    ProfileAccountController.$inject = [
        '$stateParams', '$state', '$rootScope', '$scope', 'userPopup', 'userResource',
        'authenticationResource'
    ];

    function ProfileAccountController($stateParams,
        $state,
        $rootScope,
        $scope,
        userPopup,
        userResource,
        authenticationResource) {

        $scope.formData = {};

        $rootScope.$context.$set('/profile/account', $scope);

        activate();

        $scope.translateStatus = function(status) {
            switch (status) {
            case 0:
                return 'Suspended';
            case 1:
                return 'Active';
            case 2:
                return 'Deleted';
            case 3:
                return 'New';
            default:
                return '';
            }
        };

        $scope.callback = function() {
            $scope.item = null;
            activate();
        };


        $scope.open = function(item) {

            if ($rootScope.loggedUser.type === -1)
                $state.go('main.user/edit', { id: item.id });
            else
                $state.go('main.user/view', { id: item.id });

        };

        $scope.create = function() {
            userPopup.open($scope, null, $scope.callback);
        };

        $scope.redirectToGoogleContacts = function() {
            authenticationResource.redirectToGoogleContacts().then(function(result) {
                window.location = result;
            });
        };

        function activate() {
            if ($stateParams.id) {
                userResource.getWorkgroup($stateParams.id).then(function(xhr) {
                    $scope.formData = { members: xhr.users };
                });
            } else {
                userResource.getUsers().then(function(xhr) {
                    $scope.formData = { members: xhr };
                });
            }
        }
    }
})();
