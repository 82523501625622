/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'using strict';

    angular.module('meetingMinutes.public')
        .controller('PublicMinutesController', PublicMinutesController);

    PublicMinutesController.$inject = [
        '$scope', '$state', '$stateParams', 'authenticationResource', 'meetingResource', '$uibModal'
    ];

    function PublicMinutesController($scope, $state, $stateParams, authenticationResource, meetingResource, $uibModal) {

        PopupController.$inject = ['$scope', '$uibModalInstance'];
        function PopupController($scope, $uibModalInstance) {


            $scope.ok = function() {
                $uibModalInstance.close();
            };

            $scope.cancel = function() {
                $uibModalInstance.dismiss('cancel');
            };
        };

        $scope.minutes = null;

        $scope.formData = {
            submitButtonLabel: 'Sign in',
            submitInProgress: false,
            guid: null,
            password: null,
            email: null
        };

        $scope.textToSend;

        $scope.OpenModalDownload = function() {
            var modalInstance = $uibModal.open({
                templateUrl: 'views/main/meeting/select.internalParticipant.tpl.html',
                controller: PopupController,
                scope: $scope
            });
        };
        $scope.emailNotification = false;

        $scope.OpenModalAgendaSuggestion = function(item) {
            $scope.currentAgenda = item;
            var modalInstance = $uibModal.open({
                templateUrl: 'views/public/popup.agendaSuggestion.tpl.html',
                controller: PopupController,
                scope: $scope
            });
        };

        $scope.authenticationFailedMessage = null;


        if ($stateParams.guid) {
            $scope.formData.guid = $stateParams.guid;
        }

        if ($stateParams.email) {
            $scope.formData.email = $stateParams.email;
        }

        $scope.sendSuggestion = function() {
            var dto = {
                guid: $scope.formData.guid,
                password: $scope.formData.password,
                text: $scope.textToSend,
                emailNotification: $scope.emailNotification
            }

            if ($scope.currentAgenda) {
                dto.agendaId = $scope.currentAgenda.id
            }

            meetingResource.sendPublicSuggestion(dto).then(function(response) {

            });

        };

        $scope.submitForm = function() {
            var dto = {
                email: $scope.formData.email,
                guid: $scope.formData.guid,
                password: $scope.formData.password
            };

            meetingResource.getPublicMeetingMinutes(dto).then(function(response) {

                $scope.minutes = response;


            });
        };
    }
})();
