angular.globalSettings = {};
angular.globalSettingsConst = {};

angular.globalSettingsConst.knowledgeObjectStatus = {
    draft: 0,
    published: 1
};

angular.globalSettingsConst.priorities = [
    { name: 'A', label: '', cssClass: 'btn-danger' },
    { name: 'B', label: '', cssClass: 'btn-success' },
    { name: 'C', label: '', cssClass: 'btn-success' },
    { name: 'D', label: '', cssClass: 'btn-default' },
    { name: 'E', label: '', cssClass: 'btn-default btn-outline' }
];

angular.globalSettingsConst.meetingStatus = [
    'Pending',
    'In progress',
    'Done'
];

angular.globalSettingsConst.permissions = [
    'Owner',
    'Contributor',
    'Reader'
];

require('./reactRedux/reactRedux.module');

angular.module('meetingMinutes',
    [
        'ui.router',
        'ngStorage',
        'datatables',
        'datatables.buttons',
        'ngCookies',
        'ui.slimscroll',
        'ui.calendar',
        'textAngular',
        'angular-ladda',
        'meetingMinutes.common',
        'meetingMinutes.directives',
        'meetingMinutes.public',
        'meetingMinutes.layout',
        'meetingMinutes.main',
        'meetingMinutes.main.profile',
        'meetingMinutes.main.dashboard',
        'meetingMinutes.main.knowledge',
        'meetingMinutes.main.crm',
        'meetingMinutes.main.knowledge.note',
        'meetingMinutes.main.knowledge.person',
        'meetingMinutes.main.knowledge.meeting',
        'meetingMinutes.main.library',
        'meetingMinutes.main.workgroup',
        'meetingMinutes.main.draft',
        'meetingMinutes.config',
        'meetingMinutes.repository',
        'meetingMinutes.authorization',
        'meetingMinutes.admin',
        'meetingMinutes.reactRedux'
    ]).run(function() {

});