import {getStore} from '../../src/store/store';

(function() {
    'use strict';

    angular.module('meetingMinutes.reactRedux')
        .factory('reduxStore', ['$rootScope', storeFactory]);

    function storeFactory($rootScope) {
        // http://stackoverflow.com/a/105074/1653210
     
        function get() {
            return getStore($rootScope.loggedUser.id);
        }

        return {
            'get': get
        };
    }

})();