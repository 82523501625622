/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.profile')
        .config(routesConfig);

    routesConfig.$inject = ['$stateProvider', 'authorizationProvider'];

    function routesConfig($stateProvider, authorizationProvider) {
        $stateProvider
            .state('main.profile',
                {
                    url: '/profile',
                    templateUrl: 'views/main/profile/profile.account.tpl.html?v=' + new Date(),
                    controller: 'ProfileAccountController',
                    resolve: {
                        
                    },
                    data: {
                        pageTitle: 'Edit profile'
                    }
                }).state('main.profile/crm',
                {
                    url: '/profile/crm',
                    templateUrl: 'views/main/profile/profile.crm.tpl.html?v=' + new Date(),
                    controller: 'ProfileCrmController',
                    resolve: {
                        
                    },
                    data: {
                        pageTitle: 'Edit profile - CRM'
                    }
                }).state('main.profile/templates/minutes',
                {
                    url: '/profile/templates/minutes',
                    templateUrl: 'views/main/profile/profile.minutes.templates.tpl.html?v=' + new Date(),
                    controller: 'ProfileMinutesTemplatesController',
                    resolve: {
                        
                    },
                    data: {
                        pageTitle: 'Edit profile - Minutes Templates'
                    }
                }).state('main.profile/tasks',
                {
                    url: '/profile/tasks',
                    templateUrl: 'views/main/profile/profile.tasks.tpl.html?v=' + new Date(),
                    controller: 'ProfileTasksController',
                    resolve: {
                        
                    },
                    data: {
                        pageTitle: 'Edit profile - Minutes Templates'
                    }
                }).state('main.profile/account',
                {
                    url: '/profile/account',
                    templateUrl: 'views/main/profile/profile.account.tpl.html?v=' + new Date(),
                    controller: 'ProfileAccountController',
                    resolve: {
                        
                    },
                    data: {
                        pageTitle: 'Edit profile - Minutes Templates'
                    }
                }).state('main.profile/otherAccount',
                {
                    url: '/profile/account/:id',
                    templateUrl: 'views/main/profile/profile.account.tpl.html?v=' + new Date(),
                    controller: 'ProfileAccountController',
                    resolve: {
                        
                    },
                    data: {
                        pageTitle: 'Edit profile - Minutes Templates'
                    }
                }).state('main.profile/googleContacts',
                {
                    url: '/profile/google-contacts',
                    templateUrl: 'views/main/profile/profile.google-contacts.tpl.html?v=' + new Date(),
                    controller: 'ProfileGoogleContactsController',
                    resolve: {
                        
                    },
                    data: {
                        pageTitle: 'Google Contacts'
                    }
                }).state('main.profile/tokenclients',
                {
                    url: '/profile/tokenclients',
                    templateUrl: 'views/main/profile/profile.tokenclients.tpl.html?v=' + new Date(),
                    controller: 'ProfileTokenClientsController',
                    resolve: {
                        
                    },
                    data: {
                        pageTitle: 'Token Clients'
                    }
                }).state('main.profile/refreshtokens',
                {
                    url: '/profile/refreshtokens',
                    templateUrl: 'views/main/profile/profile.refreshtokens.tpl.html?v=' + new Date(),
                    controller: 'ProfileRefreshTokensController',
                    resolve: {
                        
                    },
                    data: {
                        pageTitle: 'Refresh tokens'
                    }
                });
    }

})();
