import * as React from 'react';
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { KeyboardDateTimePickerProps } from '@material-ui/pickers/DateTimePicker';
import { Moment } from 'moment';


export interface IDateTimePickerProps {
    value: string;
    onChange(date: Moment, value?: string): void;
}

export default class DateTimePicker extends React.PureComponent<IDateTimePickerProps & KeyboardDateTimePickerProps>{
    constructor(props: any) {
        super(props);
    }

    onDateChanged = (date: any, value?: string) => {
        var stringDate:string = null;

        if (date !== null) {
            var dateVal = new Date(date);
            if (dateVal.toString() === 'Invalid Date') return;
            stringDate = dateVal.toISOString();
        }

        if (this.props.onChange !== undefined)
            this.props.onChange(date, stringDate);
    }

    render() {
        var props = {
            ...this.props,
            value: this.props.value,
            onChange: this.onDateChanged,
            format: "YYYY-MM-DD HH:mm",
            ampm: false,
        }

        return (<KeyboardDateTimePicker {...props} />)
    }
}