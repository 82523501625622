/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.knowledge')
        .controller('PinBoardController', PinBoardController);

    PinBoardController.$inject = [
        'stickyNoteTransformPopup', 'notePopup', 'accessPopup', '$state', '$rootScope', '$scope', '$location',
        '$stateParams', 'knowledgeObjectResource', 'toastr', 'userResource', 'noteResource', 'popupHelper',
        'meetingResource', 'dateHelper', '$uibModal', 'workgroupResource', 'removePopup'
    ];

    function PinBoardController(stickyNoteTransformPopup,
        notePopup,
        accessPopup,
        $state,
        $rootScope,
        $scope,
        $location,
        $stateParams,
        knowledgeObjectResource,
        toastr,
        userResource,
        noteResource,
        popupHelper,
        meetingResource,
        dateHelper,
        $uibModal,
        workgroupResource,
        removePopup) {
        $scope.attachmentUploadUrl = "";

        $scope.$context = $rootScope.$context.$set('/pinboard/');
        $scope.backUrl = $location.$$search.backUrl;
        $scope.tags = $location.$$search.tags;

        $scope.formData = {
            item: null
        };


        $scope.createRelatedNote = function() {
            popupHelper.CreateKoNote($scope, "pin-note", $scope.searchRelatedNotes);
        };

        $scope.searchRelatedNotes = function() {
            var request = {
                from: 0,
                to: 100,
                take: 100,
                tagFilter: []
            };
            request.tagFilter.push('pin-note');

            noteResource.getNotes(request).then(function(result) {
                for (var i = 0; i < result.results.length; i++) {
                    result.results[i].createTimeEx = dateHelper.enchance(result.results[i].createTime);
                }

                $scope.formData.notes = result.results;

                $rootScope.setPinNotesCount();
            });
        };

        $scope.stickyNoteTransformPopup = function(item) {
            stickyNoteTransformPopup.open($scope, item, $scope.searchRelatedNotes);
        };


        $scope.save = function() {
            noteResource.save($scope.formData.item).then(function(response) {
                $scope.mode = 'view';
                $scope.publish();
                toastr.success("Note have been succesfully saved");
            });
        };

        $scope.searchRelatedNotes();
    }
})();
