/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function () {
    'use strict';

    angular.module('meetingMinutes.main.knowledge.meeting')
        .controller('PlanMeetingWizardController', PlanMeetingWizardController);

    PlanMeetingWizardController.$inject = ['$cookieStore', '$cookies', 'uiCalendarConfig', 'taskResource', 
    '$timeout', 'selectTagsPopup', 'agendaItemPopup', 'goalItemPopup', '$location', '$scope', '$rootScope', 
    '$q', '$state', '$stateParams', 'meetingResource', 'workgroupResource', 'knowledgeObjectResource', 'toastr', 
    '$element', 'userResource', '$mdSelect', '$mdMenu', '$window', 'personResource', '$uibModal', 'dateHelper', 
    'crmResource', 'meetingQuickPlan'];

    function PlanMeetingWizardController($cookieStore, $cookies, uiCalendarConfig, taskResource, 
        $timeout, selectTagsPopup, agendaItemPopup, goalItemPopup, $location, $scope, $rootScope, 
        $q, $state, $stateParams, meetingResource, workgroupResource, knowledgeObjectResource, toastr, 
        $element, userResource, $mdSelect, $mdMenu, $window, personResource, $uibModal, dateHelper, 
        crmResource, meetingQuickPlan) {

        $scope.formData = {
            knowledgeObject: {}
        };

        var startTitle = $cookieStore.get("ctx_actionItem_title");
        var startTags = $cookieStore.get("ctx_actionItem_tags");

        $cookieStore.put("ctx_actionItem_title", null);
        $cookieStore.put("ctx_actionItem_tags", null);

        $scope.events = null;

        $cookieStore.put('sm_meeting_mode', null);

        var date = new Date();
        var d = date.getDate();
        var m = date.getMonth();
        var y = date.getFullYear();

        // $scope.settings = angular.globalSettings.User;


        $scope.loaded = false;
        // Events
        $scope.events = [
            //{ title: "Current activity", start: new Date(y, m, d, 9, 0),end: new Date(y, m, d, 10, 30),allDay: false },
        ];

        $scope.events2 = [
            //{ title: "Current activity", start: new Date(y, m, d, 9, 0),end: new Date(y, m, d, 10, 30),allDay: false },
        ];

        $scope.eventsTemp = [];

        var isEventOverDiv = function (x, y) {
            var external_events = $('#external-events');
            var offset = external_events.offset();
            offset.right = external_events.width() + offset.left;
            offset.bottom = external_events.height() + offset.top;

            // Compare
            if (x >= offset.left && y >= offset.top && x <= offset.right && y <= offset.bottom) {
                return true;
            }
            return false;
        }

        var convert = function (time) {
            //var time = '02:05 PM';
            if (time.indexOf('am') > 0 || time.indexOf('pm') > 0) {
                var hours = Number(time.match(/^(\d+)/)[0]);
                var minutes = "00";
                if (time.indexOf(':') > 0)
                    minutes = time.substr(0, time.length - 2);
                minutes = minutes.substr(minutes.indexOf(":") + 1);


                if (time.indexOf("pm") > 0 && hours < 12) hours = hours + 12;
                if (time.indexOf("am") > 0 && hours == 12) hours = hours - 12;
                var sHours = hours.toString();
                //var sMinutes = minutes.toString();
                if (hours < 10) sHours = "0" + sHours;
                //if (minutes < 10) sMinutes = "0" + sMinutes;
                return hours + ":" + minutes;
            }
            return null
        }

        $scope.editableEventsCount = 0;

        $scope.GoalPriority = function (id) {
            if (id == 0)
                return 'A';
            if (id == 1)
                return 'B';
            if (id == 2)
                return 'C';
            if (id == 3)
                return 'D';
            if (id == 4)
                return 'E';
        };

        var updateEvent = function (event) {

            if (event.koId !== $scope.formData.meeting.knowledgeObject.id) return;

            $scope.formData.meeting.plannedStartTime = event.start;
            $scope.formData.meeting.plannedEndTime = event.end;
            $scope.updateDurationClass();
        };

        $scope.cal;

        var isFirstTime = true;
        $scope.locked = false;
        $scope.selectContext = function (tag) {
            if (!$scope.formData.meeting.activityProfile) {
                $scope.formData.meeting.activityProfile = [];
            }
            var idx = $scope.formData.meeting.activityProfile.indexOf(tag);

            if (idx >= 0) {
                var temp = [];
                angular.forEach($scope.formData.meeting.activityProfile,
                    function (_tag) {
                        if (_tag.toLowerCase() != tag.toLowerCase()) {
                            temp.push(_tag);
                        }
                    });

                $scope.formData.meeting.activityProfile = [];
                angular.forEach(temp,
                    function (_x) {
                        $scope.formData.meeting.activityProfile.push(_x);
                    });
            } else {
                $scope.formData.meeting.activityProfile.push(tag);
            }
        };

        var op = crmResource.getOpenedOpportunitiesList();
        op.then(function (data) {
            $scope.availableOpportunities = data;
        });

        // if (angular.globalSettings.User)
        // $scope.availableAcitivityContexts = angular.globalSettings.User.activityContexts;

        $timeout(function () {
                if ($scope.formData.meeting)
                    uiCalendarConfig.calendars.myCalendar.fullCalendar('gotoDate',
                        $scope.formData.meeting.plannedStartTime);
            },
            1000);

        $scope.uiConfig = {
            calendar: {
                timeFormat: 'H(:mm)',
                allDaySlot: false,
                height: 700,
                locale: 'pl',
                slotDuration: '00:15:00',
                titleFormat: '',
                timezone: 'local',
                editable: true,
                defaultView: 'agendaDay',
                businessHours: true, // display business hours
                eventLimit: true,
                droppable: true, // this allows things to be dropped onto the calendar
                dragRevertDuration: 0,
                weekNumberCalculation: 'ISO',
                header: {
                    left: 'prev',
                    center: 'title',
                    right: 'next'
                },
                drop: function (event, jsEvent, ui, view) {
                    $scope.cal = view.calendar;
                    $scope.locked = true;
                },
                eventResize: function (event) {
                    //updateEvent(event);
                },
                viewRender: function (view, element) {
                    $scope.cal = view.calendar;
                    if (isFirstTime == false) {
                        taskResource.list(new Date(view.intervalStart).toISOString().slice(0,10)).then(function (xhr) {
                            $scope.events = [];
                            angular.forEach(xhr,
                                function (value) {
                                    updateEvent({
                                        start: value.plannedStartTime,
                                        // end: value.endTime
                                        end: value.plannedEndTime
                                    });

                                    if (value.status == 0 || value.status == 1) {

                                        if (value.id == $scope.formData.meeting.id) {
                                            $scope.locked = true;
                                            $scope.events.push({
                                                title: value.knowledgeObject.title,
                                                description: "",
                                                start: new Date(value.plannedStartTime + "Z"),
                                                // end: new Date(value.endTime + "Z"),
                                                end: new Date(value.plannedEndTime + "Z"),
                                                editable: true,
                                                allDay: false,
                                                stick: false,
                                                koId: value.knowledgeObject.id
                                            });
                                        } else
                                            $scope.events.push({
                                                title: value.knowledgeObject.title,
                                                description: "",
                                                className: "highPriority",
                                                start: new Date(value.plannedStartTime + "Z"),
                                                // end: new Date(value.endTime + "Z"),
                                                end: new Date(value.plannedEndTime + "Z"),                                                editable: false,
                                                allDay: false,
                                                stick: false,
                                                koId: value.knowledgeObject.id
                                            });
                                    }
                                });
                            $scope.cal.renderEvents($scope.events, false);
                        });
                    }
                },
                eventAfterAllRender: function (view) {
                    var sel = $("td.fc-axis.fc-time.fc-widget-content");

                    for (var i = 0; i < sel.length; i++) {
                        if (sel[i].innerText.length > 0) {
                            var value = convert(sel[i].innerText);
                            if (value != null)
                                sel[i].innerHTML = '<small>' + convert(sel[i].innerText) + '</small>';
                        }
                    }

                    $scope.cal = view.calendar;
                    $scope.cal.options.extraSmallTimeFormat = "H:mm";
                    if (isFirstTime == true) {
                        isFirstTime = false;
                        taskResource.list(new Date(view.intervalStart).toISOString().slice(0,10)).then(function (xhr) {
                            $scope.events = [];
                            angular.forEach(xhr,
                                function (value) {

                                    if (value.status == 1 || value.status == 0) {
                                        if (value.id == $scope.formData.meeting.id) {
                                            updateEvent({
                                                start: value.plannedStartTime,
                                                // end: value.endTime
                                                end: value.plannedEndTime
                                            });

                                            $scope.locked = true;
                                            $scope.events.push({
                                                title: value.knowledgeObject.title,
                                                description: "",

                                                start: new Date(value.plannedStartTime),
                                                // end: new Date(value.endTime + "Z"),
                                                end: new Date(value.plannedEndTime),
                                                editable: true,
                                                allDay: false,
                                                stick: true,
                                                koId: value.knowledgeObject.id
                                            });
                                        } else
                                            $scope.events.push({
                                                title: value.knowledgeObject.title,
                                                description: "",
                                                editable: false,
                                                className: "highPriority",
                                                start: new Date(value.plannedStartTime),
                                                // end: new Date(value.endTime + "Z"),
                                                end: new Date(value.plannedEndTime),
                                                allDay: false,
                                                stick: false,
                                                koId: value.knowledgeObject.id
                                            });
                                    }
                                });
                            $scope.cal.renderEvents($scope.events, false);
                        });
                    }
                },
                eventDragStop: function (event, jsEvent, ui, view) {
                    if (isEventOverDiv(jsEvent.clientX, jsEvent.clientY)) {
                        $scope.cal.removeEvents(event._id);
//                        $scope.events2 = [];
                        $scope.cal.droppable = true;
                        $scope.locked = false;

                        //var el = $("<div class='fc-event'>").appendTo('#external-events-listing').text(event.title);
                        //el.draggable({
                        //    zIndex: 999,
                        //    revert: true,
                        //    revertDuration: 0
                        //});
                        event = null;
                        //el.data('event', { title: event.title, id: event.id, stick: false });
                        $scope.editableEventsCount = 0;
                        $scope.cal.rerenderEvents();
                        updateEvent(event);
                        //$scope.eventSources.push({ title: event.title, id: event.id, stick: true });
                    }
                },
                eventRender: function (event, element) {
                    {
                        if (event.end == null) {
                            event.end = moment(event.start).add(1, 'hour');
                            //event.title = 'New activity';
                            $timeout(function () {
                                $scope.cal.rerenderEvents();
                            });

                        }
                        updateEvent(event);
                    }

                },
                eventReceive: function (event) {
                    event.title = $scope.formData.meeting.knowledgeObject.title;
                    event.koId = $scope.formData.meeting.knowledgeObject.id;
                }
            }
        };


        $scope.updateStartTime = function(time, momentTime) {
            $scope.$evalAsync(function() {
                $scope.formData.meeting.plannedStartTime = time;
            });
        };

        $scope.updateEndTime = function(time, momentTime) {
            $scope.$evalAsync(function() {
                $scope.formData.meeting.plannedEndTime = time;
            });
        };

        $scope.$watch(
            function () {
                return $scope.cal;
            },
            function (newValue, oldValue) {

            },
            false);


        $scope.totalDuration = 0;

        $scope.$watch(
            function () {
                if ($scope.formData.meeting && $scope.formData.meeting.agendaItems)
                    return $scope.formData.meeting.agendaItems;
            },
            function (newValue, oldValue) {
                $scope.totalDuration = 0;
                if ($scope.formData.meeting && $scope.formData.meeting.agendaItems)
                    angular.forEach($scope.formData.meeting.agendaItems,
                        function (agenda) {
                            $scope.totalDuration = $scope.totalDuration + agenda.duration;
                        })

            },
            true);


        //hack to refresh events

        /* Event sources array */
        $scope.eventSources = [$scope.events];

        $scope.backUrl = $location.$$search.backUrl;

        $scope.prevId = $location.$$search.prevId;
        $scope.mode = $location.$$search.mode;

        $scope.back = function () {
            $location.path($scope.backUrl);
        };

        function addMinutes(date, minutes) {
            return new Date(date.getTime() + minutes * 60000);
        }

        $scope.updateDurationClass = function () {
            if ($scope.formData.meeting) {
                var d1 = $scope.formData.meeting.plannedStartTime;
                // var d2 = $scope.formData.meeting.endTime;
                var d2 = $scope.formData.meeting.plannedEndTime;
                if (d1 && d2)
                    $scope.formData.meeting.duration = moment(d2).diff(moment(d1), 'minutes');
            }
        };

       

        $scope.selectDuration = function (minutes) {
            // $scope.formData.meeting.endTime = addMinutes($scope.formData.meeting.plannedStartTime, minutes);
            $scope.formData.meeting.plannedEndTime = addMinutes($scope.formData.meeting.plannedStartTime, minutes);
            // $scope.formData.meeting.endTimeEx = dateHelper.enchance($scope.formData.meeting.endTime);
            $scope.formData.meeting.plannedEndTimeEx = dateHelper.enchance($scope.formData.meeting.plannedEndTime);
            // $scope.formData.selectedEndHour = $scope.formData.meeting.endTimeEx.hour_minutes;
            $scope.formData.selectedEndHour = $scope.formData.meeting.plannedEndTimeEx.hour_minutes;
            $scope.updateDurationClass();
            //$scope.formData.meeting.plannedStartTime 
        }

        $scope.selectTagsPopup = function () {
            var config = {};
            config.common = true;
            selectTagsPopup.open($scope, $scope.formData.meeting.knowledgeObject.tags, config, null);
        };

        $scope.selectCommunicationPopup = function () {
            var config = {};
            config.communication = true;
            selectTagsPopup.open($scope, $scope.formData.meeting.communication, config, null);
        };

        $scope.selectWorkPopup = function () {
            var config = {};
            config.meeting = true;
            selectTagsPopup.open($scope, $scope.formData.meeting.idx, config, null);
        };

        $scope.selectProfilePopup = function () {
            var config = {};
            config.profile = true;
            selectTagsPopup.open($scope, $scope.formData.meeting.profile, config, null);
        };


        $scope.openAgendaItemPopup = function (item) {
            agendaItemPopup.open($scope, $scope.formData.meeting, item);
        }

        $scope.openGoalItemPopup = function (item) {
            if ($scope.mode == 'plantheday')
                goalItemPopup.open($scope, $rootScope, $scope.formData.meeting, item, null, true);
            else
                goalItemPopup.open($scope, $rootScope, $scope.formData.meeting, item, null, false);
        }

        $scope.calendarDate = new Date();

        $scope.selectInboxDate = function () {
            $scope.formData.meeting.plannedStartTime = $scope.calendarDate;
            $scope.formData.meeting.plannedStartTimeEx = dateHelper.enchance($scope.formData.meeting.plannedStartTime);
        }

        SelectTemplateCtrl.$inject = [
            '$scope', '$uibModalInstance'
        ];

        function SelectTemplateCtrl($scope, $uibModalInstance) {

            // $scope.addMember = function (member) {
            //     $scope.formData.meeting.internalParticipants.push(member);
            //     member.color = true;
            // }
            //
            // $scope.addGuest = function (guest) {
            //     $scope.formData.meeting.externalParticipants.push(guest);
            //     guest.color = true;
            // }

            $scope.addMember = function (member) {
                if(member.color){
                    $scope.formData.meeting.internalParticipants.splice($scope.formData.meeting.internalParticipants.indexOf(member), 1);
                    member.color = false;
                }
                else {
                    $scope.formData.meeting.internalParticipants.push(member);
                    member.color = true;
                }
            };

            $scope.addGuest = function (guest) {
                if(guest.color) {
                    $scope.formData.meeting.externalParticipants.splice($scope.formData.meeting.externalParticipants.indexOf(guest), 1);
                    guest.color = false;
                }
                else {
                    $scope.formData.meeting.externalParticipants.push(guest);
                    guest.color = true;
                }
            };

            $scope.search = {
                userName: ""
            }

            $scope.ok = function () {
                $uibModalInstance.close();
            };

            $scope.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };
        };

        PopupNotificationCtrl.$inject = ['$scope', '$uibModalInstance'];
        function PopupNotificationCtrl($scope, $uibModalInstance) {

            $scope.txtToSend =
                "Dear attendee\nYou are invited for a meeting\n\nPlease check details clicking following link:\n\n" +
                "http://www.spinminutes.com/client/index#/meeting/minutes/" +
                $scope.formData.meeting.id;

            $scope.ok = function () {
                $uibModalInstance.close();
            };

            $scope.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };
        };

        $scope.setToday = function () {
            $scope.formData.meeting.plannedStartTime = new Date();
        }

        $scope.formatDate = function (date) {
            return moment(date).format('YYYY-MM-DD');
        }

        $scope.OpenModalSelectInternalParticipant = function () {
            var modalInstance = $uibModal.open({
                templateUrl: 'views/main/meeting/select.internalParticipant.tpl.html',
                controller: SelectTemplateCtrl,
                scope: $scope
            });
        }

        $scope.OpenModalSelectExternalParticipant = function () {
            var modalInstance = $uibModal.open({
                templateUrl: 'views/main/meeting/select.externalParticipant.tpl.html',
                controller: SelectTemplateCtrl,
                scope: $scope
            });
        }

        function createFilterFor(query) {
            var lowercaseQuery = angular.lowercase(query);
            return function filterFn(vegetable) {
                vegetable = angular.lowercase(vegetable);
                return (vegetable.indexOf(lowercaseQuery) === 0);
            };
        }

        $scope.availableTags = [];
        $scope.querySearchTags = function (query) {
            var results = query ? $scope.availableTags.filter(createFilterFor(query)) : [];
            return results;
        }

        $scope.availableMeetings = [];
        $scope.querySearchMeetings = function (query) {
            var results = query ? $scope.availableMeetings.filter(createFilterFor(query)) : [];
            return results;
        }
        knowledgeObjectResource.getAvailableTags_Meeting().then(function (response) {
            $scope.availableMeetings = response;
        });

        $scope.availableProfiles = [];
        $scope.querySearchProfiles = function (query) {
            var results = query ? $scope.availableProfiles.filter(createFilterFor(query)) : [];
            return results;
        }
        knowledgeObjectResource.getAvailableTags_Profile().then(function (response) {
            $scope.availableProfiles = response;
        });

        $scope.availableCommunications = [];
        $scope.querySearchCommunications = function (query) {
            var results = query ? $scope.availableCommunications.filter(createFilterFor(query)) : [];
            return results;
        }
        knowledgeObjectResource.getAvailableTags_Communication().then(function (response) {
            $scope.availableCommunications = response;
        });


        $scope.cancel = function () {
            $state.go('main.dashboard');
        };

        $scope.validated = $scope.formData.meeting;

        //selecting participants
        $scope.selectedItem = null;
        $scope.searchText = null;

        function createFilterForMember(query) {
            var lowercaseQuery = angular.lowercase(query);

            return function filterFn(vegetable) {
                return (vegetable.userName.toLowerCase().indexOf(lowercaseQuery) === 0) ||
                    (vegetable.email.toLowerCase().indexOf(lowercaseQuery) === 0);
            };
        }

        function createFilterForPerson(query) {
            var lowercaseQuery = angular.lowercase(query);
            return function filterFn(vegetable) {
                return (vegetable.name.toLowerCase().indexOf(lowercaseQuery) === 0)
            };
        }

        $scope.querySearchForMembers = function (query) {
            var results = query ? $scope.formData.members.filter(createFilterForMember(query)) : [];
            return results;
        }

        $scope.querySearchForPersons = function (query) {
            var results = query ? $scope.formData.persons.filter(createFilterForPerson(query)) : [];
            return results;
        }

        $scope.IsMeetingRunning = null;

        $scope.save = function () {
            withProgressIndicator(meetingResource.save($scope.formData.meeting)).then(function (meeting) {
            });
        };

        $scope.saveDayPlan = function () {
            $scope.creatingDayPlan = true;
            var selectedDay = $cookieStore.get("sm_selectedDay");

            if (selectedDay)
                var date = new Date(selectedDay);
            else
                var date = new Date();

            var dto = {
                startTime: date,
                meetingId: $scope.formData.meeting.id,
                sortBy: 'priority'
            };

            withProgressIndicator(meetingResource.saveDayPlan($scope.formData.meeting.id, dto)).then(function (meeting) {
                toastr.success('Day plan successfuly published');
                $state.go('main.meetings');
                $scope.creatingDayPlan = false;
            });
        };

        $scope.actionItemId = $cookieStore.get("ctx_actionItem");
        $cookieStore.put("ctx_actionItem", null);

        $scope.attachMeetingToActionItem = function () {
            meetingResource.attachMeetingToActionItem($scope.actionItemId, $scope.formData.meeting.id).then(function () {
            });
        }

        $scope.timeline = function () {
            $state.go('main.meetingTimeline', {meetingId: $scope.formData.meeting.id});
        }

        $scope.currentPage = 0;

        $scope.setPage = function (id) {
            $scope.currentPage = id;
        }

        $scope.calendarDate = new Date();


        $scope.isPageVisible = function (id) {
            if ($scope.currentPage == id) {
                return true;
            } else {
                return false;
            }
        }

        $scope.showYears = function () {
            $scope.calendarView = 'year';
        }

        $scope._selectedStartDate = function () {
            return {
                day: moment($scope.formData.meeting.plannedStartTime).format('D'),
                day_name: moment($scope.formData.meeting.plannedStartTime).format('dddd'),
                month: moment($scope.formData.meeting.plannedStartTime).format('MMMM')
            }
        };

        $scope.estimateMeeting = function () {
            if ($scope.formData.meeting && $scope.formData.meeting.plannedStartTime)
                $scope.formData.meeting.plannedStartTime = new Date(
                    moment($scope.formData.meeting.plannedStartTime).format('YYYY'),
                    moment($scope.formData.meeting.plannedStartTime).format('MM') - 1,
                    moment($scope.formData.meeting.plannedStartTime).format('DD'),
                    getFirstPart($scope.formData.selectedStartHour, ':'),
                    getSecondPart($scope.formData.selectedStartHour, ':'));

            if ($scope.formData.meeting)
                // $scope.formData.meeting.endTime = new Date(
                //     moment($scope.formData.meeting.plannedStartTime).format('YYYY'),
                //     moment($scope.formData.meeting.plannedStartTime).format('MM') - 1,
                //     moment($scope.formData.meeting.plannedStartTime).format('DD'),
                //     getFirstPart($scope.formData.selectedEndHour, ':'),
                //     getSecondPart($scope.formData.selectedEndHour, ':'));
                $scope.formData.meeting.plannedEndTime = new Date(
                    moment($scope.formData.meeting.plannedStartTime).format('YYYY'),
                    moment($scope.formData.meeting.plannedStartTime).format('MM') - 1,
                    moment($scope.formData.meeting.plannedStartTime).format('DD'),
                    getFirstPart($scope.formData.selectedEndHour, ':'),
                    getSecondPart($scope.formData.selectedEndHour, ':'));

            $scope.checkIfGoalDirty = function (item) {
                return item['goal_' + item.goal.id].$dirty;
            }

            $scope.checkIfAgendaItemDirty = function (item) {
                return item['agendaItem_' + item.agendal.id].$dirty;
            }

            if ($scope.formData.meeting) {
                var request = {
                    id: $scope.formData.meeting.id,
                    startTime: $scope.formData.meeting.plannedStartTime,
                    // endTime: $scope.formData.meeting.endTime,
                    plannedEndTime: $scope.formData.meeting.plannedEndTime,
                };


                meetingResource.estimate(request).then(function (response) {
                    $scope.estimation.duration = response.duration;
                    $scope.estimation.durationFormatted = response.durationFormatted;
                });
            }
        }

        $scope.showMonths = function () {
            $scope.calendarView = 'month';
        }

        $scope.validated = false;
        $scope.completeness = 0;

        $scope.validate = function () {

            $scope.completeness = 0;
            if (!$scope.formData.meeting)
                return;

            if ($scope.formData.meeting.agendaItems && $scope.formData.meeting.agendaItems.length > 0)
                $scope.completeness = $scope.completeness + 20;
            if ($scope.formData.meeting.agendaItems && $scope.formData.meeting.goals.length > 0)
                $scope.completeness = $scope.completeness + 20;
            if ($scope.formData.meeting.location)
                $scope.completeness = $scope.completeness + 20;
            if ($scope.formData.meeting.knowledgeObject && $scope.formData.meeting.knowledgeObject.title)
                $scope.completeness = $scope.completeness + 20;
            if ($scope.formData.meeting.priority >= 0)
                $scope.completeness = $scope.completeness + 20;
            return true;
        }

        $scope.$watch('formData.meeting.location',
            function () {
                $scope.validated = $scope.validate();
            });

        $scope.$watch('formData.meeting.knowledgeObject.title',
            function () {
                $scope.validated = $scope.validate();
            });

        $scope.$watch('formData.meeting.agendaItems.length',
            function () {
                $scope.validated = $scope.validate();
            });
        $scope.$watch('formData.meeting.goals.length',
            function () {
                $scope.validated = $scope.validate();
            });
        $scope.$watch('formData.meeting.priority',
            function () {
                $scope.validated = $scope.validate();
            });

        $scope.$watch('formData.meeting.plannedStartTime',
            function () {
                $scope.updateDurationClass();
            });

        // $scope.$watch('formData.meeting.endTime',
        //     function () {
        //         $scope.updateDurationClass();
        //     });
        $scope.$watch('formData.meeting.plannedEndTime',
            function () {
                $scope.updateDurationClass();
            });

        $scope.$watch('formData.selectedStartHour',
            function () {
                $scope.updateDurationClass();

                if ($scope.formData.meeting) {
                    $scope.formData.meeting.plannedStartTime = new Date(
                        moment($scope.formData.meeting.plannedStartTime).format('YYYY'),
                        moment($scope.formData.meeting.plannedStartTime).format('MM') - 1,
                        moment($scope.formData.meeting.plannedStartTime).format('DD'),
                        getFirstPart($scope.formData.selectedStartHour, ':'),
                        getSecondPart($scope.formData.selectedStartHour, ':'));

                    if (!$scope.formData.selectedEndHour) {
                        $scope.formData.selectedEndHour = +getFirstPart($scope.formData.selectedStartHour, ':') +
                            1 +
                            ":" +
                            getSecondPart($scope.formData.selectedStartHour, ':');
                    }

                    if ($scope.formData.selectedEndHour && $scope.formData.selectedStartHour) {
                        $scope.estimateMeeting();
                    }
                }
            });

        $scope.$watch('formData.selectedEndHour',
            function () {
                //$scope.updateDurationClass();
                $scope.validated = $scope.validate();
                if ($scope.formData.meeting) {
                    // $scope.formData.meeting.endTime = new Date(
                    //     moment($scope.formData.meeting.endTime).format('YYYY'),
                    //     moment($scope.formData.meeting.endTime).format('MM') - 1,
                    //     moment($scope.formData.meeting.endTime).format('DD'),
                    //     getFirstPart($scope.formData.selectedEndHour, ':'),
                    //     getSecondPart($scope.formData.selectedEndHour, ':'));
                    $scope.formData.meeting.plannedEndTime = new Date(
                        moment($scope.formData.meeting.plannedEndTime).format('YYYY'),
                        moment($scope.formData.meeting.plannedEndTime).format('MM') - 1,
                        moment($scope.formData.meeting.plannedEndTime).format('DD'),
                        getFirstPart($scope.formData.selectedEndHour, ':'),
                        getSecondPart($scope.formData.selectedEndHour, ':'));
                    if ($scope.formData.selectedEndHour && $scope.formData.selectedStartHour)
                        $scope.estimateMeeting();
                }
            });


        $scope.estimation = {
            cost: 0,
            duration: 0,
            durationFormatted: null
        };

        $scope.plannedStartTimeFormatted = null;


        function getFirstPart(str, separator) {
            if (str)
                return str.slice(0, str.indexOf(separator));
            else
                return null;
        }

        function getSecondPart(str, separator) {
            if (str)
                return str.split(separator)[1];
            else
                return null;
        }

        $rootScope.label = "You are planning new meeting";

        var meetingId = null;
        if ('' !== $stateParams.meetingId && angular.isNumber(+$stateParams.meetingId)) {
            meetingId = +$stateParams.meetingId;
        }

        $scope.newScheduleVisible = false;
        $scope.tabCalendarSelected = function () {
            $scope.newScheduleVisible = true;

            $timeout(function () {
                    uiCalendarConfig.calendars.myCalendar.fullCalendar('gotoDate',
                        $scope.formData.meeting.plannedStartTime);
                },
                100);
        };

        $scope.tabEvidenceSelected = function () {
            $scope.newScheduleVisible = false;
        };


        $scope.$context = $rootScope.$context.$set('/meetings/plan');
        $scope.attachmentUploadUrl = null;

        knowledgeObjectResource.getTags().then(function (tags) {
            $scope.formData.availableTags = tags;
        });

        $scope.searchTerm;
        $scope.clearSearchTerm = function () {
            $scope.searchTerm = '';
        };
        // The md-select directive eats keydown events for some quick select
        // logic. Since we have a search input here, we don't need that logic.
        $element.find('input').on('keydown',
            function (ev) {
                ev.stopPropagation();
            });


        if ($scope.mode != 'instant') {

            if (meetingId != null && meetingId != 'NaN') {
                meetingResource.getMeetingById(meetingId).then(function (meeting) {
                    // if (!meeting.idx)
                    //     meeting.idx = [];

                    angular.forEach(meeting.goals,
                        function (goal) {
                            goal.priorityTxt = $scope.GoalPriority(goal.priority);
                        });

                    meeting.knowledgeObject.descriptionHtml = $scope.sanitizeDescriptionHtml(meeting.knowledgeObject.descriptionHtml);
                    $scope.formData.meeting = meeting;
                    $scope.formData.meeting.plannedStartTime = moment($scope.formData.meeting.plannedStartTime);
                    $scope.formData.meeting.plannedEndTime = moment($scope.formData.meeting.plannedEndTime);

                    if (startTags)
                        $scope.formData.meeting.knowledgeObject.tags = startTags;

                    if ($location.$$search.tags) {
                        $scope.formData.meeting.communication.push($location.$$search.tags);
                    }


                    $scope.priority(meeting.priority);
                    $scope.$context.$object = meeting.knowledgeObject;
                    $scope.attachmentUploadUrl = '/api/knowledgeObject/' +
                        $scope.formData.meeting.knowledgeObject.id +
                        '/attachment';

                    userResource.getUsers().then(function (members) {
                        $scope.formData.members = members;
                    });

                    knowledgeObjectResource.getAttachments($scope.formData.meeting.knowledgeObject.id).then(
                        function (xhr) {
                            $scope.formData.meeting.knowledgeObject.attachments = xhr;
                        });

                    personResource.getPersons().then(function (response) {
                        $scope.formData.persons = response;
                    });

                    if (meeting.status == 1) {
                        $scope.IsMeetingRunning = true;
                    } else {
                        $scope.IsMeetingRunning = false;
                    }
                    $scope.loaded = true;
                });
            } else {
                meetingResource.createDraft($location.$$search.prevId).then(function (meeting) {

                    meeting.plannedStartTime = new Date();


                    $timeout(function () {
                            uiCalendarConfig.calendars.myCalendar.fullCalendar('gotoDate', meeting.plannedStartTime);
                        },
                        3000);

                    $scope.IsMeetingRunning = false;
                    meeting.knowledgeObject.descriptionHtml = $scope.sanitizeDescriptionHtml(meeting.knowledgeObject.descriptionHtml);
                    $scope.formData.meeting = meeting;
                    $scope.formData.meeting.plannedMinutes = "0";
                    $scope.formData.meeting.knowledgeObject.title = startTitle;
                    $rootScope.$context.$object = meeting.knowledgeObject;
                    if ($rootScope.crmCtx.opportunity) {
                        $scope.formData.meeting.opportunityId = $rootScope.crmCtx.opportunity;
                    }
                    // if (!meeting.idx)
                    //     meeting.idx = [];

                    if (startTags)
                        $scope.formData.meeting.knowledgeObject.tags = startTags;

                    if ($location.$$search.tags) {
                        for (var i = 0; i < $location.$$search.tags.length; i++) {
                            $scope.formData.meeting.communication.push($location.$$search.tags[i]);
                        }
                    }
                    $scope.priority(meeting.priority);

                    userResource.getUsers().then(function (members) {
                        $scope.formData.members = members;
                    });

                    personResource.getPersons().then(function (response) {
                        $scope.formData.persons = response;
                    });
                    $scope.loaded = true;
                    $scope.attachmentUploadUrl = '/api/knowledgeObject/' +
                        $scope.formData.meeting.knowledgeObject.id +
                        '/attachment';
                });
            }
        }

        var step1 = {
            name: 'step1',
            title: 'Goals and Agenda',
            templateUrl: 'views/main/meeting/plan-meeting-wizard-step-1.tpl.html',
            buttons: [
                {
                    label: 'Meeting evidence',
                    handler: function () {
                        $scope.activeState = $scope.states[1];
                    }
                }
            ]
        };

        var step2 = {
            name: 'step2',
            title: 'Meeting Evidence',
            templateUrl: 'views/main/meeting/plan-meeting-wizard-step-2.tpl.html'
        };

        function goToTagsAndClassificationStep() {
            $scope.activeState = $scope.states[2];
        }

        var step3 = {
            name: 'step3',
            title: 'Participants',
            templateUrl: 'views/main/meeting/plan-meeting-wizard-step-3.tpl.html'
        };

        $scope.states = [
            step1,
            step2,
            step3
        ];

        $scope.page = function (page) {
            $scope.activeState = $scope.states[page];
        }

        $scope.activeState = $scope.states[0];

        $scope.meetingDurationOptions = [
            {durationInMinutes: 15, description: '15 minutes'},
            {durationInMinutes: 30, description: '30 minutes'},
            {durationInMinutes: 45, description: '45 minutes'},
            {durationInMinutes: 60, description: '1 hour'},
            {durationInMinutes: 75, description: '1 hour 15 minutes'},
            {durationInMinutes: 90, description: '1 hour 30 minutes'},
            {durationInMinutes: 105, description: '1 hour 45 minutes'},
            {durationInMinutes: 120, description: '2 hours'},
            {durationInMinutes: 135, description: '2 hours 15 minutes'},
            {durationInMinutes: 150, description: '2 hours 30 minutes'},
            {durationInMinutes: 165, description: '2 hours 45 minutes'},
            {durationInMinutes: 180, description: '3 hours'},
            {durationInMinutes: 195, description: '3 hours 15 minutes'},
            {durationInMinutes: 210, description: '3 hours 30 minutes'}
        ];


        $scope.checkIfInRole = function (participant) {
            if ($scope.formData.meeting.sponsor.id == participant.id ||
                $scope.formData.meeting.reporter.id == participant.id ||
                $scope.formData.meeting.facilitator.id == participant.id)
                return true;
            else
                return false;
        };

        $scope.publishMeeting = function () {
            if($scope.formData.meeting.opportunity != null){
                $scope.formData.meeting.opportunity = {
                    id: $scope.formData.meeting.opportunity.id,
                    person: $scope.formData.meeting.opportunity.person
                };
                $scope.formData.meeting.contextOpportunityId = $scope.formData.meeting.opportunity.id;
            }
            meetingResource.save($scope.formData.meeting).then(function () {
                meetingResource.publish($scope.formData.meeting.id, $scope.formData.meeting).then(function () {
                    toastr.success('Meeting successfuly published');

                    if ($scope.actionItemId)
                        $scope.attachMeetingToActionItem();

                    $state.go('main.meetingDetails', {meetingId: $scope.formData.meeting.id});
                });
            });
        };


        function overrideGoals(goals) {
            $scope.formData.meeting.goals = goals;
        }

        function overrideAgendaItems(agendaItems) {
            $scope.formData.meeting.agendaItems = agendaItems;
        }

        function overrideTags(tags) {
            $scope.formData.meeting.knowledgeObject.tags = tags;
        }

        $scope.onMemberSelect = function (selectedMember) {
            withProgressIndicator(meetingResource.addInternalParticipant(getMeetingId(), selectedMember.id).then(
                function () {
                    $scope.formData.meeting.selected = null;
                    meetingResource.getAvailableMembers($scope.formData.meeting.id).then(function (members) {
                        $scope.formData.members = members;
                    });
                }
                )
            );
        };

        $scope.switchPanel = function (participant) {
            if (!participant.showPanel || participant.showPanel == false) {
                for (var i = 0; i < $scope.formData.meeting.internalParticipants.length; i++) {
                    $scope.formData.meeting.internalParticipants[i].showPanel = false;
                }
                participant.showPanel = true;
            } else {
                participant.showPanel = false;
            }
        }

        $scope.sanitizeDescriptionHtml = function(input) {
            if (!input)
                return input;
            var regex = /<(.+@.+)>/gi;
            var output = input.replace(regex, '$1');
            return output;
        }

        $scope.priority = function (value) {
            $scope.formData.meeting.priority = value;
            $scope.formData.meeting.priorityTxt = angular.globalSettingsConst.priorities[value];
        }

        $scope.executeTemplate = function () {
            meetingResource.executeTemplate($scope.formData.selectedTemplate.id, $scope.formData.meeting.id).then(
                function (xhr) {
                    $scope.ok();
                    meetingResource.getMeetingById($scope.formData.meeting.id).then(function (meeting) {
                        meeting.knowledgeObject.descriptionHtml = $scope.sanitizeDescriptionHtml(meeting.knowledgeObject.descriptionHtml);
                        $scope.formData.meeting = meeting;
                        $scope.formData.meeting.plannedStartTime = moment($scope.formData.meeting.plannedStartTime);
                        $scope.formData.meeting.plannedEndTime = moment($scope.formData.meeting.plannedEndTime);
                    });
                });
        };


        $scope.onMemberDelete = function (deletedMember) {
            withProgressIndicator(meetingResource.deleteInternalParticipant(getMeetingId(), deletedMember.id).then(
                function () {
                    for (var i = 0; i < $scope.formData.meeting.internalParticipants.length; i++) {
                        if ($scope.formData.meeting.internalParticipants[i].id == deletedMember.id) {
                            $scope.formData.meeting.internalParticipants.splice(i, 1);
                        }
                    }
                    meetingResource.getAvailableMembers($scope.formData.meeting.id).then(function (members) {
                        $scope.formData.members = members;
                    });
                }));
        };

        function getOrCreateMeeting() {
            if (angular.isDefined($scope.formData.meeting.id)) {
                $scope.formData.meeting.priorityTxt =
                    angular.globalSettingsConst.priorities[$scope.formData.meeting.priority];
                return $q.when($scope.formData.meeting);
            }
            return meetingResource.createDraft().then(function (meeting) {
                meeting.knowledgeObject.descriptionHtml = $scope.sanitizeDescriptionHtml(meeting.knowledgeObject.descriptionHtml);
                $scope.formData.meeting = meeting;
                $scope.formData.meeting.priorityTxt =
                    angular.globalSettingsConst.priorities[$scope.formData.meeting.priority];

                return meeting;
            });
        }

        $scope.onAddGoal = function () {
            $scope.class = "saving";
            getOrCreateMeeting().then(function (meeting) {
                withProgressIndicator(meetingResource.saveGoal(meeting.id, {})).then(function (savedGoal) {
                    $scope.formData.meeting.goals.push(savedGoal);
                    $scope.class = "done";
                });
            });
        };

        $scope.onSaveGaol = function (goalRef, item) {
            getOrCreateMeeting().then(function (meeting) {
                withProgressIndicator(meetingResource.saveGoal(meeting.id, goalRef))
                    .then(function (savedGoal) {
                        goalRef = savedGoal;
                        item['goal_' + item.goal.id].$setPristine();
                        $scope.onAddGoal();
                    });
            });
        };

        $scope.onDeleteGoal = function (goal) {
            withProgressIndicator(meetingResource.deleteGoal(goal.id)).then(overrideGoals);
        };

        $scope.onAddAgendaItem = function () {
            getOrCreateMeeting().then(function (meeting) {
                withProgressIndicator(meetingResource.saveAgendaItem(meeting.id, {})).then(function (savedAgendaItem) {
                    $scope.formData.meeting.agendaItems.push(savedAgendaItem);
                })
            });
        };

        $scope.onSaveAgendaItem = function (agendaItemRef, item) {
            getOrCreateMeeting().then(function (meeting) {
                withProgressIndicator(meetingResource.saveAgendaItem(meeting.id, agendaItemRef))
                    .then(function (savedAgendaItem) {
                        agendaItemRef = savedAgendaItem;
                        $scope.onAddAgendaItem();
                        item['agendaItem_' + item.agendaItem.id].$setPristine();
                    });
            });
        };

        $scope.onDeleteAgendaItem = function (agendaItem) {
            withProgressIndicator(meetingResource.deleteAgendaItem(agendaItem.id)).then(overrideAgendaItems);
        };

        $scope.moveUpAgendaItem = function (agendaItem) {
            withProgressIndicator(
                meetingResource.moveUpAgendaItem(agendaItem)
            ).then(overwriteAgendaItems);
        };

        $scope.moveDownAgendaItem = function (agendaItem) {
            withProgressIndicator(
                meetingResource.moveDownAgendaItem(agendaItem)
            ).then(overwriteAgendaItems);
        };

        $scope.canMoveAgendaItemUp = function (agendaItemRef) {
            return agendaItemRef.number > 0;
        };

        $scope.clearSearchTerm = function () {
            $scope.searchTerm = '';
        };

        $window.addEventListener('blur',
            function () {
                $mdSelect.hide();
                $mdMenu.hide();
            });


        $scope.canMoveAgendaItemDown = function (agendaItemRef) {
            return agendaItemRef.number < $scope.formData.meeting.agendaItems.length - 1;
        };

        $scope.onAssignGoalToAgendaItem = function (agendaItemRef, goalRef) {
            // TODO Should return an array of AgendaItems
            meetingResource.assignGoalToAgendaItem(agendaItemRef.id, goalRef.id).then(function () {
                meetingResource.getMeetingById(getMeetingId()).then(function (meeting) {
                    $scope.formData.meeting.agendaItems = meeting.agendaItems;
                });
            });
        };

        $scope.onUnassignGoalFromAgendaItem = function (agendaItemRef, goalRef) {
            // TODO Should return an array of AgendaItems
            meetingResource.unassignGoalFromAgendaItem(agendaItemRef.id, goalRef.id).then(function () {
                meetingResource.getMeetingById(getMeetingId()).then(function (meeting) {
                    $scope.formData.meeting.agendaItems = meeting.agendaItems;
                });
            });
        };

        function overwriteAgendaItems(agendaItems) {
            $scope.formData.meeting.agendaItems = agendaItems;
        }

        function withProgressIndicator(promise) {
            $scope.class = 'saving';
            promise.finally(function () {
                $scope.class = 'done';
            });
            return promise;
        }

        $scope.deleteMeeting = function () {
            meetingResource.delete($scope.formData.meeting.id).then(function () {
                $state.go('main.meetings');
            });
        };

        $scope.$on('fileuploadadd',
            function (e, data) {
                if (data.paramName == 'attachments') {
                    $scope.attachmentsOptions.url = $scope.attachmentUploadUrl;
                    data.url = $scope.attachmentUploadUrl;
                    data.submit().then(function () {
                        knowledgeObjectResource.getAttachments($scope.formData.meeting.knowledgeObject.id).then(
                            function (xhr) {
                                $scope.formData.meeting.knowledgeObject.attachments = xhr;
                            });
                    });
                }
            });

        $scope.fileUploadOptions = {
            url: '',
            autoUpload: false,
            xhrFields: {
                withCredentials: true
            }
        };

        $scope.attachmentsOptions = {
            autoUpload: false,
            url: '',
            xhrFields: {
                withCredentials: true
            }
        };

        $scope.onAttachmentDelete = function (data) {
            knowledgeObjectResource.deleteAttachment(data.id).then(function () {
            });
        }

     
    }

})();
