/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.knowledge')
        .factory('salesStagePopup', salesStagePopup);

    salesStagePopup.$inject = [
        'meetingResource', 'attachmentsHelper', 'httpResource', '$uibModal', 'salesResource', '$rootScope'
    ];

    function salesStagePopup(meetingResource, attachmentsHelper, httpResource, $uibModal, salesResource, $rootScope) {
        SalesStagePopupCtrl.$inject = ['$scope', '$uibModalInstance'];
        function SalesStagePopupCtrl($scope, $uibModalInstance) {

            attachmentsHelper.initialize($scope, null);
            $scope.selectedItem;
            $scope.exists = false;
            $scope.editMode = false;
            $scope.wrongCompleteness = false;
            $scope.wrongOppMaxAge = false;
            $scope.formData = {
                tempCompleteness : 0,
                tempOppMaxAge: 0,
                tempMilestones : [],
                tempTitle : "",
                tempDescription : "",
                newMilestone: ""
            };
            if ($scope.item) {
                $scope.editMode = true;
                $scope.formData.tempCompleteness = $scope.item.completeness;
                $scope.formData.tempOppMaxAge = $scope.item.opportunityMaxAge;
                $scope.formData.tempMilestones = $scope.item.milestones.slice(0);
                $scope.formData.tempTitle = $scope.item.title;
                $scope.formData.tempDescription = $scope.item.description;
            }

      
            function assignData() {
                $scope.item.completeness = $scope.formData.tempCompleteness;
                $scope.item.opportunityMaxAge = $scope.formData.tempOppMaxAge;
                $scope.item.milestones = $scope.formData.tempMilestones;
                $scope.item.title = $scope.formData.tempTitle;
                $scope.item.description = $scope.formData.tempDescription;
            }

            $scope.canMoveMilestoneItemUp = function(milestone) {
                var index = $scope.formData.tempMilestones.indexOf(milestone);
                return index !== 0;
            };

            $scope.canMoveMilestoneItemDown = function(milestone) {
                var index = $scope.formData.tempMilestones.indexOf(milestone);
                return index !== $scope.formData.tempMilestones.length - 1;
            };

            $scope.moveDownMilestoneItem = function(milestone) {
                var index = $scope.formData.tempMilestones.indexOf(milestone);
                $scope.formData.tempMilestones[index] = $scope.formData.tempMilestones[index + 1];
                $scope.formData.tempMilestones[index + 1] = milestone;
            };

            $scope.moveUpMilestoneItem = function(milestone) {
                var index = $scope.formData.tempMilestones.indexOf(milestone);
                $scope.formData.tempMilestones[index] = $scope.formData.tempMilestones[index - 1];
                $scope.formData.tempMilestones[index - 1] = milestone;
            };

            $scope.deleteMilestoneItem = function(milestone) {
                $scope.formData.tempMilestones = $scope.formData.tempMilestones.filter(x => x !== milestone);
            };

            $scope.addMilestone = function() {            
                if ($scope.formData.newMilestone.length === 0) return;

                var lowerized = $scope.formData.newMilestone.toLowerCase().trim();

                var exists = $scope.formData.tempMilestones.find(function(elem) {
                    return elem.toLowerCase() == lowerized;                    
                });

                if (exists) return;

                $scope.formData.tempMilestones.push($scope.formData.newMilestone.trim());
                $scope.formData.newMilestone = "";
            };

            $scope.addMilestoneOnEnter = function(event) {
                if (event.which === 13)
                    $scope.addMilestone();
            }

            $scope.ok = function() {

                $scope.wrongCompleteness = false;
                $scope.wrongOppMaxAge = false;

                //completeness as percentage value should be between 0 and 100
                if ($scope.formData.tempCompleteness > 100 || $scope.formData.tempCompleteness < 0)
                    $scope.wrongCompleteness = true;

                if ($scope.formData.tempOppMaxAge < 0 || isNaN($scope.formData.tempOppMaxAge)) {
                    $scope.wrongOppMaxAge = true;
                }

                if (!$scope.wrongCompleteness && !$scope.wrongOppMaxAge) {
                    //flag to indicate if stage with provided completeness exists
                    $scope.exists = false;
                    //check all stages in selected process (its accessed through parent scope)
                    angular.forEach($scope
                        .$parent
                        .formData
                        .processes
                        .filter(function(process) {
                            return process.id === $scope.processId;
                        })[0]
                        .stages,
                        function(it) {
                            if (it.completeness == $scope.tempCompleteness && it.id !== $scope.item.id) {
                                $scope.exists = true;
                            }
                        });

                    //update process only if stage is valid
                    if (!$scope.exists) {

                        if (!$scope.item) {
                            $scope.item = {};

                            assignData();
                            salesResource.postProcessStage($scope.processId, $scope.item).then(function (result) {
                                $scope.item = null;
                                if ($scope.callback)
                                    $scope.callback();
                                $uibModalInstance.close();
                            });
                        } else {
                            assignData();
                            salesResource.putProcessStage($scope.processId, $scope.item).then(function (result) {
                                $scope.item = null;
                                if ($scope.callback)
                                    $scope.callback();
                                $uibModalInstance.close();
                            });
                        }
                    }
                }
            };

            $scope.cancel = function() {
                // if ($scope.editMode) $scope.item.completeness = $scope.tempCompleteness;
                $scope.item = null;
                $uibModalInstance.dismiss('cancel');
            };
        }

        var open = function($scope, processId, item, callback) {
            $scope.item = null;
            if (item) {
                $scope.item = item;
            }

            $scope.callback = callback;
            $scope.processId = processId;

            var modalInstance = $uibModal.open({
                templateUrl: 'views/main/popups/sales.stage.popup.tpl.html?v=' + new Date(),
                controller: SalesStagePopupCtrl,
                scope: $scope
            });
        };

        return {
            "open": open
        };
    }
})();
