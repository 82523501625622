/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;


(function () {
    'use strict';

    var authorizationProvider = {
        isAuthenticated: [
            'authorization', function(authorization) {
                return authorization.isAuthenticated();
            }
        ],

        $get: [
            '$q', '$rootScope', 'authenticationResource', 'signalrHub',
            function($q, $rootScope, authenticationResource, signalrHub) {
                var service = {
                    isAuthenticated: function () {

                        if ($rootScope.authentication !== undefined) return $rootScope.authentication;

                        return authenticationResource.getAuthentication().then(function(authentication) {
                            if (authentication === null) {
                                return $q.reject('Access is denied');
                            } else {
                                $rootScope.authentication = authentication;
                                $rootScope.loggedUser = authentication.user;

                                // var hub = signalrHub("", "notificationHub");

                                //initialize signalR connection
                                // $rootScope.notificationHub = hub;

                                // hub.connection.start().done(function() {
                                // 
                                    // hub.proxy.invoke("Send", "Test message", "Test Description");
// 
// 
                                // });

                                // hub.proxy.on("broadcastMessage", function(title, message) {
                                //     alert(title+" "+message);
                                // });
                                
                                return authentication;
                            }
                        });
                    }
                };

                return service;
            }
        ]
    };

    angular.module('meetingMinutes.authorization')
        .provider('authorization', authorizationProvider);

})();
