/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.knowledge')
        .factory('removePopup', removePopup);

    removePopup.$inject = [
        '$state', 'attachmentsHelper', 'httpResource', '$uibModal', 'userResource', '$timeout', 'workgroupResource',
        'knowledgeObjectResource', 'toastr'
    ];

    function removePopup(
        $state,
        attachmentsHelper,
        httpResource,
        $uibModal,
        userResource,
        $timeout,
        workgroupResource,
        knowledgeObjectResource,
        toastr) {

        RemovePopupCtrl.$inject = ['$scope', '$uibModalInstance'];
        function RemovePopupCtrl($scope, $uibModalInstance) {
            // $scope.deleteKnowledgeObject = function() {
            //     knowledgeObjectResource.deleteKnowledgeObject($scope.koId).then(function(xhr) {
            //         toastr.success("Item has been deleted");
            //         if ($scope.callback) {
            //             $scope.callback();
            //             $scope.cancel();
            //         }

            //     })
            // }

            // knowledgeObjectResource.getPermissions($scope.koId).then(function(response) {
            //     $scope.permissions = response;
            // });

            $scope.confirm = function() {
                $scope.callback();
                $uibModalInstance.dismiss('ok');
            };

            $scope.cancel = function() {
                $uibModalInstance.dismiss('cancel');
            };
        }

        var open = function($scope, callback) {

            $scope.callback = callback;

            var callAtTimeout = function() {
                var modalInstance = $uibModal.open({
                    templateUrl: 'views/main/popups/remove.popup.tpl.html?v=' + new Date(),
                    controller: RemovePopupCtrl,
                    scope: $scope
                });
            }
            $timeout(callAtTimeout, 100);
        };

        return {
            "open": open
        };
    }
})();
