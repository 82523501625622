/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.repository.remote')
        .factory('popupHelper', popupHelper);

    popupHelper.$inject = [
        'selectOpportunityPopup', 'agendaPopup', 'goalsPopup',
        'guestParticipantsPopup', 'userParticipantsPopup', 'notePopup', 'personPopup'
    ];

    function popupHelper(selectOpportunityPopup,
        agendaPopup,
        goalsPopup,
        guestParticipantsPopup,
        userParticipantsPopup,
        notePopup,
        userPopup,
        personPopup) {

        var EditGoals = function($scope, meeting) {
            goalsPopup.open($scope, meeting);
        }

        var EditAgenda = function($scope, meeting) {
            agendaPopup.open($scope, meeting);
        }

        var EditGuestParticipants = function($scope, meeting) {
            guestParticipantsPopup.open($scope, meeting);
        };

        var EditUserParticipants = function($scope, meeting) {
            userParticipantsPopup.open($scope, meeting);
        };

        var FocusOpportunity = function($scope, context) {
            selectOpportunityPopup.open($scope, context);
        };


        var CreateMeetingNote = function($scope, callback) {
            var tags = [];

            tags.push("m:" + $scope.meeting.id);

            notePopup.create($scope, tags, callback);
        };

        var CreateKoNote = function($scope, tag, callback) {
            var tags = [];

            tags.push(tag);

            notePopup.create($scope, tags, callback);
        };

        var CreateTimelineNote = function($scope, id, callback) {
            var tags = [];

            tags.push("t:" + id);

            notePopup.create($scope, tags);
        };

        var OpenNote = function($scope, item, callback) {
            notePopup.open($scope, item, callback);
        };

        var OpenPerson = function($scope, item, callback) {
            personPopup.open($scope, item, callback);
        };

        var OpenUser = function($scope, item, callback) {
            userPopup.open($scope, item, callback);
        }

        return {
            "FocusOpportunity": FocusOpportunity,
            "CreateMeetingNote": CreateMeetingNote,
            "CreateTimelineNote": CreateTimelineNote,
            "EditUserParticipants": EditUserParticipants,
            "EditGuestParticipants": EditGuestParticipants,
            "EditGoals": EditGoals,
            "EditAgenda": EditAgenda,
            "OpenNote": OpenNote,
            "OpenPerson": OpenPerson,
            "OpenUser": OpenUser,
            "CreateKoNote": CreateKoNote
        };
    }
})();
