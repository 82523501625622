/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function () {
    'use strict';

    angular.module('meetingMinutes.main.profile')
        .controller('ProfileRefreshTokensController', ProfileRefreshTokensController);

    ProfileRefreshTokensController.$inject = [
        '$stateParams', '$state', '$rootScope', '$scope', 'userPopup', 'userResource',
        'authenticationResource', 'tokenResource', 'tokenClientPopup'
    ];

    function ProfileRefreshTokensController($stateParams,
        $state,
        $rootScope,
        $scope,
        userPopup,
        userResource,
        authenticationResource,
        tokenResource,
        tokenClientPopup) {

        $scope.formData = {};

        $rootScope.$context.$set('/profile/refreshtokens', $scope);

        activate();

        $scope.deleteToken = function(tokenId) {
            tokenResource.deleteRefeshToken(tokenId).then(function() {
                activate();
            });
        }
            
       
        function activate() {
            tokenResource.listRefreshTokens()
                    .then(function (response) {
                        $scope.formData.refreshTokens = response;
                    });
        }
    }
})();
