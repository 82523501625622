/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
'use strict';

angular.module('meetingMinutes.repository.remote')
  .factory('signalrHub', ['$rootScope', 
  function ($rootScope) {
    var cache = {};


    function backendFactory(serverUrl, hubName) {

      if (cache[hubName] !== undefined) return cache[hubName];

      var connection = $.hubConnection();
      var proxy = connection.createHubProxy(hubName);

      var callbacks = [];

      //There must be at least one listener in order to make proxy.on working
      proxy.on("onEvent", function() 
      {
        var args = arguments;

        $rootScope.$apply(function() {
            callbacks.forEach(function (callback) {
              callback.apply(this, args);
            });
        });
      });

      connection.start().done(function () {});

      var hub = {
        onEvent: function (callback) {
          callbacks.push(callback);
        },

        offEvent: function (callback) {
          callbacks = callbacks.filter(function (item) {
            return item !== callback;
          });
        }
      };

      cache[hubName] = hub;

      return hub;
    }

    return backendFactory;
}]);

})();
