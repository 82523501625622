angular.module('meetingMinutes.repository.remote',
    [
        'meetingMinutes.config',
        'ui.router',
        'meetingMinutes.common',
        'meetingMinutes.authorization',
        'meetingMinutes.repository',
        'ui.bootstrap',
        'ui.router',
        'ui.select',
        'datePicker',
        'toastr',
        'ui.calendar',
        'xeditable'
    ]);

require('./remote-todo-resource.service');