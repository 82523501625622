/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.knowledge')
        .factory('personPopup', personPopup);

    personPopup.$inject = ['attachmentsHelper', 'httpResource', '$uibModal', 'personResource'];

    function personPopup(attachmentsHelper, httpResource, $uibModal, personResource) {

        PersonPopupCtrl.$inject = ['$scope', '$uibModalInstance'];
        function PersonPopupCtrl($scope, $uibModalInstance) {

            $scope.ok = function() {
                noteResource.save($scope.item).then(function(result) {
                    $uibModalInstance.close();
                });

            };

            $scope.cancel = function() {
                $uibModalInstance.dismiss('cancel');
            };
        }

        var create = function($scope, tags) {
            $scope.tags = tags;

            open($scope);
        }

        var open = function($scope, item) {
            if (item) {
                $scope.item = item;
            }

            var modalInstance = $uibModal.open({
                templateUrl: 'views/main/popups/note.popup.tpl.html?v=' + new Date(),
                controller: PersonPopupCtrl,
                scope: $scope
            });
        }

        return {
            "open": open,
            "create": create
        };
    }
})();
