/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular
        .module('meetingMinutes.directives.domain')
        .directive('mmTags', tagsDirectiveFactory);

    function tagsDirectiveFactory() {
        return {
            restrict: 'E',
            templateUrl: 'views/directives/domain/tags.tpl.html?v=' + new Date(),
            replace: true,
            scope: {
                mmSelectedTags: '=',
                mmAvailableTags: '=',
                mmOnTagSelect: '&',
                mmOnTagDelete: '&'
            },
            link: link
        };

        function link(scope, element, attrs) {
            scope.$onTagSelect = $onTagSelect;
            scope.$onTagDelete = $onTagDelete;

            scope.$inputTextToTag = function(newTag) {
                return { text: newTag };
            };

            function $onTagSelect(tagRef) {
                scope.mmOnTagSelect({ tag: tagRef });
            }

            function $onTagDelete(tagRef) {
                scope.mmOnTagDelete({ tag: tagRef });
            }

        }
    }

})();
