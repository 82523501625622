/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.knowledge.meeting')
        .factory('selectUserPopup', selectUserPopup);

    selectUserPopup.$inject = ['$uibModal', 'userResource'];

    function selectUserPopup($uibModal, userResource) {

        SelectUserPopupCtrl.$inject = ['$scope', '$uibModalInstance'];
        function SelectUserPopupCtrl($scope, $uibModalInstance) {

            userResource.getUsers().then(function(users) {
                $scope.members = users;
            });

            $scope.addUser = function(user) {
                $scope.addUserCallback(user);
                $uibModalInstance.close();
            };

            $scope.ok = function() {
                $uibModalInstance.close();
            };

            $scope.cancel = function() {
                $uibModalInstance.dismiss('cancel');
            };
        }

        var open = function($scope, addUserCallback, obj) {
            $scope.customData = obj;
            $scope.addUserCallback = addUserCallback;

            var modalInstance = $uibModal.open({
                templateUrl: 'views/main/popups/select-user.popup.tpl.html?v=' + new Date(),
                controller: SelectUserPopupCtrl,
                scope: $scope
            });
        }

        return {
            "open": open,
        };
    }
})();
