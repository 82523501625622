/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function () {
        'use strict';

        angular.module('meetingMinutes.main.knowledge')
            .factory('meetingQuickPlan', meetingQuickPlan);

        meetingQuickPlan.$inject = ['$rootScope', '$uibModal', 'dateHelper', 'meetingResource', 'knowledgeObjectResource', '$location', '$state',
            'selectTagsPopup', 'userResource', 'personResource', 'toastr', 'todoResource', 'crmResource', '$q'];

        function meetingQuickPlan($rootScope,
                                  $uibModal,
                                  dateHelper,
                                  meetingResource,
                                  knowledgeObjectResource,
                                  $location,
                                  $state,
                                  selectTagsPopup,
                                  userResource,
                                  personResource,
                                  toastr,
                                  todoResource,
                                  crmResource,
                                  $q) {

            PopupQuickPlanCtrl.$inject = ['$scope', '$uibModalInstance'];
            function PopupQuickPlanCtrl($scope, $uibModalInstance) {

                $scope.searchText = null;
                $scope.tags = [];
                $scope.continue = false;
                $scope.valid = true;
                $scope.title = "";
                $scope.selectedOpportunity = null;
                $scope.submitClicked = false;
                $scope.formData.externalParticipants = null;

                var op = crmResource.getOpenedOpportunitiesList();
                op.then(function (data) {
                    $scope.availableOpportunities = data;
                    if ($scope.preselectedOppId) {
                        for (let index = 0; index < $scope.availableOpportunities.length; index++) {
                            const element = $scope.availableOpportunities[index];
                            if (element.id == $scope.preselectedOppId) {
                                $scope.selectedOpportunity = element;
                                break;
                            }
                        }
                    }
                });

                if ($rootScope.meetingToDo) {
                    $scope.title = $rootScope.meetingToDo.title;
                }

                

                if ($scope.formData.members == null) {
                    userResource.getUsers().then(function (members) {
                        $scope.formData.members = members;
                        getLeads();
                    });
                }
                else {
                    getLeads();
                }
                    

                

                $scope.submit = function () {
                    $scope.submitClicked = true;


                    if (!$scope.title)
                    {
                        $scope.valid = false;
                        $scope.submitClicked = false;
                    }
                    else {
                        $scope.valid = true;
                        toastr.success("We are creating your activity.")
                        meetingResource.createDraft().then(function (meeting) {
                            var duration = 15;
                            $scope.formData.meeting = meeting;

                            //Here meetingToDo saved on root scope is used in meeting creation. It should be changed into
                            //some other system of passing data of any kind into meeting creation controller or method.
                            if ($rootScope.meetingToDo) {
                                knowledgeObjectResource.transferAttachments($rootScope.meetingToDo.koId, $scope.formData.meeting.knowledgeObject.id)
                                    .then(function(xhr) {
                                        
                                    });
                                todoResource.deleteTodo($rootScope.meetingToDo.id).then(function(xhr) {


                                });
                                $scope.formData.meeting.knowledgeObject.descriptionHtml = $rootScope.meetingToDo.descriptionTxt;
                                $scope.formData.meeting.knowledgeObject.shortDescription = $rootScope.meetingToDo.shortDescription;
                                $scope.formData.meeting.knowledgeObject.descriptionTxt = $rootScope.meetingToDo.descriptionTxt;

                                if($rootScope.meetingToDo.dto)
                                    if($rootScope.meetingToDo.dto.duration > 0)
                                        duration =  $rootScope.meetingToDo.dto.duration;
                                $rootScope.meetingToDo = null;
                            }
                            $scope.formData.meeting.knowledgeObject.title = $scope.title;

                            $scope.formData.meeting.plannedStartTime = new Date();
                            //planned end time is in default 15min after start time
                            $scope.formData.meeting.plannedEndTime = addMinutes($scope.formData.meeting.plannedStartTime, duration);

                            if($scope.selectedOpportunity != null){
                                //all needed opportunity data
                                $scope.formData.meeting.opportunity = {
                                    id: $scope.selectedOpportunity.id,
                                    person: $scope.selectedOpportunity.person,
                                };
                                //id of meeting related opportunity
                                $scope.formData.meeting.contextOpportunityId = $scope.selectedOpportunity.id;
                            }

                            if ($location.$$search.tags) {
                                $scope.formData.meeting.communication = $location.$$search.tags;
                            }

                            $scope.formData.meeting.externalParticipants =  $scope.formData.externalParticipants;
                            $scope.formData.meeting.internalParticipants =  $scope.formData.internalParticipants;
                            $scope.formData.meeting.knowledgeObject.tags = $scope.tags;

                            meetingResource.save($scope.formData.meeting).then(function successCallback(xhr) {
                                $scope.formData.meeting = xhr;
                                meetingResource.publish($scope.formData.meeting.id, $scope.formData.meeting).then(function successCallback() {
                                    var dto = {
                                        id: $scope.formData.meeting.id,
                                        endTime: $scope.formData.meeting.plannedEndTime
                                    };

                                    meetingResource.start(dto).then(function () {
                                        $uibModalInstance.close();
                                        $location.path('/meeting/timeline/' + $scope.formData.meeting.id);                                        
                                    });
                                },function errorCallback(response) {
                                        $uibModalInstance.close();
                                        $state.go('main.meetings');
                                        toastr.error("An error has occurred during activity creation.")
                                    });
                            },function errorCallback(response) {
                                $uibModalInstance.close();
                                $state.go('main.meetings');
                                toastr.error("An error has occurred during activity creation.")
                            });
                        });
                    }


                    function addMinutes(date, minutes) {
                        return new Date(date.getTime() + minutes * 60000);
                    }
                };

                $scope.cancel = function () {
                    $uibModalInstance.close();
                };

                $scope.selectTagsPopup = function () {
                    var config = {};
                    config.common = true;
                    selectTagsPopup.open($scope, $scope.tags, config, null);
                };

                $scope.OpenModalSelectInternalParticipant = function () {
                    $uibModal.open({
                        templateUrl: 'views/main/meeting/select.internalParticipant.tpl.html?v=' + new Date(),
                        controller: SelectTemplateCtrl,
                        scope: $scope
                    });
                };

                $scope.OpenModalSelectExternalParticipant = function () {
                    $uibModal.open({
                        templateUrl: 'views/main/meeting/select.externalParticipant.tpl.html?v=' + new Date(),
                        controller: SelectTemplateCtrl,
                        scope: $scope
                    });
                };

                $scope.querySearchForMembers = function (query) {
                    var results = query ? $scope.formData.members.filter(createFilterForMember(query)) : [];
                    return results;
                };

                $scope.querySearchForPersons = function (query) {
                    var results = query ? $scope.formData.persons.filter(createFilterForPerson(query)) : [];
                    return results;
                };

                function selectLead() {
                    if ($scope.preselectedLeadId) {
                        for (let index = 0; index < $scope.formData.persons.length; index++) {
                            const element = $scope.formData.persons[index];
                            if (element.id == $scope.preselectedLeadId) {
                                $scope.formData.externalParticipants = [element];
                                break;
                            }
                        }
                    }
                }

                function getAllMembersNames() {
                    return $scope.formData.members.map(function (x) {
                        return x.userName;
                    });
                }

                function getLeads() {
                    if ($scope.formData.persons == null) {
                        var canceller = $q.defer();
                        personResource.getPersonsWithFilter(getAllMembersNames(), canceller).then(function (response) {
                            $scope.formData.persons = response;
                            selectLead();
                        });
                    } else {
                        selectLead();
                    }
                };

                function createFilterForMember(query) {
                    var lowercaseQuery = angular.lowercase(query);
                    return function filterFn(vegetable) {
                        return (vegetable.userName.toLowerCase().indexOf(lowercaseQuery) === 0) ||
                            (vegetable.userEmail.toLowerCase().indexOf(lowercaseQuery) === 0);
                    };
                }

                function createFilterForPerson(query) {
                    var lowercaseQuery = angular.lowercase(query);
                    return function filterFn(vegetable) {
                        return (vegetable.name.toLowerCase().indexOf(lowercaseQuery) === 0)
                    };
                }

                SelectTemplateCtrl.$inject = ['$scope', '$uibModalInstance'];
                function SelectTemplateCtrl($scope, $uibModalInstance) {

                    if ($scope.formData.members == null) {
                        userResource.getUsers().then(function (members) {
                            $scope.formData.members = members;
                            getLeads();
                        });
                    }
                    else {
                        getLeads();
                    }

                    if($scope.formData.internalParticipants == null)
                        $scope.formData.internalParticipants = [];
                    if($scope.formData.externalParticipants == null)
                        $scope.formData.externalParticipants = [];

                    $scope.addMember = function (member) {
                        if(member.color){
                            $scope.formData.internalParticipants.splice($scope.formData.internalParticipants.indexOf(member), 1);
                            member.color = false;
                        }
                        else {
                            $scope.formData.internalParticipants.push(member);
                            member.color = true;
                        }
                    };

                    $scope.addGuest = function (guest) {
                        if(guest.color) {
                            $scope.formData.externalParticipants.splice($scope.formData.externalParticipants.indexOf(guest), 1);
                            guest.color = false;
                        }
                        else {
                            $scope.formData.externalParticipants.push(guest);
                            guest.color = true;
                        }
                    };

                    $scope.search = {
                        userName: ""
                    };

                    $scope.ok = function () {
                        $uibModalInstance.close();
                    };

                    $scope.cancel = function () {
                        $uibModalInstance.dismiss('cancel');
                    };
                };

            }

            var open = function ($scope, item, callback, oppId, leadId) {
                if (item) {
                    $scope.item = item;
                }

                $scope.callback = callback;

                var modalInstance = $uibModal.open({
                    templateUrl: 'views/main/popups/meeting-quick-plan.popup.tpl.html?v=' + new Date(),
                    controller: PopupQuickPlanCtrl,
                    scope: $scope,
                    backdrop: 'static',
                    keyboard: false
                });
                var currentDateTime = new Date();
                $scope.start = {
                    time: dateHelper.enchance(currentDateTime).hour_minutes,
                    date: currentDateTime
                };
                $scope.end = {
                    time: dateHelper.enchance(currentDateTime).hour_minutes,
                    date: currentDateTime
                };

                    $scope.preselectedOppId = oppId;
                    $scope.preselectedLeadId = leadId;
            };

            return {
                "open": open,
            };
        }
    })();

