(function() {
    'use strict';

    function registerRouteAuthorizationErrorHandler($rootScope, $state, $log) {
        $rootScope.$on('$stateChangeError',
            function(event, toState, toParams, fromState, fromParams, error) {
                $log.error(error);
                $state.go('public.dispatch');
            });
    }

    registerRouteAuthorizationErrorHandler.$inject = ['$rootScope', '$state', '$log'];

    angular.module('meetingMinutes.public',
        [
            'meetingMinutes.repository',
            'ui.router',
            'toastr',
            'ui.bootstrap'
        ]).run(registerRouteAuthorizationErrorHandler);

})();