import * as React from 'react';
import { TodoDto } from '../../store/models/todo';
import TodoView from './TodoView';
import { ITodoModalModel } from '../../store/uimodels/uitodo';

export interface TodoListProps { 
    items: TodoDto[],
    onInstantAngular: (todo: any) => void;
}


interface TodoListActions {
    uiTodoOpenModal: (model: ITodoModalModel) => void;
    listTodoRequest: () => void;
    doneTodoRequest: (todoId: number) => void;
}

export default class TodoList extends React.PureComponent<TodoListProps & TodoListActions, {}> {
    
    componentDidMount() {
        this.props.listTodoRequest();
    }

    onEdit = (item: TodoDto) => {
        this.props.uiTodoOpenModal({
            todo: item
        });
    }

    onDone = (item: TodoDto) => {
        this.props.doneTodoRequest(item.id);
    }

    onInstant = (item: TodoDto) => {
        this.props.onInstantAngular(item);
    }

    render() {
        if (this.props.items === undefined || this.props.items.length == 0) return '';

        var list = this.props.items.map((item) => 
        <div key={item.id}>
            <TodoView item={item} onEdit={this.onEdit} onDone={this.onDone} onInstant={this.onInstant} />
        </div>)

        return (
<div>
    <div>
        <label>To-Dos ({this.props.items.length})</label>
    </div>

    <div className="hpanel hred">
        <div className="panel-body list">
            <div>
                <small>
                    {list}
                </small>
            </div>
        </div>
    </div>
</div>
        );
    }
}