/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.repository.remote')
        .factory('ajaxErrorInterceptor', ajaxErrorInterceptor)
        .config(registerAjaxErrorInterceptor);

    registerAjaxErrorInterceptor.$inject = ['$httpProvider'];

    function registerAjaxErrorInterceptor($httpProvider) {
        $httpProvider.interceptors.push('ajaxErrorInterceptor');
    }

    ajaxErrorInterceptor.$inject = ['$q', '$injector'];

    function ajaxErrorInterceptor($q, $injector) {
        return {
            responseError: function(rejection) {
                // FIXME This transition doesn't work :(
                //$injector.get('$state').go('public.error');
                return $q.reject(rejection);
            }
        };
    }

})();
