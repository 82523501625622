/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.knowledge.meeting')
        .controller('MeetingDetailsController', MeetingDetailsController);

    MeetingDetailsController.$inject = [
        'popupHelper', 'notePopup', 'userParticipantsPopup', 'guestParticipantsPopup', 'agendaPopup', 'goalsPopup',
        '$scope', '$rootScope', '$location', '$stateParams', 'meetingResource', 'authenticationResource', '$uibModal',
        '$state', 'dateHelper', 'noteResource', 'dateHelper', 'toastr', 'accessPopup'
    ];

    StartMeetingCtrl.$inject = [
        '$rootScope',
        '$scope',
        '$uibModalInstance',
        'meetingResource',
        '$state',
        'dateHelper',
    ];

    function StartMeetingCtrl($rootScope,
                            $scope,
                            $uibModalInstance,
                            meetingResource,
                            $state,
                            dateHelper,
    )
    {

        if ($scope.meeting.duration == 0) {
            $scope.meeting.duration = 15;
        }

        $scope.entryMessage = $rootScope.cutEntryMessage();

        $scope.estimation = {
            cost: 0,
            duration: 0,
            durationFormatted: null
        };


        $scope.plannedStartTimeFormatted = null;

        function getFirstPart(str, separator) {
            return str.slice(0, str.indexOf(separator));
        }

        function getSecondPart(str, separator) {
            return str.split(separator)[1];
        }

        $scope.meeting.realStartTime = new Date();

        $scope.meeting.plannedEndTime = addMinutes($scope.meeting.realStartTime, $scope.meeting.duration);
        // $scope.meeting.endTime = addMinutes($scope.meeting.realStartTime, $scope.meeting.duration);

        if ($scope.meeting.opportunity) {
            $rootScope.setOpportunityContext($scope.meeting.opportunity);
        }

        // $scope.selectedEndHour = moment($scope.meeting.endTime).format("HH:mm");
        $scope.selectedEndHour = moment($scope.meeting.plannedEndTime).format("HH:mm");

        $scope.$watch('selectedEndHour',
            function() {
                $scope.estimateMeeting();
            });

        function addMinutes(date, minutes) {
            return new Date(date.getTime() + minutes * 60000);
        }

        $scope.estimateMeeting = function() {

            var request = {
                id: $scope.meeting.id,
                startTime: $scope.meeting.realStartTime,
                endTime: $scope.meeting.plannedEndTime,
            };

            meetingResource.estimate(request).then(function(response) {
                $scope.estimation.duration = response.duration;
                if (response.duration > 0) {
                    $scope.estimation.durationEx = dateHelper.friendlyDuration(response.duration);
                }
                if (response.duration < 0) {
                    $scope.estimation.durationEx = "Unable to estimate";
                }
            });
        }

        $scope.realStartTime = moment(Date()).format("HH:mm");


        $scope.ok = function() {
            $uibModalInstance.close();
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.runMeeting = function() {
            var dto = {
                id: $scope.meeting.id,
                // endTime: $scope.meeting.endTime
                endTime: $scope.meeting.plannedEndTime
            };

            meetingResource.start(dto).then(function() {
                $state.go('main.meetingTimeline', { meetingId: $scope.meeting.id });
            });
        }

        $scope.estimateMeeting();
    }

    CloseMeetingCtrl.$inject = [
        '$scope', '$uibModalInstance'
    ];

    function CloseMeetingCtrl($scope, $uibModalInstance) {
        $scope.ok = function() {
            $uibModalInstance.close();
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }

    function MeetingDetailsController(popupHelper,
                                    notePopup,
                                    userParticipantsPopup,
                                    guestParticipantsPopup,
                                    agendaPopup,
                                    goalsPopup,
                                    $scope,
                                    $rootScope,
                                    $location,
                                    $stateParams,
                                    meetingResource,
                                    authenticationResource,
                                    $uibModal,
                                    $state,
                                    dateHelper,
                                    noteResource,
                                      toastr,
                                      accessPopup                                  
    ) {
        var meetingId = +$stateParams.meetingId;
        $scope.timelineContainsAttachments = false;

        $scope.pastFollowUps = [];
        meetingResource.getPastFollowUps(meetingId).then(function(xhr) {
            $scope.pastFollowUps = xhr;
        });

        $scope.futureFollowUps = [];
        meetingResource.getFutureFollowUps(meetingId).then(function(xhr) {
            $scope.futureFollowUps = xhr;
        });

        $scope.removeActivity = function() {
            $rootScope.removePopup($scope, function() {
                meetingResource.delete(meetingId)
                    .then(function (xhr){
                            $state.go('main.meetings');
                        });
            });
        };

        $scope.sharePopup = function() {
            // accessPopup.open($scope, $scope.meeting, null, null);
            $rootScope.sharePopup($scope, $scope.meeting, null);
        };

        $scope.status = "";
        authenticationResource.getAuthentication().then(function(authentication) {
            $scope.loggedUser = authentication;
            $rootScope.authentication = authentication;
        });

        $scope.suggestionText = "";

        $scope.formatSuggestionDate = function(item) {
            return moment(item).format('MMMM Do YYYY, h:mm:ss');
        };

        $scope.searchRelatedNotes = function() {
            // var request = {
            //     from: 0,
            //     to: 100,
            //     take: 100,
            //     tagFilter: []
            // };
            // request.tagFilter.push('m:' + meetingId);
            // noteResource.getNotes(request).then(function(result) {
            //     for (var i = 0; i < result.results.length; i++) {
            //         result.results[i].createTime = dateHelper.enchance(result.results[i].createTime);
            //
            //     }
            //
            //     $scope.meeting.relatedNotes = result;
            // });
        };

        $scope.startMeeting = function() {
            var modalInstance = $uibModal.open({
                templateUrl: 'views/main/meeting/start.tpl.html?v=' + new Date(),
                controller: StartMeetingCtrl,
                scope: $scope
            });
        };

        $scope.closeMeeting = function() {
            var modalInstance = $uibModal.open({
                templateUrl: 'views/main/meeting/close.tpl.html?v=' + new Date(),
                controller: CloseMeetingCtrl,
                scope: $scope
            });
        };

        $scope.abandonMeeting = function() {
            meetingResource.deleteInternalParticipant($scope.meeting.id, $scope.loggedUser.user.id).then(
                function(response) {
                    toastr.success('You have abandoned the meeting');
                    $scope.viewMeeting($scope.meeting.id);
                },
                function(response) {
                    // toastr.info('You are not invited');
                    $state.go('main.meetings');
                }
            );
        };

        $scope.viewMeeting = function(id) {
            $state.go('main.meetingDetails', { meetingId: id });
        };

        $scope.suggest = function() {
            var dto = {
                meetingId: meetingId,
                text: $scope.suggestionText
            };

            meetingResource.suggestion(dto).then(function() {
                $scope.suggestionText = "";
                meetingResource.getSuggestions($scope.meeting.id).then(function(response) {
                        $scope.suggestions = response;
                    }
                );
            });
        };

        $scope.planMeeting = function() {
            $state.go('main.planMeeting', { meetingId: $scope.meeting.id });
        };

        $scope.timeline = function() {
            $state.go('main.meetingTimeline', { meetingId: $scope.meeting.id });
        };

        $scope.suggestions = [];

        $scope.meetingMinutes = function() {
            $state.go('main.minutes', { meetingId: $scope.meeting.id });
        };

        $scope.followUp = function() {
            meetingResource.planNewMeeting($scope.meeting.id,
                null,
                meetingResource.meetingDetailsUrl($scope.meeting.id));
        };

        meetingResource.getMeetingById(meetingId).then(function(meeting) {
            $rootScope.meetingId = meeting.id;

            if (meeting.realStartTime)
                meeting.realStartTimeEx = dateHelper.enchance(meeting.realStartTime);
            if (meeting.plannedStartTime)
                meeting.plannedStartTimeEx = dateHelper.enchance(meeting.plannedStartTime);
            if (meeting.plannedEndTime)
                meeting.plannedEndTimeEx = dateHelper.enchance(meeting.plannedEndTime);
            if (meeting.realEndTime)
                meeting.realEndTimeEx = dateHelper.enchance(meeting.realEndTime);

            meetingResource.getSuggestions(meeting.id).then(function(response) {
                    $scope.suggestions = response;
                }
            );
            //meeting = dateHelper.enchanceMeeting(meeting);

            meeting.isCoowner = false;

            if (meeting.opportunity && meeting.opportunity.coownersIds) {
                meeting.isCoowner = meeting.opportunity.coownersIds.indexOf($scope.loggedUser.user.id) >= 0;
            }

            $scope.meeting = meeting;
            $scope.searchRelatedNotes();
            $scope.status = meetingResource.translateStatus(meeting.status);
            $scope.authorization = {};
            $scope.$context = $rootScope.$context.$set('/meeting/view');
            $scope.enableEditButton = meeting.status !== 4 && meeting.status !== 2 && meeting.isSponsor === true;
            $scope.enableStartButton = meeting.status === 0 && (meeting.isFacilitator || meeting.isSponsor);
            $scope.enableCancelButton = meeting.status === 0 && meeting.isSponsor;
            $scope.enableFollowUpButton = meeting.status === 4 || meeting.status === 2;
            $scope.enableCloseButton = meeting.status === 1 && (meeting.isSponsor || meeting.isReporter);
            $scope.enableAbandonButton = meeting.status === 0 &&
                (meeting.isSponsor === false && meeting.isFacilitator === false && meeting.isReporter === false);
            $scope.enableJoinButton = meeting.status !== 0 && (meeting.isParticipant || meeting.isSponsor || meeting.isCoowner);
            $scope.enableMeetingMinutesButton = meeting.status === 2 && meeting.isParticipant;

            authenticationResource.getAuthentication().then(function(x) {
                $scope.authorization = x;
            });

            $scope.getMeetingTimeline(meetingId);
        });

        $scope.updateAgenda = function() {
            popupHelper.EditAgenda($scope.meeting);
        };

        $scope.updateGoals = function() {
            popupHelper.EditGoals($scope.meeting);
        };

        $scope.updateGuestParticipants = function() {
            popupHelper.EditGuestParticipants($scope.meeting);
        };

        $scope.updateUserParticipants = function() {
            popupHelper.EditUserParticipants($scope.meeting);
        };

        $scope.createRelatedNote = function() {
            popupHelper.CreateMeetingNote($scope, $scope.searchRelatedNotes);
        };

        $scope.getMeetingTimeline = function (meetingId) {
            meetingResource.getTimeline(meetingId).then(function (response) {
                $scope.timelineItems = response.items;
                for(var i = 0; i < response.items.length; i++)
                    if(response.items[i].knowledgeObject){
                        if(response.items[i].knowledgeObject.attachments && response.items[i].knowledgeObject.attachments.length > 0)
                            $scope.timelineContainsAttachments = true;
                    }
            })
        };

        $scope.dateToString = function (date) {
            return dateHelper.enchance(date);
        }
    }
})();
