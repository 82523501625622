/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    function SignOutController($state, authenticationResource, msgraphCalendarResource) {

        authenticationResource.signOut()
            .then(transitionToDispatchState);

        msgraphCalendarResource.clearAuthToken();

        function transitionToDispatchState() {
            $state.go('public.dispatch');
        }

    }

    SignOutController.$inject = ['$state', 'authenticationResource', 'msgraphCalendarResource'];

    angular.module('meetingMinutes.public')
        .controller('SignOutController', SignOutController);

})();

