/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.repository.remote')
        .factory('salesResource', remoteSalesResourceFactory);

    remoteSalesResourceFactory.$inject = ['httpResource'];

    function remoteSalesResourceFactory(httpResource) {

        function processes() {
            return httpResource.get('/crm/processes');
        }

        function postProcess(data) {
            return httpResource.post('/crm/process', data);
        }

        function putProcess(data) {
            return httpResource.put('/crm/process', data);
        }

        function postProcessStage(id, data) {
            return httpResource.post('/crm/process/' + id + '/stage', data);
        }

        function putProcessStage(id, data) {
            return httpResource.put('/crm/process/'+id+'/stage', data);
        }

        function deleteProcessStage(id, stageId) {
            return httpResource.delete('/crm/process/'+id+'/stage/' + stageId);
        }

        return {
            "deleteProcessStage": deleteProcessStage,
            "putProcessStage": putProcessStage,
            "postProcessStage": postProcessStage,
            "putProcess": putProcess,
            "postProcess": postProcess,
            "processes": processes
        };
    }
})();
