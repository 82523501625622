/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.repository.remote')
        .factory('contactResource', remoteContactResourceFactory);

    remoteContactResourceFactory.$inject = ['$http', 'REMOTE_API_PREFIX', 'httpResource'];

    function remoteContactResourceFactory($http, REMOTE_API_PREFIX, httpResource) {

        function sendMessage(message) {
            return httpResource.post('/contact/message', message);
        }

        function reportProblem(problem) {
            return $http.post(REMOTE_API_PREFIX + '/contact/problem', problem);
        }

        return {
            sendMessage: sendMessage,
            reportProblem: reportProblem
        }
    }

})();
