angular.module('meetingMinutes.main',
    [
        'meetingMinutes.authorization',
        'ui.router',
        'angles',
        'ng-peity',
        'ion.rangeslider',
        'ui.knob',
        'NgSwitchery',
        'ngMaterial',
        'toastr',
        'mdPickers',
        'ui.bootstrap',
        '720kb.datepicker',
        'pikaday',
        'ngStorage',
        'datatables',
        'datatables.buttons',
        'datatables.select'
    ]);