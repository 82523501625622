/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.repository.remote')
        .factory('taskHelper', taskHelper);

    taskHelper.$inject = ['httpResource'];

    function taskHelper(httpResource) {

        var priorities = [
            {
                priority: 0,
                title: "A - most important",
            }, {
                priority: 1,
                title: "B - very important",
            }, {
                priority: 2,
                title: "C - nice to do",
            }, {
                priority: 3,
                title: "D - better delegate",
            }, {
                priority: 4,
                title: "E - delete it at once",
            }
        ];

        return {
            "priorities": priorities
        };
    }

})();
