/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.draft')
        .controller('DraftMeetingsController', DraftMeetingsController);

    DraftMeetingsController.$inject = ['$scope', '$state', 'meetingResource'];

    function DraftMeetingsController($scope, $state, meetingResource) {
        // $scope.edit = false;
        // $scope.onToggleEdit = onToggleEdit;
        // $scope.onDelete = onDelete;
        // $scope.isDeleteConfirmationVisible = isDeleteConfirmationVisible;
        // $scope.onMeetingClick = onMeetingClick;
        // $scope.onConfirmDelete = onConfirmDelete;
        // $scope.onCancelDelete = onCancelDelete;
        //
        // activate();
        //
        // function activate() {
        //     getMeetings();
        // }
        //
        // function getMeetings() {
        //     meetingResource.getDrafts().then(function(meetings) {
        //         $scope.edit = false;
        //         $scope.meetings = meetings;
        //         $scope.displayDeleteConfirmation = {};
        //         angular.forEach(meetings,
        //             function(meeting) {
        //                 $scope.displayDeleteConfirmation[meeting.id] = false;
        //             });
        //     });
        // }
        //
        // function onToggleEdit() {
        //     $scope.edit = !$scope.edit;
        // }
        //
        // function onDelete(meeting) {
        //     $scope.displayDeleteConfirmation[meeting.id] = true;
        // }
        //
        // function isDeleteConfirmationVisible(meeting) {
        //     return $scope.displayDeleteConfirmation[meeting.id];
        // }
        //
        // function onConfirmDelete(meeting) {
        //     meetingResource.delete(meeting.id).then(getMeetings);
        // }
        //
        // function onCancelDelete(meeting) {
        //     $scope.displayDeleteConfirmation[meeting.id] = false;
        // }
        //
        // function onMeetingClick(meeting) {
        //     meetingResource.getMeetingById(meeting.id).then(function(meeting) {
        //         $state.go('main.planMeeting', { meetingId: meeting.id });
        //     });
        // }

    }

})();
