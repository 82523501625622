/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.knowledge.meeting')
        .factory('selectTagsPopup', selectTagsPopup);

    selectTagsPopup.$inject = ['$rootScope', 'httpResource', '$uibModal', 'knowledgeObjectResource', 'tagsResource'];

    function selectTagsPopup($rootScope, httpResource, $uibModal, knowledgeObjectResource, tagsResource) {

        SelectTagsPopupCtrl.$inject = ['$scope', '$uibModalInstance'];
        function SelectTagsPopupCtrl($scope, $uibModalInstance) {

            $scope.addTag = function(tag) {
                if ($scope.tags.indexOf(tag) === -1) {
                    $scope.tags.push(tag);
                }
            };

            $scope.ok = function() {
                $uibModalInstance.close();
            };

            $scope.cancel = function() {
                $uibModalInstance.dismiss('cancel');
            };
        }

        var open = function($scope, tags, config, callback) {
            $scope.tags = tags;
            $scope.config = config;
            $scope.callback = callback;
            $scope.availableTags = [];
            $scope.specialTags = [];

            var translateTags = function(tags) {
                var all = [];
                for (var i = 0; i < tags.length; i++) {
                    var special = tagsResource.translateTag(tags[i]);
                    all.push(tags[i]);
                    if (special !== null) {
                        $scope.specialTags[tags[i]] = {
                            tag: tags[i],
                            translated: special
                        };
                    }
                }

                return all;
            };

            var filterTags = function() {

            };

            if (config.profile) {

                $scope.tagDef = $rootScope.getTagDefinition('Profile');
                knowledgeObjectResource.getAvailableTags_Profile().then(function(xhr) {
                    $scope.availableTags = translateTags(xhr);
                    $scope.searchText = "";

                });
            }

            if (config.person) {
                $scope.tagDef = $rootScope.getTagDefinition('Person');
                knowledgeObjectResource.getAvailableTags_Person().then(function(xhr) {
                    $scope.availableTags = translateTags(xhr);
                    $scope.searchText = "";

                });
            }

            if (config.meeting) {
                $scope.tagDef = $rootScope.getTagDefinition('Meeting');
                knowledgeObjectResource.getAvailableTags_Meeting().then(function(xhr) {
                    $scope.availableTags = translateTags(xhr);
                    $scope.searchText = "";

                });
            }

            if (config.communication) {
                $scope.tagDef = $rootScope.getTagDefinition('Communication');
                knowledgeObjectResource.getAvailableTags_Communication().then(function(xhr) {
                    $scope.availableTags = translateTags(xhr);
                    $scope.searchText = "";

                });
            }

            if (config.common) {
                $scope.tagDef = $rootScope.getTagDefinition('Tag');
                knowledgeObjectResource.getTags().then(function(xhr) {
                    $scope.availableTags = translateTags(xhr);
                    $scope.searchText = "";
                });
            }

            if (config.branch) {
                $scope.tagDef = $rootScope.getTagDefinition('Branch');
                knowledgeObjectResource.getTagsByType($scope.tagDef.id).then(function(xhr) {
                    $scope.availableTags = translateTags(xhr);
                    $scope.searchText = "";

                });
            }

            if (config.role) {
                $scope.tagDef = $rootScope.getTagDefinition('Role');
                knowledgeObjectResource.getTagsByType($scope.tagDef.id).then(function(xhr) {
                    $scope.availableTags = translateTags(xhr);
                    $scope.searchText = "";

                });
            }

            if (config.position) {
                $scope.tagDef = $rootScope.getTagDefinition('Position');
                knowledgeObjectResource.getTagsByType($scope.tagDef.id).then(function(xhr) {
                    $scope.availableTags = translateTags(xhr);
                    $scope.searchText = "";

                });
            }

            var modalInstance = $uibModal.open({
                templateUrl: 'views/main/popups/select-tags.popup.tpl.html?v=' + new Date(),
                controller: SelectTagsPopupCtrl,
                scope: $scope
            });


            // $scope.exactMatch = $scope.tagDef.expanded === false;

        };

        return {
            "open": open,
        };
    }
})();
