/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.knowledge')
        .factory('notePopup', notePopup);

    notePopup.$inject = ['$rootScope', 'attachmentsHelper', 'httpResource', '$uibModal', 'noteResource'];

    function notePopup($rootScope, attachmentsHelper, httpResource, $uibModal, noteResource) {

        NotePopupCtrl.$inject = ['$scope', '$uibModalInstance'];
        function NotePopupCtrl($scope, $uibModalInstance) {
            function refresh() {
                noteResource.getNote($scope.note.id).then(function(note) {
                    $scope.note.knowledgeObject.attachments = note.knowledgeObject.attachments;
                });
            }

            // $scope.isToDo = false;

            // $scope.$watch('item.knowledgeObject.tags',
            //     function() {
            //         if ($scope.note && $scope.note.knowledgeObject && $scope.note.knowledgeObject.tags)
            //             $scope.isToDo = $scope.note.knowledgeObject.tags.indexOf("todo") >= 0;
            //     },
            //     true);

            $scope.options = {
                skin: {
                    type: 'tron',
                    width: 2,
                    color: '#1ab394',
                    spaceWidth: 1
                },
                barColor: '#1ab394',
                trackWidth: 2,
                barWidth: 5,
                textColor: '#1ab394',
                step: 1,
                max: 60,
                size: 50
            };


            attachmentsHelper.initialize($scope, refresh);

            if ($scope.note) {
                noteResource.getNote($scope.note.id).then(function(note) {
                    if ($scope.operation == 'transform-to-todo') {
                        note.knowledgeObject.tags.push('todo');
                        note.knowledgeObject.tags = 
                            note.knowledgeObject.tags
                                .filter((elem, idx) => {
                                    return elem !== 'pin-note';
                                });
                    }

                    $scope.note = note;
                    attachmentsHelper.setUrl($scope, note.knowledgeObject.id);
                });
            } else {
                if ($scope.type) {
                    noteResource.createTypedNote($scope.type).then(function(note) {
                        $scope.note = note;
                        //special tag which indicates type of note (to-do, pin-note)
                        $scope.noteTypeTags = $scope.tags;
                        $scope.note.knowledgeObject.tags = $scope.tags;
                        attachmentsHelper.setUrl($scope, note.knowledgeObject.id);
                    });

                } else {
                    noteResource.createNote().then(function(note) {
                        $scope.note = note;
                        $scope.noteTypeTags = $scope.tags;
                        $scope.addSpecialTags();
                        attachmentsHelper.setUrl($scope, note.knowledgeObject.id);
                    });
                }
            }

            $scope.ok = function() {
                $scope.savingNote = true;

                $scope.addSpecialTags();

                noteResource.save($scope.note).then(function(result) {
                    if ($scope.callback)
                        $scope.callback();
                    $scope.savingNote = false;
                    $uibModalInstance.close();
                });

            };
            $scope.showMoreDescription = false;
            $scope.savingNote = false;

            $scope.switchMoreDescription = function() {
                $scope.showMoreDescription = !$scope.showMoreDescription;
            };

            $scope.cancel = function() {
                $uibModalInstance.dismiss('cancel');
            };

            $scope.addSpecialTags = function() {
                ////should be done more generic
                if ($scope.noteTypeTags && !$scope.note.knowledgeObject.tags.includes($scope.noteTypeTags[0])) {
                    $scope.note.knowledgeObject.tags.push($scope.noteTypeTags[0]);
                }
                if (($scope.note.knowledgeObject.tags.indexOf("todo") === -1) &&
                    ($scope.operation === "transform-to-todo")) {
                        $scope.note.knowledgeObject.tags.push('todo');
                }
                if (($scope.note.knowledgeObject.tags.indexOf("pin-note") === -1) &&
                    ($scope.note.knowledgeObject.tags.indexOf("todo") === -1) &&
                    ($scope.operation !== "transform-to-todo")) {
                        $scope.note.knowledgeObject.tags.push('pin-note');
                }
                ////
            }


        }

        var create = function($scope, tags, callback) {
            $scope.tags = tags;
            open($scope, null, callback);
        };

        var createTyped = function($scope, type, tags, callback) {
            $scope.tags = tags;
            $scope.type = type;
            $scope.callback = callback;

            open($scope);
        };

        var open = function($scope, item, callback, operation) {
            if (item) {
                $scope.note = item;
            }

            $scope.operation = operation;

            $scope.callback = callback;

            let modalConf = {
                    templateUrl: 'views/main/popups/note.popup.tpl.html?v=' + new Date()
            };
            

            var modalInstance = $uibModal.open(
                Object.assign({}, modalConf, {
                    controller: NotePopupCtrl,
                    scope: $scope
                })
            );
        };

        return {
            "open": open,
            "create": create,
            "createTyped": createTyped
        };
    }
})();
