/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.public')
        .controller('SignUpController', SignUpController);

    SignUpController.$inject = ['$scope', '$state', '$stateParams', 'toastr', 'workgroupResource', 'userResource'];

    function SignUpController($scope, $state, $stateParams, toastr, workgroupResource, userResource) {
        $scope.formData = {
            submitButtonLabel: 'Create an account',
            submitInProgress: false,
            account: { }
        };

        $scope.$stateParams = $stateParams;

        userResource.getTimezones().then((response) => {
            $scope.timezones = response;
        });

        $scope.submitForm = function() {
            if ($scope.signUpForm.$valid) {
                submitInProgressOn();

                var accountPromise = workgroupResource.createWorkgroup($scope.formData.account, "");
                accountPromise.finally(submitInProgressOff);
                accountPromise.then(transitionToSignInState, displayErrorMessage);
                accountPromise.then(displayNotification);
            }

            function displayErrorMessage(clientError) {
                $scope.formData.authenticationFailedMessage = clientError.exceptionMessage;
                $scope.signUpForm.$submitted = false;
            }

            function displayNotification(createdAccount) {
                toastr.success('Account for ' +
                    createdAccount.userEmail +
                    ' has been created. Please check your email to confirm registration.');
            }

            function transitionToSignInState() {
                $state.go('public.signIn', {appId: $stateParams.appId, returnUrl: $stateParams.returnUrl, forwardUrl: $stateParams.forwardUrl});
            }

            function submitInProgressOn() {
                $scope.formData.submitButtonLabel = 'Creating an account...';
                $scope.formData.submitInProgress = true;
            }

            function submitInProgressOff() {
                $scope.formData.submitButtonLabel = 'Create an account';
                $scope.formData.submitInProgress = false;
            }

        };
    }

})();
