/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.profile')
        .controller('ProfileCrmController', ProfileCrmController);

    ProfileCrmController.$inject = [
        '$interval', '$timeout', '$rootScope', '$scope', 'salesResource', 'salesPopup', 'salesStagePopup',
        'accessPopup', 'knowledgeObjectResource', 'authenticationResource', 'workgroupResource', 'toastr'
    ];

    function ProfileCrmController($interval,
                                    $timeout,
                                    $rootScope,
                                    $scope,
                                    salesResource,
                                    salesPopup,
                                    salesStagePopup,
                                    accessPopup,
                                    knowledgeObjectResource,
                                    authenticationResource,
                                  workgroupResource,
                                  toastr) {

        $scope.formData = {};
        $rootScope.$context.$set('/profile/templates', $scope);

        //#region LeadSource
        $scope.leadSourceEditMode = false;
        $scope.leadSourceToAdd = "";

        workgroupResource.getLeadSources().then(function (response) {
            $scope.leadSources = response;
        });
        $scope.addLeadSource = function(source){
            var tempLeadSources = [];
            angular.forEach($scope.leadSources, function (it) {
                tempLeadSources.push(it.toLowerCase());
            });
            if(tempLeadSources.indexOf(source.toLowerCase()) === -1)
                $scope.leadSources.push(source);
        };
        $scope.deleteLeadSource = function(leadSourceId){
            $scope.leadSources.splice(leadSourceId, 1);
        };
        $scope.saveLeadSources = function(){
            workgroupResource.updateLeadSources($scope.leadSources).then(function (response) {
                toastr.success("Lead sources have been saved succesfully");
            });
        };
        //#endregion LeadSource

        //#region OpportunityUnit
        $scope.opportunityUnitEditMode = false;
        $scope.opportunityUnitToAdd = "";

        workgroupResource.getOpportunityUnits().then(function (response) {
            $scope.opportunityUnits = response;
        });
        $scope.addOpportunityUnit = function(source){
            var tempOpportunities = [];
            angular.forEach($scope.opportunityUnits, function (it) {
                tempOpportunities.push(it.toLowerCase());
            });
            if(tempOpportunities.indexOf(source.toLowerCase()) === -1)
                $scope.opportunityUnits.push(source);
        };
        $scope.deleteOpportunityUnit = function(opportunityUnitId){
            $scope.opportunityUnits.splice(opportunityUnitId, 1);
        };
        $scope.saveOpportunityUnits = function(){
            workgroupResource.updateOpportunityUnits($scope.opportunityUnits).then(function (response) {
                toastr.success("Opportunity units have been saved succesfully");
            });
        };
        //#endregion OpportunityUnit

        $scope.callback = function() {
            activate();
        };

        $scope.createProcess = function() {
            salesPopup.open($scope, null, $scope.callback);
        };

        $scope.editSalesProcess = function(repeatScope, process){
            salesResource.putProcess(process).then(function (response) {
                    repeatScope.editProcessTitleMode = false;
                toastr.success("Sales Process has been updated");
            })
        };

        $scope.open = function(process, item) {
            //sales stage can be modified only by super user
            if ($rootScope.authentication.user.type <= 0)
                salesStagePopup.open($scope, process.id, item, $scope.callback);
        };

        $scope.createStage = function(item) {
            salesStagePopup.open($scope, item.id, null, $scope.callback);
        };

        $scope.access = function() {
            accessPopup.open($scope, $scope.selectedProcess.knowledgeObject, $scope.addOtherPermissions, true);
        }

        $scope.addPermission = function(member) {
            var dto = {
                user: member,
                permissionType: 2,
                knowledgeObjectId: $scope.koId,
                relatedWithProcess: true
            };

            knowledgeObjectResource.addPermission(dto).then(function(response) {
                $scope.refreshLists(response);
            });
        }


        $scope.permissions = [];

        function activate() {
            if ($rootScope.authentication.user.type <= 0) {
                salesResource.processes().then(function(xhr) {
                    if(xhr !== undefined && xhr !== null && xhr.length > 0) {
                    $scope.formData = { processes: xhr };
                    $scope.process = xhr[0];
                    $scope.selectedProcess = xhr[0];
                    }
                });
            }

        }

        activate();
    }
})();
