/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.workgroup')
        .factory('ui.factory', ui);

    function ui() {
        function Button(label, onClick, enable) {
            var button = {
                label: label,
                onClick: onClick,
                enable: enable
            };
            return button;
        }

        return {
            Button: Button
        }
    }
})();
