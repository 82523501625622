import { all } from "@redux-saga/core/effects";
import { watchTodoListRequest, watchTodoAddRequest, watchTodoUpdateRequest, watchTodoDoneRequest, watchTodoConvertNoteRequest } from "./models/todo";
import { watchWorkgroupMeberListRequest } from "./models/workgroup";

export default function* rootSaga() {
    yield all([
      watchTodoListRequest(),
      watchTodoAddRequest(),
      watchTodoUpdateRequest(),
      watchWorkgroupMeberListRequest(),
      watchTodoDoneRequest(),
      watchTodoConvertNoteRequest() // todo: check how to better compose multiple sagas
    ])
    // code after all-effect
  }


