/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.main.knowledge.meeting')
        .factory('guestParticipantsPopup', guestParticipantsPopup);

    guestParticipantsPopup.$inject = ['$uibModal', 'meetingResource', 'personResource'];

    function guestParticipantsPopup($uibModal, meetingResource, personResource) {

        GuestParticipantsPopupCtrl.$inject = ['$scope', '$uibModalInstance'];
        function GuestParticipantsPopupCtrl($scope, $uibModalInstance) {
            $scope.persons = [];

            personResource.getPersons().then(function(response) {
                $scope.persons = response;
            });

            $scope.addGuest = function(guest) {
                $scope.meeting.externalParticipants.push(guest);
                meetingResource.addExternalParticipant($scope.meeting.id, guest.id);
            }

            $scope.deleteGuest = function(guest) {
                meetingResource.deleteExternalParticipant($scope.meeting.id, guest.id);
            }

            $scope.ok = function() {
                $uibModalInstance.close();
            };

            $scope.cancel = function() {
                $uibModalInstance.dismiss('cancel');
            };
        }

        var open = function($scope, meeting) {
            $scope.meeting = meeting;

            var modalInstance = $uibModal.open({
                templateUrl: 'views/main/popups/guest-participants.popup.tpl.html?v=' + new Date(),
                controller: GuestParticipantsPopupCtrl,
                scope: $scope
            });
        }

        return {
            "open": open,
        };
    }
})();
