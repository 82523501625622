/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.repository.remote')
        .factory('taskResource', remoteTaskResourceFactory);

    remoteTaskResourceFactory.$inject = ['httpResource'];

    function remoteTaskResourceFactory(httpResource) {
        function update(task) {
            return httpResource.put('/task/' + task.id, task);
        }

        function list(date) {
            return httpResource.get('/tasks?date=' + date);
        }

        function monthSummary(yy, mm) {
            return httpResource.get('/tasks/monthSummary?yy=' + yy + '&mm=' + mm);
        }

        function listGoogle() {
            return httpResource.get('/tasks/google');
        }

        function listOffice365() {
            return httpResource.get('/tasks/office365');
        }

        function withDeadline(deadline) {
            return httpResource.get('/tasks?deadline=' + deadline);
        }

        function overtimed() {
            return httpResource.get('/tasks?overtimed=true');
        }

        function get(taskId) {
            return httpResource.get('/task/' + taskId);
        }

        function $delete(taskId) {
            return httpResource.delete('/task/' + taskId);
        }

        function done(taskId) {
            return httpResource.patch('/task/' + taskId + '/done');
        }

        function pending(taskId) {
            return httpResource.patch('/task/' + taskId + '/pending');
        }

        function cancelled(taskId) {
            return httpResource.patch('/task/' + taskId + '/cancelled');
        }

        function overtime(taskId) {
            return httpResource.patch('/task/' + taskId + '/overtimed');
        }

        return {
            "list": list,
            "listGoogle": listGoogle,
            "listOffice365": listOffice365,
            "withDeadline": withDeadline,
            "get": get,
            "delete": $delete,
            "update": update,
            "done": done,
            "pending": pending,
            "cancelled": cancelled,
            "overtimed": overtimed,
            "overtime": overtime,
            "monthSummary": monthSummary
        };
    }

})();
