/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.repository.remote')
        .factory('accountResource', remoteAccountResourceFactory);

    remoteAccountResourceFactory.$inject = ['httpResource'];

    function remoteAccountResourceFactory(httpResource) {

        var SEND_AS_URL_ENCODED_PARAMS = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        };

        // function create(account) {
        //     return httpResource.post('/account', account);
        // }

        function update(account) {
            return httpResource.put('/account/update', account);

        }

        function resetPassword(email) {
            return httpResource.post('/account/resetPassword',
                $.param({ email: email }),
                SEND_AS_URL_ENCODED_PARAMS);
        }

        function changePassword(request) {
            return httpResource.post('/account/changePassword',
                $.param(request),
                SEND_AS_URL_ENCODED_PARAMS);
        }

        function changePasswordFor(requestId) {
            return httpResource.get('/account/changePassword', { params: { requestId: requestId } });
        }

        return {
            // create: create,
            resetPassword: resetPassword,
            changePasswordFor: changePasswordFor,
            changePassword: changePassword,
            update: update
        }
    }

})();
