/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function() {
    'use strict';

    angular.module('meetingMinutes.repository.remote')
        .factory('userResource', remoteUserResourceFactory);

    remoteUserResourceFactory.$inject = ['httpResource'];

    function remoteUserResourceFactory(httpResource) {

        function getProfile() {
            return httpResource.get('/user/profile');
        }

        function getUser(id) {
            return httpResource.get('/user/' + id + '/profile');
        }

        function updateSettings(settings) {
            return httpResource.patch('/user/profile/settings', settings);
        }

        function getUserCosts(id) {
            return httpResource.get('/user/' + id + '/costs');
        }

        function getUsers() {
            return httpResource.get('/workgroup/members');
        }

        function getWorkgroup(id) {
            return httpResource.get('/workgroup/' + id);
        }

        function createUser(dto, guid) {
            return httpResource.post('/user/profile?guid='+guid, dto);
        }

        // function createFullUser(dto) {
        //     return httpResource.post('/user/fullProfile', dto);
        // }

        function saveProfilePicture(coords) {
            return httpResource.post('/user/profile/picture/crop', coords);
        }

        function saveUserPicture(id, coords) {
            return httpResource.post('/user/' + id + '/profile/picture/crop', coords);
        }

        function saveKnowledgeObjectPicture(id, coords) {
            return httpResource.post('/knowledgeObject/' + id + '/profile/picture/crop', coords);
        }


        function saveUserPictureUrl(id, url) {
            return httpResource.get('/user/' + id + '/profile/picture/url?url=' + url);
        }

        function updateUser(id, dto, guid) {
            return httpResource.put('/user/' + id + '/profile?guid=' + guid, dto);
        }

        function updateSignedUser(dto, guid) {
            return httpResource.put('/user/profile?guid=' + guid, dto);
        }

        function setSettings(dto) {
            return httpResource.patch('/settings', dto);
        }

        function getSettings(key) {
            return httpResource.get('/settings/all');
        }

        function getTimezones() {
            return httpResource.get('/settings/timezones');
        }

        function getDesktop(date) {
            return httpResource.get('/user/desktop?date=' + date);
        }

        function getApplicationSettings() {
            return httpResource.get('/settings/application');
        }

        function confirmUser(token, dto) {
            return httpResource.post('/user/confirm?token='+token, dto);
        }

        function getToken(appId) {
            return httpResource.get('/user/token/'+appId);
        }

        function deleteUser(userId, guid) {
            return httpResource.delete('/user/' + userId + '?guid=' + guid);
        }

        return {
            getWorkgroup: getWorkgroup,
            getProfile: getProfile,
            getToken: getToken,
            getUser: getUser,
            getUsers: getUsers,
            createUser: createUser,
            // createFullUser: createFullUser,
            updateUser: updateUser,
            updateSignedUser: updateSignedUser,
            saveProfilePicture: saveProfilePicture,
            saveUserPicture: saveUserPicture,
            saveKnowledgeObjectPicture: saveKnowledgeObjectPicture,
            saveUserPictureUrl: saveUserPictureUrl,
            updateSettings: updateSettings,
            confirmUser: confirmUser,
            //statistics
            getUserCosts: getUserCosts,
            deleteUser: deleteUser,

            //settings
            setSettings: setSettings,
            getSettings: getSettings,
            getApplicationSettings: getApplicationSettings,
            getDesktop: getDesktop,
            getTimezones: getTimezones
        };
    }
})();
