/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = false;

(function () {
    'use strict';
    angular.module('meetingMinutes.main.library')
        .controller('LibraryListController', LibraryListController);

        LibraryListController.$inject = [
        '$rootScope', '$scope', '$state', '$location',
        'knowledgeObjectResource', 'dateHelper', 'dictionaries', 'libraryResource'
    ];

    function LibraryListController(
                                $rootScope,
                                $scope,
                                $state,
                                $location,
                                knowledgeObjectResource,
                                dateHelper,
                                dictionaries,
                                libraryResource) {
        $rootScope.meetingId = null;

        $scope.loading = false;
        
        $scope.nextVisible = false;
        $scope.previousVisible = false;
        
        $scope.currentTypeFilter = null;
        $scope.tags = [];
        $scope.type = $location.$$search.type;

        $scope.rowsPerPage = 20;
        $scope.textFilter = null;
        $scope.currentFrom = 0;
        $scope.currentTo = 20;

        $scope.formData = {
            commonTags: [],
            timelines: [],
            autoTags: []
        };

        libraryResource.getAllTags().then(function(response) {
            $scope.formData.commonTags = response;
        });

        $scope.searchParams = {
            rowsPerPage: 20,
            currentFrom: 0,
            currentTo: 20,
            currentPage: 0,
            text: null,
            type: null,
            tags: []
        };

        $scope.searchResults = [];

        $scope.statuses = [
            {id: 0, name: 'Lost'},
            {id: 1, name: 'Active'},
            {id: 2, name: 'Suspended'},
            {id: 3, name: 'Won'}
        ];

        $scope.typesData = {
            0: {name: 'Note', icon: '/client/images/icons/essentials/notepad.png', url:'/note/view/'},
            1: {name: 'Activity', icon: '/client/images/icons/essentials/time.png', url:'/meeting/view/'},
            2: {name: 'Timeline notice', icon: '/client/images/icons/essentials/clock-1.png', url:'/meeting/timeline/'},
            3: {name: 'Timeline action', icon: '/client/images/icons/essentials/clock-1.png', url:'/meeting/timeline/'},
            4: {name: 'Timeline emotion', icon: '/client/images/icons/essentials/clock-1.png', url:'/meeting/timeline/'},
            5: {name: 'Timeline action item', icon: '/client/images/icons/essentials/clock-1.png', url:'/meeting/timeline/'},
            7: {name: 'Agenda', icon: '/client/images/icons/essentials/placeholder-3.png', url:'/meeting/timeline/'},
            8: {name: 'Lead', icon: null, url:'/person/view/'},
            10: {name: 'Workgroup', icon: '/client/images/icons/essentials/users.png', url:'/workgroup/view/'},
            11: {name: 'User', icon: null, url:'/user/view/'},
            26: {name: 'Opportunity', icon: '/client/images/icons/essentials/push-pin.png', url:'/opportunity/view/'},
            29: {name: 'Todo', icon: '/client/images/icons/essentials/clock-1.png', url:''}
        };

        $scope.getIcon = function(ko) {
            var icon = $scope.typesData[ko.objectType].icon;

            //Handle User Icon
            if (ko.objectType == 11) {
                icon = '/api/user/ko/' + ko.id + '/profile/picture';
            } 

            if (ko.objectType == 8) {
                icon = '/api/knowledgeObject/' + ko.id + '/profile/picture';
            }

            return icon;
        };
        
        $scope.goto = function(ko) {
            libraryResource.getReferenceId(ko.id).then(function (objectId) {
                var url = '/client/index#' + $scope.typesData[ko.objectType].url+objectId;
                window.open(url, 'blank');
            });
        };

        $scope.opportunityTypeToString = function (type) {
            if (type > -1) {
                var names = $scope.statuses.filter(function (el) {
                    return el.id === type;
                });
                if (names.length > 0)
                    return names[0].name;
                return;
            }
        };

        $scope.leadStatusToString = function (status) {
            if (status > -1 && status != undefined && status != null) {
                var statuses = dictionaries.lead().statuses.filter(function (el) {
                    return el.id === status;
                });
                if (statuses)
                    return statuses[0].value;
            }
        };

        $scope.showMore = function (note) {
            note.more = true;
        };

        $scope.showLess = function (note) {
            note.more = undefined;
        };

        $scope.search = function () {
            search();
        };

        $scope.addToContext = function (tag) {
            $rootScope.addToContext(tag);
        };

        $scope.removeFromContext = function (tag) {
            $rootScope.removeFromContext(tag);
        };

        $scope.selectTag = function (tag) {
            $scope.addToContext(tag);
        };

        $scope.countContextTags = function () {
            if ($rootScope.contextTags.tags)
                return $rootScope.contextTags.tags.length;
            else
                return 0;
        };

        $scope.isTag = function (tag, note) {
            if (note.tags) {
                for (var i = 0; i < note.tags.length; i++) {
                    if (note.tags[i] == tag)
                        return true
                }
            }
            return false;
        };

        $scope.previous = function () {
            search($scope.currentFrom - 20);
        };

        $scope.next = function () {
            search($scope.currentFrom + 20);
        };

        $scope.filterByType = function (type) {
            $scope.currentTypeFilter = type;
            $scope.currentTypeFilterDef = knowledgeObjectResource.knowledgeObjectDefinition(type);
            search();
        };

        $scope.focusOnOpportunity = function (item) {
            $rootScope.setOpportunityContext(item);
        };

        $scope.clearFilterByType = function () {
            $scope.currentTypeFilter = null;
            $scope.currentTypeFilterDef = null;
            $rootScope.contextTags.tags = [];
            search();
        };

        function activate() {
            $rootScope.$context.$set('/library', $scope);

            if ($scope.type) {
                $scope.currentTypeFilter = 26;
            }

            $scope.$watch(
                function () {
                    return $rootScope.contextTags;
                },
                function (newValue, oldValue) {
                    if ($scope.loading == false)
                        search();
                },
                true);
        }

        function unique (arr) {
            var a = arr.concat();
            for(var i=0; i<a.length; ++i) {
                for(var j=i+1; j<a.length; ++j) {
                    if(a[i] === a[j])
                        a.splice(j--, 1);
                }
            }
        
            return a;
        }

        function search(skip) {
            if (!skip)
                skip = 0;

            if ($scope.currentFilter != $scope.textFilter) {
                skip = 0;
            }

            var dto = {
                tagFilter: $rootScope.contextTags.tags,
                textFilter: $scope.textFilter,
                typeFilter: $scope.currentTypeFilter,
                take: 20,
                skip: skip,
                metricsEnabled: true
            };

            libraryResource.search(dto.textFilter, dto.tagFilter, dto.typeFilter, dto.skip).then(function (searchResults) {
                $scope.loading = true;                
                $scope.formData.timelines = [];
                var timelineItemsText = [];
                var timelineItemsTags = [];

                for (var i = 0; i < searchResults.items.length; i++) {
                    searchResults.items[i].createTime = dateHelper.enchance(searchResults.items[i].created + 'Z');

                    //Process Additional Data, which displays object specific annotations
                    if (searchResults.items[i].additionalData) {
                        if (searchResults.items[i].additionalData.PlannedStart != null && searchResults.items[i].additionalData.PlannedStart !== '')
                            searchResults.items[i].startTime = dateHelper.enchance(searchResults.items[i].additionalData.PlannedStart);
                        if (searchResults.items[i].additionalData.RealStart != null && searchResults.items[i].additionalData.RealStart !== '')
                            searchResults.items[i].startTime = dateHelper.enchance(searchResults.items[i].additionalData.RealStart);
                        
                        if (searchResults.items[i].objectType === 1 && searchResults.items[i].additionalData.Status === 2)
                        if (searchResults.items[i].additionalData.PlannedEnd != null && searchResults.items[i].additionalData.PlannedEnd !== '')
                            searchResults.items[i].endTime = dateHelper.enchance(searchResults.items[i].additionalData.PlannedEnd);
                        if (searchResults.items[i].additionalData.RealEnd != null && searchResults.items[i].additionalData.RealEnd !== '')
                            searchResults.items[i].endTime = dateHelper.enchance(searchResults.items[i].additionalData.RealEnd);


                        searchResults.items[i].priority =
                            angular.globalSettingsConst.priorities[searchResults.items[i].additionalData.Priority];
                        searchResults.items[i].statusTxt =
                            angular.globalSettingsConst.meetingStatus[searchResults.items[i].additionalData.Status];

                        if (searchResults.items[i].additionalData.Status == 0)
                            searchResults.items[i].priorityClass = 'btn-success';
                        if (searchResults.items[i].additionalData.Status == 1)
                            searchResults.items[i].priorityClass = 'btn-success';
                        if (searchResults.items[i].additionalData.Status == 2)
                            searchResults.items[i].priorityClass = 'btn-default';
                    }

                    //Tags and timelineTags drillDown
                    if (searchResults.items[i].fullTags) {
                        for (var j = 0; j < searchResults.items[i].fullTags.length; j++) {
                            //type = 15 means timeline items like emotions and assignments
                            // __RAFAL: tego nie ma w nowym systemie
                            if ((timelineItemsText.indexOf(searchResults.items[i].fullTags[j].text) === -1) &&
                                (searchResults.items[i].fullTags[j].type === 15)) {
                                timelineItemsText.push((searchResults.items[i].fullTags[j]).text);
                                timelineItemsTags.push($rootScope.converted((searchResults.items[i].fullTags[j].text)));
                            }
                        }
                    }
                }

                $scope.formData.timelines = [...timelineItemsTags];

                $scope.formData.timelines = unique($scope.formData.timelines);
                
                $scope.currentFrom = dto.skip;
                $scope.currentTo = dto.skip + 20;
                if (searchResults.count < 20) {
                    $scope.currentTo = dto.skip + searchResults.count;
                }
                $scope.currentFilter = $scope.textFilter;
                $scope.searchResults = searchResults;

                $scope.previousVisible = $scope.currentFrom > 0;
                $scope.nextVisible = $scope.currentTo < $scope.searchResults.count;
                $scope.loading = false;

                
            });
        }

        $scope.showMeeting = function (id) {
            $state.go('main.meetingDetails', {meetingId: id});
        };

        $scope.metricEnabled = function (metric) {
            switch (metric.type) {
                case 1:
                case 8:
                case 26:
                    return true;
                default:
                    return false;
            }
        };

        // $scope.view = function (note) {
        //     $scope.note = note;

        //     // if (note.objectType == 1) //meeting
        //     // {
        //     //     note.dto.plannedStartTimeTxt = moment(note.dto.plannedStartTime).format('YYYY.MM.DD HH:mm');
        //     // }
        // };

        activate();
    }
})();
